import { IAjaxLoader } from "./IAjaxLoader";

export class AjaxLoader
{
	/**
	 * Defaults
	 */
    private defaults: IAjaxLoader = {
        enabled: true,
        height: '5px',
        position: 'top',
        background: 'black',
        containerClass: null
	};

	/**
	 * Settings
	 */
	private settings: IAjaxLoader;

	/**
	 * Element
	 */
	private elem: JQuery;


    private started: number = 0;

    private completed: number = 0;

    private currentPercent: number = 0;

	/**
	 * Constructor
	 */
	constructor(settings: IAjaxLoader = null) {
		this.settings = jQuery.extend({}, this.defaults, settings);
        if(this.settings.enabled) {
            this.prepareElement();
            this.attachEvents();
        }
	}


	/**
	 * Creates container and place it into body end when loaded
	 */
    private prepareElement()
    {
		this.elem = jQuery('<div></div>');
        this.elem.css({
            'position': 'fixed',
            'z-index': 999999999,
            'width': 0,
            'left': 0,
            'height': this.settings.height,
            'background': this.settings.background
        });

        if(this.settings.position == 'bottom') {
            this.elem.css({'bottom': 0});
        }
        else {
            this.elem.css({'top': 0});
        }

        if(this.settings.containerClass) {
		    this.elem.addClass(this.settings.containerClass);
        }
		jQuery(() => { jQuery('body').append(this.elem); });
	}    

    /**
     * Attach ajax events
     */
    private attachEvents()
    {
        jQuery(document).ajaxSend(this.ajaxSendHandler.bind(this) as any);
        jQuery(document).ajaxComplete(this.ajaxCompletedHandler.bind(this) as any);
        jQuery(document).ajaxStop(this.ajaxAllCompletedHandler.bind(this) as any);
    }

    private computeWidth(force: boolean = false)
    {
        let w = 0;
        if(this.started > 0) {
            w = this.completed == 0 && this.started > 0 ? 
                30 : 
                Math.round((this.completed / this.started) * 100);
        }
        if(w > this.currentPercent || force) {
            this.currentPercent = w;
            this.elem.css({
                transition: w > 0 ? 'all .2s' : 'none',
                width: w.toString() + '%'
            });
        }
    }

    /**
     * Ajax started
     */
    private ajaxSendHandler(event: any, xhr: JQueryXHR, options: any)
    {
        this.started++;
        //console.log('send', this.started, this.completed);
        this.computeWidth();
    }

    /**
     * All ajax requests completed
     */
    private ajaxCompletedHandler(event: any, xhr: JQueryXHR, options: any)
    {
        this.completed++;
        //console.log('cmple', this.started, this.completed);
        this.computeWidth();
    }

    /**
     * All ajax requests completed
     */
    private ajaxAllCompletedHandler(event: any)
    {
        //console.log('all', this.started, this.completed);
        this.started = 0;
        this.completed = 0;
        this.currentPercent = 0;
        this.computeWidth();
        window.setTimeout(() => {
            this.computeWidth(true);
        }, 400);
    }

}