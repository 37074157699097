import {h} from "../../../../tracejs/src/utils/JSXFactory";
import {BaseViewModel} from "../../common/BaseViewModel";
import {injectable} from "tsyringe";
import {KendoHelpers} from "../../../model/KendoHelpers";
import {Objects} from "../../../../tracejs/src/utils/Objects";
import {i18n} from "i18next-ko";
import {BadRequestError} from "../../../../tracejs/src/application/BadRequestError";
import {IGridConfiguratorOptions} from "../../components/GridConfigurator/IGridConfiguratorOptions";
import {GridConfigurator} from "../../components/GridConfigurator/GridConfigurator";
import {BaseDialogViewModel} from "../../common/BaseDialogViewModel";

/**
 * Connect Subject
 */
@injectable()
export class ConnectSubject extends BaseDialogViewModel<any> {

    /**
     * Knockout Observable for userData
     */
    private subjectSubject: KnockoutObservable<any> = ko.observable(ko.mapping.fromJS({
        active: true,
        subjectId: null,
        sourceId: null,
        attr: '',
        autoApprovePrice: false,
    }));

    private subjectsDataSource: kendo.data.DataSourceOptions = {
        serverFiltering: true,
        transport: {
            read: async (options: kendo.data.DataSourceTransportReadOptions) => {

                let srch = options.data.filter && options.data.filter.filters && options.data.filter.filters[0] ? 
                    (options.data.filter.filters[0] as any).value : 
                    '';

                let response: any = await this.rpc.call('subject.get', {
                    query: {
                        search: srch,
                        select: '*,country(iso)',
                        page: 1,
                        pageSize: 100,
                        sort: [{ field: 'name', dir: 'asc' }] 
                    }
                });

                options.success(response.data);
            }
        }        
    };

    // vytvoří spojení vybraného sbujektu s jiným subjektem
    public async save(): Promise<any> {
        let subjectSubject = ko.mapping.toJS(this.subjectSubject);
        subjectSubject.sourceId = this.settings.sourceId;
        await this.rpc.call('subject.connectWithSubject', {subjectSubject: subjectSubject});
        return true;
    }

    public dialogTemplate = (): HTMLElement => (
        <div>
            <ko with="$root.subjectSubject">
                <ko if="$root.settings.sourceId">
                    <div className="row" id="connect-with-new-subject">
                        <div className="col">
                            <fieldset>
                                <div className="row mb-2">
                                    <div class="col-md-100">
                                        <h2><span className="pr-2" data-bind="i18n: 'common.captions.connectWithSubject'"></span></h2>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <label data-bind="i18n: 'common.captions.subject', uniqueFor: 'subjectId'"></label>
                                    </div>
                                    <div className="col-md-7">
                                        <input className="w-100" data-bind="uniqueId: 'subjectId', kendoDropDownList: {
                                            dataSource: $root.subjectsDataSource,
                                            dataTextField: 'name',
                                            dataValueField: 'subjectId',
                                            optionLabel: '-',
                                            filter: 'contains',
                                            value: subjectId,
                                            animation: false
                                        }"/>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <label data-bind="i18n: 'common.captions.role', uniqueFor: 'attr'"></label>
                                    </div>
                                    <div className="col-md-7">
                                        <select className="form-select w-auto d-inline-block" data-bind="uniqueId: 'attr', value: attr">
                                            <option value="carrier" data-bind="i18n: 'common.captions.subjectsSubjectsRole.carrier'"></option>
                                            <option value="partner" data-bind="i18n: 'common.captions.subjectsSubjectsRole.partner'"></option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <label className="form-check-label" data-bind="i18n: 'common.captions.active', uniqueFor: 'active'"></label>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" data-bind="uniqueId: 'active', checked: active"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <label className="form-check-label" data-bind="i18n: 'common.captions.autoApprovePrice', uniqueFor: 'autoApprovePrice'"></label>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" data-bind="uniqueId: 'autoApprovePrice', checked: autoApprovePrice "/>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </ko>
            </ko>
        </div>
    )
}
