import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { Tracedo } from "../../entities/Tracedo";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";

/**
 * Pass transport
 * ATD
 */
@injectable()
export class Pass extends BaseDialogViewModel<any>
{
    /**
     * Tracedo ID
     */
    private id: number;

    /**
     * Tracedo object
     */
    private tracedo: any = null;

    // Codelists
    private currencies: any[] = [];
    private carriers: any[] = [];

    /**
     * DOpravce, kteremu se tracedo preda
     */
    private carrierId: KnockoutObservable<number> = ko.observable(null);

    /**
     * Cena pro noveho dopravce
     */
    private price: KnockoutObservable<number> = ko.observable(null);

    /**
     * Mena ceny pro noveho dopravce
     */
    private currencyId: KnockoutObservable<number> = ko.observable(null);


    /**
     * Event when the transport is passed to a new carrier OR changed to another carrier from previous one
     */
    public onPassed: SyncEvent<{ tracedoId: number, pass: { tracedo: Tracedo, field: string}}> = new SyncEvent<{ tracedoId: number, pass: { tracedo: Tracedo, field: string}}>();



    /**
     * ViewModel Startup
     *
     * @param id Tracedo ID
     */
    public async startup(): Promise<void>
    {
        await super.startup();

        if (!this.user.isAllowed('entity.tracedo', 'pass')) {
            throw new BadRequestError('Access denied', 403);
        }

        let tracedo: Tracedo = await this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId, query: { select: "id,atd" } });
        this.tracedo = tracedo;

        // Pokud jsem spediter => nebude se vynechavat muj subjekt, mohu to vezt sam
        let excludeMe = tracedo.myField === 'spediter' ? false : true;

        let response: any = await this.rpc.batch()
            .call('currencies', 'currency.get', { query: { select: 'currencyId,iso' } })
            .call('carriers', 'subject.getCarriers', { excludeMe: excludeMe, query: { select: 'subjectId,name' } })
            .run();

        this.currencies = response['currencies'];
        this.carriers = response['carriers'];

        // Pokud jiz je predany subjekt zadan, predvyplnime jej i s cenou a umoznime zmenu
        if(this.tracedo.supplierPartnerId) {
            this.carrierId(this.tracedo.supplierPartnerId);
            this.price(this.tracedo.purchasePrice);
            this.currencyId(this.tracedo.purchasePriceCurrencyId);
        }
    }

    /**
     * Ulozit => vytvorit postoupenou prepravu, obousmerne propojeni ...
     */
    public async save(): Promise<{ tracedo: Tracedo, field: string}>
    {
        // Dopravce musi byt pri postoupeni prepravy zadan
        if(!this.carrierId()){
            this.alertDialog(i18n.t('system.alerts.emptyCarrier'));
            return;
        }

        let result: { tracedo: Tracedo, field: string } = await this.rpc.call('tracedo.pass', {
            id: this.tracedo.id,
            carrierId: this.carrierId(),
            price: this.price(),
            currencyId: this.currencyId(),
            query: {
                select: '*,supplierPartner(*)'
            }
        });

        // fire onPAssed event
        this.onPassed.post({ tracedoId: this.settings.tracedoId, pass: result });

        return result;
    }

	public dialogTemplate = (): HTMLElement => (

        <div>
            <ko with="$root.tracedo">

                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'carrierId', i18n: 'common.captions.carrier'"></label>
                    <div className="col-md-10">
                        <input className="w-100" id="carrierId" data-bind="kendoDropDownList: {
                                data: $root.carriers,
                                dataTextField: 'name',
                                dataValueField: 'subjectId',
                                optionLabel: '-',
                                filter: 'contains',
                                value: $root.carrierId,
                                animation: false 
                            }" />
                    </div>
                </div>

                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'price', i18n: 'common.captions.price'"></label>
                    <div className="col-md-10">
                        <div class="row">
                            <div class="col pe-1">
                                <input type="number" data-bind="uniqueId: 'price', value: $root.price" className="form-control" />
                            </div>
                            <div class="col-auto ps-1">
                                <select data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: $root.currencyId" className="form-select"></select>
                            </div>
                        </div>
                    </div>
                </div>	                

            </ko>
        </div>

	);

}
