import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { i18n } from "i18next-ko";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { Edit } from "./Edit";


/**
 * Users
 */
@injectable()
export class Users extends BaseViewModel<any>
{
		
	// GRID element
	private gridElement: JQuery = null;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

	/**
	 * Configure kendo GRID
	 */
	private configureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var toolbarConfig = [];
		if(this.user.isAllowed('entity.user', 'create')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus" ></i><span> ' + i18n.t('common.actions.create') + '</span></button>' });
		}
		if(this.user.isAllowed('entity.user', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="update"><i class="icon-edit" ></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
		}
		if(this.user.isAllowed('entity.user', 'delete')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>' });
		}
		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
						options.data.search = (this.grid.dataSource as any).searchText;
						let users: any = await this.rpc.call('user.getMain', { query: {
							...options.data,
							select: 'userId,ident,loginname,firstName,lastName,email,companyFunction,phone,disabled,userRoles(role(*))'
						}});
						options.success(users);
					}
				},
				schema: {
					model: {
						id: 'userId',
						fields: {
							'userId': { editable: false, type: "number" },
							'ident': { editable: false, type: "string" },
							'email': { editable: false, type: "string" },
							'loginname': { editable: false, type: 'string' },
							'firstName': { editable: false, type: "string" },
							'lastName': { editable: false, type: "string" },
							'companyFunction': { editable: false, type: "string" },
							'phone': { editable: false, type: "string" },
							'disabled': { editable: false, type: "boolean" }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "lastName", dir: "asc" },
				pageSize: 100,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.grid);
				this.gridElement.find('[data-action=delete]').prop('disabled', true);
				this.gridElement.find('[data-action=update]').prop('disabled', true);
			},
			change: (arg: kendo.ui.GridChangeEvent) => {
				var selected = this.grid.select().length > 0;
				this.gridElement.find('[data-action=delete]').prop('disabled', !selected);
				this.gridElement.find('[data-action=update]').prop('disabled', !selected);
			},
			columns: [{
				field: "ident",
				title: i18n.t('system.user.edit.ident'),
				width: 60
			}, { 
				field: "loginname",
				title: i18n.t('system.user.profile.username'),
				width: 180
			}, { 
				field: "lastName",
				title: i18n.t('system.user.profile.lastName'),
				width: 120
			}, { 
				field: "firstName",
				title: i18n.t('system.user.profile.firstName'),
				width: 120
			}, {
				field: "email",
				title: i18n.t('system.user.profile.email'),
				width: 180
			}, {
				field: "companyFunction",
				title: i18n.t('common.captions.companyFunction'),
				width: 120
			// }, {
			// 	field: "disabled",
			// 	title: i18n.t('system.user.edit.disabled'),
			// 	template: '#= disabled ? \'<i class="icon-ok"></i>\': \'-\' #',
			// 	attributes: {
			// 		style: "text-align: center;"
			// 	},
			// 	width: 70
			}, {
				field: "phone",
				title: i18n.t('system.user.profile.phone'),
				width: 80
			}, {
				field: "roles",
				sortable: false,
				filterable: false,
				template: (data: any) => {
					let roles: string[] = [];
					data.userRoles.forEach((ur: any) => {
						roles.push(ur.role.name);
					});
					return roles.join(', ');
				}
			}]
		};
	}

	/**
	 * ViewModel startup
	 */
	public async startup(): Promise<any>
	{
		// Pokud nemuze do teto sekce, nepustime ho
		if(!this.user.isAllowed('section.settingsUsers', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		await super.startup();
		// Configure GRID
		this.configureGrid();
	}

	public async rendered(): Promise<any>
	{
		// grid element
		this.gridElement = this.element.find('[data-grid=users]');
		// initialize grid and store reference
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		// BIND action buttons
		//this.gridElement.on('dblclick', 'tbody tr', this.itemDetailHandler.bind(this));
		this.gridElement.on('click', '[data-action=delete]', this.deleteUser.bind(this));
		this.gridElement.on('click', '[data-action=create]', this.insertHandler.bind(this));
		this.gridElement.on('click', 'button[data-action=update]', this.updateHandler.bind(this));
		this.gridElement.on('dblclick', 'tbody tr', this.updateHandler.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'User.Management',
			exports: [{
				name: 'XLS export',
				method: 'user.xlsExport'
			}]
		} as IGridConfiguratorOptions);
	}

	/**
	 * Opens dialog for user insert
	 */
	public async insertHandler()
	{
		await this.userDialogHandler(null);
	}

	/**
	 * Opens dialog for user update
	 */
	public async updateHandler()
	{
		let user: any = this.grid.dataItem(this.grid.select());
		this.userDialogHandler(user.userId)
	}
	
	/**
	 * Delete user
	 */
	private async deleteUser(): Promise<any>
	{
		let user: any = this.grid.dataItem(this.grid.select());
		let yesNo = await this.confirmDialog(i18n.t('system.question.deleteUser'));
		if(yesNo) {
			await this.rpc.call('user.delete', { userId: user.userId });
			this.grid.dataSource.read();
		}
	}



	/**
	 * Opens dialog for user insert/update
	 */
	public async userDialogHandler(userId: number = null)
	{
		await this.loadViewFrame<Edit>(Edit, 'edit', {
			userId: userId,
			dialog: {
				modal: true,
				width: 940,
				height: 680,
				title: userId ? i18n.t('system.user.edit.editUser') : i18n.t('system.user.edit.addUser'),
				buttons: (vm: Edit, window: kendo.ui.Window) => {
					return [
						{ 
							align: 'right', 
							cls: 'btn-link', 
							label: i18n.t('common.actions.close'),
							click: () => {
								window.close();
							}
						},
						{ 
							align: 'right', 
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: async () => {
								let result = await vm.save();
								if(result) {
									window.close(); 
									this.grid.dataSource.read();
								}
							}
						}
					];
				}
			}
		});

	}

    
	public template = (): HTMLElement => (

		<div>
			
			<h2 data-bind="i18n: 'nav.userManagement'"></h2>

			<view-frame name="gridconf" className="mb-2" />

			<div data-grid="users" className="grid-autoheight"></div>

			<view-frame name="edit" />

		</div>
	);

}