import i18next from 'i18next';

export class KnockoutHelpers
{
	
	public static registerHandlers(): void
	{

/*		// Internationalization Binding
		ko.bindingHandlers.i18n = {
			//translate: (element: HTMLElement, str: string) => {
				//if (typeof (str) === 'string') {
			//        element.innerHTML = i18next.t(str);
				//}
				//else {
				//	jQuery(element).html(jQuery.i18n.translate.apply(jQuery.i18n, str));
				//}
			//},

			init: (element: HTMLElement, valueAccessor, allBindingsAccessor, viewModel, bindingContext) => {
				element.innerHTML = i18next.t(valueAccessor());
			},

			update: (element: HTMLElement, valueAccessor, allBindingsAccessor, viewModel) => {
				element.innerHTML = i18next.t(valueAccessor());
			}
		};
*/
	}
}
/*

// AppendElement Binding
(<any>ko.bindingHandlers).appendElement = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		window.setTimeout(function () { valueAccessor().appendTo(element); }, 0);
	}
};
// uniqueId binding in the ViewModels context
(<any>ko.bindingHandlers).uniqueId = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var rootVm = bindingContext.$root;
		$(element).attr('id', 'vm-' + rootVm.viewModelNumber.toString() + '-' + valueAccessor());
	}
};
// uniqueFor binding in the ViewModels context
(<any>ko.bindingHandlers).uniqueFor = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var rootVm = bindingContext.$root;
		$(element).attr('for', 'vm-' + rootVm.viewModelNumber.toString() + '-' + valueAccessor());
	}
};
// date knockout bindings
(<any>ko.bindingHandlers).date = new function () {
	var setDateTime = function (element, valueAccessor, viewModel) {
		var matches = $(element).attr('data-bind').match(/(?:^|,\s*)date\: ([a-zA-Z0-9_\.\-]+)(?:\s*,|$)/);
		var val = valueAccessor();
		if (typeof (val) === 'function') {
			val = val();
		}
		if (val === null) {
			$(element).html('');
		}
		else {
			var property = matches[1];
			var valDate = moment(val, (<any>moment).ISO_8601);
			if (viewModel[property + 'Time']) {
				var time = typeof (viewModel[property + 'Time']) === 'function' ? viewModel[property + 'Time']() : viewModel[property + 'Time'];
				var valTime = moment(time, (<any>moment).ISO_8601);
				if (moment(valTime).format('HH:mm') == 'Invalid date') {
					$(element).html(moment(valDate).format('DD.MM.YYYY'));
				} else {
					$(element).html(moment(valDate).format('DD.MM.YYYY') + ' ' + moment(valTime).format('HH:mm'));
				}
			}
			else {
				$(element).html(moment(valDate).format('DD.MM.YYYY'));
			}
		}
	};
	this.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		setDateTime(element, valueAccessor, viewModel);
	},
	this.update = function (element, valueAccessor, allBindingsAccessor, viewModel) {
		setDateTime(element, valueAccessor, viewModel);
	}
};
// date knockout bindings
(<any>ko.bindingHandlers).datetime = new function () {
	var setDateTime = function (element, valueAccessor, viewModel) {
		var val = valueAccessor();
		if (typeof (val) === 'function') {
			val = val();
		}
		if (val === null) {
			$(element).html('');
		}
		else {
			var valDate = moment(val, (<any>moment).ISO_8601);
			$(element).html(moment(valDate).format('DD.MM.YYYY HH:mm:ss'));
		}
	};
	this.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		setDateTime(element, valueAccessor, viewModel);
	},
	this.update = function (element, valueAccessor, allBindingsAccessor, viewModel) {
		setDateTime(element, valueAccessor, viewModel);
	}
};
// BOOLean knockout binding
(<any>ko.bindingHandlers).bool = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var val = valueAccessor();
		if (typeof (val) === 'function') {
			val = val();
		}
		$(element).html(val === null ? 'No' : (val ? 'Yes' : 'No'));
	},
	update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
		var val = valueAccessor();
		if (typeof (val) === 'function') {
			val = val();
		}
		$(element).html(val === null ? 'No' : (val ? 'Yes' : 'No'));
	}
};

// FileSize binding handler
(<any>ko.bindingHandlers).filesize = new function() {
	var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB'];

	var setFilesize = function (element, val) {
		if (val === null) {
			$(element).html('');
		}
		else {
			var index = 0;
			while (val > 1024) {
				val /= 1024;
				index++;
			}
			$(element).html((Math.round(val*100)/100).toString() + ' ' + (units[index] ? units[index] : '?'));
		}
	};

	this.init = function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
		var val = valueAccessor();
		if (typeof (val) === 'function') {
			val = val();
		}
		setFilesize(element, parseInt(val));
	};

	this.update = function (element, valueAccessor, allBindingsAccessor, viewModel) {
		var val = valueAccessor();
		if (typeof (val) === 'function') {
			val = val();
		}
		setFilesize(element, parseInt(val));
	};

};
 */