import moment from "moment";
import { inject, injectable } from "tsyringe";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { CodelistManager } from "../../model/CodelistManager";

import { BaseDialogViewModel } from "../common/BaseDialogViewModel";

import { Tracedo } from "../../entities/Tracedo";
import { TruckType } from "../../entities/TruckType";
import { User } from "../../entities/User";

/**
 * Nastaveni ridice k useku
 * 
 * Uzivatel smi nastavit ridice jen pro svuj nebo podrizeny subjekt dopravce
 */
@injectable()
export class SetDriver extends BaseDialogViewModel<any>
{

	private codelistManager: CodelistManager;


	/**
	 * Ja jsem finalnim dopravcem
	 */
	private isFinalMyself: boolean = false;

	/**
	 * Muj dodavatel je finalnim dopravcem
	 */
	private isFinalSupplier: boolean = false;


	/**
	 * Can set driver
	 */
	private canSetDriver: KnockoutObservable<boolean>;



	/**	
	 * List of previously used SPZs
	 */
	private spzList: KnockoutObservableArray<{ spz: string, truckTypeId: number }>;

	/**
	 * Driver users of the fin al carrier subject
	 */
	private drivers: KnockoutObservableArray<User>;
	
	/**
	 * Truck types
	 */
	private truckTypes: KnockoutObservableArray<TruckType>;


	/**
	 * Knockout Observable for tracedo
	 */
	private tracedo: MappedType<Tracedo>;


	/**
	 * Pickup address computed
	 */
	public pickupFull: KnockoutObservable<string>;

	/**
	 * Delivery address computed
	 */
	public deliveryFull: KnockoutObservable<string>;


	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param codelistManager CodelistManager
	 */
	 constructor(@inject(Client) rpc: Client, @inject(CodelistManager) codelistManager: CodelistManager) {
		super(rpc);
		this.codelistManager = codelistManager;
	}	

	/**
	 * ViewModel Startup
	 */
	public async startup(): Promise<void>
	{
		await super.startup();

		let batch = this.rpc.batch();

		batch.call('tracedo', 'tracedo.getOne', {
			id: this.settings.tracedoId,
			query: {
				select: 'id,' +
						'spz,' +
						'driverInfo,' + 
						'truckTypeId,' +
						'myField,' +
						'mySubjectId,' +
						'supplierPartnerId,' +
						'etdFrom,' +
						'etdTo,' +
						'etaFrom,' +
						'etaTo,' +
						'driverUserId,' + 
						'driverUser(userId,firstName,lastName,spz,driverInfo),' +
						'pickupAddressId,' + 
						'pickupAddress(name,countryId,country(iso,descr,nameEn),street,street2,town,zipCode),' + 
						'deliveryAddressId,' + 
						'deliveryAddress(name,countryId,country(iso,descr,nameEn),street,street2,town,zipCode)'
			}
		});
		batch.call('spzList', 'tracedo.getPreviousSpz', { 
			id: this.settings.tracedoId
		});
		batch.call('drivers', 'tracedo.getAvailableDrivers', { 
			id: this.settings.tracedoId, 
			query: {
				select: 'userId,firstName,lastName,spz,driverInfo',
				sort: [{ field: 'lastName', dir: 'asc' }] 
			} 
		});

		// Find final carrier
		// let carrBatch = this.rpc.batch();
		// carrBatch.call('isFinalMyself', 'tracedo.isFinalCarrier', { id: tracedo.id, subjectId: tracedo.mySubjectId });
		// if(tracedo.supplierPartnerId) {
		// 	carrBatch.call('isFinalSupplier', 'tracedo.isFinalCarrier', { id: tracedo.id, subjectId: tracedo.supplierPartnerId });
		// }
		// let carrResp: any = await carrBatch.run();
		// this.isFinalMyself = carrResp['isFinalMyself'] ? true : false;
		// this.isFinalSupplier = carrResp['isFinalSupplier'] ? true : false;

		// if(!this.isFinalMyself && !this.isFinalSupplier) {
		// 	return;
		// }

		// Finalni dopravce
		// const carrierSubjectId = this.isFinalMyself ? tracedo.mySubjectId : tracedo.supplierPartnerId;

		this.canSetDriver = ko.observable(true);

		let response: any = await batch.run();

		let tracedo: Tracedo = response['tracedo'];
		tracedo.etdTo = tracedo.etdTo === null ? null : kendo.parseDate(tracedo.etdTo as string);
		tracedo.etaTo = tracedo.etaTo === null ? null : kendo.parseDate(tracedo.etaTo as string);

		// Je-li jiz nastaven na useku ridic A ZAROVEN pokud tento neni mezi vracenymi ridici, tak ho tam přidá
		let isHere = false;
		response['drivers'].forEach((driver: any) => {
			if(tracedo.driverUserId && tracedo.driverUserId == driver.userId) {
				isHere = true;
			}
		});
		if(tracedo.driverUserId && !isHere) {
			response['drivers'].push(tracedo.driverUser);
		}

		this.spzList = ko.observableArray(response['spzList']);
		this.drivers = ko.observableArray(response['drivers']);
		this.truckTypes = ko.observableArray(this.codelistManager.getTruckTypes());

		this.tracedo = ko.mapping.fromJS(tracedo);
		this.tracedo.driverUserId.subscribe((driverUserId: any) => {
			this.drivers().forEach((driver: User) => {
				if(driver.userId == driverUserId) {
					this.tracedo.spz(driver.spz);
					this.tracedo.driverInfo(driver.driverInfo);
				}
			});
		});

		const t = this.tracedo;
		this.pickupFull = ko.observable(
			t.pickupAddressId() ? 
				t.pickupAddress.name() + '<br />' + 
				t.pickupAddress.street() + '<br />' + 
				(jQuery.trim(t.pickupAddress.street2()) !== '' ? t.pickupAddress.street2() + '<br />' : '') + 
				(t.pickupAddress.countryId() ? ' ' + t.pickupAddress.country.iso() : '') 
			: ''
		);

		this.deliveryFull = ko.observable(
			t.deliveryAddressId() ?
				t.deliveryAddress.name() + '<br />' + 
				t.deliveryAddress.street() + '<br />' +
				(jQuery.trim(t.deliveryAddress.street2()) !== '' ? t.deliveryAddress.street2() + '<br />' : '') + 
				(t.deliveryAddress.countryId() ? ' ' + t.deliveryAddress.country.iso() : '')
			: ''
		);
	}

	/**
	 * Save
	 */
	public async save(): Promise<void>
	{
		// ulozit
		var tracedo = ko.mapping.toJS(this.tracedo);
		//if (tracedo.spz !== null && tracedo.spz !== '' && !tracedo.truckTypeId) {
		//	alert($.i18n.t('system.alerts.fillTruckType'));
		//	return;
		//}
		var data = {
			tracedoId: this.settings.tracedoId,
			driverId: tracedo.driverUserId,
			spz: tracedo.spz,
			truckTypeId: tracedo.truckTypeId,
			driverInfo: tracedo.driverInfo
		};
		await this.rpc.call('tracedo.setDriver', data);
	}

	/**
	 * SPZ selection changed
	 * Pri vyberu SPZ zmenime taky typ nakladaku tomu odpovidajici
	 */
	private spzChanged(element: HTMLElement)
	{
		let combo = jQuery(element).data('kendoComboBox');
		let spzItem = combo.dataItem(combo.select());
		this.tracedo.truckTypeId(spzItem ? spzItem.truckTypeId : null);
	}

	/**
	 * Dialog template
	 */
	public dialogTemplate = (): HTMLElement => (

		<div>
			<fieldset>
				<legend data-bind="i18n: 'trade.demand.edit.information'"></legend>

				<hr />

				<table class="table mb-0">
					<tr>
						<th className="align-top" data-bind="i18n: 'common.captions.etd'"></th>
						<td className="align-top" data-bind="datetime: $root.tracedo.etdFrom"></td>
						
						<th className="align-top" data-bind="i18n: 'common.captions.eta'"></th>
						<td className="align-top" data-bind="datetime: $root.tracedo.etaFrom"></td>
					</tr>
					<tr>
						<th className="align-top" data-bind="i18n: 'trade.demand.edit.pickup'"></th>
						<td className="align-top" data-bind="html: $root.pickupFull"></td>

						<th className="align-top" data-bind="i18n: 'trade.demand.edit.delivery'"></th>
						<td className="align-top" data-bind="html: $root.deliveryFull"></td>
					</tr>
				</table>
			</fieldset>

			<hr />

			<ko ifnot="$root.canSetDriver">
				<div>
					<p data-bind="i18n: 'common.captions.cannotSetDriver"></p>
				</div>
			</ko>

			<ko if="$root.canSetDriver">

				<div className="row mb-2">
					<label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'driverId',i18n: 'common.captions.driver'"></label>
					<div className="col-md-9">
						<input type="text" className="w-100" data-bind="
							uniqueId: 'driverId', 
							kendoDropDownList: {
								value: $root.tracedo.driverUserId,
								dataTextField: 'realName',
								dataValueField: 'userId',
								filter: 'contains',
								autoBind: true,
								minLength: 2,
								data: $root.drivers
							}
						" />
					</div>
				</div>				

				<div className="row mb-2">
					<label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'spz', i18n: 'common.captions.spz'"></label>
					<div className="col-md-9">
						<input type="text" className="w-100" data-bind="
							uniqueId: 'spz',
							kendoComboBox: {
								value: $root.tracedo.spz, 
								dataTextField: 'spz',
								dataValueField: 'spz',
								filter: 'contains',
								autoBind: true,
								minLength: 0,
								data: $root.spzList,
								change: $root.spzChanged.bind($root, $element)
							}
						" />
					</div>
				</div>

				<div class="row mb-2">
					<label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'truckType', i18n: 'common.captions.truckType'"></label>
					<div class="col-md-9">
						<select className="form-select" data-bind="
							uniqueId: 'truckType',
							options: $root.truckTypes,
							optionsValue: 'truckTypeId',
							optionsText: 'name',
							optionsCaption: '-',
							value: $root.tracedo.truckTypeId
						" />
					</div>
				</div>

				<div className="row mb-2">
					<label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'driverInfo', i18n: 'common.captions.driverInfo'"></label>
					<div class="col-md-9">
						<input type="text" className="form-control" data-bind="uniqueId: 'driverInfo', value: $root.tracedo.driverInfo" />
					</div>
				</div>

			</ko>
		</div>

	);

}