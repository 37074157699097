import { injectable } from "tsyringe";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { BaseViewModel } from "../common/BaseViewModel";

/**
 * Culture switch
 */
@injectable()
export class CultureSwitch extends BaseViewModel<any>
{
    public currentCulture: KnockoutObservable<string> = ko.observable(null);

    public async startup()
    {
        await super.startup();
        this.currentCulture(this.culture.locale.toLowerCase());
        this.culture.onLocaleChanged.attach((e) => {
            this.currentCulture(e.locale.toLowerCase());
        });
    }

    public setCulture(culture: string): void
    {
        this.culture.setLocale(culture);
    }

	public template = (): HTMLElement => (
        <ul className="culture-switch">
            <li>
                <a href="#" data-bind="
                    click: () => $root.setCulture('en-US'), 
                    css: { 'active text-decoration-none text-white': $root.currentCulture() === 'en-us' }
                " class="culture culture-en-us">English</a>
            </li>
            <li>
                <a href="#" data-bind="
                    click: () => $root.setCulture('cs-CZ'), 
                    css: { 'active text-decoration-none text-white':  $root.currentCulture() === 'cs-cz' }
                " class="culture culture-cs-cz">Česky</a>
            </li>
        </ul>
    );

}


