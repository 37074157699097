import { h } from "../../../../tracejs/src/utils/JSXFactory";


export class ReadOnlyCustomFields
{
	public static template = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (

		<ko foreach="$root.customFields">

			<div className="row mb-2">

				<label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: $data.name, html: $data.labels[$root.culture.localeShort]"></label>
				<label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} inline col-form-label`}>

					<ko if="$data.type === 'number'">
						<span data-bind="uniqueId: $data.name, text: kendo.toString($root.tracedo.fields[$data.name](), 'n')"></span>
					</ko>
					<ko if="$data.type === 'string'">
						<span data-bind="uniqueId: $data.name, text: $root.tracedo.fields[$data.name]"></span>
					</ko>
					<ko if="$data.type === 'date'">
						<span data-bind="uniqueId: $data.name, text: kendo.toString(kendo.parseDate($root.tracedo.fields[$data.name]()), 'd')"></span>
					</ko>
					<ko if="$data.type === 'datetime'">
						<span data-bind="uniqueId: $data.name, text: kendo.toString(kendo.parseDate($root.tracedo.fields[$data.name]()), 'g')"></span>
					</ko>
					<ko if="$data.type === 'bool'">
						<span data-bind="uniqueId: $data.name, text: $root.tracedo.fields[$data.name] ? i18n.t('common.captions.yes') : i18n.t('common.captions.no')"></span>
					</ko>
					<ko if="$data.type === 'select'">
						<span data-bind="uniqueId: $data.name, text: $root.tracedo.fields[$data.name]"></span>
					</ko>
					
				</label>

			</div>

		</ko>
	);


}