import { i18n } from "i18next-ko";
import moment from "moment";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";


/**
 * Set Discharge - Unload
 */
@injectable()
export class Discharge extends BaseDialogViewModel<any>
{
    /**
     * ATA datum
     */
    private ata: KnockoutObservable<Date>;

    /**
     * Tracedo object
     */
    private tracedo: KnockoutObservable<any> = ko.observable(null);


    /**
     * ViewModel Startup
     *
     * @param id Tracedo ID
     */
    public async startup()
    {
        await super.startup();

        if (!this.user.isAllowed('entity.tracedo', 'approve')) {
            throw new BadRequestError('Access denied', 403);
        }

        let tracedo: any = this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId, query: { select: "id, ata" } });
        this.ata = ko.observable(tracedo.ata ? kendo.parseDate(tracedo.ata) : new Date());
        this.tracedo(ko.mapping.fromJS(tracedo));        
    }

    /**
     * Save ATA
     */
    public async save(): Promise<boolean>
    {
        let ata = this.ata();
        if (ata === null){
            this.alertDialog(i18n.t('system.alerts.emptyAPU'));
            return false;
        }
        let ataString = ata === null ? null : moment(ata).format();
        await this.rpc.call('tracedo.unload', { id: this.settings.tracedoId, time: ataString });
        return true;
    }

	public dialogTemplate = (): HTMLElement => (

        <div>
            <ko with="$root.tracedo">

                <div class="row mb-2">
					<label className="inline col-md-3 col-form-label" data-bind="uniqueFor: 'ata', i18n: 'common.captions.ata'"></label>
					<div class="col-md-9">
                        <input type="text" data-bind="uniqueId: 'ata', kendoDateTimePicker: { animation: false, value: $root.ata }" />
					</div>
				</div>

            </ko>
        </div>
	);    

}