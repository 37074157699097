import { injectable } from "tsyringe";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { IRoute } from "../../../tracejs/src/application/IRoute";

import { BaseViewModel } from "../common/BaseViewModel";
import { Costs } from "./Costs";
import { Revenues } from "./Revenues";
import { Results } from "./Results";

/**
 * Finances 
 */
@injectable()
export class Finances extends BaseViewModel<any>
{

	/**
	 * Setup Routes
	 */
	protected configureRoutes = (): IRoute[] => [
		{ name: 'index', pattern: '/$' },
		{ name: 'costs', pattern: '/costs$' },
		{ name: 'revenues', pattern: '/revenues$' },
		{ name: 'results', pattern: '/results$' }
	];	

	/**
	 * Routed
	 * @param route Route
	 * @param Object params Parameters
	 */
	public async defaultRoute(route: string): Promise<any>
	{
		switch(route) {
			case 'index': 
				return await this.routeTo('costs');
			case 'costs':
				return await this.loadRouteFrame<Costs>(Costs);
			case 'revenues':
				return await this.loadRouteFrame<Revenues>(Revenues);
			case 'results':
				return await this.loadRouteFrame<Results>(Results);
		}
	}

	public template = (): HTMLElement => (

		<div>

			<nav className="navbar navbar-expand-lg navbar-light bg-gray-200 sub-menu mb-3">

				<div class="container-fluid ps-0 pe-0">

					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSettings" aria-controls="navbarSettings" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>

					<div class="collapse navbar-collapse" id="navbarSettings">
						<ul class="navbar-nav me-auto mb-2 mb-lg-0 main-menu">
							<li class="nav-item">
								<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('costs'), css: { 'active': $root.currentRoute() == 'costs' }">
									<i className="icon-money"></i>
									<span data-bind="i18n: 'nav.costs'"></span>
								</a>
							</li>
							<li class="nav-item">
								<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('revenues'), css: { 'active': $root.currentRoute() == 'revenues' }">
									<i className="icon-money"></i>
									<span data-bind="i18n: 'nav.revenues'"></span>
								</a>
							</li>
							<li class="nav-item">
								<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('results'), css: { 'active': $root.currentRoute() == 'results' }">
									<i className="icon-doc-text"></i>
									<span data-bind="i18n: 'nav.transportResults'"></span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<route-frame className="panel" />

		</div>

	);


} 