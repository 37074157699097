import { i18n, t } from "i18next-ko";
import moment from "moment";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { inject, injectable } from "tsyringe";
import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { IDialogSettings } from "../../common/IDialogSettings";
import { Subject } from "../../../entities/Subject";
import { Client } from "../../../../tracejs/src/net/jsonrpc/Client";
import { CodelistManager } from "../../../model/CodelistManager";
import { Fee } from "../../../entities/Fee";
import { TracedoCalculator } from "../../../entities/TracedoCalculator";
import { Exchange } from "../../../entities/Exchange";
import { EditCalculatorHelpers } from "./EditCalculatorHelpers";

export interface EditCalculatorSettings extends IDialogSettings
{
    /** Tracedo ID */
    tracedoId: number;
    /** Typ (cost/revenue) */
    type: string;
    /** Road Type ID */
    roadTypeId: KnockoutObservable<number>;
    /** Kind ID */
    kindId: KnockoutObservable<number>;
    /** Edit or Prefill from this calculator item */
    calculator?: TracedoCalculator;
}

/**
 * Calculator edit
 */
@injectable()
export class EditCalculator extends BaseDialogViewModel<EditCalculatorSettings>
{
    // Byl nacten subjekt k editaci
    private initialSubjectLoaded: boolean = false;

    // Codelist manager
    private codelistManager: CodelistManager;

    // Fees
    private fees: Fee[] = [];
    // Current xxchange rates
    private rates: Exchange[] = [];
    // CZK currency ID
    private czkId: number;

    // Edit Calculator Helpers for Dialog Edit
    private editCalculatorHelpers: EditCalculatorHelpers;

    /**
     * Constructor
     * @param rpc 
     * @param codelistManager 
     */
    constructor(@inject(Client) rpc: Client, @inject(CodelistManager) codelistManager: CodelistManager)
    {
        super(rpc);
        this.codelistManager = codelistManager;
    }

    /**
     * Startup
     */
    public async startup(): Promise<any>
    {
        await super.startup();

        this.editCalculatorHelpers = new EditCalculatorHelpers(this.rpc, this.codelistManager, {
            tracedoId: this.settings.tracedoId,
            kindId: this.settings.kindId,
            roadTypeId: this.settings.roadTypeId
        });
        await this.editCalculatorHelpers.configureFor(this.settings.type, this.settings.calculator);
    }

    
    /**
     * Ulozeni nakladu / vynosu
     */
    public async save(): Promise<boolean>
    {
        let calc = ko.mapping.toJS(this.editCalculatorHelpers.calculator);

        if(!calc.subjectId) {
            this.alertDialog(i18n.t('common.captions.calculatorRequired_subjectId'));
            return false;
        }

        if(!calc.feeId) {
            this.alertDialog(i18n.t('common.captions.calculatorRequired_feeId'));
            return false;
        }

        await this.rpc.call('calculator.save', {
            tracedoId: this.settings.tracedoId,
            calculator: calc
        });

        return true;
    }

    public dialogTemplate = (labelCol: string = 'col-md-4', inputCol: string = 'col-md-8', input1Col: string = 'col-md-5', input2Col: string = 'col-md-3'): HTMLElement => (
        <div data-bind="with: $root.editCalculatorHelpers.calculator">

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'subjectId', i18n: 'common.captions.subject'"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="w-100" data-bind="uniqueId: 'subjectId', kendoDropDownList: {
                        optionLabel: i18n.t('common.captions.selectSubject'),
                        dataTextField: 'name',
                        dataValueField: 'subjectId',
                        filter: 'contains',
                        autoBind: true,
                        minLength: 0,
                        dataSource: $root.editCalculatorHelpers.subjectComboDataSource,
                        value: subjectId,
                        enable: !$data.autogenerated() || $data.unlocked()
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'feeId', i18n: 'common.captions.fee' + ($root.settings.type == 'cost' ? 'Cost' : 'Revenue')"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="w-100" data-bind="uniqueId: 'feeId', kendoDropDownList: {
                        optionLabel: i18n.t('common.captions.selectFee'),
                        dataTextField: 'nameCs',
                        dataValueField: 'id',
                        filter: 'contains',
                        autoBind: true,
                        minLength: 0,
                        dataSource: $root.editCalculatorHelpers.fees,
                        value: feeId,
                        enable: !$data.autogenerated() || $data.unlocked()
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'feeGroupName', i18n: 'common.captions.feeGroup' + ($root.settings.type == 'cost' ? 'Cost' : 'Revenue')"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="form-control w-100" readonly="readonly" data-bind="
                        uniqueId: 'feeGroupName',
                        value: $root.editCalculatorHelpers.feeGroupName
                    " />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'unit', i18n: 'common.captions.unit'"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="form-control w-100" data-bind="
                        uniqueId: 'unit',
                        value: unit,
                        enable: !$data.autogenerated() || $data.unlocked()
                    " />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'amount', i18n: 'common.captions.amount'"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="w-100" data-bind="uniqueId: 'amount', kendoNumericTextBox: {
                        value: amount,
                        enable: !$data.autogenerated() || $data.unlocked()
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'unitPrice', i18n: 'common.captions.unitPrice'"></label>
                <div className={`${input1Col}`}>
                    <input type="text" className="w-100" data-bind="uniqueId: 'unitPrice', kendoNumericTextBox: {
                        value: unitPrice,
                        enable: !$data.autogenerated() || $data.unlocked()
                    }" />
                </div>
                <div className={`${input2Col}`}>
                    <input type="text" className="w-100" data-bind="uniqueId: 'currencyId', kendoDropDownList: {
                        optionLabel: '-',
                        dataTextField: 'iso',
                        dataValueField: 'currencyId',
                        filter: 'contains',
                        autoBind: true,
                        minLength: 0,
                        dataSource: $root.codelistManager.getCurrencies(),
                        value: currencyId,
                        enable: !$data.autogenerated() || $data.unlocked()
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'price', i18n: 'common.captions.totalPrice'"></label>
                <div className={`${input1Col}`}>
                    <input type="text" className="w-100" readonly="readonly" data-bind="uniqueId: 'price', kendoNumericTextBox: { value: price }" />
                </div>
                <div className={`${input2Col}`}>
                    <input type="text" className="form-control w-100" readonly="readonly" data-bind="uniqueId: 'currencyId', value: $root.editCalculatorHelpers.currencyIso" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'invoicedCurrencyId', i18n: 'common.captions.invoicedCurrency'"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="w-100" data-bind="uniqueId: 'invoicedCurrencyId', kendoDropDownList: {
                        optionLabel: '-',
                        dataTextField: 'iso',
                        dataValueField: 'currencyId',
                        filter: 'contains',
                        autoBind: true,
                        minLength: 0,
                        dataSource: $root.codelistManager.getCurrencies(),
                        value: invoicedCurrencyId,
                        enable: !$data.autogenerated() || $data.unlocked()
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'invoicedPrice', i18n: 'common.captions.invoicedPrice'"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="w-100" readonly="readonly" data-bind="uniqueId: 'invoicedPrice', kendoNumericTextBox: { value: invoicedPrice }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'priceCzk', i18n: 'common.captions.priceCzk'"></label>
                <div className={`${inputCol}`}>
                    <input type="text" className="w-100" readonly="readonly" data-bind="uniqueId: 'priceCzk', kendoNumericTextBox: { value: priceCzk }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'description', i18n: 'common.captions.description'"></label>
                <div className={`${inputCol}`}>
                    <textarea className="form-control w-100" data-bind="uniqueId: 'description', value: description" />
                </div>
            </div>

            <ko if="$root.editCalculatorHelpers.rateUsedCzk() !== '' || $root.editCalculatorHelpers.rateUsedInvoiced() !== ''">
                <hr />
                <p className="mb-2"><strong data-bind="i18n: 'common.captions.exchangeUsed'"></strong></p>
                <p className="mb-1" data-bind="html: $root.editCalculatorHelpers.rateUsedCzk"></p>
                <p className="mb-1" data-bind="html: $root.editCalculatorHelpers.rateUsedInvoiced"></p>
            </ko>
            <ko if="$root.editCalculatorHelpers.tradeCurrency != 0 && $root.settings.type === 'revenue'
            && $root.editCalculatorHelpers.calculator.currencyId() && $root.editCalculatorHelpers.calculator.invoicedCurrencyId()
            && $root.editCalculatorHelpers.calculator.currencyId() != $root.editCalculatorHelpers.calculator.invoicedCurrencyId()">
                <p className="mt-2">
                    <strong data-bind="i18n: 'common.captions.tradeCurrencyUsed'"></strong>+
                    <span className="mb-1" data-bind="html: $root.editCalculatorHelpers.tradeCurrency"></span>
                    <span>%</span>
                </p>
            </ko>

            <ko if="$data.autogenerated() && !$data.unlocked()">
                <div className="alert alert-danger" data-bind="i18n: 'common.captions.autogenerated'"></div>
            </ko>

        </div>
    );

}