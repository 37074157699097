import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";

import { i18n } from "i18next-ko";

@injectable()
export class ApiKeys extends BaseViewModel<any>
{
    // Grid Element
    private keysGridEl: JQuery<HTMLElement>;

    // Grid
    private keysGrid: kendo.ui.Grid;

    /**
     * GenerateKey
     */
    private async generateKey()
    {
        let response: string = await this.rpc.call('subject.generateApiKey');
        kendo.alert(i18n.t('common.captions.apiKeyInfo') + '<div class="api-key-preview-container">' + response + '</div>');
        this.refreshGrid();
    }

    private refreshGrid()
    {
        this.keysGrid.dataSource.read();
    }

    /**
     * Setup Keys Grid
     */
    private setupKeysGrid()
    {
        this.keysGridEl = this.element.find('.api-keys-grid');
        this.keysGrid = this.keysGridEl.kendoGrid({
			autoBind: true,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
                        let response = await this.rpc.call('subject.getApiKeys', {
							query: {
								...options.data,
								select: '*,createdUser(firstName,lastName,realName)'
							}
						});
						options.success(response);
					}
				},
				schema: {
					model: {
                        id: 'id',
						fields: {
							'id': { type: "number" },
                            'subjectId': { type: "number" },
                            'createdStamp': { type: "date" },
							'createdUser.realName': { type: "string" },
							'key': { type: "string" },
						}
					}
				},
				sort: [{ field: 'id', dir: 'asc'}],
				serverPaging: true,
			},
			selectable: false,
			scrollable: false,
			reorderable: false,
			columnMenu: false,
			resizable: false,
			filterable: false,
			sortable: false,
			pageable: true,
            columns: [{
                field: 'key',
                title: i18n.t('common.captions.apiKey')
            }, {
                field: 'createdStamp',
                title: i18n.t('common.captions.createdStamp'),
                format: '{0:g}',
                width: 115
            }, {
                field: 'createdUser.realName',
                title: i18n.t('common.captions.createdBy'),
                width: 150
            }, {
                template: (data: any) => '<button type="button" data-action="delete" class="btn btn-danger">' + i18n.t('common.actions.delete') + '</button>',
            }]
        }).data('kendoGrid');
    }



    public async startup()
    {
        await super.startup();
    }

	/**
	 * On render 
	 */	
	public async rendered()
	{
        this.setupKeysGrid();

        this.keysGridEl.on('click', 'button[data-action=delete]', (e: JQuery.ClickEvent) => {
            const uid = jQuery(e.target).closest('tr').data('uid');
            const data = this.keysGrid.dataSource.getByUid(uid);
            kendo.confirm(i18n.t('common.captions.reallyDeleteApiKey')).done( async () => {
                await this.rpc.call('subject.deleteApiKey', { id: data.id });
                this.refreshGrid();
            })
        });
	}

	public template = (): HTMLElement => (

		<div>

            <div className="mb-3">
                <button type="button" class="btn btn-primary" data-bind="
                    i18n: 'common.actions.generate',
                    click: $root.generateKey.bind($root)
                "></button>
            </div>

            <div className="api-keys-grid"></div>

		</div>

	);    

}