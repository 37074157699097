import { injectable } from "tsyringe";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";


import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { IDialogSettings } from "../../common/IDialogSettings";

export interface MergeDuplicitiesSettings extends IDialogSettings
{
	usersToMerge?: [];
}

/**
 * Addresses - edit
 */
@injectable()
export class MergeDuplicities extends BaseDialogViewModel<MergeDuplicitiesSettings>
{
	/**
	 * Knockout Observable for editing users
	 */
	public users = ko.observableArray([]);

    public selectedUser = ko.observable(null);

	/**
	 * ViewModel Startup
	 */
	public async startup()
	{
		await super.startup();

        this.users(this.settings.usersToMerge);
        this.selectedUser(this.users()[0].contactId);
	}

    public async merge(){
        let batch = this.rpc.batch();

        batch.call("contact", "contact.mergeDuplicities", { contactsIds: this.users().map(user => user.contactId), mergeTo: this.selectedUser() });
        let response: any = await batch.run();

        return response;
    }

	public dialogTemplate = (): HTMLElement => (
		<div class="px-1">
            <label data-bind="text: i18n.t('settings.addresses.selectContact')" class="mb-1"></label>
            <div class="row gap-1 user-select-none" data-bind="foreach: users">
                <div data-bind="click: function(user, data){ $root.selectedUser(user.contactId) }, css: { 'border-primary': $root.selectedUser() == $data.contactId }"
                    class="card card-body col-4 py-2 position-relative"
                >
                    <input type="checkbox" class="form-check-input position-absolute" style="pointer-events: none; top: 8px; right: 16px;" data-bind="checked: $root.selectedUser() == $data.contactId" />

                    <h3 data-bind="text: fullName" class="m-0"></h3>
                    <span data-bind="text: email"></span>
                    <span data-bind="text: mobile"></span>
                </div>
            </div>
        </div>
	);
}
