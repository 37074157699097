import { i18n, t } from "i18next-ko";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { IDialogSettings } from "../common/IDialogSettings";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { CodelistManager } from "../../model/CodelistManager";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { Fee } from "../../entities/Fee";
import { Kind } from "../../entities/Kind";
import { RoadType } from "../../entities/RoadType";
import { FeeAssign } from "../../entities/FeeAssign";


export interface EditCustomFeeSettings extends IDialogSettings
{
    /** ID custom poplatku */
    id?: number;
}

/**
 * Editace custom poplatku
 */
@injectable()
export class EditCustomFee extends BaseDialogViewModel<EditCustomFeeSettings>
{
    // Codelist Manager
	protected codelistManager: CodelistManager;

    // Fee
    protected fee: MappedType<Fee>;

    // Kinds
    protected kinds: Kind[];

    // Road Types
    protected roadTypes: RoadType[];

    // Prirazeni Custom Fee k RoadType + Kind
    protected assigns: MappedType<{roadType: RoadType, kinds: { kind: Kind, checked: boolean }[], costs: boolean, revenues: boolean }[]>;

	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param codelistManager Codelist manager
	 */
	constructor(rpc: Client, codelistManager: CodelistManager)
	{
		super(rpc);
		this.codelistManager = codelistManager;

        this.kinds = this.codelistManager.getKinds()
        this.roadTypes = this.codelistManager.getRoadTypes();

        this.assigns = [];
        this.roadTypes.forEach((rt: RoadType) => {
            let kinds: { kind: Kind, checked: boolean }[] = [];
            this.kinds.forEach((kind: Kind) => {
                kinds.push({
                    kind: kind,
                    checked: false
                });
            });
            this.assigns.push(ko.mapping.fromJS({
                roadType: rt,
                kinds: kinds,
                costs: false,
                revenues: false
            }));
        });
	}

    /**
     * Startup
     */
    public async startup(): Promise<any>
    {
        await super.startup();

        if(this.settings.id) {

            let fee: Fee = await this.rpc.call('calculator.getOneFee', {
                id: this.settings.id,
                query: { select: '*,assigns(*)' } 
            });
            this.fee = ko.mapping.fromJS(fee);

            // preset assigns
            fee.assigns.forEach((assign: FeeAssign) => {
                let rtAsssign = this.assigns.find(a => a.roadType.id() == assign.roadTypeId);
                if(rtAsssign) {
                    let kAssign = rtAsssign.kinds().find(k => k.kind.kindId() == assign.kindId);
                    if(kAssign) {
                        kAssign.checked(true);
                    }
                    rtAsssign.costs(assign.costs);
                    rtAsssign.revenues(assign.revenues);
                }
            });

        }
        else {

            this.fee = ko.mapping.fromJS({
                nameCs: '',
                nameEn: '',
                groupId: null
            });

        }
    }

    /**
     * Save Custom Fee
     */
    public async save(): Promise<boolean>
    {
        let fee = ko.mapping.toJS(this.fee);
        let assigns = ko.mapping.toJS(this.assigns);

        let assignments: FeeAssign[] = [];
        for(let a of assigns) {
            for(let k of a.kinds) {
                if(k.checked) {
                    if(!a.costs && !a.revenues) {
                        kendo.alert(i18n.t('common.captions.checkCostsRevenues'))
                        return false;
                    }
                    assignments.push({
                        costs: a.costs,
                        revenues: a.revenues,
                        kindId: k.kind.kindId,
                        roadTypeId: a.roadType.id
                    });
                }
            }
        }

        await this.rpc.call('calculator.saveCustomFee', {
            fee: fee,
            assigns: assignments
        });

        return true;
    }

    public dialogTemplate = (labelCol: string = 'col-md-3', inputCol: string = 'col-md-9'): HTMLElement => (
        <div data-bind="with: $root.fee">

            <fieldset className="mb-3">
                <legend data-bind="i18n: 'common.captions.nameAndGroup'"></legend>

                <div className="row mb-2">
                    <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'nameCs'"><span data-bind=" i18n: 'common.captions.name'"></span> CS</label>
                    <div className={`${inputCol}`}>
                        <input type="text" className="form-control w-100" data-bind="uniqueId: 'nameCs', value: $root.fee.nameCs" />
                    </div>
                </div>

                <div className="row mb-2">
                    <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'nameEn'"><span data-bind=" i18n: 'common.captions.name'"></span> EN</label>
                    <div className={`${inputCol}`}>
                        <input type="text" className="form-control w-100" data-bind="uniqueId: 'nameEn', value: $root.fee.nameEn" />
                    </div>
                </div>

                <div className="row mb-2">
                    <label className={`inline ${labelCol} col-form-label`} data-bind="uniqueFor: 'groupId', i18n: 'common.captions.feeGroup'"></label>
                    <div className={`${inputCol}`}>
                        <input type="text" className="w-100" data-bind="uniqueId: 'groupId', kendoDropDownList: {
                            optionLabel: i18n.t('common.captions.selectFee'),
                            dataTextField: 'name',
                            dataValueField: 'id',
                            filter: 'contains',
                            autoBind: true,
                            minLength: 0,
                            dataSource: $root.codelistManager.getFeeGroups(),
                            value: $root.fee.groupId
                        }" />
                    </div>
                </div>

            </fieldset>

            <fieldset>
                <legend data-bind="i18n: 'common.captions.feeAssign'"></legend>

                <table className="table">
                    <thead>
                        <tr>
                            <th data-bind="i18n: 'common.captions.roadType'"></th>
                            <th data-bind="i18n: 'common.captions.kind'"></th>
                            <th><span data-bind="i18n: 'common.captions.costs'"></span> + <span data-bind="i18n: 'common.captions.revenues'"></span></th>
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: $root.assigns">
                        <tr>
                            <th className="align-baseline"><span data-bind="text: $data.roadType.name"></span></th>
                            <td className="align-baseline">
                                <ul data-bind="foreach: $data.kinds" className="list-unstyled mb-0">
                                    <li><label><input type="checkbox" data-bind="checked: $data.checked" /> <span data-bind="text: $data.kind.name"></span></label></li>
                                </ul>
                            </td>
                            <td className="align-baseline">
                                <ul className="list-unstyled mb-0">
                                    <li><label><input type="checkbox" data-bind="checked: $data.costs" /> <span data-bind="i18n: 'common.captions.costs'"></span></label></li>
                                    <li><label><input type="checkbox" data-bind="checked: $data.revenues" /> <span data-bind="i18n: 'common.captions.revenues'"></span></label></li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </fieldset>

        </div>
    );

}