import { SyncEvent } from 'ts-events';

import { BaseRequest } from './BaseRequest';
import { BatchRequest } from './BatchRequest';
import { Request } from './Request';
import { IClientOptions } from './IClientOptions';

// Client
export class Client
{
	
	public onBeforeSend: SyncEvent<{ request: BaseRequest, xhr: JQuery.jqXHR, settings: JQuery.AjaxSettings }> 
		= new SyncEvent<{ request: BaseRequest, xhr: JQuery.jqXHR, settings: JQuery.AjaxSettings }>();

	public onComplete: SyncEvent<{ request: BaseRequest, xhr: JQuery.jqXHR, textStatus: JQuery.Ajax.TextStatus }> 
		= new SyncEvent<{ request: BaseRequest, xhr: JQuery.jqXHR, textStatus: JQuery.Ajax.TextStatus }>();

	public onSuccess: SyncEvent<{ request: BaseRequest, response: any, textStatus: JQuery.Ajax.SuccessTextStatus, xhr: JQuery.jqXHR<any> }> 
		= new SyncEvent<{ request: BaseRequest, response: any, textStatus: JQuery.Ajax.SuccessTextStatus, xhr: JQuery.jqXHR<any> }>();

	public onConnectionError: SyncEvent<{ request: BaseRequest, xhr: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string }> 
		= new SyncEvent<{ request: BaseRequest, xhr: JQuery.jqXHR, textStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string }>();

	public onError: SyncEvent<{ errorResponse: any, response: any, request: BaseRequest }>
		= new SyncEvent<{ errorResponse: any, response: any, request: BaseRequest }>();


	// defaults
	private defaults: IClientOptions = {
		idPrefix: 'autoid',
		version: 2.0,
		timeout: 5000,
		endPoint: null
	};

	// RPC Client settings
	private _settings: IClientOptions;

	// Key-Value pair of headers
	private _headers: { [ key: string ]: string };

	/**
	 * Get headers Hashmap
	 */
	public get headers() {
		return this._headers;
	}

	/**
	 * Get Client settings
	 */
	public get settings() {
		return this._settings;
	}
	
	// constructor
	constructor(options: IClientOptions) {
		if (!options.endPoint) {
			throw new Error('No end point URL specified in given Client options');
		}
		this._settings = jQuery.extend({}, this.defaults, options);
		this._headers = { };
	}

	// batch request factory
	public batch(): BatchRequest {
		return new BatchRequest(this);
	}

	// call
	public async call<T>(method: string, params: any = {}, id: string = null, options: any = {}): Promise<T> {
		var request = new Request(this, method, params, false, id);
		return await request.run<T>(options);
	}

	// notify
	public async notify<T>(method: string, params: any = {}, options: any = {}): Promise<void> {
		var request = new Request(this, method, params, true);
		return await request.run(options);
	}


	// Send request via client
	public async run<T>(request: BaseRequest, options: any): Promise<T> {
		let payload = request.getPayload();
		let response = await jQuery.ajax({
			url: this._settings.endPoint,
			headers: this._headers,
			contentType: 'application/json',
			type: 'POST',
			// dataType: 'json', // notifications does not return JSON, it wouldn't be successfully parsed by jQuery.ajax method
			cache: false,
			timeout: this._settings.timeout,
			async: options.async === undefined || options.async === true,
			data: payload,
			processData: false,
			xhrFields: {
				withCredentials: true
			},
			beforeSend: (jqXHR: JQuery.jqXHR<any>, settings: JQuery.AjaxSettings) => {
				this.onBeforeSend.post({ request: request, xhr: jqXHR, settings: settings });
			},
			complete: (jqXHR: JQuery.jqXHR<any>, textStatus: JQuery.Ajax.TextStatus) => {
				this.onComplete.post({ request: request, xhr: jqXHR, textStatus: textStatus });
			},
			success: (response: any, textStatus: JQuery.Ajax.SuccessTextStatus, jqXHR: JQuery.jqXHR<any>) => {
				this.onSuccess.post({ request: request, response: response, textStatus: textStatus, xhr: jqXHR });
			},
			error: (jqXHR: JQuery.jqXHR<any>, textStatus: JQuery.Ajax.ErrorTextStatus, errorThrown: string) => {
				this.onConnectionError.post({ request: request, xhr: jqXHR, textStatus: textStatus, errorThrown: errorThrown });
			}
		});

		// no body returned (notification?)
		if(jQuery.trim(response) === '') {
			return null;
		}

		let resp: T = null;
		try {
			resp = request.parseResponse<T>(response);
		}
		catch (errorResponse) {
			// triggers error with:
			//		- errorResponse(always one, first found), 
			//		- response (could be more = batch request) ...
			this.onError.post({ errorResponse: errorResponse, response: response, request: request });
			throw errorResponse;
		}

		return resp;
	}

}
