import { i18n } from "i18next-ko";
import moment from "moment";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { BaseViewModel } from "../../common/BaseViewModel";
import { SyncEvent } from "ts-events";

import { CodelistManager } from "../../../model/CodelistManager";
import { Client } from "../../../../tracejs/src/net/jsonrpc/Client";
import { Country } from "../../../entities/Country";

export interface IAddressContactEditorAddress {
	name?: string,
	street?: string,
	street2?: string,
	city?: string,
	zipCode?: string,
	countryId?: number
}

export interface IAddressContactEditorContact {
	firstName?: string,
	lastName?: string,
	phone?: string,
	email?: string
}

export interface IAddressContactEditorSettings {
	enableAddress?: boolean,
	enableContact?: boolean,
	address?: IAddressContactEditorAddress,
	contact?: IAddressContactEditorContact
}

/**
 * Address + Contact editor
 */
@injectable()
export class AddressContactEditor extends BaseViewModel<IAddressContactEditorSettings>
{

	protected codelistManager: CodelistManager;

	protected countriesArray: KnockoutObservableArray<Country>;

	protected countryNameField: string;

	protected addressColStyle: string = 'min-width: 60px';

	protected contactColStyle: string = 'min-width: 60px';


	protected address: MappedType<IAddressContactEditorAddress>;

	protected contact: MappedType<IAddressContactEditorContact>;


	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param codelistManager Codelist manager
	 */
	constructor(rpc: Client, codelistManager: CodelistManager)
	{
		super(rpc);
		this.codelistManager = codelistManager;
	}

	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();
		if(this.settings.enableAddress === undefined) {
			this.settings.enableAddress = true;
		}
		if(this.settings.enableContact === undefined) {
			this.settings.enableContact = true;
		}
		
		if(this.settings.enableAddress) {
			this.countriesArray = ko.observableArray(this.codelistManager.getCountries());
			this.countryNameField = this.culture.localeShort == 'cs' ? 'descr' : 'nameEn';
			this.address = ko.mapping.fromJS(this.settings.address);
		}

		if(this.settings.enableContact) {
			this.contact = ko.mapping.fromJS(this.settings.contact);
		}
	}

	/**
	 * Is address modified
	 * @returns 
	 */
	public isAddressModified(): boolean
	{
		if(!this.settings.enableAddress) {
			return false;
		}

		return this.settings.address.name.trim() !== this.address.name().trim() ||
			this.settings.address.street.trim() !== this.address.name().trim() ||
			this.settings.address.street2.trim() !== this.address.name().trim() ||
			this.settings.address.city.trim() !== this.address.name().trim() ||
			this.settings.address.zipCode.trim() !== this.address.name().trim() ||
			this.settings.address.countryId != this.address.countryId();
	}

	/**
	 * is contact modified
	 * @returns 
	 */
	public isContactModified(): boolean
	{
		if(!this.settings.enableContact) {
			return false;
		}

		return this.settings.contact.firstName.trim() !== this.contact.firstName().trim() ||
			this.settings.contact.lastName.trim() !== this.contact.lastName().trim() ||
			this.settings.contact.phone.trim() !== this.contact.phone().trim() ||
			this.settings.contact.email.trim() !== this.contact.email().trim();
	}

	/**
	 * Is modified? address or contact or both
	 * @returns 
	 */
	public isModified(): boolean
	{
		return this.isAddressModified() || this.isContactModified();
	}

	/**
	 * Edited data getter
	 */
	public get data(): { address?: IAddressContactEditorAddress, contact?: IAddressContactEditorContact }
	{
		let data: { address?: IAddressContactEditorAddress, contact?: IAddressContactEditorContact } = {};
		if(this.settings.enableAddress) {
			data.address = ko.mapping.toJS(this.address);
		}
		if(this.settings.enableContact) {
			data.contact = ko.mapping.toJS(this.contact);
		}
		return data;		
	}

	/**
	 * Template
	 */
	public template = (): HTMLElement => (
		<div className="row">

			<ko if="settings.enableAddress">

				<div className="col">
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.addressColStyle} data-bind="uniqueFor: 'name', i18n: 'trade.demand.edit.addressName'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'name', value: $root.address.name" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.addressColStyle} data-bind="uniqueFor: 'street', i18n: 'trade.demand.edit.addressStreet'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'street', value: $root.address.street" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.addressColStyle} data-bind="uniqueFor: 'street2', i18n: 'trade.demand.edit.addressStreet2'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'street2', value: $root.address.street2" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.addressColStyle} data-bind="uniqueFor: 'city', i18n: 'trade.demand.edit.addressCity'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'city', value: $root.address.city" />
						</div>
						<label className="col-auto col-form-label" data-bind="uniqueFor: 'zipCode', i18n: 'trade.demand.edit.addressZipCode'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'zipCode', value: $root.address.zipCode" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.addressColStyle} data-bind="uniqueFor: 'countryId', i18n: 'trade.demand.edit.addressCountry'"></label>
						<div className="col">
							<input type="text" className="w-100" data-bind="uniqueId: 'countryId', kendoDropDownList: {
									value: $root.address.countryId,
									filter: 'contains',
									dataSource: $root.countriesArray,
									optionLabel: i18n.t('common.captions.dropDownSelectValue'),
									dataTextField: $root.countryNameField,
									dataValueField: 'countryId'
								}" />
						</div>
					</div>
				</div>

			</ko>
			<ko if="settings.enableContact">

				<div className="col">

					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.contactColStyle} data-bind="uniqueFor: 'firstName', i18n: 'registration.firstName'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'firstName', value: $root.contact.firstName" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.contactColStyle} data-bind="uniqueFor: 'lastName', i18n: 'registration.lastName'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'lastName', value: $root.contact.lastName" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.contactColStyle} data-bind="uniqueFor: 'phone', i18n: 'registration.phone'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'phone', value: $root.contact.phone" />
						</div>
					</div>
					<div className="row mb-1">
						<label className="col-auto col-form-label" style={this.contactColStyle} data-bind="uniqueFor: 'email', i18n: 'registration.email'"></label>
						<div className="col">
							<input type="text" className="form-control" data-bind="uniqueId: 'email', value: $root.contact.email" />
						</div>
					</div>

				</div>

			</ko>
        </div>
    );

}