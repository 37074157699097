import { i18n } from "i18next-ko";
import moment from "moment";
import { inject, singleton } from "tsyringe";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";

@singleton()
export class CustomFieldsHelper {

	/**
	 * RPC Client
	 */
	private rpc: Client;


	public customFields: Array<any>;

	public customFieldsSelect: string;

	/**
	 * Constructor
	 * @param rpc RPC
	 */
	constructor(@inject(Client) rpc: Client) {
		this.rpc = rpc;
	}

	/**
	 * Reads and prepares custom fields for select + grid
	 */
	public async loadCustomFields(): Promise<void>
	{
		this.customFields = await this.rpc.call('fields.getMy');

		let fields: string[] = [];
		this.customFields.forEach((f) => {
			fields.push('fields_' + f.name);
		});

		this.customFieldsSelect = fields.join(',');
	}

	/**
	 * Returns custom fields for select (for API) glued from start with the "glue"
	 * If no custom fields are defined, returns empty string without glue
	 * @param glue 
	 */
	public getCustomFieldsSelect(glue: string = ','): string
	{
		return (jQuery.trim(this.customFieldsSelect) !== '' ? glue + this.customFieldsSelect : '');
	}


	/**
	 * Appends custom fields to given columns collection
	 * 
	 * @param kendoGridColumns
	 * @param langIdent
	 */
	public appendColumnsTo(kendoGridColumns: kendo.ui.GridColumn[], langIdent: string): void
	{
		this.customFields.forEach((cf) => {

			var columnDef: kendo.ui.GridColumn = {
				field: 'fields_' + cf.name, // ->>
				title: cf.labels[langIdent],
				template: (data: any) => {
					var value = data['fields_' + cf.name] !== undefined ? data['fields_' + cf.name] : null;
					if (value === null) {
						return '';
					}
					var settings = cf.settings ? cf.settings : {};
					switch (cf.type) {
						case 'date':
							return kendo.toString(moment(value).toDate(), i18n.t('common.formats.kendoDate'));
						case 'datetime':
							return kendo.toString(moment(value).toDate(), i18n.t('common.formats.kendoDateTime'));
						case 'number':
							return kendo.toString(value, 'n' + settings.decimals.toString());
						case 'bool':
							return value ? i18n.t('common.captions.yes') : i18n.t('common.captions.no');
						case 'string':
							return settings.multiline ? value.replace(/\n/ig, '<br>') : value;
						case 'select':
							for (var i in settings.options) {
								if (settings.options[i].value == value) {
									return settings.options[i].text;
								}
							}
						default:
							return value;
					}
				},
				sortable: true,
				width: 70
			};

			// For selects add values list
			if (cf.type === 'select') {
				columnDef.filterable = {
					ui: (el: any) => {
						el.kendoDropDownList({
							dataSource: cf.settings.options,
							optionLabel: "--Select Value--",
							dataTextField: "text",
							dataValueField: "value"
						});
					}
				};
			}

			kendoGridColumns.push(columnDef);
		});
	}

	/**
	 * Append fields to schema - model - fields definition
	 * @param kendoSchemaModelFields
	 */
	public appendSchemaFieldsTo(kendoSchemaModelFields: kendo.data.DataSourceSchemaModelFields): void
	{
		this.customFields.forEach((cf) => {
			kendoSchemaModelFields['fields_' + cf.name] = {
				type: cf.type === 'datetime' ? 'date' : (
					cf.type === 'select' ? 'string' : (
						cf.type === 'bool' ? 'boolean' : cf.type
					)
				)
			}
		});
	}

	/**
	 * Vrati pole custom fieldu s typem date / datetime
	 */
	public getDateFields(prefix: string = ''): {[key:string]:string}
	{
		let fields: {[key:string]:string} = {};
		this.customFields.forEach((cf) => {
			if(cf.type == 'datetime' || cf.type == 'date') {
				fields[prefix + cf.name] = cf.type;
			}
		});
		return fields;
	}

}
