import { Client } from './Client';

export class BaseRequest {

	// Client reference
	protected client: Client;

	// Constructor
	constructor(client: Client) {
		this.client = client;
	}

	// Returns Request payload
	public getPayload(): string {
		throw new Error('To be overriden in subclasses');
	}

	// parse response returned from the server
	public parseResponse<T>(response: any): T {
		throw new Error('To be overriden in subclasses');
	}

	// Run Request
	public async run<T>(options: any = {}): Promise<T> {
		return this.client.run<T>(this, options);
	}
}