import { i18n } from "i18next-ko";
import moment from "moment";
import { inject, instanceCachingFactory } from "tsyringe";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { User } from "../../../tracejs/src/security/User";
import { BdpContainerType } from "../../entities/BdpContainerType";
import { Checkpoint } from "../../entities/Checkpoint";
import { CheckpointType } from "../../entities/CheckpointType";
import { ColliType } from "../../entities/ColliType";
import { Country } from "../../entities/Country";
import { Currency } from "../../entities/Currency";
import { Depot } from "../../entities/Depot";
import { InvoicingStatus } from "../../entities/InvoicingStatus";
import { Kind } from "../../entities/Kind";
import { PackingGroup } from "../../entities/PackingGroup";
import { PassStatus } from "../../entities/PassStatus";
import { PoItem } from "../../entities/PoItem";
import { PoItemConnection } from "../../entities/PoItemConnection";
import { RoadType } from "../../entities/RoadType";
import { Season } from "../../entities/Season";
import { Stackable } from "../../entities/Stackable";
import { Status } from "../../entities/Status";
import { Tracedo } from "../../entities/Tracedo";
import { TruckType } from "../../entities/TruckType";
import { CodelistManager } from "../../model/CodelistManager";

import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { EditCheckpoint } from "./EditCheckpoint";
import {AddressbookRamp} from "../../entities/AddressbookRamp";
import { AddressContactEditor } from "../components/AddressContactEditor/AddressContactEditor";
import { AddressContactAddress, AddressContactContact } from "../../entities/AddressContact";

/**
 * Tracedo edit + Order create predecessor
 */
export abstract class AbstractEdit extends BaseDialogViewModel<any>
{
    protected codelistManager: CodelistManager;

	// Main subject ID + Main Subject
	protected mainSubjectId: number;
	protected mainSubject: any;

	// is ordering ? TRUE = objednavka u dodavatele, FALSE = zakladani spediterem
	protected isOrdering: boolean = false;

	/** Does current user act as a spediter or as a carrier or as a customer on this tracedo? */
	protected isCarrier: boolean = false;
	protected isCustomer: boolean = false;
	protected isSpediter: boolean = false;
    // Resource name for ACL
	protected resourceName: string = null;

    // Can pass
    protected canPass: boolean = false;

    // Autoincrement for PO Items when Creating (to be able to connect it to origin/destionation/checkpoints)
    protected createPosIndexAutoincrement: number = 1;

	// codelists, seasons, custom fields ...
	protected suppliers: KnockoutObservableArray<any> = ko.observableArray();
	protected customers: KnockoutObservableArray<any> = ko.observableArray();
	protected carriers: KnockoutObservableArray<any> = ko.observableArray();
	protected mainUsers: KnockoutObservableArray<User> = ko.observableArray();
	protected colliTypes: KnockoutObservableArray<ColliType> = ko.observableArray();
	protected currencies: KnockoutObservableArray<Currency> = ko.observableArray();
	protected truckTypes: KnockoutObservableArray<TruckType> = ko.observableArray();
	protected bdpContainerTypes: KnockoutObservableArray<BdpContainerType> = ko.observableArray();
	protected stackables: KnockoutObservableArray<Stackable> = ko.observableArray();
	protected transportKinds: KnockoutObservableArray<Kind> = ko.observableArray();
	protected roadTypes: KnockoutObservableArray<RoadType> = ko.observableArray();
	protected roadTypesHashByIdent: { [key: string]: RoadType };
	protected statuses: KnockoutObservableArray<Status> = ko.observableArray();
	protected statusesHash: { [key: number]: Status };
	protected passStatuses: KnockoutObservableArray<PassStatus> = ko.observableArray();
	protected passStatusesHash: { [key: number]: PassStatus };
	protected invoicingStatuses: KnockoutObservableArray<InvoicingStatus> = ko.observableArray();
	protected invoicingStatusesHash: { [key: number]: InvoicingStatus };
	protected depots: KnockoutObservableArray<Depot> = ko.observableArray();
	protected packingGroups: KnockoutObservableArray<PackingGroup> = ko.observableArray();
	protected countries: KnockoutObservableArray<Country> = ko.observableArray();
	protected countriesHash: { [key: number]: string };
	protected checkpointTypes: KnockoutObservableArray<CheckpointType> = ko.observableArray();
	protected checkpointTypesHash: { [key: number]: CheckpointType };
	protected customFields: KnockoutObservableArray<any> = ko.observableArray([]);
	protected customFieldsDefaults: { [key: string]: any } = {};
	protected seasons: KnockoutObservableArray<Season> = ko.observableArray();
    
    protected currentSeason: any = null;

	/** tracedo object */
	protected tracedo: MappedType<Tracedo> = ko.mapping.fromJS({
        id: null,
        adr: false,
        ata: null,
        ataReady: null,
        atd: null,
        atdReady: null,
        bdpCntrTypeId: null,
        cargoDescription: null,
        clientRef: null,
        clientRef2: null,
        clientRef3: null,
        cntrNr: null,
		consigneeName: null,
        createdTimestamp: null,
        createdUserId: null,
        custom: null,
        customOffice: null,
        declarant: null,
        deleted: false,
        deliveryContactId: null,
        depotId: null,
        destAddressCity: null,
        destAddressCountryId: null,
        destAddressName: null,
        destAddressStreet: null,
        destAddressStreet2: null,
        destAddressZipCode: null,
        destinationContactFirstName: null,
        destinationContactLastName: null,
        destinationContactPhone: null,
        destinationContactEmail: null,
        destinationNote: null,
        distributionCalcStatusId: null,
        driverInfo: null,
        driverUserId: null,
        etaFrom: null,
        etaTo: null,
        etdFrom: null,
        etdTo: null,
        handedOverToPerson: null,
        kindId: null,
        ldm: null,
        loadingReference: null,
        modeId: null,
        
        onPreCarriageExist: false,
        originAddressCity: null,
        originAddressCountryId: null,
        originAddressName: null,
        originAddressStreet: null,
        originAddressStreet2: null,
        originAddressZipCode: null,
        originContactFirstName: null,
        originContactLastName: null,
        originContactPhone: null,
        originContactEmail: null,
        originNote: null,
        pickupContactId: null,
        rejdar: null,
        roadTypeId: null,
        sealNr: null,
		shipperName: null,
        spz: null,
        token: null,
        trcStatusId: null,
        truckTypeId: null,
        unloadingReference: null,
        vessel: null,
        extId: null,

        rampId: null,
        driverNote: null,
        adrClass: null,
        packingGroup: null,
        un: null,
        chargeableVolume: null,
        kgs: null,
        cbm: null,
        colli: null,

        sumKgs: null,
        sumCbm: null,
        sumColli: null,

        rdlFrom: null,
        rdlTo: null,
        ecrFrom: null,
        ecrTo: null,
        mbl: null,
        voyageNr: null,
        commodity: null,
        commodityValue: null,
        
        metAtb: null,
        metBookingNr: null,
        metPin: null,
        
        currencyId: null,
        pickupAddressId: null,
        deliveryAddressId: null,

        // Subjects
        customerPartnerId: null,
		customerPartnerPicUserId: null,

        myField: null,
        mySubjectId: null,
		myPicUserId: null,
        myinvoicingStatusId: null,
        mySeasonId: null,
        myUzp: null,

        supplierPartnerId: null,
        supplierPartnerUzp: null,
        supplierPartnerPicUserId: null,

        sellPrice: null,
        sellPriceCurrencyId: null,
        purchasePrice: null,
        purchasePriceCurrencyId: null,

        // Custom fields
        fields: {},

		// Checkpoints
		checkpoints: [],

        // Origin + Destination colli, kgs, colliTypeId
        originColli: null,
        originKgs: null,
        originColliTypeId: null,
        destinationColli: null,
        destinationKgs: null,
        destinationColliTypeId: null,

        destinationRamp: {
            id: null,
            name: null,
        },
        originRamp: {
            id: null,
            name: null,
        },
        destinationRampId : null,
        destinationRampName : null,
        originRampId : null,
        originRampName : null,

        carriersOriginData: null,
        carriersDestinationData: null,

        cancelRequest: null
	});

    // selected PO Items for origin
    protected originPoItems: KnockoutObservableArray<number> = ko.observableArray([]);

    // selected PO Items for destination
    protected destinationPoItems: KnockoutObservableArray<number> = ko.observableArray([]);

	// PO Items for this tracedo - used only when creating new tracedo!
	protected poItems: KnockoutObservableArray<MappedType<PoItem>> = ko.observableArray([]);



	/**
	 * Constructor
	 * 
	 * @param rpc RPC Client
	 * @param codelistManager CodelistManager
	 */
    constructor(@inject(Client) rpc: Client, @inject(CodelistManager) codelistManager: CodelistManager) {
        super(rpc);
        this.codelistManager = codelistManager;
	}


    /**
     * Set initial PO Items connection to given tracedo's checkpoints and otigin/destination collection
     * @param tracedo 
     * @param connections 
     */
    protected setPoItemsConnections(tracedo: Tracedo, connections: PoItemConnection[])
    {
        // HashMap checkpointId => poItemId[]
        let checkpointPoItems: {[key: number]: number[]} = {};

        this.originPoItems.removeAll();
        this.destinationPoItems.removeAll();
        
        connections.forEach((conn: PoItemConnection) => {
            // Checkpoint ID
            if(conn.checkpointId) {
                if(!checkpointPoItems[conn.checkpointId]) {
                    checkpointPoItems[conn.checkpointId] = [];
                }
                checkpointPoItems[conn.checkpointId].push(conn.poItemId);
            }
            // Origin
            else if(conn.origin) {
                this.originPoItems.push(conn.poItemId);
            }
            else if(conn.destination) {
                this.destinationPoItems.push(conn.poItemId);
            }
        });

        // Fill PO Items for checkpoints
        if(tracedo.checkpoints) {
            tracedo.checkpoints.forEach((cp: Checkpoint) => {
                if(checkpointPoItems[cp.id]) {
                    cp.poItems = checkpointPoItems[cp.id];
                }
                else {
                    cp.poItems = [];
                }
            });
        }
    }


	/**
	 * Create new checkpoint
	 * @param name Nazev checkpointu
	 * @param typeId ID typu typoveho checkpointu (null = obecny, netypovy checkpoint)
	 * @return Added Checkpoint
	 */
    protected addCheckpoint(name: string = null, typeId: number = null): KnockoutObservableType<Checkpoint>
    {
        let checkpoint: MappedType<Checkpoint> = ko.mapping.fromJS({
            typeId: typeId,
            addressName: '',
            addressStreet: '',
            addressStreet2: '',
            addressTown: '',
            addressZipCode: '',
            addressCountryId: null,
            addressbookId: null,
            ata: null,
            atd: null,
            contactEmail: '',
            contactFirstName: '',
            contactId: null,
            contactLastName: '',
            contactPhone: '',
            eta: null,
            etd: null,
            name: name,
            note: '',
            reference: '',
            // Colli / Po Items for checkpoint
            kgs: null,
            colliTypeId: null,
            colli: null,
            poItems: [],
            ramp: {"id": null, "name": null},
            rampId: null,
            rampName: null
        });
        this.tracedo.checkpoints.push(checkpoint);
        return checkpoint;
    }
 
    /**
     * Create new empty checkpoint
     */
    protected addEmptyCheckpoint(): void
    {
        this.addCheckpoint(i18n.t('common.captions.newCheckpoint'), null);
    }

    protected async editOriginCheckpoint(): Promise<any>
    {
        let t = this.tracedo;
        await this.editCheckpoint({
            typeId: null,
            addressName: t.originAddressName,
            addressStreet: t.originAddressStreet,
            addressStreet2: t.originAddressStreet2,
            addressTown: t.originAddressCity,
            addressZipCode: t.originAddressZipCode,
            addressCountryId: t.originAddressCountryId,
            addressbookId: t.pickupAddressId,
            atd: t.atd,
            contactEmail: t.originContactEmail,
            contactFirstName: t.originContactFirstName,
            contactId: t.pickupContactId,
            contactLastName: t.originContactLastName,
            contactPhone: t.originContactPhone,
            etdFrom: t.etdFrom,
            etdTo: t.etdTo,
            atdReady: t.atdReady,
            name: ko.observable('Origin'),
            note: t.originNote,
            reference: t.loadingReference,
            colli: t.originColli,
            kgs: t.originKgs,
            colliTypeId: t.originColliTypeId,
            poItems: this.originPoItems,
            ramp: t.originRamp,
            rampId: t.originRampId,
            rampName: t.originRampName,
        }, 'origin');
    }
 
    protected async editDestinationCheckpoint(): Promise<any>
    {
        let t = this.tracedo;
        await this.editCheckpoint({
            typeId: null,
            addressName: t.destAddressName,
            addressStreet: t.destAddressStreet,
            addressStreet2: t.destAddressStreet2,
            addressTown: t.destAddressCity,
            addressZipCode: t.destAddressZipCode,
            addressCountryId: t.destAddressCountryId,
            addressbookId: t.deliveryAddressId,
            ata: t.ata,
            contactEmail: t.destinationContactEmail,
            contactFirstName: t.destinationContactFirstName,
            contactId: t.deliveryContactId,
            contactLastName: t.destinationContactLastName,
            contactPhone: t.destinationContactPhone,
            etaFrom: t.etaFrom,
            etaTo: t.etaTo,
            ataReady: t.ataReady,
            name: ko.observable('Destination'),
            note: t.destinationNote,
            reference: t.unloadingReference,
            colli: t.destinationColli,
            kgs: t.destinationKgs,
            colliTypeId: t.destinationColliTypeId,
            poItems: this.destinationPoItems,
            ramp: t.destinationRamp,
            rampId: t.destinationRampId,
            rampName: t.destinationRampName,
        }, 'destination');
    }

    protected async editStandardCheckpoint(checkpoint: MappedType<Checkpoint>): Promise<any>
    {
        await this.editCheckpoint(checkpoint, 'checkpoint');
    }

    /**
     * Editace predaneho mapovaneho checkpointu
     * @param checkpoint 
     */
    protected async editCheckpoint(checkpoint: MappedType<Checkpoint>, editType: string): Promise<any>
    {
        let editTypeUpperCase = editType.toLocaleUpperCase().substring(0, 1) + editType.substring(1);
        await this.loadViewFrame<EditCheckpoint>(EditCheckpoint, 'edit-checkpoint', {
            checkpoint: checkpoint,
            countries: this.countries(),
            checkpointTypes: this.checkpointTypes(),
            tracedoId: this.tracedo.id(),
            editType: editType,
            poItems: this.tracedo.id() ? null : this.poItems,
            dialog: {
                width: 645, // 760,
                height: 700, // 710,
                modal: true,
                title: i18n.t('common.captions.edit' + editTypeUpperCase),
                buttons: (editVm: EditCheckpoint, window: kendo.ui.Window) => {
                    return [{
                        align: 'right',
                        cls: 'btn-primary',
                        label: i18n.t('common.captions.ok'),
                        click: () => {
                            // Pokud se data v editaci checkpointu zvaliduji (vse je vyplneno spravne), zavreme okno
                            if(editVm.validate()) {
                                //console.log('saved', ko.mapping.toJS(this.tracedo));
                                window.close();
                            }
                        }
                    }];
                }
            }
        });
    }

    /**
     * Odebrat checkpoint
     * @param checkpoint 
     */
    protected async deleteCheckpoint(checkpoint: Checkpoint)
    {
        let yesNo = await this.confirmDialog(i18n.t('common.captions.removeCheckpoint'));
        if(yesNo) {
            this.tracedo.checkpoints.remove(checkpoint);
        }
    }    


	/**
	 * Nacteni ciselniku pro formular
	 */
    protected async readCodelists()
    {
        let batch = this.rpc.batch();
        batch.call('currentSeason', 'season.getCurrent', {});
        // Kazdy zucastneny subjekt si muze nastavit svou zodpovednou osobu
        batch.call('mainUsers', 'user.getMain', { query: { 
            select: 'userId,firstName,lastName',
            sort: [{ field: 'lastName', dir: 'asc' }] 
        }});
        // jako spediter si nactu sve zakazniky
        batch.call('customers', 'subject.getCustomers', { query: {
            select: 'subjectId,name',
            sort: [{ field: 'name', dir: 'asc' }]
        }});
        // jako spediter si nactu sve prepravce
        batch.call('carriers', 'subject.getCarriers', { query: {
            select: 'subjectId,name',
            sort: [{ field: 'name', dir: 'asc' }]
        }});
        // Pokud objednavame, potrebujeme ciselnik dodavatelu (zprostredkovatelu)
        // jako zakaznik, co objednava si nactu sve dodavatele
        batch.call('suppliers', 'subject.getSuppliers');
        // custom pole
        batch.call('customFields', 'fields.getMy');

        // run batch and fill codelists
        let response: any = await batch.run();
        this.mainUsers(response['mainUsers']);

        // set codelists
        this.customers(response['customers']);
        this.carriers(response['carriers']);
        this.suppliers(response['suppliers']);

        this.stackables(this.codelistManager.getStackables());
        this.packingGroups(this.codelistManager.getPackingGroups());

        // countries translated names + hashmap
        this.countries(this.codelistManager.getCountries());
        this.countriesHash = {};
        this.countries().forEach((country: Country) => {
            country.nameTranslated = this.culture.localeShort == 'cs' ? country.descr : country.nameEn
            this.countriesHash[country.countryId] = country.iso;
        });

        // typy checkpointu
        this.checkpointTypes(this.codelistManager.getCheckpointTypes());
        this.checkpointTypesHash = {};
        this.checkpointTypes().forEach((type: CheckpointType) => {
            type.nameTranslated = this.culture.localeShort == 'cs' ? type.nameCs : type.nameEn
            this.checkpointTypesHash[type.id] = type;
        });

        // Road types hashmap
        this.roadTypes(this.codelistManager.getRoadTypes());
        this.roadTypesHashByIdent = {};
        this.roadTypes().forEach((roadType: RoadType) => {
            this.roadTypesHashByIdent[roadType.ident] = roadType;
        });

        // TRC statuses hash
        this.statuses(this.codelistManager.getStatuses());
        this.statusesHash = {};
        this.statuses().forEach((status: Status) => {
            this.statusesHash[status.id] = status;
        });

        // Pass statuses hash
        this.passStatuses(this.codelistManager.getPassStatuses());
        this.passStatusesHash = {};
        this.passStatuses().forEach((status: PassStatus) => {
            status.nameTranslated = this.culture.localeShort == 'cs' ? status.nameCs : status.nameEn;
            this.passStatusesHash[status.statusId] = status;
        });

        // TRC statuses hash
        this.invoicingStatuses(this.codelistManager.getInvoicingStatuses());
        this.invoicingStatusesHash = {};
        this.invoicingStatuses().forEach((status: InvoicingStatus) => {
            this.invoicingStatusesHash[status.statusId] = status;
        });
        
        this.transportKinds(this.codelistManager.getKinds());
        this.colliTypes(this.codelistManager.getColliTypes());
        this.currencies(this.codelistManager.getCurrencies());
        this.truckTypes(this.codelistManager.getTruckTypes());
        this.bdpContainerTypes(this.codelistManager.getBdpCntrTypes());
        this.depots(this.codelistManager.getDepots());
		this.seasons(this.codelistManager.getSeasons());    // 2 posledni sezony

		this.currentSeason = response['currentSeason'];

        // Custom Fields + defaults
        this.customFields.removeAll();
        this.customFieldsDefaults = {};
        response['customFields'].forEach((field: any) => {
            this.customFields.push(field);
            this.customFieldsDefaults[field.name] = null;
        });
    }


    /**
     * Common startup for createorder and create/edit tracedo
     */
    public async startup()
    {
        await super.startup();

    }

    /**
     * Common render
     */
    public async rendered()
    {
        await super.rendered();
    }





	/**
	 * Secist rozmery/poctu kusu/vahu... volano pri zmene colli, kgs ... v EditablePoItems.tsx (PO Items na tracedo)
	 */
    protected recountPoItems()
    {
        if(this.poItems().length > 0) {
            var totalColli: number = 0;
            var totalCbm: number = 0;
            var totalKgs: number = 0;
            this.poItems().forEach((poItem: MappedType<PoItem>) => {
                if (poItem.colli() == null || (poItem.colli() as any) == '' || poItem.colli() < 1) {
                    poItem.colli(1);
                }
                totalColli += (poItem.colli() * 1);
                totalCbm += poItem.colli() * (poItem.width() / 100) * (poItem.length() / 100) * (poItem.height() / 100);
                totalKgs += poItem.colli() * poItem.weight();
            });
            this.tracedo.sumColli(totalColli);
            this.tracedo.sumKgs(totalKgs);
            this.tracedo.sumCbm(totalCbm);
        }
    }

	/**
	 * Add PO item to items observable list
	 */
    protected addPoItem()
    {
        // Auto Pos Index (and ID) for creating
        const autoId = this.createPosIndexAutoincrement;
        this.createPosIndexAutoincrement++;

        let poItem: MappedType<PoItem> = ko.mapping.fromJS({
            id: autoId,
            createPosIndex: autoId,
            descr: null,
            pn1: null,
            pn2: null,
            pn3: null,
            ecrTimestamp: null,
            colliTypeId: this.colliTypes().filter((colliType: any) => colliType.ident == 'CRT')[0].colliTypeId, // CRATE as default
            stackable: 'NO',
            colli: 1,
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
        })
        this.poItems.push(poItem);
    }
  
    /**
     * Remove PO item from items observable list
     */
    protected removePoItem(poItem: any)
    {
        this.poItems.remove(poItem);
    }


    /**
     * Naformatuje date/datetime pole do spravneho formatu pro API (2020-01-01T01:23:45+0100)
     * @param tracedo 
     */
    protected formatDateFieldsForSave(tracedo: any)
    {
		tracedo.etdFrom = tracedo.etdFrom === null || tracedo.etdFrom == '' ? null : moment(tracedo.etdFrom).format();
		tracedo.etdTo = tracedo.etdTo === null || tracedo.etdTo == '' ? moment(tracedo.etdFrom).format() : moment(tracedo.etdTo).format();
		tracedo.etaFrom = tracedo.etaFrom === null || tracedo.etaFrom == '' ? null : moment(tracedo.etaFrom).format();
		tracedo.etaTo = tracedo.etaTo === null || tracedo.etaTo == '' ? moment(tracedo.etaFrom).format() : moment(tracedo.etaTo).format();
		tracedo.atd = tracedo.atd === null || tracedo.atd == '' ? null : moment(tracedo.atd).format();
		tracedo.atdReady = tracedo.atdReady === null || tracedo.atdReady == '' ? null : moment(tracedo.atdReady).format();
		tracedo.ata = tracedo.ata === null || tracedo.ata == '' ? null : moment(tracedo.ata).format();
		tracedo.ataReady = tracedo.ataReady === null || tracedo.ataReady == '' ? null : moment(tracedo.ataReady).format();

		tracedo.myUzp = tracedo.myUzp === null || tracedo.myUzp == '' ? null : moment(tracedo.myUzp).format();
		tracedo.supplierPartinerUzp = tracedo.supplierPartinerUzp === null || tracedo.supplierPartinerUzp == '' ? null : moment(tracedo.supplierPartinerUzp).format();

		tracedo.checkpoints.forEach((checkpoint: any) => {
			checkpoint.eta = checkpoint.eta === null || checkpoint.eta == '' ? null : moment(checkpoint.eta).format();
			checkpoint.etd = checkpoint.etd === null || checkpoint.etd == '' ? null : moment(checkpoint.etd).format();
			checkpoint.ata = checkpoint.ata === null || checkpoint.ata == '' ? null : moment(checkpoint.ata).format();
			checkpoint.atd = checkpoint.atd === null || checkpoint.atd == '' ? null : moment(checkpoint.atd).format();
		});

		tracedo.ecrFrom = tracedo.ecrFrom === null || tracedo.ecrFrom == '' ? null : moment(tracedo.ecrFrom).format();
		tracedo.ecrTo = tracedo.ecrTo === null || tracedo.ecrTo == '' ? null : moment(tracedo.ecrTo).format();
		tracedo.rdlFrom = tracedo.rdlFrom === null || tracedo.rdlFrom == '' ? null : moment(tracedo.rdlFrom).format();
		tracedo.rdlTo = tracedo.rdlTo === null || tracedo.rdlTo == '' ? null : moment(tracedo.rdlTo).format();

		// proper format of dates & datetimes in custom fields
		jQuery.each(tracedo.fields, (f, v) => {
			if (typeof (v) === 'object' && v instanceof Date) {
				tracedo.fields[f] = moment(v).format();
			}
		});        
    }


    public buildCarriersAddress(address: KnockoutObservableType<AddressContactAddress>): string
    {
        return address.name() + "<br />\n" +
            address.street() + "<br />\n" + 
            (address.street2() ? address.street2() + "<br />\n" : '') + 
            address.city() + ' ' + address.zipCode() + "<br />\n" +
            this.countriesHash[address.countryId()];
    }

    public buildCarriersContact(contact: KnockoutObservableType<AddressContactContact>): string
    {
        return i18n.t('common.captions.person') + ': ' + contact.firstName() + " " + contact.lastName() + "<br />\n" +
            i18n.t('common.captions.phone') + ': ' + contact.phone() + "<br />\n" +
            i18n.t('common.captions.email') + ': ' + contact.email();
    }    

}