import { inject, injectable } from "tsyringe";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { Objects } from "../../../tracejs/src/utils/Objects";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { CustomFieldsHelper } from "../../model/CustomFieldsHelper";

import { BaseViewModel } from "../common/BaseViewModel";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../model/KendoHelpers";
import { GridConfigurator } from "../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../components/GridConfigurator/IGridConfiguratorOptions";
import { CodelistManager } from "../../model/CodelistManager";
import { TracedoHelpers } from "./TracedoHelpers";
import { TracedoManager } from "../../model/TracedoManager";
import { TypedCheckpointsHelper } from "../../model/TypedCheckpointsHelper";
import moment from "moment";

/**
 * Orders - vypis objednavek
 */
@injectable()
export class Orders extends BaseViewModel<any>
{

	// GRID element
	private gridElement: JQuery<HTMLElement> = null;
	//private gridVm: any = null;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

	// filtrace dle export/import
	private kindId: number = null;

	// Transport kinds
	private transportKinds: Array<{ kindId: number, name: string }> = [];


	// // pro moznost manipulaci s nadrazenimy akcemi
	private gridConfiguratorViewModel: GridConfigurator;


	// custom fields helper
	protected customFieldsHelper: CustomFieldsHelper;

	// typed checkpoints helper
	protected typedCheckpointsHelper: TypedCheckpointsHelper;

	// Codelist manager
	protected codelistManager: CodelistManager;

	// Tracedo manager
	protected tracedoManager: TracedoManager;


	// Main subject ID prihlaseneho uzivatele
	protected mainSubjectId: number;

	// Max passed carriers count
	protected maxCarriers: number;

	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param culture Culture
	 */
	constructor(
			@inject(Client) rpc: Client, 
			@inject(CustomFieldsHelper) customFieldsHelper: CustomFieldsHelper,
			@inject(TypedCheckpointsHelper) typedCheckpointsHelper: TypedCheckpointsHelper,
			@inject(CodelistManager) codelistManager: CodelistManager,
			@inject(TracedoManager) tracedoManager: TracedoManager
		) {
		super(rpc);
		this.customFieldsHelper = customFieldsHelper;
		this.typedCheckpointsHelper = typedCheckpointsHelper;
		this.codelistManager = codelistManager;
		this.tracedoManager = tracedoManager;
	}	

	/**
	 * Metoda konfigurujici parametry GRIDu
	 */
	private configureGrid(): void
	{
		// pripravit toolbar
		let toolbarConfig = TracedoHelpers.tracedoGridCreateToolbar({
			// 'createOrder': true, - zatim vypnuto - p. Lejček 2022-03-15
			'detail': true,
			'edit': true,
			'duplicateOrder': true,
			'purchasePriceDetail': true,
			'generateTransportOrder': true
		}, this.user, null, this.transportKinds);

		// pripravit definici sloupcu s filtrama
		let gridColumnsDef = TracedoHelpers.tracedoGridDefineColumns(this.user, this.codelistManager, true);
		// pridat custom fields
		this.customFieldsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShort);
		// pridat typed checkpoints
		this.typedCheckpointsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShortCapitalized);

		// Kendo grid Schema model fields
		let schemaModelFields = TracedoHelpers.tracedoGridDefineSchema();
		// pridat do neho custom fields
	 	this.customFieldsHelper.appendSchemaFieldsTo(schemaModelFields);
		// pridat do nebo typed checkpoints fields
		this.typedCheckpointsHelper.appendSchemaFieldsTo(schemaModelFields);

		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

						options.data.search = (this.grid.dataSource as any).searchText;

						// filtrovat prepravy dle import export
						options.data = KendoHelpers.filterByKindId(options.data, this.kindId, 'kindId');

						let dateFields = jQuery.extend({},
							TracedoHelpers.tracedoGridDateColumns(),
							this.typedCheckpointsHelper.getDateFields(),
							this.customFieldsHelper.getDateFields('fields_')
						);
						TracedoHelpers.compatibilizeRequestData(options.data, '__', dateFields);

						let response: any = await this.rpc.call('tracedo.getOrderView', {
							query: {
								...options.data,
								select: TracedoHelpers.getSelect() + 
										this.customFieldsHelper.getCustomFieldsSelect() +
										this.typedCheckpointsHelper.getCheckpointTypesSelect()
							}
						});

						response.data = TracedoHelpers.processResponseData(response.data);
						response.data = KendoHelpers.tracedoSetRowColor(response.data);

						//TracedoHelpers.compatibilizeResponseData(response.data);

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: schemaModelFields
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "etdFrom", dir: "desc" },
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			columns: gridColumnsDef,
			detailInit: (e: kendo.ui.GridDetailInitEvent) => { TracedoHelpers.tracedoGridDetailInit(this.rpc, e, this.codelistManager); },
			dataBound: (e: kendo.ui.GridDataBoundEvent) => { TracedoHelpers.tracedoGridDataBound(this.grid, this.gridElement, e); },
			change: (e: kendo.ui.GridChangeEvent) => { TracedoHelpers.tracedoGridSelectionChanged(this.grid, this.gridElement, this.user, this.codelistManager); }
		};
	}

	/**
	 * Reread grid
	 */
	public reload()
	{
		this.grid.dataSource.read();
	}

	// Startup
	public async startup(): Promise<void>
    {
	 	await super.startup();

	 	// Pokud nemuze do Objednavek, tak ho vyhodime
	 	if (!this.user.isAllowed('section.orders', 'enter')) {
	 		throw new BadRequestError('Access denied', 403);
	 	}
		 
		// Read Typed Checkpoints
		await this.typedCheckpointsHelper.loadCheckpointTypes();
	 	// Read custom fields
	 	await this.customFieldsHelper.loadCustomFields();

		// Read codelists
		let batch = this.rpc.batch();
		batch.call('mainSubject', 'subject.getMain');
		batch.call('maxCarriers', 'tracedo.getMaxCarrier');
		let batchResult: any = await batch.run();
		this.transportKinds = this.codelistManager.getKinds();
		this.mainSubjectId = batchResult['mainSubject'].subjectId;
		this.maxCarriers = batchResult['maxCarriers'];

		// Configure GRID
		this.configureGrid();
	}

	/**
	 * Component was rendered
	 */
	public async rendered(): Promise<void>
	{
		// grid element
		this.gridElement = this.element.find('div[data-grid=orders]');
		// initialize grid and save reference
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		TracedoHelpers.tracedoGridBindActions(this, this.grid, this.gridElement, this.user, this.tracedoManager);

		// Rychlý filtr pro IMPORT/EXPORT - nastavit kindId promennou a obnovit grid
		this.gridElement.on('change', '[data-action=selectKind]', (event: JQuery.ChangeEvent) => {
			let val = jQuery(event.currentTarget).val();
			this.kindId = val !== '-' ? parseInt(val as string, 10) : null;
			this.grid.dataSource.read();
		});

		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Order.Orders',
			exports: [{
				name: 'XLS export',
				method: 'tracedo.xlsExport'
			}]
		} as IGridConfiguratorOptions);

		this.gridConfiguratorViewModel = vm;
	}





	

	public template = (): HTMLElement => (

		<div>
			<h1 data-bind="i18n: 'nav.orders'"></h1>

			<div className="panel">
				<view-frame name="gridconf" className="mb-2" />

				<div data-grid="orders" className="grid-autoheight"></div>

				<view-frame name="actionDialog" />
			</div>
		</div>
	);
}