import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import moment from "moment";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { KendoHelpers } from "../../model/KendoHelpers";
import { Objects } from "../../../tracejs/src/utils/Objects";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { GridConfigurator } from "../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../components/GridConfigurator/IGridConfiguratorOptions";
import { PoEdit } from "./PoEdit";


/**
 * PO Management - Tracedo TAB
 */
@injectable()
export class PoTracedo extends BaseViewModel<any> {

	/**
	 * PO items grid element
	 */
	private poGridElement: JQuery = null;

	/**
	 * PO Management Grid Options
	 */
	private poGridConfig: kendo.ui.GridOptions;

	/**
	 * PO items kendo grid
	 */
	private poGrid: kendo.ui.Grid;

	/**
	 * Loaded tracedo on startup
	 */
	private tracedo: any;


	/**
	 * Potrebuje refresh
	 */
	public onRefreshDetail: SyncEvent<void> = new SyncEvent<void>();


	/**
	 * Konfigurace gridu
	 */
	private configureGrid(): void
	{
		// Toolbar Config
		let toolbarConfig = undefined;
		if(!this.settings.readOnly) {
			toolbarConfig = [];
			if(this.user.isAllowed('entity.tracedo', 'edit')) {
				toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus"></i><span> ' + i18n.t('common.actions.create') + '</span></button>' });
				toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-secondary" data-action="edit" title="' + i18n.t('common.actions.edit') + '"><i class="icon-search"></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
				toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.delete') + '"><i class="icon-trash"></i></button>' });
			}
		}

		this.poGridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportReadOptions) => {

						(options.data as any).search = (this.poGrid.dataSource as any).searchText;

						KendoHelpers.replaceRequestData(options.data, '__');

						let response: any = await this.rpc.call('poItem.getByTracedoId', {
								tracedoId: this.settings.tracedoId,
								query: {
									...options.data,
									select: 'id,createdTimestamp,pn1,pn2,pn3,colli,pcs,descr,length,width,height,weight,stackable,oogcntr,packaging,colliTypeId,colliType(*),subjectId,subject(*),' +
											'cargoValueCurrId,cargoValueCurr(*),cargoValue,contactPersonEmail,ecrTimestamp,hsCode,metOriginCountryId,metOriginCountry(*)'
								}
							});

						response.data.forEach((poItem: any) => {
							poItem.length = poItem.length ? poItem.length * 100 : null;
							poItem.width = poItem.width ? poItem.width * 100 : null;
							poItem.height = poItem.height ? poItem.height * 100 : null;
						});
						response.data = Objects.flatten(response.data, '__');
						options.success(response);
					}
				},
				schema: {
					model: {
						id: 'id',
						fields: {
							'id': { editable: false, type: "number" },
							'createdTimestamp': { editable: false, type: 'date' },
							// 'poItemId': { editable: true, type: "number" },
							'pn1': { editable: true, type: 'string' },
							'pn2': { editable: true, type: "string" },
							'pn3': { editable: true, type: "string" },
							'colli': { editable: true, type: "string" },
							'pcs': { editable: true, type: "string" },
							'descr': { editable: true, type: "string" },
							'length': { editable: true, type: "number" },
							'weight': { editable: true, type: "number" },
							'width': { editable: true, type: "number" },
							'height': { editable: true, type: "number" },
							'stackable': { editable: true, type: "string" },
							'oogcntr': { editable: true, type: "string" },
							'packaging': { editable: true, type: "string" },
							'colliTypeId': { editable: true, type: "string" },
							'colliType__name': { editable: true, type: "string" },
							'subject__name': { editable: true, type: "string" },
							// 'countOrder': { editable: true, type: "string" },

							// 'orderItemId': { editable: false, type: 'number' },
						// 'orderItem__truckNr': { editable: false, type: "string" },
						// 'orderItem__cntrNr': { editable: false, type: "string" },
						// 'orderItem__metPin': { editable: false, type: "string" },
						// 'orderItem__metAtb': { editable: false, type: "string" },
							// 'orderId': { editable: false, type: "number" },
							// 'order__number': { editable: false, type: "string" },
							// 'order__modeId': { editable: false, type: "number" },

							'cargoValueCurrId': { editable: false, type: 'string' },
							'cargoValueCurr__iso': { editable: false, type: 'string' },
							'cargoValue': { editable: false, type: 'number' },
							'contactPersonEmail': { editable: true, type: "string" },
							'ecrTimestamp': { type: "date" },
							'hsCode': { editable: true, type: "string" }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "id", dir: "desc" },
				pageSize: 10,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			dataBound: this.onDataBound.bind(this),
			toolbar: toolbarConfig,
			change: this.onChange.bind(this),
			columns: [
				//{ field: "orderItemId", title: i18n.t('common.captions.orderItemVaz'), width: 80, template: "#= orderItem__truckNr ? orderItem__truckNr : (orderItem__cntrNr ? orderItem__cntrNr : orderItemId) #" },
				//{ field: "orderItem__metPin", title: i18n.t('common.captions.metPin'), width: 80, template: "#= orderItem__metPin ? orderItem__metPin : '' #" },
				//{ field: "orderItem__metAtb", title: i18n.t('common.captions.metAtb'), width: 80, template: "#= orderItem__metAtb ? orderItem__metAtb : '' #" },
				{ field: 'createdTimestamp', title: i18n.t('common.captions.createdTimestamp'), format: i18n.t('common.formats.dateTime'), width: 110 },
				{ field: "pn1", title: "PN 1", width: 120, template: "# if(pn1 != null){# #=pn1.substr(0,75)# #}# " }, // <div class=\"po-item#= haveChildren ? '-has-children' : '-no-children' #\"></div>
				{ field: "pn2", title: "PN 2", width: 120, template: "# if(pn2 != null){# #=pn2.substr(0,75)# #}# " },
				{ field: "pn3", title: "PN 3", width: 120, template: "# if(pn3 != null){# #=pn3.substr(0,75)# #}# " },
				{ field: "cargoValue", width: 100, title: i18n.t('common.captions.cargoValue'), template: "#= cargoValue ? ( cargoValueCurrId ? Math.round(cargoValue) + ' ' + cargoValueCurr__iso : Math.round(cargoValue)) : '' #" },
				{ field: "colli", title: "Colli", width: 50, format: "{0:n0}" },
				{ field: "pcs", title: "Pcs", width: 50, format: "{0:n0}" },
				{ field: "length", title: i18n.t('edit.poItem.length'), width: 80, format: "{0:n0}" },
				{ field: "width", title: i18n.t('edit.poItem.width'), width: 80, format: "{0:n0}" },
				{ field: "height", title: i18n.t('edit.poItem.height'), width: 80, format: "{0:n0}" },
				{ field: "weight", title: i18n.t('edit.poItem.weight'), width: 80, format: "{0:n3}" },
				{ field: "colliTypeId", title: i18n.t('edit.poItem.packaging'), width: 80, template: "#= colliTypeId ? colliType__name : '' #" },
				{ field: "stackable", title: i18n.t('edit.poItem.stackable'), width: 60, template: "#=stackable#" },
				{ field: "descr", title: i18n.t('edit.poItem.descr'), width: 160, template: "#= descr != null ? descr.substr(0,75) : '' #" },
				{ field: "hsCode", title: "Hs code", width: 80 },
				{ field: "ecrTimestamp", title: i18n.t('common.captions.ecr'), format: i18n.t('common.formats.date'), width: 100 },
				{ field: "metOriginCountry__descr", title: i18n.t('edit.poItem.metOriginCountry'), width: 130, template: '#= metOriginCountryId ? metOriginCountry__' + i18n.t('edit.poItem.metOriginCountryName') + ' : "" #' },
				//{ field: "contactPersonEmail", title: i18n.t('edit.poItem.contactEmail'), width: 150 }
			]
		};
	}

	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		// Nacteme Traceco
		this.tracedo = await this.rpc.call('tracedo.getOne', {
			id: this.settings.tracedoId,
			query: {
				select: 'id,createdUserId,trcStatusId,trcStatus(*),poItems(*)'
			}
		});

		this.configureGrid();
	}

	/**
	 * Rendered
	 */
	public async rendered()
	{
		/// initialize grid
		this.poGridElement = this.element.find('[data-grid=po-items]');
		this.poGridElement.kendoGrid(this.poGridConfig);
		
		if(!this.settings.readOnly) {
			// BIND action buttons	
			this.poGridElement.on('click', '[data-action=create]', this.poCreateHandler.bind(this));
			this.poGridElement.on('click', '[data-action=edit]', this.poEditHandler.bind(this));
			this.poGridElement.on('dblclick', 'tbody tr', this.poEditHandler.bind(this));
			this.poGridElement.on('click', '[data-action=delete]', this.poDelete.bind(this));
		}
		
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.poGridElement);
		this.poGrid = this.poGridElement.data('kendoGrid');


		// load grid configurator
		if(!this.settings.readOnly) {

			await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
				grid: this.poGrid,
				name: 'PomanagementOrderParts',
				exports: [{
					name: 'XLS export',
					method: 'poItem.xlsExport',
					params: {
						tracedoId: this.settings.tracedoId
					}
				}]
			} as IGridConfiguratorOptions);

		}
		else {

			// read data - if read-only we do not have gridconfigurator that triggers read
			this.poGrid.dataSource.read();

		}
	}

	/**
	 * Pri zmene oznaceni PO itemu v gridu
	 */
	private onChange()
	{
		let selected = this.poGrid.select().length > 0;

		if(this.tracedo.trcStatus.ident === 'new' || this.tracedo.trcStatus.ident === 'accepted') {
			this.poGridElement.find('[data-action=edit]').prop('disabled', !selected);
			this.poGridElement.find('[data-action=delete]').prop('disabled', !selected);
		}
		else {
			this.poGridElement.find('[data-action=edit]').prop('disabled', true);
			this.poGridElement.find('[data-action=delete]').prop('disabled', true);
		}
	}

	/**
	 * On data bound to GRID - Set color to PoItems
	 */
	private onDataBound()
	{
		KendoHelpers.highlightColumn(this.poGrid);

		if(this.tracedo.trcStatus.ident === 'new' || this.tracedo.trcStatus.ident == 'accepted') {
			// zneaktivneni buttonu
			this.poGridElement.find('[data-action=edit]').prop('disabled', true);
			this.poGridElement.find('[data-action=delete]').prop('disabled', true);
		}
		else {
			// zneaktivneni buttonu
			//this.poGridElement.find('[data-action=create]').prop('disabled', true);
			this.poGridElement.find('[data-action=edit]').prop('disabled', true);
			this.poGridElement.find('[data-action=delete]').prop('disabled', true);
		}
		
		//odstranění detailInit pokud neexistují subpoložky
		this.poGridElement.find('.po-item-no-children').each(function () {
			jQuery(this).closest('tr').find('td.k-hierarchy-cell').html('');
		});
	}

	/**
	 * Delete selected PoItem
	 */
	private async poDelete(): Promise<any>
	{
		var item: any = this.poGrid.dataItem(this.poGrid.select());
		let yesNo = await this.confirmDialog(i18n.t('system.question.deletePo'));
		if(yesNo) {
			await this.rpc.call('poItem.delete', { id: item.id });
			this.poGrid.dataSource.read();
			this.onRefreshDetail.post();
		}
	}

	/**
	 * Opens dialog for poItem edit
	 */
	private poEditHandler()
	{
		var item: any = this.poGrid.dataItem(this.poGrid.select());
		this.poOpenDialog(item.id);
	}

	/**
	 * Opens dialog for poItem insert
	 */
	private poCreateHandler()
	{
		this.poOpenDialog(null);
	}

	/**
	 * Reread grid data
	 */
	private reload()
	{
		this.poGrid.dataSource.read();
	}

	/**
	 * Dialog for poItem
	 */
	private poOpenDialog(poItemId: number = null) // , multipleDelivery = false
	{
		this.loadViewFrame<PoEdit>(PoEdit, 'edit', {
			tracedoId: this.settings.tracedoId,
			poItemId: poItemId,
			dialog: {
				width: 755,
				height: 560,
				modal: true,
				title: poItemId ? i18n.t('nav.poItemEdit') : i18n.t('nav.poItemCreate'),
				buttons: (editVm: PoEdit, window: kendo.ui.Window) => {
					return [{ 
							align: 'right', 
							cls: 'btn-link',
							label: i18n.t('common.actions.cancel'),
							click: () => window.close()
						}, {
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: async () => {
								await editVm.save();
								this.reload();
								this.onRefreshDetail.post();
								window.close();
							}
						}];
				}
			}
		});
	}


	public template = (): HTMLElement => (
		<div>
			<view-frame name="gridconf" className="mb-2" />

			<div data-grid="po-items"></div>
			
			<view-frame name="edit" />
		</div>
	);

}
