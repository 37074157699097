import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import moment from "moment";
import { i18n } from "i18next-ko";

/**
 * Tracedo - Gps Log
 */
@injectable()
export class GpsLog extends BaseViewModel<any>
{

	/**
	 * Tracedo ID
	 */
	tracedoGpsLogs: any = null;

	map: any;

	tracedo: Object = null;

	pickupAddress: string = null;
	deliveryAddress: string = null;

	/**
	 * Konfigurace mapy
	 */
	private configureMap()
	{
		let google = (window as any).google;

		var markersIcons = {
			'green': 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
			'red': 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
			'blue': 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
			'darkGreen': 'https://intra.estone.cz/images/ico/googleMaps/dark-green-dot.png',
			'azure': 'https://intra.estone.cz/images/ico/googleMaps/azure-dot.png'
		};
		
		var firstx = 0;
		var firsty = 0;
		var locations: any = [];
		var tracedoArray = []; 
		//colection to generate markers and titles
		var markers: any = [];
		var markersCoordinates: any = [];
		var culture = $.inArray(this.culture.locale, ['cs-CZ', 'en-US']) > -1 ? this.culture.locale : 'en-US';
		var tracedoObj: Object = {};
		var last = null;

		this.tracedoGpsLogs.forEach((gps: any) => {
			var operation = '';
			//move longtitude if markers have exactly same locations
			var exist = false;
			var tempObj = { 'lat': gps.latitude(), 'lon': gps.longitude()};
			$.each(markersCoordinates, (key, val) => {
				if (JSON.stringify(tempObj) === JSON.stringify(val)) {
					exist = true;
				}
			});
			if (exist) {
				var newLongitude = parseFloat(gps.longitude()) + (1/50000);
				gps.longitude(newLongitude);
			} else {
				markersCoordinates.push(tempObj);
			}

			if (gps.logs() !== null) {
				if (gps.logs()[0]) {
					if (culture === 'cs-CZ') {
						operation = gps.logs()[0].actionId() ? gps.logs()[0].action.name() + (gps.logs()[0].issueId() ? ": " + gps.logs()[0].issue.category.nameTranslation() + " - " + gps.logs()[0].issue.type.nameTranslation() : "") : "";
					} else {
						operation = gps.logs()[0].action.id() ? gps.logs()[0].action.nameTranslation() + (gps.logs()[0].issueId() ? ": " + gps.logs()[0].issue.category.name() + " - " + gps.logs()[0].issue.type.name() : "") : "";
					}
				}
			}
			if (gps.typeId()) {
				if (gps.typeId() != 7) {
					locations.push([moment(gps.createdTimestamp()).format(i18n.t('common.formats.momentDate') + ' ' + i18n.t('common.formats.momentTime')) + '<br/>' + operation, parseFloat(gps.latitude()), parseFloat(gps.longitude()), gps.typeId()]);
				}
				markers.push([moment(gps.createdTimestamp()).format(i18n.t('common.formats.momentDate') + ' ' + i18n.t('common.formats.momentTime')) + '<br/>' + operation, parseFloat(gps.latitude()), parseFloat(gps.longitude()), gps.typeId()]);
			}
			else {
				locations.push([moment(gps.createdTimestamp()).format(i18n.t('common.formats.momentDate') + ' ' + i18n.t('common.formats.momentTime')) + '<br/>' + operation, parseFloat(gps.latitude()), parseFloat(gps.longitude()), gps.typeId()]);
			}
			//zobrazit prvni gps z kazdeho useku
			
			if (firsty === 0) {
				firstx = parseFloat(gps.latitude());
				firsty = parseFloat(gps.longitude());
			}
		});

		let marker;

		var totalLegth = locations.length;
		
		var bounds = new google.maps.LatLngBounds();
		//map canvas
		var map = new google.maps.Map(document.getElementById('map'), {
			zoom: 8,
			center: new google.maps.LatLng(firstx, firsty),
			mapTypeId: google.maps.MapTypeId.ROADMAP
		});
		var infowindow = new google.maps.InfoWindow();
		if (firstx !== 0 && firsty !== 0) {
			bounds.extend(new google.maps.LatLng(firstx, firsty));
		}
		//nastavit zacatek a konec dle adresy
		var geocoder = new google.maps.Geocoder();
		if (this.pickupAddress) {
			codeAddress(i18n.t('common.captions.pickupAddress')+"</br>" + this.pickupAddress, this.pickupAddress, map, markersIcons.green);
		}
		//lib to generate route
		var rendererOptions = {
			map: map,
			suppressMarkers: true
		};
		var directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
	
		//colection of coordinates to draw line
		var polylinePoints = [];
	
		//colection waypoints and route
		var getWaypointAfter = Math.ceil(totalLegth/8);
		var showWayPosition = getWaypointAfter;
		var getMarkerpointAfter = 1;//Math.ceil(totalLegth/(30-markers.length));
		var showMarkerPosition = 0;
		var polyIcons = [];
		for (let polyStart = 1; polyStart < 10; polyStart++) {
			polyIcons.push({
				icon: {
					path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
					zIndex: 9999999999
				},
				offset: polyStart.toString() + '0%'
			});
		};

		//generate route
			for (let i = 0; i < locations.length; i++) {
				polylinePoints.push({ lat: locations[i][1], lng: locations[i][2] });
				bounds.extend({ lat: locations[i][1], lng: locations[i][2] });
			}
			markers = markers.concat(locations);
			//draw red line
			var flightPath = new google.maps.Polyline({
				path: polylinePoints,
				geodesic: true,
				strokeColor: '#FF0000',
				strokeOpacity: 1.0,
				strokeWeight: 2,
				icons: polyIcons
			});		

			flightPath.setMap(map);
		//draw markers
		$.each(markers, function(i, location){
			var point = new google.maps.LatLng(location[1], location[2]);
			if (location[3] !== 0 && location[3] !== null && location[3]) {
				marker = new google.maps.Marker({
					position: point,
					map: map,
					icon: location[3] === 1 || location[3] === 5 ? markersIcons.darkGreen : (location[3] === 2 || location[3] === 6 ? markersIcons.blue : markersIcons.red)
				});
			} else {
				var icon = {
					path: google.maps.SymbolPath.CIRCLE,
					scale: 4,
					fillColor: 'green',
					fillOpacity: 1,
					strokeColor: 'white',
					strokeWeight: 1
				};
				marker = new google.maps.Marker({
					position: point,
					icon: icon,
					map: map
				});
			}
			
			google.maps.event.addListener(marker, 'click', (function (marker, i) {
				return function () {
					infowindow.setContent(location[0]);
					infowindow.open(map, marker);
				};
			})(marker, i));
		});

		//nastavit konec dle adresy
		if (this.deliveryAddress) {
			codeAddress(i18n.t('common.captions.deliveryAddress') + '</br>' + this.deliveryAddress, this.deliveryAddress, map, markersIcons.azure, true);
		}else {
			sleep(500).then(() => {
				map.fitBounds(bounds);
			});
		}
		var markersCoordinates2: any = [];

		function codeAddress(name: any, address: any, map: any, icon: any, mapCenter = false) {
			geocoder.geocode({ 'address': address }, function (results: any, status: any) {
				if (status == google.maps.GeocoderStatus.OK) {
					
					//move longtitude if markers have exactly same locations
					var exist = false;
					var tempObj = results[0].geometry.location;
					$.each(markersCoordinates2, (key, val) => {
						if (JSON.stringify(tempObj) === JSON.stringify(val)) {
							exist = true;
						}
					});
					if (exist) {
						var newLongitude = parseFloat(tempObj.lng()) + (1 / 50000);
						tempObj.lng(newLongitude);
						tempObj = new google.maps.LatLng(tempObj.lat(), newLongitude);
					} else {
						markersCoordinates2.push(tempObj);
					}

					var marker = new google.maps.Marker({
						map: map,
						icon: icon,
						position: tempObj
					});

					google.maps.event.addListener(marker, 'click', (function (marker) {
						return function () {
							infowindow.setContent(name);
							infowindow.open(map, marker);
						};
					})(marker));
					bounds.extend(results[0].geometry.location);
					if (mapCenter) {
						sleep(500).then(() => {
							map.fitBounds(bounds);
						});
					}
				} else {
					return false;
				}
			});
		}

		function sleep(time: any) {
			return new Promise((resolve) => setTimeout(resolve, time));
		}
	}

	/**
	 * Startup
	 * 
	 * @param tracedoId - Tracedo ID
	 * @return JQueryPromise<any>
	 */
	public async startup(): Promise<any>
	{
		await super.startup();
	}
	
	/**
	 * On render read map
	 */
	public async rendered(): Promise<any>
	{
		await this.reload();
	}

	/**
	 * Reload map
	 */
	public async reload()
	{
		let batch = this.rpc.batch();
		// get one tracedo
		batch.call('tracedo', 'tracedo.getOne', {
			id: this.settings.tracedoId,
			query: { select: '*,deliveryAddress(country),destAddressCountry(*),pickupAddress(country),originAddressCountry(*)' }
		});
		batch.call('gpsLogs', 'tracedo.getGpsLogs', {
			id: this.settings.tracedoId,
			query: { select: 'tracedoId,latitude,longitude,createdUserId,createdTimestamp,typeId,logs(actionId,action,changes,createdUser,issueId,issue(typeId,type,categoryId,category))' }
		});
		let response: any = await batch.run();

		let gpsArray: any = [];
		response['gpsLogs'].forEach((gps: any) => {
			gpsArray.push(ko.mapping.fromJS(gps));
		});

		let addresses: any = { pickupAddress: null, deliveryAddress: null };

		let tracedo = ko.mapping.toJS(response['tracedo']);

		if (tracedo) {

			addresses.pickupAddress = 
				tracedo.originAddressStreet + 
				(jQuery.trim(tracedo.originAddressStreet2) !== '' ? ', ' + tracedo.originAddressStreet2 : '') + 
				', ' + tracedo.originAddressCity + 
				', ' + tracedo.originAddressZipCode + 
				', ' + (tracedo.originAddressCountryId ? tracedo.originAddressCountry.iso : '');

			addresses.deliveryAddress = 
				tracedo.destAddressStreet + 
				(jQuery.trim(tracedo.destAddressStreet2) !== '' ? ', ' + tracedo.destAddressStreet2 : '') + 
				', ' + tracedo.destAddressCity + 
				', ' + tracedo.destAddressZipCode + 
				', ' + (tracedo.destAddressCountryId ? tracedo.destAddressCountry.iso : '');
		}

		if (addresses) {
			this.pickupAddress = addresses.pickupAddress;
			this.deliveryAddress = addresses.deliveryAddress;
		}

		this.tracedoGpsLogs = gpsArray;

		this.configureMap();
	}

	public template = (): HTMLElement => (
		<div id="map" style="height: 600px;"></div>
	);
}