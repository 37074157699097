import { IQueryParams } from './IQueryParams';
import { IRouteRequest } from './IRouteRequest';
import { Culture } from './Culture';
import { singleton, InjectionToken, container } from 'tsyringe';

import { Router } from './Router';

import { ViewModelFactory } from './ViewModelFactory';
import { IApplicationSettings } from './IApplicationSettings';
import { ViewModel } from './ViewModel';
import { IViewModelSettings } from './IViewModelSettings';

/**
 * TraceJS Application
 */
@singleton()
export class Application {

	/**
	 * Application settings
	 */
	private settings: IApplicationSettings;

	/**
	 * ViewModel Factory
	 */
	private viewModelFactory: ViewModelFactory;

	/**
	 * Router
	 */
	private router: Router;

	/**
	 * Culture
	 */
	private _culture: Culture;

	/**
	 * Main View Model Reference
	 */
	private _mainViewModel: ViewModel<IViewModelSettings>;

	/**
	 * Main View Element
	 */
	private _mainElement: JQuery;

	/**
	 * Main Frame
	 */
	private _mainFrame: JQuery;

	/**
	 * Current application parameters
	 */
	private params: any;

	/**
	 * Main ViewModel
	 */
	public get mainViewModel(): ViewModel<IViewModelSettings> {
		return this._mainViewModel;
	}

	/**
	 * Culture
	 */
	public get culture() {
		return this._culture;
	}

	/**
	 * TraceJS application constructor
	 * @param settings Application settings
	 * @param viewModelFactory ViewModel Factory
	 * @param router Application router
	 * @param culture Culture
	 */
	constructor(
		settings: IApplicationSettings,
		viewModelFactory: ViewModelFactory,
		router: Router,
		culture: Culture
	) {
		this.settings = settings;
		this.viewModelFactory = viewModelFactory;
		this.router = router;
		this._culture = culture;
		this._mainViewModel = null;
		this._mainElement = null;
		this._mainFrame = jQuery('<div data-view-frame="application"></div>');
		this.params = {};
	}

	/**
	 * Run the application
	 */
	public async run<T>(token: InjectionToken<T>, settings: Object = null): Promise<void>
	{
		this._mainFrame.appendTo('body');

		// Load View Model
		await this.loadViewModel<T>(token, settings);

		// Process routing
		return this.router.startRouting(this.mainViewModel);		
	}

	/**
	 * Loads main Application ViewModel
	 */
	protected async loadViewModel<T>(token: InjectionToken<T>, settings: Object = null): Promise<void>
	{
		// Create main VM and load it into main FRAME
		const vmEl = this.viewModelFactory.createViewModel<T>(null, token, settings);
		
		// set class for main element
		if (this.settings.mainElementClass) {
			vmEl.el.addClass(this.settings.mainElementClass);
		}
		
		// Save references
		this._mainViewModel = vmEl.vm as any; 
		this._mainElement = vmEl.el;

		// Run ViewModel
		await (vmEl.vm as any).run();

		// Place Main ViewModel into MainFrame element
		ko.cleanNode(this._mainFrame.get(0));
		this._mainFrame.html('').append(vmEl.el);

		// Remove temp element
		vmEl.temp.remove();

		// Component rendered
		return this.mainViewModel.rendered();
	}

}