import { inject, injectable } from "tsyringe";
import { i18n } from "i18next-ko";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { BaseViewModel } from "../common/BaseViewModel";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { FlashMessenger } from "../../../tracejs/src/ui/FlashMessenger";

/**
 * Offcanvas
 */
@injectable()
export class Offcanvas extends BaseViewModel<any>
{

	/**
	 * Flash messenger Client
	 */
    protected flashMessenger: FlashMessenger;

    /**
     * Messages
     */
    protected messages: KnockoutObservableArray<{ type: string, text: string, className: string }> = ko.observableArray([]);

    /**
     * Constructor
     * 
     * @param rpc RPC
     * @param culture Culture
     */
    constructor(
            @inject(Client) rpc: Client,
            @inject(FlashMessenger) flashMessenger: FlashMessenger
        )
    {
        super(rpc);
        this.flashMessenger = flashMessenger as FlashMessenger;
    }

    /**
     * Startup
     */
    public async startup()
    {
        await super.startup();
        this.flashMessenger.onMessage.attach((message) => {
            let cls = 'alert-' + message.type;
            if(message.type === 'error') {
                cls = 'alert-danger';
            }
            let msg = {
                type: message.type,
                text: message.text,
                className: cls,
                time: kendo.toString(new Date(), 'g')
            };
            this.messages.push(msg);
        });
    }

    /**
     * On render = add caption
     */
    public async rendered()
    {
        await super.rendered();
        // nadpis
        this.element.closest('.offcanvas').find('.offcanvas-header').prepend('<h5>' + i18n.t('common.captions.messageLog') + '</h5>');
    }

	public template = (): HTMLElement => (
        <div>
            <ul className="flash-messages-offcanvas" data-bind="foreach: $root.messages">
                <li>
                    <div data-bind="attr: { 'class': 'alert ' + $data.className }" role="alert">
                        <div data-bind="html: $data.time" className="mb-1" style="opacity: .5"></div>
                        <div data-bind="html: $data.text"></div>
                    </div>
                </li>
            </ul>
        </div>
    );

}