import { injectable } from "tsyringe";
import { Objects } from "../../../../tracejs/src/utils/Objects";

import { i18n } from "i18next-ko";
import { h } from "../../../../tracejs/src/utils/JSXFactory";

import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";

import { KendoHelpers } from "../../../model/KendoHelpers";

import { BaseViewModel } from "../../common/BaseViewModel";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";

import { Edit } from "./Edit";
import { EditContactPersons } from "./EditContactPersons";

/**
 * Addresses
 */
@injectable()
export class Addresses extends BaseViewModel<any>
{

	// GRID element
	private gridElement: JQuery<HTMLElement>;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;


	// pro moznost manipulaci s nadrazenimy akcemi
	private gridConfiguratorViewModel: GridConfigurator;

	/**
	 * Configure grid
	 */
	private configureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var toolbarConfig = [];
		if (this.user.isAllowed('entity.address', 'create')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus" ></i><span> ' + i18n.t('common.actions.create') + '</span></button>' });
		}
		if (this.user.isAllowed('entity.address', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="update"><i class="icon-edit" ></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
		}
		if (this.user.isAllowed('entity.address', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="updateContactPerson"><i class="icon-edit" ></i><span>' + i18n.t('settings.addresses.editContactPerson') + '</span></button>'});
		}
		if (this.user.isAllowed('entity.address', 'delete')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>' });
		}
		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

						options.data.search = (this.grid.dataSource as any).searchText;

						KendoHelpers.replaceRequestData(options.data, '__'); // also replaces fields__ to fields->>'

						let response: any = await this.rpc.call('addressbook.get', {
							query: {
								...options.data,
								select: 'addressbookId,name,street,street2,zipCode,town,countryId,defaultNote,country(iso))'
							}
						});

						response.data = Objects.flatten(response.data, '__');

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: {
							'addressbookId': { editable: false, type: 'number' },
							'name': { editable: false, type: 'string' },
							'street': { editable: false, type: 'string' },
							'street2': { editable: false, type: 'string' },
							'town': { editable: false, type: 'string' },
							'zipCode': { editable: false, type: 'string' },
							'country__iso': { editable: false, type: 'string' },
							'defaultNote': { editable: false, type: 'string' }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: 'name', dir: 'asc' },
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				pageSize: 100,
				pageSizes: [10, 20, 50, 100],
				refresh: true
			},
			toolbar: toolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.grid);
				this.gridElement.find('[data-action=delete]').prop('disabled', true);
				this.gridElement.find('[data-action=update]').prop('disabled', true);
				this.gridElement.find('[data-action=updateContactPerson]').prop('disabled', true);
			},
			change: () => {
				var selected = this.grid.select().length > 0;
				this.gridElement.find('[data-action=delete]').prop('disabled', !selected);
				this.gridElement.find('[data-action=update]').prop('disabled', !selected);
				this.gridElement.find('[data-action=updateContactPerson]').prop('disabled', !selected);
			},
			columns: [
				{ field: "name", title: i18n.t('common.captions.name'), width: 150 },
				{ field: "street", title: i18n.t('trade.demand.edit.addressStreet'), width: 200 },
				{ field: "street2", title: i18n.t('trade.demand.edit.addressStreet2'), width: 200 },
				{ field: "zipCode", title: i18n.t('registration.firmZipCode'), width: 70 },
				{ field: "town", title: i18n.t('registration.firmCity'), width: 120 },
				{ field: "country__iso", title: i18n.t('registration.firmCountry'), width: 40 },
				{ field: "defaultNote", title: i18n.t('common.captions.defaultNote'), width: 200 }
			]
		};
	}	

	/**
	 * ViewModel startup
	 */
	public async startup() {
		
		// Pokud nemuze do spravy adres, 403
		if (!this.user.isAllowed('section.settingsAddresses', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}		

		await super.startup();
		
		// Configure GRID
		this.configureGrid();
	}

	/**
	 * Render
	 */
	public async rendered()
	{

		// grid element
		this.gridElement = this.element.find('[data-grid=addresses]');

		/// initialize grid
		this.gridElement.kendoGrid(this.gridConfig);
		// Kendo GRID reference
		this.grid = this.gridElement.data('kendoGrid');
		// BIND action buttons
		this.gridElement.on('click', '[data-action=delete]', this.deleteAddress.bind(this));
		this.gridElement.on('click', '[data-action=create]', this.insertHandler.bind(this));
		this.gridElement.on('click', 'button[data-action=update]', this.updateHandler.bind(this));
		this.gridElement.on('click', 'button[data-action=updateContactPerson]', this.updateContactPersonHandler.bind(this));
		this.gridElement.on('dblclick', 'tbody tr', this.updateHandler.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);


		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Settings.Addresses',
			exports: [{
				name: 'XLS export',
				method: 'addressbook.xlsExport'
			}]
		} as IGridConfiguratorOptions);

		this.gridConfiguratorViewModel = vm;
	}

	/**
	 * Opens dialog for user insert
	 */
	public async insertHandler(): Promise<void>
	{
		await this.addressDialogHandler(null);
		await this.addressContactPersonDialogHandler(null);
	}

	/**
	 * Opens dialog for user update
	 */
	public async updateHandler(event: any): Promise<void>
	{
		let address: any = this.grid.dataItem(this.grid.select());
		if (this.user.isAllowed('entity.address', 'edit')) {
			await this.addressDialogHandler(address.addressbookId);
		}
	}

	public async updateContactPersonHandler(event: any): Promise<void>
	{
		let address: any = this.grid.dataItem(this.grid.select());
		if(this.user.isAllowed('entity.address', 'edit')) {
			await this.addressContactPersonDialogHandler(address.addressbookId);
		}
	}
	
	/**
	 * delete user
	 */
	public async deleteAddress(event: any): Promise<void>
	{
		let addressbook: any = this.grid.dataItem(this.grid.select());

		await kendo.confirm(i18n.t('system.question.deleteAddress')).fail(() => {});

		await this.rpc.call('addressbook.delete', { id: addressbook.addressbookId });
		
		this.grid.dataSource.read();
	}

	/**
	 * Opens dialog for user insert/update
	 */
	private async addressDialogHandler(addressbookId: number): Promise<void>
	{
		await this.loadViewFrame<Edit>(Edit, 'edit', {
			addressId: addressbookId,
			dialog: {
				modal: true,
				width: 450,
				height: 350,
				title: i18n.t('settings.addresses.edit'),
				buttons: (vm: Edit, window: kendo.ui.Window) => {
					return [
						{ 
							align: 'right', 
							cls: 'btn-link', 
							label: i18n.t('common.actions.close'),
							click: () => window.close()
						},
						{ 
							align: 'right', 
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: () => vm.save().then(() => { 
								window.close(); 
								this.grid.dataSource.read();
							}) 
						}
					];
				}
			}
		});
	}

	/**
	 * Opens dialog for address edit
	 */
	private async addressContactPersonDialogHandler(addressbookId: number): Promise<void>
	{
		await this.loadViewFrame<EditContactPersons>(EditContactPersons, 'edit', {
			addressId: addressbookId,
			dialog: {
				modal: true,
				width: 650,
				height: 550,
				title: i18n.t('settings.addresses.editContactPerson'),
				buttons: (vm: EditContactPersons, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => window.close()
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: () => vm.save()
						}
					]
				}
			}
		})
	}
	
	public template = (): HTMLElement => (

		<div>

			<h2 data-bind="i18n: 'nav.addresses'"></h2>

			<view-frame name="gridconf" className="mb-2" />

			<div data-grid="addresses" className="grid-autoheight"></div>

			<view-frame name="edit" />

		</div>

	);

}