/**
 * Bad Request Error
 */
export class BadRequestError implements Error {

	// Bad Request Error name
	private _name: string = 'BadRequestError';

	// Message
	private _message: string;

	// Error code
	private _code: number;


	public get name(): string {
		return this._name;
	}

	public get message(): string {
		return this._message;
	}

	public get code(): number {
		return this._code;
	}

	/**
	 * Constructor
	 */
	constructor(message: string, code: number) {
		this._message = message;
		this._code = code;
	}
}