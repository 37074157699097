import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";

import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { IDialogSettings } from "../common/IDialogSettings";
import { i18n } from "i18next-ko";


/**
 * User Profile
 */
@injectable()
export class Profile extends BaseDialogViewModel<IDialogSettings>
{
	/**
	 * Currently logged user
	 */ 
	private userObject: KnockoutObservable<any> = ko.observable(null);

	/**
	 * Old password
	 */
	private oldPassword: KnockoutObservable<string> = ko.observable('');

	/**
	 * New password
	 */
	private password: KnockoutObservable<string> = ko.observable('');

	/**
	 * Password check
	 */
	private password2: KnockoutObservable<string> = ko.observable('');

	private languages: KnockoutObservableArray<any> = ko.observableArray();
	
	private notifications: KnockoutObservableArray<any> = ko.observableArray(null);

	/**
	 * Dashboard Startup
	 */
	public async startup()
	{
		await super.startup();

		let batch = this.rpc.batch();
		batch.call('langs', 'language.get');
		batch.call('user', 'user.getLoggedUser');
		batch.call('notifications', 'user.getMyNotifications');

		let results: any = await batch.run();

		this.languages(results['langs']);
		this.userObject(results['user']);
		
		var lang = this.culture.localeShortCapitalized;
		results['notifications'].forEach((value: any) => {
		 	value.name = value['name' + lang] ? value['name' + lang] : value['name'];
		 	value.description = value['description' + lang] ? value['description' + lang] : value['description'];
		});
		this.notifications(results['notifications']);
	}

	/**
	 * Set given notification for currently logged user
	 * @param notification 
	 */
	public async setMyNotification(notification: any)
	{
		await this.rpc.call('user.setMyNotification', { notifyRoleId: notification.vazId });
	}

	/**
	 * Save profile data
	 */
	public async save(): Promise<boolean>
	{
		let oldPass = null;
		let newPass = null;

		// test email + email validity
		if (this.userObject().email == '' || this.userObject().email == null) {
			this.alertDialog(i18n.t('system.alerts.emptyEmail'));
			return false;
		}
		var re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
		if (!re.test(this.userObject().email)) {
			this.alertDialog(i18n.t('system.alerts.notValidEmail'));
			return false;
		}
		// password changed ?
		if (this.password() !== '' && this.oldPassword() !== '') {
			if (this.password() !== this.password2()) {
				this.alertDialog(i18n.t('system.alerts.passMismatched'));
				return false;
			}
			oldPass = this.oldPassword();
			newPass = this.password();
		}

		// save profile
		await this.rpc.call('user.saveProfile', { user: this.userObject(), oldPassword: oldPass, newPassword: newPass });

		return true;
	}


	public dialogTemplate = (): HTMLElement => (
		<div>

			<div id='orderTabStrip' data-bind="kendoTabStrip: { animation: false }">
				<ul>
					<li data-bind="css: 'k-state-active', i18n: 'common.captions.information'"></li>
					<li data-bind="i18n: 'system.user.profile.notification'"></li>
				</ul>
				<div className="k-state-active">
					<div className="row">
						<div className="col-md-6">
							<fieldset>
								<legend data-bind="i18n: 'system.user.profile.personalInfo'"></legend>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'firstName', i18n: 'system.user.profile.firstName'"></label>
									<div className="col-md-8">
										<input type="text" className="form-control" data-bind="value: $root.userObject().firstName, uniqueId: 'firstName'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'lastName', i18n: 'system.user.profile.lastName'"></label>
									<div className="col-md-8">
										<input type="text" className="form-control" data-bind="value: $root.userObject().lastName, uniqueId: 'lastName'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'email', i18n: 'system.user.profile.email'"></label>
									<div className="col-md-8">
										<input type="text" className="form-control" data-bind="value: $root.userObject().email, uniqueId: 'email'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'phone', i18n: 'system.user.profile.phone'"></label>
									<div className="col-md-8">
										<input type="text" className="form-control" data-bind="value: $root.userObject().phone, uniqueId: 'phone'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'mobile', i18n: 'system.user.profile.mobile'"></label>
									<div className="col-md-8">
										<input type="text" className="form-control" data-bind="value: $root.userObject().mobile, uniqueId: 'mobile'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'companyFunction', i18n: 'system.user.profile.companyFunction'"></label>
									<div className="col-md-8">
										<input type="text" className="form-control" data-bind="value: $root.userObject().companyFunction, uniqueId: 'companyFunction'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'notes', i18n: 'system.user.profile.notes'"></label>
									<div className="col-md-8">
										<textarea className="form-control" data-bind="value: $root.userObject().notes, uniqueId: 'notes'"></textarea>
									</div>
								</div>
							</fieldset>
						</div>
						<div className="col-md-6">
							<fieldset>
								<legend data-bind="i18n: 'system.user.profile.loginInfo'"></legend>
								<div className="row mb-2">
									<label className="inline col-md-5 col-form-label" data-bind="i18n: 'system.user.profile.username'"></label>
									<label className="inline col-md-7 col-form-label" data-bind="text: $root.userObject().loginname"></label>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-5 col-form-label" data-bind="uniqueFor: 'oldPassword', i18n: 'system.user.profile.oldPassword'"></label>
									<div className="col-md-7">
										<input className="form-control" type="password" data-bind="value: $root.oldPassword, uniqueId: 'oldPassword'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-5 col-form-label" data-bind="uniqueFor: 'password', i18n: 'system.user.profile.newPassword'"></label>
									<div className="col-md-7">
										<input className="form-control" type="password" data-bind="value: $root.password, uniqueId: 'password'" />
									</div>
								</div>
								<div className="row mb-2">
									<label className="inline col-md-5 col-form-label" data-bind="uniqueFor: 'password2', i18n: 'system.user.profile.confirmPassword'"></label>
									<div className="col-md-7">
										<input className="form-control" type="password" data-bind="value: $root.password2, uniqueId: 'password2'" />
									</div>
								</div>

								<p data-bind="i18n: 'system.user.profile.noModifyPasswordNotification'"></p>

							</fieldset>
						</div>
					</div>
				</div>
				<div>
					<div className="row">
						<div className="col-md-3">
							<label data-bind="i18n: 'system.user.profile.notificationLanguange', uniqueFor: 'language'"></label>
							<select className="form-select w-auto d-inline-block ms-2" data-bind="uniqueId: 'language', options: $root.languages, optionsText: 'name', optionsValue: 'languageId', value: $root.userObject().notificationLanguageId"></select>
						</div>
						<div className="col-md-9">
							<div className="form-check pt-2">
								<input class="form-check-input" type="checkbox" data-bind="uniqueId: 'sendToAllTrcOrders', checked: $root.userObject().notificationTrcSendAll" />
								<label class="form-check-label" data-bind="uniqueFor: 'sendToAllTrcOrders', i18n: 'system.user.profile.sendToAllTrcOrders'"></label>
							</div>
							<div className="form-check pt-2">
								<input class="form-check-input" type="checkbox" data-bind="uniqueId: 'sendToContactTrcOrders', checked: $root.userObject().notificationTrcSendContact" />
								<label class="form-check-label" data-bind="uniqueFor: 'sendToContactTrcOrders', i18n: 'system.user.profile.sendToContactTrcOrders'"></label>
							</div>
						</div>
					</div>
					<div>
						<table className="table table-bordered mt-2" style="padding: 6px;">
							<thead className="table-light">
								<tr>
									<th data-bind="i18n: 'system.user.profile.notification'"></th>
									<th data-bind="i18n: 'common.captions.description'"></th>
									<th data-bind="i18n: 'system.user.profile.active'"></th>
								</tr>
							</thead>
							<tbody data-bind="foreach: $root.notifications">
								<tr>
									<td data-bind="text: $data.name"></td>
									<td data-bind="text: $data.description"></td>
									<td><input type="checkbox" data-bind="event: { change: (data) => $root.setMyNotification(data) }, checked: $data.active" /></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
	);
	
} 