
import { i18n } from "i18next-ko";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { Objects } from "../../../tracejs/src/utils/Objects";
import { PoItem } from "../../entities/PoItem";
import { KendoHelpers } from "../../model/KendoHelpers";

import { BaseViewModel } from "../common/BaseViewModel";
import { GridConfigurator } from "../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../components/GridConfigurator/IGridConfiguratorOptions";
import { PoEdit } from "./PoEdit";

@injectable()
export class PoManagement extends BaseViewModel<any>
{	
	/// GRID element
	private gridElement: JQuery = null;

	/// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;


	private filtration: KnockoutObservable<number> = ko.observable(0);
	private findOnTracedo: KnockoutObservable<boolean> = ko.observable(false);

	private mainSubjectId: number;


	// GRID Configurator VM
	private gridConfigurator: GridConfigurator = null;

	// PO item statuses
	// private poStatuses: any[] = [];
	// private poStatusesHash: {[key:string]: string} = {};

	/**
	 * Metoda konfigurujici parametry GRIDu
	 */
	private configureGrid()
	{
		var toolbarConfig = [];
		if (this.user.isAllowed('entity.tracedo', 'edit')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="edit" title="' + i18n.t('common.actions.edit') + '"><i class="icon-search"></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.delete') + '"><i class="icon-trash"></i></button>' });
		}

		/// Grid Config
		this.gridConfig = {
			autoBind: false, 
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportReadOptions) => {
						(options.data as any).search = (this.grid.dataSource as any).searchText;

						KendoHelpers.replaceRequestData(options.data, '__');

						let response: any = await this.rpc.call('poItem.getMy', {
							query: {
								...options.data,
								select: '*,tracedo(id,myNumber,myField),colliType(*),cargoValueCurr(*),createdUser(*),subject(*),metOriginCountry(*)'
							},
							findOnTracedo: this.findOnTracedo(),
							// expand: 'poItemOrderItems:(order:(mode),orderItem,orderItem:(delivery,pickup)),subject,colliType,cargoValueCurr,metOriginCountry,portOfLoading',
							// select: 'id,pn1,pn2,pn3,colli,pcs,descr,length,width,height,stackable,ecrTimestamp,hsCode,metOriginCountryId,portOfLoadingId,'+
							// 	'oogcntr,cntrnr,status,packaging,colliTypeId,subjectId,weight,contactPersonEmail,cargoValue,cargoValueCurrId,'+ 
							// 	'metOriginCountry:(descr,nameEn),colliType:(name),subject:(name),cargoValueCurr:(iso),portOfLoading:(name)'
						});
						response.data.forEach((poItem: any) => {
							poItem.length = poItem.length * 100;
							poItem.width = poItem.width * 100;
							poItem.height = poItem.height * 100;
						});
						response.data = Objects.flatten(response.data, '__');
						//console.log(response.data);

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: {
							'id': { editable: false, type: "number" },
							'tracedo__myNumber': { editable: false, type: "string" },
							'createdTimestamp': { editable: false, type: "date" },
							'pn1': { editable: true, type: 'string' },
							'pn2': { editable: true, type: "string" },
							'pn3': { editable: true, type: "string" },
							'colli': { editable: true, type: "string" },
							'pcs': { editable: true, type: "string" },
							'descr': { editable: true, type: "string" },
							'length': { editable: true, type: "number" },
							'weight': { editable: true, type: "number" },
							'width': { editable: true, type: "number" },
							'height': { editable: true, type: "number" },
							'stackable': { editable: true, type: "string" },
							'oogcntr': { editable: true, type: "string" },
							'packaging': { editable: true, type: "string" },
							'hsCode': { editable: true, type: "string" },
							'colliTypeId': { editable: true, type: "string" },
							'colliType__name': { editable: true, type: "string" },
							'subjectId': { editable: true, type: "string" },
							'subject__name': { editable: true, type: "string" },
							'ecrTimestamp': { editable: true, type: "date" },
							'metOriginCountryId': { editable: true, type: "string" },
							'metOriginCountry__descr': { editable: true, type: "string" },
							'metOriginCountry__nameEn': { editable: true, type: "string" },
							'cargoValue ': { editable: true, type: "number" },
							'total_m2 ': { editable: false, type: "number" },
							'total_m3 ': { editable: false, type: "number" },
							//'status ': { editable: false, type: "string" }
							// 'contactPersonEmail': { editable: true, type: "string" },
							// 'portOfLoadingId': { editable: true, type: "string" },
							// 'portOfLoading__name': { editable: true, type: "string" },
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "id", dir: "desc" },
				pageSize: 50,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			dataBound: this.onDataBound.bind(this),
			toolbar: toolbarConfig,
			change: this.onGridSelectionChanged.bind(this),
			columns: [{ 
					field: "createdTimestamp",
					title: i18n.t('common.captions.createdTimestamp'),
					format: i18n.t('common.formats.dateTime'),
					width: 110
				}, { 
					field: "tracedo__myNumber",
					title: i18n.t('edit.poItem.tracedoNumber'),
					width: 80
				}, { 
					field: "pn1",
					title: "PN 1",
					width: 200,
					template: "#= pn1 != null ? pn1.substr(0,75) : ''#" 
				}, { 
					field: "pn2",
					title: "PN 2",
					width: 120,
					template: "#= pn2 != null ? pn2.substr(0,75) : ''#" 
				}, { 
					field: "pn3",
					title: "PN 3",
					width: 100,
					template: "#= pn3 != null ? pn3.substr(0,75) : ''#"
				}, { 
					field: "stackable",
					title: i18n.t('edit.poItem.stackable'),
					width: 80
				}, { 
					field: "cargoValue",
					width: 120,
					title: i18n.t('common.captions.cargoValue'),
					template: "#= cargoValue ? ( cargoValueCurrId ? Math.round(cargoValue) + ' ' + cargoValueCurr__iso : Math.round(cargoValue)) : '' #"
				}, { 
					field: "colli",
					title: i18n.t('edit.poItem.colli'),
					width: 80,
					format: "{0:n0}"
				}, { 
					field: "colliType__name",
					title: i18n.t('edit.poItem.packaging'),
					width: 80,
					template: "#= colliTypeId ? colliType__name : '' #"
				}, { 
					field: "pcs",
					title: i18n.t('edit.poItem.pcs'),
					width: 80,
					format: "{0:n0}"
				}, { 
					field: "length",
					title: i18n.t('edit.poItem.length'),
					width: 100,
					format: "{0:n0}"
				}, { 
					field: "width",
					title: i18n.t('edit.poItem.width'),
					width: 100,
					format: "{0:n0}"
				}, { 
					field: "height",
					title: i18n.t('edit.poItem.height'),
					width: 100,
					format: "{0:n0}"
				}, { 
					field: "weight",
					title: i18n.t('edit.poItem.weight'),
					width: 100,
					format: "{0:n3}"
				}, { 
					field: "descr",
					title: i18n.t('edit.poItem.descr'),
					template: "#= descr != null ? descr.substr(0,75) : '' #",
					width: 150
				}, { 
					field: "ecrTimestamp",
					title: i18n.t('common.captions.ecr'),
					format: i18n.t('common.formats.date'),
					width: 100
				}, { 
					field: "metOriginCountry__descr",
					title: i18n.t('edit.poItem.metOriginCountry'),
					width: 130,
					template: '#= metOriginCountryId ? metOriginCountry__' + i18n.t('edit.poItem.metOriginCountryName') + ' : "" #'
				}, { 
					field: "total_m2",
					title: i18n.t('common.captions.m2'),
					width: 90,
					template: "#= kendo.toString( Math.round(colli*width*length/10000), 'n2')  #",
					sortable: false,
					filterable: false
				}, { 
					field: "total_m3",
					title: i18n.t('common.captions.m3'),
					width: 90,
					template: "#= kendo.toString( Math.round(colli*width*length*height/1000000), 'n2') #",
					sortable: false,
					filterable: false
				}, { 
					field: "hsCode",
					title: i18n.t('common.captions.hsCode'),
					width: 80
				},
				// {
				// 	field: "status",
				// 	title: i18n.t('common.captions.status'),
				// 	width: 100,
				// 	template: (data: any) => {
				// 		if(data.status) {
				// 			return this.poStatusesHash[data.status] ? this.poStatusesHash[data.status] : data.status
				// 		}
				// 		return '';
				// 	},
				// 	filterable: {
				// 		ui: (element: JQuery) => {
				// 			element.kendoDropDownList({
				// 				dataSource: this.poStatuses,
				// 				optionLabel: "--Select Value--",
				// 				dataTextField: "name",
				// 				dataValueField: "ident"
				// 			});
				// 		}
				// 	}
				// }
			]
			// { field: "portOfLoading__name", title: i18n.t('common.captions.portOfLoading'), width: 120, template: '#= portOfLoadingId ? portOfLoading__name : "" #' },
			// { field: "contactPersonEmail", title: i18n.t('edit.poItem.contactEmail'), width: 150 },
		};
		
	}
	
	private onGridSelectionChanged()
	{
		var selected = this.grid.select().length > 0;
		if(selected) {
			let poItem: PoItem = ((this.grid.dataItem(this.grid.select()) as unknown) as PoItem);

			const resourceName = 'entity.tracedo' + ((poItem as any).tracedo__myField === 'spediter' ? 'Spediter' : ((poItem as any).tracedo__myField === 'customer' ? 'Customer' : 'Carrier'));

			this.gridElement.find('[data-action=edit]').prop('disabled', !this.user.isAllowed(resourceName, 'edit'));
			this.gridElement.find('[data-action=delete]').prop('disabled', !this.user.isAllowed(resourceName, 'edit'));
		}	
		else {
			this.gridElement.find('[data-action=edit]').prop('disabled', true);
			this.gridElement.find('[data-action=delete]').prop('disabled', true);
		}
	}

	/**
	* On Data Bound
	*/
	private onDataBound(arg: any)
	{
		KendoHelpers.highlightColumn(this.grid);

		this.gridElement.find('[data-action=edit]').prop('disabled', true);
		this.gridElement.find('[data-action=delete]').prop('disabled', true);

		var dataView = arg.sender.dataSource.view();
		for (var i = 0; i < dataView.length; i++) {
			var item = dataView[i];
			if (item.highlight) {
				var el = this.gridElement.find("tr[data-uid=" + item.uid + "]").removeClass("k-alt");
				el.addClass("trc-green");
			}
		}
	}

	/// Startup
	public async startup(): Promise<any>
	{
		await super.startup();

		// Pokud nemuze do PO Managementu, tak ho vyhodime
		if (!this.user.isAllowed('section.poManagement', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		// Nacist statusy
		let batch = this.rpc.batch();
		batch.call('mainSubject', 'subject.getMain');
		// batch.call('statuses', 'poItem.getStatuses');
		let response: any = await batch.run();

		this.mainSubjectId = response['mainSubject'].subjectId;

		// this.poStatuses = response['statuses'];
		// this.poStatuses.forEach((status) => this.poStatusesHash[status.ident] = status.name);

		this.findOnTracedo.subscribe(() => {
			setTimeout(() => {
				this.searchChanged();
			}, 1);
		});

		this.configureGrid();
	}

	public async rendered(): Promise<any>
	{
		// grid element
		this.gridElement = this.element.find('[data-grid="po-items"]');

		// initialize grid
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		// BIND action buttons
		//this.gridElement.on('click', '[data-action=create]', this.createHandler.bind(this));
		this.gridElement.on('click', '[data-action=delete]', this.deleteHandler.bind(this));
		this.gridElement.on('change', '[data-action=selectFiltration]', this.onSelectFiltration.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		this.gridElement.on('click', '[data-action=edit]', this.editHandler.bind(this));
		this.gridElement.on('dblclick', 'tbody tr', this.editHandler.bind(this));

		// load grid configurator
		this.gridConfigurator = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Po Management',
			exports: [{
				name: 'XLS export',
				method: 'poItem.xlsExport',
				params: {
					findOnTracedo: this.findOnTracedo
				}
			}]
		} as IGridConfiguratorOptions);
	}

	/**
	* on select read grid change filter
	*/
	private onSelectFiltration(event: any)
	{
		// this.filtration = event.currentTarget.selectedIndex;
		// this.gridConfigurator.setSettings({
		// 	xlsExportParams: { findOnTracedo: this.findOnTracedo() },
		// });
		this.grid.dataSource.read();
	}

	/**
	 * Reload Grid
	 */
	private reloadGrid()
	{
		this.grid.dataSource.read();
	}

	/**
	* Delete selected item
	*/
	private async deleteHandler()
	{
		let yesNo = await this.confirmDialog(i18n.t('system.question.deletePo'));
		if(yesNo) {
			let item: any = this.grid.dataItem(this.grid.select());
			await this.rpc.call('poItem.delete', { id: item.id });
			this.reloadGrid();
		}
	}

	/**
	 * WHen search text is changed, set settings to GridCOnfigurator
	 */
	private searchChanged()
	{
		this.gridConfigurator.settings.xlsExportParams = { findOnTracedo: this.findOnTracedo() };
	}

	/**
	 * Opens dialog for poItem edit
	 */
	private async editHandler(): Promise<any>
	{
		let item: any = this.grid.dataItem(this.grid.select());
		await this.openDialog(item.id);
	}

	/**
	 * Opens dialog for poItem insert
	 */
	private async createHandler(): Promise<any>
	{
		await this.openDialog(null);
	}

	/**
	 * Open edit dialog
	 */
	private async openDialog(poItemId: number = null): Promise<any>
	{
		await this.loadViewFrame<PoEdit>(PoEdit, 'edit', {
			poItemId: poItemId,
			dialog: {
				width: 755,
				height: 560,
				modal: true,
				title: poItemId ? i18n.t('nav.poItemEdit') : i18n.t('nav.poItemCreate'),
				buttons: (editVm: PoEdit, window: kendo.ui.Window) => {
					return [{ 
						align: 'right', 
						cls: 'btn-link',
						label: i18n.t('common.actions.cancel'),
						click: () => window.close()
					}, {
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.save'),
						click: async () => {
							await editVm.save();
							this.reloadGrid();
							window.close();
						}
					}];
				}
			}
		});
	}


	public template = (): HTMLElement => (

		<div>

			<h1><span data-bind="i18n: 'nav.poManagement'"></span> (<span data-bind="i18n: 'common.captions.customer'"></span>)</h1>

			<div className="panel">

				<view-frame name="gridconf" />

				<div className="d-flex pt-1 mb-1">
					<div className="ms-auto" title="Search in (Number, Client ref, Client ref, CP/VDD)">
						<div class="form-check form-switch">
							<input class="form-check-input" type="checkbox" data-bind="uniqueId: 'findOnTracedo', checked: $root.findOnTracedo" />
							<label class="form-check-label" for="searchInNumberClentRef" data-bind="uniqueFor: 'findOnTracedo', i18n: 'common.actions.findOnTracedo'"></label>
						</div>
					</div>
				</div>

				<div data-grid="po-items" className="grid-autoheight"></div>

			</div>

			<view-frame name="edit" />

		</div>

	);


}