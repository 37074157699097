/**
 * Object Tools - Static methods for object
 */
export class Objects {

	/**
	 * Udela z predaneho objektu plochy objekt, kde vnorene objekty oddeli delimiterem
	 */
	static flatten(input: Array<Object>, delimiter: string): Array<Object>;
	static flatten(input: Object, delimiter: string): Object;
	static flatten(input: any, delimiter: string): any {
		if (typeof (input) !== 'object') {
			throw new Error('Invalid input. Required "object" or "array" but "' + typeof (input) + '" given');
		}
		var appendProperties = function (flat: any, prefix: string, input: any) {
			for (var property in input) {
				var value: any = input[property];
				if (value === null) {
					flat[prefix + property] = null;
				}
				else if (typeof (value) === 'object') {
					appendProperties(flat, prefix + property + delimiter, value);
				}
				/*else if (typeof (value) === 'array') {
					for (var index in input) {
						appendProperties(flat, prefix + index.toString() + delimiter, value);
					}
				}*/
				else {
					flat[prefix + property] = input[property];
				}
			}
		};
		if (input instanceof Array) {
			var arr = [];
			for (var index in input) {
				var flat = {};
				appendProperties(flat, '', input[index]);
				arr.push(flat);
			}
			return arr;
		}
		var flat = {};
		appendProperties(flat, '', input);
		return flat;
	}

	/**
	 * Udela z flattened objektu strukturu, kde rozpadne objekty podle lepidla glue na vnorene objekty
	 */
	static structurize(input: Array <Object>, glue: string): Array<Object>;
	static structurize(input: Object, glue: string): Object;
	static structurize(input: any, glue: string): any {
		if (typeof (input) !== 'object') {
			throw new Error('Invalid input. Required "object" or "array" but "' + typeof (input) + '" given');
		}
		var appendProperties = function (structurized: any, prefix: string, input: any) {
			for (var property in input) {
				var value = input[property];
				var propertyParts = property.split(glue);
				var obj = structurized;
				while (propertyParts.length > 0) {
					var part = propertyParts.shift();
					if (propertyParts.length < 1) {
						obj[part] = value;
						break;
					}
					else {
						if (obj[part] === undefined) {
							obj[part] = {};
						}
						obj = obj[part];
					}
				}
			}
		};
		if (input instanceof Array) {
			var arr = [];
			for (var index in input) {
				var structurized = {};
				appendProperties(structurized, '', input[index]);
				arr.push(structurized);
			}
			return arr;
		}
		var structurized = {};
		appendProperties(structurized, '', input);
		return structurized;
	}


	/**
	 * Find and return class by given class path. If no class is found, it returns null
	 * @param classPath Class path
	 * @param context Object to search in (null = window)
	 * @return Object|null
	 */
	static findClass(classPath: string, context: Window|any = null) {
		// try to find class
		var cls = context === null ? window : context;
		var classParts = classPath.split('.');
		for (var index in classParts) {
			var part = classParts[index];
			if (cls[part]) {
				cls = cls[part];
			}
			else {
				cls = null;
				break;
			}
		}
		return cls;
	}

}
