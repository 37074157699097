﻿import { cs } from '../locales/cs/app.js';
import { en } from '../locales/en/app.js';

/**
 * Configuration
 */
export let config = {	

	application: {
		mainElementClass: "hexpand",
		errorViewModel: "Error"
	},
	
	rpc: {
		endPoint: "TBD",
		timeout: 600000		
	},

	flashMessenger: {
		hideTimeout: 5000
	},

	tracyJS: {
		enabled: true
	},

	ajaxLoader: {
		enabled: true,
		position: 'top',
		height: '2px',
		background: '#a7a6bd',
		containerClass: 'ajax-loader'
	},

	culture: {
		locales: {
			"cs-CZ": {
				name: "Česky"
			},
			"en-US": {
				default: true,
				name: "English"
			}
		},
		translations: {
			cs: {
				translation: cs,
			},
			en: {
				translation: en
			}
		}
	}
}