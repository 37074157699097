import { IResource } from './IResource';
import { IRole } from './IRole';
import { IAuthorizator } from './IAuthorizator';

export class Permission implements IAuthorizator {

	/** Role storage */
	private roles: any = {};

	/** Resource storage */
	private resources: any = {};

	/** @var array  Access Control List rules; whitelist (deny everything to all) by default */
	private rules: any = {
		'allResources': {
			'allRoles': {
				'allPrivileges': {
					'type': false,	// DENY
					'assert': null,
				},
				'byPrivilege': {}
			},
			'byRole': {}
		},
		'byResource': {}
	};

	/** @var mixed */
	private queriedRole: any

	/** @var mixed */
	private queriedResource: any;



	// ----------------------------------------------------------------------------------------------------------------------------



	/**
	 * Adds a Role to the list. The most recently added parent
	 * takes precedence over parents that were previously added.
	 * @param  string
	 * @param  string|array
	 * @throws Error
	 * @return self
	 */
	public addRole(role: string, parents: string|string[] = null)
	{
		this.checkRole(role, false);
		if(this.roles[role]) {
			throw new Error("Role '" + role + "' already exists in the list.");
		}

		let roleParents: { [key: string]: boolean } = {};

		if (parents !== null) {
			if (!Array.isArray(parents)) {
				parents = [parents];
			}
			jQuery.each(parents, (i, parent) => {
				this.checkRole(parent);
				roleParents[parent] = true;
				this.roles[parent]['children'][role] = true;
			});
		}

		this.roles[role] = {
			'parents': roleParents,
			'children': {}
		};

		return this;
	}


	/**
	 * Returns TRUE if the Role exists in the list.
	 * @param  string
	 * @return bool
	 */
	public hasRole(role: string): boolean
	{
		this.checkRole(role, false);
		return this.roles[role] !== undefined;
	}


	/**
	 * Checks whether Role is valid and exists in the list.
	 * @param  string
	 * @param  bool
	 * @throws Nette\InvalidStateException
	 * @return void
	 */
	private checkRole(role: string, need: boolean = true)
	{
		if (!((typeof (role) === 'string') && role != '')) {
			throw new Error("Role must be a non-empty string.");
		}
		if(need && !this.roles[role]) {
			throw new Error("Role '" + role + "' does not exist.");
		}
	}


	/**
	 * Returns all Roles.
	 * @return array
	 */
	public getRoles(): string[] {
		var roles: string[] = [];
		for (var r in this.roles) {
			roles.push(r);
		}
		return roles;
	}

	/**
	 * Returns existing Role's parents ordered by ascending priority.
	 * @param  string
	 * @return array
	 */
	public getRoleParents(role: string): string[]
	{
		this.checkRole(role);
		var roles: string[] = [];
		for (var r in this.roles[role]['parents']) {
			roles.push(r);
		}
		return roles;
	}


	/**
	 * Returns TRUE if $role inherits from $inherit. If $onlyDirect is TRUE,
	 * then $role must inherit directly from $inherit.
	 * @param  string
	 * @param  string
	 * @param  bool
	 * @throws Nette\InvalidStateException
	 * @return bool
	 */
	public roleInheritsFrom(role: string, parentRole: string, onlyDirect = false): boolean
	{
		this.checkRole(role);
		this.checkRole(parentRole);

		var inherits = this.roles[role]['parents'][parentRole] !== undefined;

		if (inherits || onlyDirect) {
			return inherits;
		}

		for(var parent in this.roles[role]['parents']) {
			if (this.roleInheritsFrom(parent, parentRole)) {
				return true;
			}
		}

		return false;
	}


	/**
	 * Removes the Role from the list.
	 *
	 * @param  string
	 * @throws Nette\InvalidStateException
	 * @return self
	 */
	public removeRole(role: string): this
	{
		this.checkRole(role);

		for(var child in this.roles[role]['children']) {
			delete this.roles[child]['parents'][role];
		}

		for(var parent in this.roles[role]['parents']) {
			delete this.roles[parent]['children'][role];
		}

		delete this.roles[role];

		for (var roleCurrent in this.rules['allResources']['byRole']) {
			var rules = this.rules['allResources']['byRole'][roleCurrent];
			if (role === roleCurrent) {
				delete this.rules['allResources']['byRole'][roleCurrent];
			}
		}

		for (var resourceCurrent in this.rules['byResource']) {
			var visitor = this.rules['byResource'][resourceCurrent];
			if (visitor['byRole']) {
				for (var roleCurrent in visitor['byRole']) {
					var rules = visitor['byRole'][roleCurrent];
					if (role === roleCurrent) {
						delete this.rules['byResource'][resourceCurrent]['byRole'][roleCurrent];
					}
				}
			}
		}

		return this;
	}


	/**
	 * Removes all Roles from the list.
	 *
	 * @return self
	 */
	public removeAllRoles(): this {
		this.roles = {};

		for (var roleCurrent in this.rules['allResources']['byRole']) {
			delete this.rules['allResources']['byRole'][roleCurrent];
		}

		for (var resourceCurrent in this.rules['byResource']) {
			var visitor = this.rules['byResource'][resourceCurrent];
			for (var roleCurrent in visitor['byRole']) {
				delete this.rules['byResource'][resourceCurrent]['byRole'][roleCurrent];
			}
		}

		return this;
	}




	// ----------------------------------------------------------------------------------------------------------------------------




	/**
	 * Adds a Resource having an identifier unique to the list.
	 *
	 * @param  string
	 * @param  string
	 * @throws Error
	 * @return self
	 */
	public addResource(resource: string, parent: string = null): this
	{
		this.checkResource(resource, false);

		if (this.resources[resource]) {
			throw new Error("Resource '" + resource + "' already exists in the list.");
		}

		if (parent !== null) {
			this.checkResource(parent);
			this.resources[parent]['children'][resource] = true;
		}

		this.resources[resource] = {
			'parent': parent,
			'children': {}
		};

		return this;
	}


	/**
	 * Returns TRUE if the Resource exists in the list.
	 * @param  string
	 * @return bool
	 */
	public hasResource(resource: string): boolean
	{
		this.checkResource(resource, false);
		return this.resources[resource];
	}


	/**
	 * Checks whether Resource is valid and exists in the list.
	 * @param  string
	 * @param  bool
	 * @throws Nette\InvalidStateException
	 * @return void
	 */
	private checkResource(resource: string, need: boolean = true)
	{
		if (!((typeof (resource) === 'string') && resource != '')) {
			throw new Error("Resource must be a non-empty string.");
		}
		if (need && !this.resources[resource]) {
			throw new Error("Resource '" + resource + "' does not exist.");
		}
	}


	/**
	 * Returns all Resources.
	 * @return array
	 */
	public getResources(): string[]
	{
		var resources: string[] = [];
		for (var r in this.resources) {
			resources.push(r);
		}
		return resources;
	}


	/**
	 * Returns TRUE if $resource inherits from $inherit. If $onlyParents is TRUE,
	 * then $resource must inherit directly from $inherit.
	 *
	 * @param  string
	 * @param  string
	 * @param  bool
	 * @throws Nette\InvalidStateException
	 * @return bool
	 */
	public resourceInheritsFrom(resource: string, parentResource: string, onlyDirect: boolean = false): boolean
	{
		this.checkResource(resource);
		this.checkResource(parentResource);

		if (this.resources[resource]['parent'] === null) {
			return false;
		}

		var parent = this.resources[resource]['parent'];
		if (parentResource === parent) {
			return true;
		}
		else if (onlyDirect) {
			return false;
		}

		while (this.resources[parent]['parent'] !== null) {
			parent = this.resources[parent]['parent'];
			if (parentResource === parent) {
				return true;
			}
		}

		return false;
	}


	/**
	 * Removes a Resource and all of its children.
	 *
	 * @param  string
	 * @throws Nette\InvalidStateException
	 * @return self
	 */
	public removeResource(resource: string): this
	{
		this.checkResource(resource);

		var parent = this.resources[resource]['parent'];
		if (parent !== null) {
			delete this.resources[parent]['children'][resource];
		}

		var removed = [resource];
		for (var child in this.resources[resource]['children']) {
			this.removeResource(child);
			removed.push(child);
		}

		for (var i in removed) {
			var resourceRemoved = removed[i];
			for(var resourceCurrent in this.rules['byResource']) {
				if (resourceRemoved === resourceCurrent) {
					delete this.rules['byResource'][resourceCurrent];
				}
			}
		}

		delete this.resources[resource];

		return this;
	}


	/**
	 * Removes all Resources.
	 * @return self
	 */
	public removeAllResources() {
		for (var resource in this.resources) {
			for (var resourceCurrent in this.rules['byResource']) {
				if (resource === resourceCurrent) {
					delete this.rules['byResource'][resourceCurrent];
				}
			}
		}

		this.resources = {};

		return this;
	}




	// ----------------------------------------------------------------------------------------------------------------------------




	/**
	 * Allows one or more Roles access to [certain $privileges upon] the specified Resource(s).
	 * If $assertion is provided, then it must return TRUE in order for rule to apply.
	 *
	 * @param  string|array|Permission::ALL  roles
	 * @param  string|array|Permission::ALL  resources
	 * @param  string|array|Permission::ALL  privileges
	 * @param  callable    assertion
	 * @return self
	 */
	public allow(roles: string|string[] = null, resources: string|string[] = null, privileges: string|string[] = null, assertion: Function = null)
	{
		this.setRule(true, true, roles, resources, privileges, assertion);
		return this;
	}


	/**
	 * Denies one or more Roles access to [certain $privileges upon] the specified Resource(s).
	 * If $assertion is provided, then it must return TRUE in order for rule to apply.
	 *
	 * @param  string|array|Permission::ALL  roles
	 * @param  string|array|Permission::ALL  resources
	 * @param  string|array|Permission::ALL  privileges
	 * @param  callable    assertion
	 * @return self
	 */
	public deny(roles: string|string[] = null, resources: string|string[] = null, privileges: string|string[] = null, assertion: Function = null)
	{
		this.setRule(true, false, roles, resources, privileges, assertion);
		return this;
	}


	/**
	 * Removes "allow" permissions from the list in the context of the given Roles, Resources, and privileges.
	 *
	 * @param  string|array|Permission::ALL  roles
	 * @param  string|array|Permission::ALL  resources
	 * @param  string|array|Permission::ALL  privileges
	 * @return self
	 */
	public removeAllow(roles: string|string[] = null, resources: string|string[] = null, privileges: string|string[] = null)
	{
		this.setRule(false, true, roles, resources, privileges);
		return this;
	}


	/**
	 * Removes "deny" restrictions from the list in the context of the given Roles, Resources, and privileges.
	 *
	 * @param  string|array|Permission::ALL  roles
	 * @param  string|array|Permission::ALL  resources
	 * @param  string|array|Permission::ALL  privileges
	 * @return self
	 */
	public removeDeny(roles: string|string[] = null, resources: string|string[] = null, privileges: string|string[] = null)
	{
		this.setRule(false, false, roles, resources, privileges);
		return this;
	}


	/**
	 * Performs operations on Access Control List rules.
	 * @param  bool  operation add?
	 * @param  bool  type
	 * @param  string|array|Permission::ALL  roles
	 * @param  string|array|Permission::ALL  resources
	 * @param  string|array|Permission::ALL  privileges
	 * @param  callable    assertion
	 * @throws Nette\InvalidStateException
	 * @return self
	 */
	private setRule(toAdd: boolean, type: boolean, roles: string|string[], resources: string|string[], privileges: string|string[], assertion: Function = null)
	{
		// ensure that all specified Roles exist; normalize input to array of Roles or NULL
		if (roles === null) {
			roles = [null];

		} else {
			if (!Array.isArray(roles)) {
				roles = [roles];
			}

			for (var i in roles) {
				var role = roles[i];
				this.checkRole(role);
			}
		}

		// ensure that all specified Resources exist; normalize input to array of Resources or NULL
		if (resources === null) {
			resources = [null];

		} else {
			if (!Array.isArray(resources)) {
				resources = [resources];
			}

			for (var i in resources) {
				var resource = resources[i];
				this.checkResource(resource);
			}
		}

		// normalize privileges to array
		if (privileges === null) {
			privileges = [];

		}
		else if(!Array.isArray(privileges)) {
			privileges = [privileges];
		}

		if (toAdd) { // add to the rules
			for (var i in resources) {
				var resource = resources[i];
				for (var j in roles) {
					var role = roles[j];
					var rules = this.getRules(resource, role, true);
					if (privileges.length === 0) {
						rules['allPrivileges']['type'] = type;
						rules['allPrivileges']['assert'] = assertion;
						if (!rules['byPrivilege']) {
							rules['byPrivilege'] = {};
						}
					} else {
						for (var k in privileges) {
							var privilege = privileges[k];
							if (!rules['byPrivilege'][privilege]) rules['byPrivilege'][privilege] = {};
							rules['byPrivilege'][privilege]['type'] = type;
							rules['byPrivilege'][privilege]['assert'] = assertion;
						}
					}
				}
			}
		}
		else { // remove from the rules
			for (var i in resources) {
				var resource = resources[i];
				for (var j in roles) {
					var role = roles[j];
					var rules = this.getRules(resource, role);
					if (rules === null) {
						continue;
					}
					if (privileges.length === 0) {
						if (resource === null && role === null) {
							if (type === rules['allPrivileges']['type']) {
								rules = {
									'allPrivileges': {
										'type': false,
										'assert': null
									},
									'byPrivilege': {}
								};
							}
							continue;
						}
						if (type === rules['allPrivileges']['type']) {
							delete rules['allPrivileges'];
						}
					} else {
						for (var k in privileges) {
							var privilege = privileges[k];
							if (rules['byPrivilege'][privilege] && type === rules['byPrivilege'][privilege]['type']) {
								delete rules['byPrivilege'][privilege];
							}
						}
					}
				}
			}
		}
		return this;
	}



	// ----------------------------------------------------------------------------------------------------------------------------



	/**
	 * Returns TRUE if and only if the Role has access to [certain $privileges upon] the Resource.
	 *
	 * This method checks Role inheritance using a depth-first traversal of the Role list.
	 * The highest priority parent (i.e., the parent most recently added) is checked first,
	 * and its respective parents are checked similarly before the lower-priority parents of
	 * the Role are checked.
	 *
	 * @param  string|Permission::ALL|IRole  role
	 * @param  string|Permission::ALL|IResource  resource
	 * @param  string|Permission::ALL  privilege
	 * @throws Nette\InvalidStateException
	 * @return bool
	 */
	public isAllowed(role: string|IRole = null, resource: string|IResource = null, privilege: string = null): boolean
	{
		this.queriedRole = role;
		if (role !== null) {
			if (typeof(role) == 'object' && typeof(role['getRoleId']) == 'function') { // instanceof IRole
				role = role.getRoleId();
			}
			this.checkRole(<string>role);
		}

		this.queriedResource = resource;
		if (resource !== null) {
			if (typeof (resource) == 'object' && typeof (resource['getResourceId']) == 'function') { // instanceof IResource
				resource = resource.getResourceId();
			}
			this.checkResource(<string>resource);
		}

		let result: boolean;
		do {

			// depth-first search on $role if it is not 'allRoles' pseudo-parent
			if (role !== null && null !== (result = this.searchRolePrivileges(privilege === null, <string>role, <string>resource, privilege))) {
				break;
			}

			if (privilege === null) {
				var rules = this.getRules(<string>resource, null);
				if (rules) { // look for rule on 'allRoles' psuedo-parent
					var breakDoWhile = false;
					for (var priv in rules['byPrivilege']) {
						var rule = rules['byPrivilege'][priv];
						if (false === (result = this.getRuleType(<string>resource, null, priv))) {
							breakDoWhile = true;
							break;
						}
					}
					if (breakDoWhile) {
						break;
					}
					if (null !== (result = this.getRuleType(<string>resource, null, null))) {
						break;
					}
				}
			}
			else {
				if (null !== (result = this.getRuleType(<string>resource, null, privilege))) { // look for rule on 'allRoles' pseudo-parent
					break;

				}
				else if (null !== (result = this.getRuleType(<string>resource, null, null))) {
					break;
				}
			}

			resource = this.resources[<string>resource]['parent']; // try next Resource
		}
		while (true);

		this.queriedRole = null;
		this.queriedResource = null;

		return result;
	}


	/**
	 * Returns real currently queried Role. Use by assertion.
	 * @return mixed
	 */
	public getQueriedRole()
	{
		return this.queriedRole;
	}


	/**
	 * Returns real currently queried Resource. Use by assertion.
	 * @return mixed
	 */
	public getQueriedResource()
	{
		return this.queriedResource;
	}




	// ----------------------------------------------------------------------------------------------------------------------------



	/**
	 * Performs a depth-first search of the Role DAG, starting at $role, in order to find a rule
	 * allowing/denying $role access to a/all $privilege upon $resource.
	 * @param  bool  all (true) or one?
	 * @param  string
	 * @param  string
	 * @param  string  only for one
	 * @return mixed  NULL if no applicable rule is found, otherwise returns ALLOW or DENY
	 */
	private searchRolePrivileges(all: boolean, role: string, resource: string, privilege: string): boolean|null
	{
		var dfs: any = {
			'visited': {},
			'stack': [role],
		};
		while (null != (role = dfs['stack'].pop())) {
			if (dfs['visited'][role]) {
				continue;
			}
			if (all) {
				var rules = this.getRules(resource, role);
				if (rules) {
					for (var privilege2 in rules['byPrivilege']) {
						var rule = rules['byPrivilege'][privilege2];
						if (false === this.getRuleType(resource, role, privilege2)) {
							return false;
						}
					}
					var type = this.getRuleType(resource, role, null);
					if (null !== type) {
						return type;
					}
				}
			} else {
				var type = this.getRuleType(resource, role, privilege);
				if (null !== type) {
					return type;

				}
				else {
					var type = this.getRuleType(resource, role, null);
					if (null !== type) {
						return type;
					}
				}
			}

			dfs['visited'][role] = true;
			for(var roleParent in this.roles[role]['parents']) {
				dfs['stack'].push(roleParent);
			}
		}
		return null;
	}


	/**
	 * Returns the rule type associated with the specified Resource, Role, and privilege.
	 * @param  string|Permission::ALL
	 * @param  string|Permission::ALL
	 * @param  string|Permission::ALL
	 * @return mixed  NULL if a rule does not exist or assertion fails, otherwise returns ALLOW or DENY
	 */
	private getRuleType(resource: string, role: string, privilege: string): boolean|null
	{
		var rule = null;

		var rules = this.getRules(resource, role);

		if (!rules) {
			return null;
		}

		if (privilege === null) {
			if (rules['allPrivileges']) {
				rule = rules['allPrivileges'];
			} else {
				return null;
			}
		}
		else if(!rules['byPrivilege'][privilege]) {
			return null;
		}
		else {
			rule = rules['byPrivilege'][privilege];
		}

		if (rule['assert'] === null || rule['assert'].apply(this, role, resource, privilege)) {
			return rule['type'];

		}
		else if(resource !== null || role !== null || privilege !== null) {
			return null;
		}
		else if(true === rule['type']) {
			return false;
		}
		else {
			return true;
		}
	}


	/**
	 * Returns the rules associated with a Resource and a Role, or NULL if no such rules exist.
	 * If the $create parameter is TRUE, then a rule set is first created and then returned to the caller.
	 * @param  string|Permission::ALL
	 * @param  string|Permission::ALL
	 * @param  bool
	 * @return array|NULL
	 */
	private getRules(resource: string, role: string, create: boolean = false)
	{
		var visitor: any = null;

		if (resource === null) {
			visitor = this.rules['allResources'];
		}
		else {
			if (!this.rules['byResource'][resource]) {
				if (!create) {
					return null;
				}
				this.rules['byResource'][resource] = {};
			}
			visitor = this.rules['byResource'][resource];
		}

		if (role === null) {
			if (!visitor['allRoles']) {
				if (!create) {
					return null;
				}
				visitor['allRoles']['byPrivilege'] = {};
			}
			return visitor['allRoles'];
		}

		if (!visitor['byRole'] || !visitor['byRole'][role]) {
			if (!create) {
				return null;
			}
			if (!visitor['byRole']) visitor['byRole'] = {};
			visitor['byRole'][role] = {};
			visitor['byRole'][role]['byPrivilege'] = {};
		}

		return visitor['byRole'][role];
	}
}
