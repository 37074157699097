import { BaseViewModel } from "./BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";
import i18next from "i18next";

import { IDialogSettings } from "./IDialogSettings";
import { SyncEvent } from "ts-events";

/**
 * User Profile
 */
export abstract class BaseDialogViewModel<T extends IDialogSettings> extends BaseViewModel<T>
{

    public onDialogOpened: SyncEvent<void> = new SyncEvent<void>();
	
    public onDialogClosed: SyncEvent<void> = new SyncEvent<void>();

	/**
	 * Was kendo window activated ?
	 */
	protected isActivated: KnockoutObservable<boolean> = ko.observable(false);

	/**
	 * Internal flag - are buttons of the dialog created?
	 */
	protected buttonsCreated: boolean = false;

	/**
	 * Default settings
	 */
	protected _settings: any = {
		dialog: {
			title: '',
			actions: ['Close'],
			modal: true,
			resizable: true,
			width: 1080,
			height: 640,
			maximize: false,
			buttons: null
		}
	};

	/**
	 * Kendo Window Settings
	 * @returns 
	 */
	protected kendoWindowSettings: any;
	
	/**
	 * Confgure + prepare kendo window configuration object
	 * @param settings 
	 */
	public configure(settings: IDialogSettings = null): void
	{
		super.configure(settings as any);
		const s = this.settings;
		const vm = this;
		this.kendoWindowSettings = {
			deactivate: function() {
				this.destroy();
				vm.onDialogClosed.post();
			},
			title: s.dialog.title,
			actions: s.dialog.actions,
			isOpen: true,
			resizable: s.dialog.resizable,
			modal: s.dialog.modal,
			width: s.dialog.width,
			height: s.dialog.height,
			open: function(e: any) {
				this.center();
				if(s.dialog.maximize) {
					this.maximize();
				}
				if(typeof(s.dialog.buttons) === 'function') {
					vm.windowButtons(e.sender.element, s.dialog.buttons(vm, this, e.sender));
				}
				vm.isActivated(true);
				vm.onDialogOpened.post();
			},
			visible: vm.isActivated
		};
	}

	
	/**
	 * Create buttons
	 * 
	 * @param element 
	 * @param buttons 
	 * @returns 
	 */
	protected windowButtons(element: JQuery, buttons: Array<any>): void
	{
		if(this.buttonsCreated) {
			return;
		}
		this.buttonsCreated = true;
		
		var wrapper = jQuery('<div class="k-button-panel"></div>');
		if(buttons.length > 0) {
			jQuery.each(buttons, (i, button: any) => {
				var btn = jQuery('<button type="button" class="btn"></button>');
				if (button.cls) {
					btn.addClass(button.cls);
				}
				if(button.align == 'right') {
					btn.addClass('float-end');
				}
				btn.html(button.label);
				if (button.click) {
					jQuery(btn).on('click', (button as any).click);
				}
				btn.appendTo(wrapper);
			});
		}
		element.after(wrapper);
		// some styles
		element.css({ position: 'absolute', right: 0, left: 0, height: 'auto' });
		element.closest('.k-window').css({ paddingTop: 0 });
		element.siblings('.k-window-titlebar').css({ marginTop: 0 });
	}

	/**
	 * Close dialog (if ViewModel is displayed as dialog)
	 */
	public closeDialog()
	{
		if(this.settings.dialog) {
			this.element.data('kendoWindow').close();
		}
	}

	/**
	 * Template
	 * 
	 * @returns HTMLElement
	 */
	public template(): HTMLElement
	{
		// Dialog ViewModel - use Kendo Window
		if(this.settings.dialog) {
			return (
				<div data-bind="kendoWindow: $root.kendoWindowSettings, visible: $root.isActivated">
					{this.dialogTemplate()}
				</div>
			);
		}
		// Standard ViewModel - no kendo window
		return this.dialogTemplate();
	}

	/**
	 * Dialog template
	 * 
	 * @returns HTMLElement
	 */
	public abstract dialogTemplate(): HTMLElement;
	
} 