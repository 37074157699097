import { Client } from './Client';
import { BaseRequest } from './BaseRequest';

// Single Request
export class Request extends BaseRequest {

	// AUTO ID
	static autoId = 0;

	// client
	client: Client;

	// is this notification ?
	isNotification: boolean;

	// request ID
	id: string;

	// method to call
	method: string;

	// parameters
	params: any;

	/**
	 * Get request ID
	 * @returns 
	 */
	private getRequestId(): string|number|undefined {
		if(this.isNotification) {
			return undefined;
		}
		return this.id == null ? this.client.settings.idPrefix + (++Request.autoId).toString() : this.id;
	}

	// constructor
	constructor(client: Client, method: string, params: any = {}, isNotification: boolean = false, id: string = null) {
		super(client);
		this.isNotification = isNotification;
		this.id = id;
		this.method = method;
		this.params = params;
	}

	/**
	 * Returns request payload as a string
	 * @returns string
	 */
	public getPayload(): string {
		return JSON.stringify({
			jsonrpc: this.client.settings.version,
			method: this.method,
			params: this.params,
			id: this.getRequestId()
		});
	}

	// Parse response
	public parseResponse<T>(response: any): T {
		if (typeof (response) == 'string' && response != "") {
			response = JSON.parse(response);
		}
		if (response.error) {
			throw response;
		}
		return response.result;
	}
}
