import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { IDialogSettings } from "../../common/IDialogSettings";
import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { SubjectWizard } from "../../components/SubjectWizard/SubjectWizard";
import { ISubjectData } from "../../components/SubjectWizard/ISubjectData";

export interface IConnectWithCarrierSettings extends IDialogSettings
{
}

/**
 * Connect with carrier
 */
@injectable()
export class ConnectWithCarrier extends BaseDialogViewModel<IConnectWithCarrierSettings>
{

    public onButtonsChanged: SyncEvent<Array<{ label: string, method: Function }>> = new SyncEvent<Array<{ label: string, method: Function }>>();

    public onDone: SyncEvent<any> = new SyncEvent<any>();

    private wizardViewModel: SubjectWizard = null;

    /**
     * Startup
     */
    public async startup()
    {
        await super.startup();

        if(!this.user.isAllowed('entity.carrier', 'connect')) {
            throw new BadRequestError('Access denied', 403);
        }        
    }

    /**
     * Render
     */
    public async rendered(): Promise<void>
    {
		this.wizardViewModel = await this.loadViewFrame<SubjectWizard>(SubjectWizard, 'wizard', {});
        this.wizardViewModel.onStepChanged.attach((change) => {
            this.onButtonsChanged.post(change.buttons);
        });
        this.wizardViewModel.onFinished.attach((data) => {
            this.connectWithCarrier(data);
        });
    }
    
    /**
     * Render buttons
     */
    public async refreshButtons(): Promise<any>
    {
        this.wizardViewModel.adjustButtons();
    }


    /**
     * Save / Send connection request/invitation to carrier
     */
    public async connectWithCarrier(subjectData: ISubjectData): Promise<void>
    {
    	let saved = await this.rpc.call('carrier.connectWithCarrier', { subjectData: subjectData });
        this.onDone.post(saved);
    }


    public dialogTemplate = (): HTMLElement => (
        <div>
            {/*<p data-bind="i18n: 'registration.connectProcesInfo'"></p>*/}
            <view-frame name="wizard" />
        </div>    
    )

}