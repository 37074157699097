import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { ConnectWithSpediter } from "./ConnectWithSpediter";


/**
 * Spediters GRID
 */
@injectable()
export class Spediters extends BaseViewModel<any>
{

	// GRID element
	private gridElement: JQuery<HTMLElement>;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

	// pro moznost manipulaci s nadrazenimy akcemi
	private gridConfiguratorViewModel: GridConfigurator;	


	// Grid Config
	private configureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var toolbarConfig = [];
		if (this.user.isAllowed('entity.spediter', 'connect')) {
			toolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="connect"><i class="icon-plus" ></i><span> ' + i18n.t('common.actions.connectToNewSpediter') + '</span></button>' });
		}
		//if (this.user.isAllowed('listSpediters', 'edit')) {
		//	toolbarConfig.push({ template: '<button type="button" class="k-button" data-action="update"><i class="icon-edit" ></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
		//}
		//if (this.user.isAllowed('listSpediters', 'delete')) {
		//	toolbarConfig.push({ template: '<button type="button" class="k-button" data-action="delete" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>' });
		//}
		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

						options.data.search = (this.grid.dataSource as any).searchText;

						KendoHelpers.replaceRequestData(options.data, '__');

						let response: any = await this.rpc.call('spediter.getMy', {
							excludeMe: true,
							query: {
								...options.data,
								select: '*,country(*)'
							}
						});

						response.data = Objects.flatten(response.data, '__');

						options.success(response);

					}
				},
				schema: {
					model: {
						fields: {
							'name': { editable: false, type: "string" },
							'ic': { editable: false, type: "string" },
							'address1': { editable: false, type: "string" },
							'city': { editable: false, type: "string" },
							'country__iso': { editable: false, type: "string" },
							'phone': { editable: false, type: "string" },
							'email': { editable: false, type: "string" }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "name", dir: "asc" },
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.grid);
				this.gridElement.find('[data-action=delete]').prop('disabled', true);
				this.gridElement.find('[data-action=update]').prop('disabled', true);
			},
			change: () => {
				var selected = this.grid.select().length > 0;
				this.gridElement.find('[data-action=delete]').prop('disabled', !selected);
				this.gridElement.find('[data-action=update]').prop('disabled', !selected);
			},
			columns: [{ 
				field: "name", 
				title: i18n.t('common.captions.name'),
				width: 80 
			}, { 
				field: "ic", 
				title: i18n.t('registration.firmIco'),
				width: 40 
			}, {
				field: 'address1',
				title: i18n.t('common.captions.address'),
				width: 250,
				template: '#= address1 + ", " + city + (countryId ? " " + country__iso : "") #'
			}, { 
				field: "phone", 
				title: i18n.t('registration.phone'),
				width: 40 
			}, { 
				field: "email", 
				title: i18n.t('registration.email'),
				width: 40 				
			}	]
		};
	}


	/**
	 * ViewModel startup
	 */
	public async startup(): Promise<any>
	{
		// Pokud sem nemuze, tak ho vyhodime
		if(!this.user.isAllowed('section.settingsSpediters', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		super.startup();

		// Configure GRID
		this.configureGrid();
	}


	public async rendered() 
	{
		// grid element
		this.gridElement = this.element.find('[data-grid=spediters]');

		/// initialize grid
		this.gridElement.kendoGrid(this.gridConfig);
		// Kendo GRID reference
		this.grid = this.gridElement.data('kendoGrid');
		// BIND action buttons
		this.gridElement.on('click', '[data-action=connect]', this.connectWithSpediter.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Spediter.Management',
			exports: [{
				name: 'XLS export',
				method: 'spediter.xlsExport',
				params: {
					excludeMe: true
				}
			}]
		} as IGridConfiguratorOptions);

		this.gridConfiguratorViewModel = vm;
	}

	/**
	 * Opens dialog for user insert
	 */
	private async connectWithSpediter()
	{
		let vm = await this.loadViewFrame<ConnectWithSpediter>(ConnectWithSpediter, 'edit', {
			dialog: {
				modal: true,
				width: 670,
				height: 430,
				title: i18n.t('common.actions.connectToNewSpediter'),
				buttons: (vm: ConnectWithSpediter, window: kendo.ui.Window): any[] => {
					return [{
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.cancel'),
						click: () => window.close() 
					}, {
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.save'),
						click: async () => {
							let result = await vm.connectWithSpediter();
							if(result) {
								window.close();
							}
						}
					}];
				}
			}
		});
		vm.onDone.attach((savedInfo: any) => { 
			if(savedInfo.approvalSent) {
				this.flash.success(i18n.t('system.subjects.connectionInvitationSent'));
			}
			vm.closeDialog(); 
			this.grid.dataSource.read();
		});
	}
	
   
	public template = (): HTMLElement => (

		<div>

			<h2 data-bind="i18n: 'nav.spediters'"></h2>

			<view-frame name="gridconf" className="mb-2" />

			<div data-grid="spediters" className="grid-autoheight"></div>

			<view-frame name="edit" />

		</div>
	);

}