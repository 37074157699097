import moment from "moment";

export class KnockoutHelpers
{

    /**
     * Replace nested property of mapped object
     * @param object Object
     * @param property Nested property
     * @param value NULL, scalar value or object to be mapped
     */
    public static replaceNested(object: any, property: string, value: any): void
    {
        if((typeof(value) !== 'object' || value === null) || (object[property] && typeof(object[property]) === 'function')) {
            object[property] = ko.mapping.fromJS(value);
        }
        else {
            ko.mapping.fromJS(value, {}, object[property]);
        }    
    }


    /**
     * Registrace pomocnych knockoutjs bindings
     */
    public static registerBindings(): void
    {
        // file src
        ko.bindingHandlers.fileSrc = {
            init: function (element, valueAccessor) {
                ko.utils.registerEventHandler(element, "change", function () {
                    var reader = new FileReader();
        
                    reader.onload = function (e) {
                        var value = valueAccessor();
                        value(e.target.result);
                    }
        
                    reader.readAsDataURL(element.files[0]);
                });
            }
        };

        // AppendElement Binding
        ko.bindingHandlers.appendElement = {
            init: function (element, valueAccessor) {
                //console.log('appending', valueAccessor);
                //console.log('appending', valueAccessor(), 'to', element);
                window.setTimeout(() => { valueAccessor().appendTo(element); }, 20);
            }
        };

        // uniqueId binding in the ViewModels context
        ko.bindingHandlers.uniqueId = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var rootVm = bindingContext.$root;
                jQuery(element).attr('id', 'vm-' + rootVm.viewModelNumber.toString() + '-' + valueAccessor());
            }
        };

        // uniqueFor binding in the ViewModels context
        ko.bindingHandlers.uniqueFor = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var rootVm = bindingContext.$root;
                jQuery(element).attr('for', 'vm-' + rootVm.viewModelNumber.toString() + '-' + valueAccessor());
            }
        };


        // date knockout bindings
        let setDateTimeDate = function(element: HTMLElement, valueAccessor: any, viewModel: any) {
            var matches = jQuery(element).attr('data-bind').match(/(?:^|,\s*)date\: ([a-zA-Z0-9_\.\-\$]+)(?:\s*,|$)/);
            var val = valueAccessor();
            if (typeof (val) === 'function') {
                val = val();
            }
            if (val === null) {
                jQuery(element).html('');
            }
            else {
                var property = matches[1];
                var valDate = moment(val, (<any>moment).ISO_8601);
                if (viewModel[property + 'Time']) {
                    var time = typeof (viewModel[property + 'Time']) === 'function' ? viewModel[property + 'Time']() : viewModel[property + 'Time'];
                    var valTime = moment(time, (<any>moment).ISO_8601);
                    if (moment(valTime).format('HH:mm') == 'Invalid date') {
                        jQuery(element).html(moment(valDate).format('DD.MM.YYYY'));
                    } else {
                        jQuery(element).html(moment(valDate).format('DD.MM.YYYY') + ' ' + moment(valTime).format('HH:mm'));
                    }
                }
                else {
                    jQuery(element).html(moment(valDate).format('DD.MM.YYYY'));
                }
            }
        };
        ko.bindingHandlers.date = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                setDateTimeDate(element, valueAccessor, viewModel);
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                setDateTimeDate(element, valueAccessor, viewModel);
            }
        };


        // date knockout bindings
        var setDateTimeDateTime = function (element: HTMLElement, valueAccessor: any, viewModel: any) {
            var val = valueAccessor();
            if (typeof (val) === 'function') {
                val = val();
            }
            if (val === null) {
                jQuery(element).html('');
            }
            else {
                var valDate = moment(val, (<any>moment).ISO_8601);
                jQuery(element).html(moment(valDate).format('DD.MM.YYYY HH:mm:ss'));
            }
        };        
        ko.bindingHandlers.datetime = {

            init: function(element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                setDateTimeDateTime(element, valueAccessor, viewModel);
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                setDateTimeDateTime(element, valueAccessor, viewModel);
            }
        };


        // BOOLean knockout binding
        ko.bindingHandlers.bool = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var val = valueAccessor();
                if (typeof (val) === 'function') {
                    val = val();
                }
                jQuery(element).html(val === null ? 'No' : (val ? 'Yes' : 'No'));
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                var val = valueAccessor();
                if (typeof (val) === 'function') {
                    val = val();
                }
                jQuery(element).html(val === null ? 'No' : (val ? 'Yes' : 'No'));
            }
        };

        // FileSize binding handler
        let filesizeUnits = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB'];
        let setFilesize = function(element: HTMLElement, val: number) {
            if (val === null) {
                jQuery(element).html('');
            }
            else {
                var index = 0;
                while (val > 1024) {
                    val /= 1024;
                    index++;
                }
                jQuery(element).html((Math.round(val*100)/100).toString() + ' ' + (filesizeUnits[index] ? filesizeUnits[index] : '?'));
            }
        };
        ko.bindingHandlers.filesize = {
            init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
                var val = valueAccessor();
                if (typeof (val) === 'function') {
                    val = val();
                }
                setFilesize(element, parseInt(val));
            },
            update: function (element, valueAccessor, allBindingsAccessor, viewModel) {
                var val = valueAccessor();
                if (typeof (val) === 'function') {
                    val = val();
                }
                setFilesize(element, parseInt(val));
            }
        };




    }

}