import { i18n } from "i18next-ko";
import moment from "moment";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { Tracedo } from "../../entities/Tracedo";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";

/**
 * Set Loaded (On the way)
 * ATD
 */
@injectable()
export class Load extends BaseDialogViewModel<any>
{
    /**
     * ATD datum
     */
    private atd: KnockoutObservable<Date>;

    /**
     * Tracedo object
     */
    private tracedo: KnockoutObservableType<Tracedo>;

    /**
     * ViewModel Startup
     *
     * @param id Tracedo ID
     */
    public async startup()
    {
        await super.startup();

        if (!this.user.isAllowed('entity.tracedo', 'approve')) {
            throw new BadRequestError('Access denied', 403);
        }

        let tracedo: Tracedo = await this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId, query: { select: "id, atd" } });
        this.atd = ko.observable(tracedo.atd ? kendo.parseDate(tracedo.atd as string) : new Date());
        this.tracedo = ko.mapping.fromJS(tracedo);
    }

    /**
     * Ulozit "Nakladku"
     */
    public async save(): Promise<boolean>
    {
        let atd = this.atd();
        if (atd === null){
            this.alertDialog(i18n.t('system.alerts.emptyATD'));
            return false;
        }
        let atdString = atd === null ? null : moment(atd).format();
        // setLoladed
        await this.rpc.call('tracedo.load', { id: this.settings.tracedoId, time: atdString });
        return true;
    }

	public dialogTemplate = (): HTMLElement => (

        <div>
            <ko with="$root.tracedo">

                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'atd', i18n: 'common.captions.atd'"></label>
                    <div className="col-md-10">
                        <input type="text" className="form-control" data-bind="kendoDateTimePicker: { value: $root.atd, animation: false }, uniqueId: 'atd'" />
                    </div>
                </div>

            </ko>
        </div>

	);

}
