import { h } from "../../../../tracejs/src/utils/JSXFactory";

export class EditablePoItems
{

    /**
	 * Editable PO Items template - for order creation / tracedo creation only
	 */
     public static template = (): HTMLElement => (    

        <div>
            
            <div className="mb-3">
                <ko with="$root.tracedo">
                    <table className="input-table">
                        <tr>
                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.truckType', uniqueFor: 'truckTypeId'"></label>: </td>
                            <td className="pe-4">
                                <select className="form-select" data-bind="
                                        uniqueId: 'truckTypeId',
                                        options: $root.truckTypes,
                                        optionsValue: 'truckTypeId',
                                        optionsText: 'name',
                                        optionsCaption: '-',
                                        value: $data.truckTypeId,
                                        attr:{ tabindex: '1' }
                                    " />
                            </td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.commodity', uniqueFor: 'commodity'"></label>: </td>
                            <td className="pe-4">
                                <input className="form-control" type="text" data-bind="
                                        uniqueId: 'commodity',
                                        value: $data.commodity,
                                        attr:{ tabindex: '2' }
                                    " />
                            </td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.commodityValue', uniqueFor: 'commodityValue'"></label>: </td>
                            <td className="pe-4">
                                <input className="form-control" type="number" data-bind="
                                        uniqueId: 'commodityValue',
                                        value: $data.commodityValue,
                                        attr:{ tabindex: '3' }
                                    " />
                            </td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.currency', uniqueFor: 'currencyId'"></label>: </td>
                            <td className="pe-4">
                                <select className="form-select" style="width: 80px;" data-bind="
                                        uniqueId: 'currencyId',
                                        value: $data.currencyId,
                                        options: $root.currencies,
                                        optionsText: 'iso',
                                        optionsValue: 'currencyId',
                                        attr:{ tabindex: '4' }
                                    "></select>
                            </td>
                        </tr>
                        <tr>
                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.colli', uniqueFor: 'colli'"></label>: </td>
                            <td className="pe-4">
                                <strong data-bind="text: kendo.toString($data.sumColli(), 'n0')"></strong>
                            </td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.kgs', uniqueFor: 'kgs'"></label>: </td>
                            <td className="pe-4">
                                <strong data-bind="text: kendo.toString($data.sumKgs(), 'n2')"></strong>
                            </td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.cbm', uniqueFor: 'cbm'"></label>: </td>
                            <td className="pe-4">
                                <strong data-bind="text: kendo.toString($data.sumCbm(), 'n2')"></strong>
                            </td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.ldm', uniqueFor: 'ldm'"></label>: </td>
                            <td className="pe-4">
                                <strong data-bind="text: kendo.toString($data.ldm(), 'n2')"></strong>
                            </td>
                        </tr>
                        {/*<tr>
                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.colli', uniqueFor: 'colli'"></label>: </td>
                            <td className="pe-4"><input className="form-control" type="number" data-bind="uniqueId: 'colli', value: $data.sumColli, enable: $root.poItems().length <= 0, attr:{ tabindex: '014' }" /></td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.kgs', uniqueFor: 'kgs'"></label>: </td>
                            <td className="pe-4"><input className="form-control" type="number" data-bind="uniqueId: 'kgs', value: $data.sumKgs, enable: $root.poItems().length <= 0, attr:{ tabindex: '015' }" /></td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.cbm', uniqueFor: 'cbm'"></label>: </td>
                            <td className="pe-4"><input className="form-control" type="number" data-bind="uniqueId: 'cbm', value: $data.sumCbm, enable: $root.poItems().length <= 0, attr:{ tabindex: '016' }" /></td>

                            <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.ldm', uniqueFor: 'ldm'"></label>: </td>
                            <td className="pe-4"><input className="form-control" type="number" data-bind="uniqueId: 'ldm', value: $data.ldm, attr:{ tabindex: '017' }" /></td>
                        </tr>*/}
                        {/* Pokud se nejedna o objednavani, zobrazime pole cena / cena dopravce 
                        <ko ifnot="$root.isOrdering">
                            <tr>
                                <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.price', uniqueFor: 'price'"></label>: </td>
                                <td className="pe-4">
                                    <input className="form-control d-inline-block me-2" type="number" data-bind="uniqueId: 'price', value: $data.price" style="width: 61%" />
                                    <select className="form-select d-inline-block" data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: $data.priceCurrencyId" style="width: 35%" />
                                </td>
                                <ko if="carrierId() != spediterId()">
                                    <td><label className="inline col-form-label" data-bind="i18n: 'common.captions.priceCarrier',uniqueFor: 'priceCarrier'" style="white-space: pre-wrap;line-height: 13px;"></label>: </td>
                                    <td className="pe-4">
                                        <input className="form-control d-inline-block me-2" type="number" id="priceCarrier" data-bind="uniqueId: 'priceCarrier', value: $data.priceCarrier" style="width: 61%" />
                                        <select className="form-select d-inline-block" data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: $data.priceCarrierCurrencyId" style="width: 35%" />
                                    </td>
                                </ko>
                            </tr>
                        </ko>*/}
                    </table>
                </ko>
            </div>

            <div>
                {/*<h3 class="form-h3"><span data-bind="i18n: 'trade.demand.edit.poItems'"></span></h3>*/}
                
                <div className="row mb-2">
                    <div className="col-sm-8">

                        <fieldset>
                            <legend data-bind="i18n: 'trade.demand.edit.poItem'"></legend>
                            <ko if="$root.poItems().length > 0">
                                <table class="input-table">
                                    <thead>
                                        <tr>
                                            <th style="width: 170px;">PN1</th>
                                            <th style="width: 170px;">PN2</th>
                                            <th style="width: 170px;">PN3</th>
                                            <th style="width: 170px;" data-bind="i18n: 'edit.poItem.descr'"></th>
                                            <th style="width: 125px;" data-bind="i18n: 'edit.poItem.packaging'"></th>
                                            <th style="width: 60px;" data-bind="i18n: 'edit.poItem.stackable'"></th>
                                            <th style="width: 90px;" data-bind="i18n: 'edit.poItem.colli'"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ko foreach="$root.poItems">
                                            <tr>
                                                <td><input className="form-control" type="text" data-bind="
                                                        value: $data.pn1,
                                                        attr: { tabindex: (($index()+1)*100).toString() + '01'}
                                                    " /></td>
                                                <td><input className="form-control" type="text" data-bind="
                                                        value: $data.pn2,
                                                        attr: { tabindex: (($index()+1)*100).toString() + '02'}
                                                    " /></td>
                                                <td><input className="form-control" type="text" data-bind="
                                                        value: $data.pn3,
                                                        attr: { tabindex: (($index()+1)*100).toString() + '03'}
                                                    " /></td>
                                                <td><input className="form-control" type="text" data-bind="
                                                        value: $data.descr,
                                                        attr: { tabindex: (($index()+1)*100).toString() + '04'}
                                                    " /></td>
                                                <td><select className="form-select" data-bind="
                                                        options: $root.colliTypes,
                                                        optionsCaption: '-',
                                                        optionsText: 'name',
                                                        optionsValue: 'colliTypeId',
                                                        value: $data.colliTypeId,
                                                        attr: { tabindex: (($index()+1)*100).toString() + '05'}
                                                    "></select></td>
                                                <td><select className="form-select" data-bind="
                                                        options: $root.stackables,
                                                        optionsText: 'name',
                                                        optionsValue: 'id',
                                                        value: $data.stackable,
                                                        attr: { tabindex: (($index()+1)*100).toString() + '06'}
                                                    "></select></td>
                                                <td><input className="form-control select-on-zero" type="number" data-bind="
                                                        value: $data.colli,
                                                        event: { change: $root.recountPoItems.bind($root, $parent, $parent.items) },
                                                        attr: { tabindex: (($index()+1)*100).toString() + '07'}
                                                    " /></td>
                                            </tr>
                                        </ko>
                                    </tbody>
                                </table>
                            </ko>
                        </fieldset>

                    </div>
                    <div className="col-sm-4">

                        <fieldset>
                            <legend>per colli</legend>
                            <ko if="$root.poItems().length > 0">
                                <table class="input-table">
                                    <thead>
                                        <tr>
                                            <th data-bind="i18n: 'edit.poItem.length'"></th>
                                            <th data-bind="i18n: 'edit.poItem.width'"></th>
                                            <th data-bind="i18n: 'edit.poItem.height'"></th>
                                            <th data-bind="i18n: 'edit.poItem.kgs'"></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ko foreach="$root.poItems">
                                        <tr>
                                            <td><input className="form-control select-on-zero" type="number" data-bind="
                                                    value: $data.length,
                                                    event: { change: $root.recountPoItems.bind($root, $parent, $parent.items) },
                                                    attr: { tabindex: (($index()+1)*100).toString() + '08' }
                                                " /></td>
                                            <td><input className="form-control select-on-zero" type="number" data-bind="
                                                    value: $data.width,
                                                    event: { change: $root.recountPoItems.bind($root, $parent, $parent.items) },
                                                    attr: { tabindex: (($index()+1)*100).toString() + '09' }
                                                " /></td>
                                            <td><input className="form-control select-on-zero" type="number" data-bind="
                                                    value: $data.height,
                                                    event: { change: $root.recountPoItems.bind($root, $parent, $parent.items) },
                                                    attr: { tabindex: (($index()+1)*100).toString() + '10' }
                                                " /></td>
                                            <td><input className="form-control select-on-zero" type="number" data-bind="
                                                    value: $data.weight,
                                                    event: { change: $root.recountPoItems.bind($root, $parent, $parent.items) },
                                                    attr: { tabindex: (($index()+1)*100).toString() + '11' }
                                                " /></td>
                                            <td><button type="button" class="btn btn-outline-danger" data-bind="click: $root.removePoItem.bind($root, $data, $parent.items)"><i class="icon-cancel"></i></button></td>
                                        </tr>
                                        </ko>
                                    </tbody>
                                </table>
                            </ko>
                        </fieldset>

                    </div>
                </div>
                <div>
                    <button type="button" class="btn btn-outline-primary" data-bind="click: $root.addPoItem.bind($root, $data)"><i class="icon-plus"></i> <span data-bind="i18n: 'trade.demand.edit.addPoItem'"></span></button>
                </div>
            </div>


        </div>
     );
}