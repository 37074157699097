import { i18n } from "i18next-ko";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { FileManager } from "../components/FileManager/FileManager";
import { Chat } from "./Chat";
import { DataLog } from "./DataLog";
import { GpsLog } from "./GpsLog";
import { PoTracedo } from "../PoManagement/PoTracedo";
import { ReadOnlyCustomFields } from "./_editPartials/ReadOnlyCustomFields";
import { EditableCustomFields } from "./_editPartials/EditableCustomFields";
import { ReadOnlyCheckpointsTemplate } from "./_editPartials/ReadOnlyCheckpointsTemplate";
import { EditableCheckpointsTemplate } from "./_editPartials/EditableCheckpointsTemplate";
import { EditablePoItems } from "./_editPartials/EditablePoItems";
import { AbstractEdit } from "./AbstractEdit";
import { TracedoHelpers } from "./TracedoHelpers";
import { Tracedo } from "../../entities/Tracedo";
import { Checkpoint } from "../../entities/Checkpoint";
import { ReadOnlyHeaderTemplate } from "./_editPartials/ReadOnlyHeaderTemplate";
import { EditableHeaderTemplate } from "./_editPartials/EditableHeaderTemplate";
import { User } from "../../entities/User";
import { PriceLog } from "./PriceLog";
import { Pass } from "./Pass";
import { KnockoutHelpers } from "../../model/KnockoutHelpers";
import { PoItem } from "../../entities/PoItem";
import { Calculator } from "../components/Calculator/Calculator";
import { CodelistManager } from "../../model/CodelistManager";

import { AddressbookRamp } from "../../entities/AddressbookRamp";
import { AddressContactEditor } from "../components/AddressContactEditor/AddressContactEditor";

/**
 * Tracedo edit
 */
@injectable()
export class Edit extends AbstractEdit
{
    protected destAddressEditor?: AddressContactEditor;
    protected destContactEditor?: AddressContactEditor;

    protected origAddressEditor?: AddressContactEditor;
    protected origContactEditor?: AddressContactEditor;

	protected checkpointsEditors: {[key: number]: { address: AddressContactEditor, contact: AddressContactEditor }};

	// GPS Log map is generated
	private mapGenerated: boolean = false;

	// GPS log ViewModel
	private gpsMapViewModel: any = null;

	// vynuceno read-only zobrazeni ? (detail dialog)
	private forcedReadOnly: boolean = false;

	// Je aktualni editujici poslednim dopravcem?
	private isFinalCarrier: boolean = false;

	// Zda je mozno editovat colli/kgs/rozmery na hlavicce tracedo (= neexistuje zadna PO Item)
	private editableDimensions: KnockoutComputed<boolean> = ko.computed(() => this.poItems().length <= 0);

	//  status přepravy (string), unloaded, new, canceled
	private tracedoStatusIdent: string = null;

	// Zda bude viditelny sloupec s COLLI v zastavkach
	/*private colliCheckpointsVisible: KnockoutComputed<boolean> = ko.computed(() => { 
		if(this.tracedo) {
			if(this.tracedo.originColli || 
				this.tracedo.destinationColli || 
				(this.originPoItems && this.originPoItems().length > 0) || 
				(this.destinationPoItems && this.destinationPoItems().length > 0)) {
				return true;
			}
			let inCheckpoints = false;
			this.tracedo.checkpoints().forEach((cp: Checkpoint) => {
				if(cp.colli || (cp.poItems && cp.poItems.length > 0)) {
					inCheckpoints = true;
				}
			});
			return inCheckpoints;
		}
		return false;
	});*/

	// Zda jsou v Originu nebo v Destinationu nebo v zastavkach nejake PO Items (pokud ne, je editovatelne colli,kgs,colliTypeId)
	private colliCheckpointsEditable: KnockoutComputed<boolean> = ko.pureComputed(() => {
		// neni jeste tracedo? neni editovatelne colli
		if(!this.tracedo) {
			return false;
		}
		// Pokud jsou PO Items v originu nebo destinationu, neni colli editovatelne
		if((this.originPoItems && this.originPoItems().length > 0) || (this.destinationPoItems && this.destinationPoItems().length > 0)) {
			return false;
		}
		// je nejaky PO Item v zastavkach?
		let poInCheckpoints = false;
		this.tracedo.checkpoints().forEach((cp: any) => {
			if(cp.poItems && cp.poItems().length > 0) {
				poInCheckpoints = true;
			}
		});
		// Pokud neni v checkpointech, muzeme editovat colli
		return !poInCheckpoints;
	});

	// Zda je editovane tracedo na seznamu tech, na ktere ma aktualni uzivatel dostavat notifikace (NEBO zda ma zapnuto, ze chce dostavat vsechny notifikace)
	private isNotificatedTracedo: KnockoutObservable<boolean> = ko.observable(false);
	// Zda ma aktualni uzivatel zapnuto zasilani notifikaci o vsech relevantnich prepravach
	private hasSendToAllEnabled: KnockoutObservable<boolean> = ko.observable(false);

	// Trial counts (max = max. pocet povoleny systemem, allowed = kolik jich jest pro aktualni subjekt zbyva)
	private trialCount: { max: number, allowed: number };
	// Trial - muze vytvorit? (nebo pokud edituje, tak je take true)
	private trialCanCreate: KnockoutObservable<boolean> = ko.observable(false);

	// Maximalni index dopravce
	private maxCarrier: number;

	// PIC user pro zakaznika (odberatele)
	private initialCustomerPartnerPicUserLoaded: boolean = false;
	private customerPartnerPicUserComboConfig: kendo.ui.DropDownListOptions = {
		cascadeFrom: 'customerPartnerIdInput',
		autoBind: true,
		dataTextField: 'realName',
		dataValueField: 'userId',
		optionLabel: '-',
		filter: 'contains',
		animation: false,
		dataSource: {
			serverFiltering: true,
			transport: {
				read: async (options: kendo.data.DataSourceTransportReadOptions) => {
					const result = await this.subjectPicUserRead(options, this.tracedo.customerPartnerId, this.tracedo.customerPartnerPicUserId, this.initialCustomerPartnerPicUserLoaded);
					if(result) {
						this.initialCustomerPartnerPicUserLoaded = true;
					}
				}
			}
		},
	}

	// PIC user pro dodavatele
	private initialSupplierPartnerPicUserLoaded: boolean = false;
	private supplierPartnerPicUserComboConfig: kendo.ui.DropDownListOptions = {
		cascadeFrom: 'supplierPartnerIdInput',
		autoBind: true,
		dataTextField: 'realName',
		dataValueField: 'userId',
		optionLabel: '-',
		filter: 'contains',
		animation: false,
		dataSource: {
			serverFiltering: true,
			transport: {
				read: async (options: kendo.data.DataSourceTransportReadOptions) => {
					const result = await this.subjectPicUserRead(options, this.tracedo.supplierPartnerId, this.tracedo.supplierPartnerPicUserId, this.initialSupplierPartnerPicUserLoaded);
					if(result) {
						this.initialSupplierPartnerPicUserLoaded = true;
					}
				}
			}
		},
	}	

    /**
     * Read users of subject based on the parent observable
     * 
     * @param picUserType What type of PIC user (customer, carrier, spediter)
     * @param options 
     * @param parentObservable 
     * @param valueObservable 
     * @returns 
     */
	 protected async subjectPicUserRead(
        options: kendo.data.DataSourceTransportReadOptions,
        parentObservable: KnockoutObservable<number>,
        valueObservable: KnockoutObservable<number>,
        initialLoaded: boolean
    ): Promise<boolean>
	{
		// pokud neni vybran subjekt, vratime prazdne uzivatele
		if(!parentObservable()) {
			options.success([]);
			return false;
		}
		// Pokud neni zafiltrovano, nevratime nic
		if(!options.data.filter || !options.data.filter.filters || !options.data.filter.filters[0]) {
			options.success([]);
			return false;
		}

		let searchText = null;
		let subjectId = null;
		options.data.filter.filters.forEach((flt: any) => {
			if(flt.field == 'realName') {
				searchText = flt.value;
			}
			if(flt.field == 'subjectId') {
				subjectId = flt.value;
			}
		});

		if(!subjectId) {
			options.success([]);
			return false;
		}

		let batch = this.rpc.batch();

		// pocatecni nacteni nastavene osoby
		let fltr = undefined;
		if(valueObservable() && !initialLoaded) {
			batch.call('selected', 'user.getBySubjectId', {
				subjectId: subjectId,
				deleted: true,
				query: {
					select: 'userId,firstName,lastName,realName',
					filter: {
						logic: 'and',
						filters: [{
							field: 'userId',
							operator: 'eq',
							value: valueObservable()
						}]
					},
				}
			});
		}

		batch.call('users', 'user.getBySubjectId', {
			subjectId: subjectId,
			query: {
				filter: fltr,
				search: searchText,
				select: 'userId,firstName,lastName,realName',
				page: 1,
				pageSize: 100,
				sort: [{ field: 'lastName', dir: 'asc' }],
			}
		});

		let response: any = await batch.run();

		let users: User[] = response['users'].data;

		// Pokud se selected user nevratil sam (strankovani / smazany), vlozime ho na konec
		if(response['selected'] && response['selected'].length > 0) {
			let selectedUser: User = response['selected'][0];
			let isSelectedThere = users.filter(u => u.userId == selectedUser.userId).length > 0;
			if(!isSelectedThere) {
				users.push(selectedUser);
			}
		}

		options.success(response['users'].data);
        return true;
	}


	/**
	* Startup
	* @param tracedoId tracedo ID (null if new tracedo is being created)
	* @return JQueryPromise<any>
	*/
	public async startup(): Promise<any>
	{
		await super.startup();

		// nemam pravo na create u tracedo a ID je prazdne?
		if(!this.user.isAllowed('entity.tracedo', 'create') && !this.settings.tracedoId) {
			throw new BadRequestError('Access denied - cannot create', 403);
		}

		// nemam pravo editovat tracedo a ID neni prazdne?
		if(!(this.user.isAllowed('entity.tracedo', 'edit') || this.user.isAllowed('entity.tracedo', 'view')) && this.settings.tracedoId) {
			throw new BadRequestError('Access denied - cannot edit/view', 403);
		}

		// Kolik muze byt dopravcu? Tolik jich zkusime vytahnout (v API se nam stejne omezi na ty, na ktere mame pravo)
		this.maxCarrier = this.codelistManager.getMaxCarrier();

		let batchInitial = this.rpc.batch();
		batchInitial.call('trialCount', 'tracedo.getTrialCount');
    	batchInitial.call('mainSubject', 'subject.getMain');
		batchInitial.call('loggedUser', 'user.getLoggedUser');

		// get one tracedo
		if(this.settings.tracedoId !== null) {
			batchInitial.call('tracedo', 'tracedo.getOne', {
				id: this.settings.tracedoId,
				query: {
					select: '*,' + 
						'customerPartner(*),customerPartnerPicUser(*),' + 
						'supplierPartner(*),supplierPartnerPicUser(*),' + 
						'mySubject(*),myPicUser(*),myInvoicingStatus(*),myPassStatus(*),myPassedFromStatus(*),mySeason(*),' + 
						'purchasePriceCurrency(*),sellPriceCurrency(*),' + 
						'mode(*),kind(*),roadType(*),bdpCntrType(*),truckType(*),' +
						'driverUser(*),trcStatus(*),createdUser(*),deliveryContact(*),depot(*),pickupContact(*),poItems(*),' +
						'checkpoints(*, ramp(*)),' +
						'deliveryAddress(country),destAddressCountry(*),pickupAddress(country),' +
						'originRamp(*),destinationRamp(*))',

					criteria: {
						checkpoints: {
							sort: [{field: 'posIndex', dir: 'asc'}]
						}
					}
				}
			});
			batchInitial.call('poConnection', 'poItem.getCheckpointConnections', { tracedoId: this.settings.tracedoId });
			batchInitial.call('notificated', 'tracedo.isMyNotificatedTracedo', { id: this.settings.tracedoId });
		}

		// Read initial batch + codelists
		let responseInitial: any = await batchInitial.run();

		this.mainSubjectId = responseInitial['mainSubject'].subjectId;
		this.mainSubject = responseInitial['mainSubject'];

		// Trial Count
		this.trialCount = responseInitial['trialCount'];
		this.trialCanCreate(this.settings.tracedoId || this.trialCount.allowed > 0 || this.trialCount.allowed == -1);
		if(!this.trialCanCreate()) {
			this.onDialogOpened.attach(() => {
				// Trial - cannot create next - hide save button
				this.element.next('.k-button-panel').find('.btn-primary').remove();
			});
		}

		let tracedo: Tracedo = responseInitial['tracedo'] ?? null;
		if (tracedo) {
			this.tracedoStatusIdent = this.codelistManager.getOneStatus(tracedo.trcStatusId, 'ident');
			if (tracedo.originRamp === null) {
				tracedo.originRamp = {"id": null, "name": null};
			}
			if (tracedo.destinationRamp === null) {
				tracedo.destinationRamp = {"id": null, "name": null};
			}
			if (tracedo.checkpoints) {
				for (let checkpoint of tracedo.checkpoints) {
					if (checkpoint.ramp === null) {
						checkpoint.ramp = {"id": null, "name": null};
					}
					if (!checkpoint.rampId) {
						checkpoint.rampId = null;
					}
				}
			}
		}

		// Pokud editujeme tracedo (a nezakladame nove)
		if(this.settings.tracedoId !== null) {

			// PO Items navazane k checkpointum / originu / destinationu
			this.setPoItemsConnections(tracedo, responseInitial['poConnection']);

			//zjistime vazbu prihlaseneho uzivatele k preprave

			// muj subjekt je shodny se spediterem na preprave?
			this.isSpediter = tracedo.myField === 'spediter';
			// muj subjekt je shodny s nejakym dopravcem na preprave>
			this.isCarrier = tracedo.myField.substring(0, 7) === 'carrier';
			// muj subjekt je shodny s dopravcem na preprave
			this.isCustomer = tracedo.myField === 'customer';
			// Nazev resourcu pro opravneni
			this.resourceName = 'entity.tracedo' + (this.isSpediter ? 'Spediter' : (this.isCustomer ? 'Customer' : 'Carrier'));
			
			// Je muj subjekt finalnim dopravcem ?
			if(this.isSpediter || this.isCarrier) {
				this.isFinalCarrier = await this.rpc.call('tracedo.isFinalCarrier', { id: this.settings.tracedoId, subjectId: tracedo.mySubjectId });
			}

			// Pokud jsem dopravce, zjistim, zda jsem poslednim dopravcem a zda mohu prepravu predat na dalsiho dopravce
			if(this.isCarrier) {
				let myCarrierNo = tracedo.myField === 'carrier' ? 1 : parseInt(tracedo.myField.substring(7), 10);
				if(this.isFinalCarrier && myCarrierNo < this.maxCarrier) {
					this.canPass = true;
				}
			}

			// read only = DETAIL ?
			this.forcedReadOnly = this.settings.forceReadOnly;

			// bud je mezi notifikovanymi NEBO dostava automaticky notifikace o vsech
			this.isNotificatedTracedo(responseInitial['notificated'] || responseInitial['loggedUser'].notificationTrcSendAll);
		}
		else {
			// zakladame nove tracedo => jsme spediter
			this.isSpediter = true;
			this.resourceName = 'entity.tracedoSpediter';

			// vychozi nastaveni pro zasilani notifikaci o teto preprave
			this.isNotificatedTracedo(responseInitial['loggedUser'].notificationTrcSendAll);
		}
		// dostava notifikace o vsech usecich?
		this.hasSendToAllEnabled(responseInitial['loggedUser'].notificationTrcSendAll);
		
		// Nacteni ciselniku
		await this.readCodelists();

		// pokud je toto nova preprava - prednastaveni dat
		if (this.settings.tracedoId === null) {

			// custom fields defalt values (NULLs)
			Object.keys(this.customFieldsDefaults).forEach((field: string) => { this.tracedo.fields[field] = ko.observable(this.customFieldsDefaults[field]); });

			// Dve posledni sezony pro novou prepravu
			this.seasons(this.seasons().slice(0, 2));

			// Moje sezona - prednastavit na aktualni
			this.tracedo.mySeasonId(this.currentSeason.seasonId);
			// Defaultni je export
			this.tracedo.kindId(this.transportKinds().filter((kind: any) => kind.ident.toLocaleUpperCase() === 'E')[0].kindId);

			// spediter/customer/carrier = main subject
			this.tracedo.myField('spediter');
			this.tracedo.mySubjectId(this.mainSubjectId);
			this.tracedo.customerPartnerId(this.mainSubjectId);
			this.tracedo.supplierPartnerId(this.mainSubjectId);

			// responsible person = current user
			this.tracedo.myPicUserId(this.user.identity.data.userId);
			
			// orders properties
			this.tracedo.mbl(null);
			this.tracedo.voyageNr(null);

			this.tracedo.destinationRampId(null);
			this.tracedo.destinationRampName(null);
			this.tracedo.originRampId(null);
			this.tracedo.originRampName(null);
			this.tracedo.cancelRequest(null);
			// vynutit nacteni sablony pro checkpointy
			await this.readCheckpointTemplate(true);
		}
		else {
			// datumy
			tracedo.etdFrom = tracedo.etdFrom === null ? null : kendo.parseDate(tracedo.etdFrom as string);
			tracedo.etdTo = tracedo.etdTo === null ? null : kendo.parseDate(tracedo.etdTo as string);
			tracedo.etaFrom = tracedo.etaFrom === null ? null : kendo.parseDate(tracedo.etaFrom as string);
			tracedo.etaTo = tracedo.etaTo === null ? null : kendo.parseDate(tracedo.etaTo as string);
			tracedo.atd = tracedo.atd === null ? null : kendo.parseDate(tracedo.atd as string);
			tracedo.atdReady = tracedo.atdReady === null ? null : kendo.parseDate(tracedo.atdReady as string);
			tracedo.ata = tracedo.ata === null ? null : kendo.parseDate(tracedo.ata as string);
			tracedo.ataReady = tracedo.ataReady === null ? null : kendo.parseDate(tracedo.ataReady as string);
			
			tracedo.myUzp = tracedo.myUzp === null ? null : kendo.parseDate(tracedo.myUzp as string);
			tracedo.supplierPartnerUzp = tracedo.supplierPartnerUzp === null ? null : kendo.parseDate(tracedo.supplierPartnerUzp as string);

			if(tracedo.checkpoints) {
				tracedo.checkpoints.forEach((checkpoint: any) => {
					checkpoint.eta = checkpoint.eta === null ? null : kendo.parseDate(checkpoint.eta);
					checkpoint.etd = checkpoint.etd === null ? null : kendo.parseDate(checkpoint.etd);
					checkpoint.ata = checkpoint.ata === null ? null : kendo.parseDate(checkpoint.ata);
					checkpoint.atd = checkpoint.atd === null ? null : kendo.parseDate(checkpoint.atd);
				});
			}
			else {
				tracedo.checkpoints = [];
			}

			tracedo.ecrFrom = tracedo.ecrFrom === null ? null : kendo.parseDate(tracedo.ecrFrom as string);
			tracedo.ecrTo = tracedo.ecrTo === null ? null : kendo.parseDate(tracedo.ecrTo as string);
			tracedo.rdlFrom = tracedo.rdlFrom === null ? null : kendo.parseDate(tracedo.rdlFrom as string);
			tracedo.rdlTo = tracedo.rdlTo === null ? null : kendo.parseDate(tracedo.rdlTo as string);
			
			// custom fields - extends default fields with already saved values
			tracedo.fields = jQuery.extend({}, this.customFieldsDefaults, tracedo.fields);

			// Pokud duplikujeme (kopirujeme prepravu, odebereme ID, aby se zalozila nova)
			if(this.settings.duplicate) {
				this.prefillDuplicate(tracedo);
			}
			else {
				// FINALLY SET tracedo OBJECT
				this.tracedo = ko.mapping.fromJS(tracedo);
			}
		}

		this.subscribeCheckpointTemplateChanges();
	}

	/**
	 * Vycisti duplikat
	 * @param tracedo 
	 */
	private prefillDuplicate(tracedo: Tracedo): void
	{

		this.tracedo.mySeasonId(this.currentSeason.seasonId);

		this.tracedo.customerPartnerId(tracedo.customerPartnerId);
		this.tracedo.myPicUserId(tracedo.myPicUserId);
		this.tracedo.supplierPartnerId(tracedo.supplierPartnerId);
		this.tracedo.kindId(tracedo.kindId);
		this.tracedo.roadTypeId(tracedo.roadTypeId);

		this.tracedo.pickupAddressId(tracedo.pickupAddressId);
		this.tracedo.originAddressCity(tracedo.originAddressCity);
		this.tracedo.originAddressCountryId(tracedo.originAddressCountryId);
		this.tracedo.originAddressName(tracedo.originAddressName);
		this.tracedo.originAddressStreet(tracedo.originAddressStreet);
		this.tracedo.originAddressStreet2(tracedo.originAddressStreet2);
		this.tracedo.originAddressZipCode(tracedo.originAddressZipCode);

		this.tracedo.pickupContactId(tracedo.pickupContactId);
		this.tracedo.originContactEmail(tracedo.originContactEmail);
		this.tracedo.originContactFirstName(tracedo.originContactFirstName);
		this.tracedo.originContactLastName(tracedo.originContactLastName);
		this.tracedo.originContactPhone(tracedo.originContactPhone);

		this.tracedo.deliveryAddressId(tracedo.deliveryAddressId);
		this.tracedo.destAddressCity(tracedo.destAddressCity);
		this.tracedo.destAddressCountryId(tracedo.destAddressCountryId);
		this.tracedo.destAddressName(tracedo.destAddressName);
		this.tracedo.destAddressStreet(tracedo.destAddressStreet);
		this.tracedo.destAddressStreet2(tracedo.destAddressStreet2);
		this.tracedo.destAddressZipCode(tracedo.destAddressZipCode);

		this.tracedo.deliveryContactId(tracedo.deliveryContactId);
		this.tracedo.destinationContactEmail(tracedo.destinationContactEmail);
		this.tracedo.destinationContactFirstName(tracedo.destinationContactFirstName);
		this.tracedo.destinationContactLastName(tracedo.destinationContactLastName);
		this.tracedo.destinationContactPhone(tracedo.destinationContactPhone);

		this.tracedo.bdpCntrTypeId(tracedo.bdpCntrTypeId);
		this.tracedo.rejdar(tracedo.rejdar);
		this.tracedo.custom(tracedo.custom);
		this.tracedo.customOffice(tracedo.customOffice);
		this.tracedo.shipperName(tracedo.shipperName);
		this.tracedo.consigneeName(tracedo.consigneeName);
		this.tracedo.depotId(tracedo.depotId);


		this.tracedo.originRampId(tracedo.originRampId);
		this.tracedo.originRampName(tracedo.originRampName);
		this.tracedo.destinationRampId(tracedo.destinationRampId);
		this.tracedo.destinationRampName(tracedo.destinationRampName);
		this.tracedo.cancelRequest(null);


		if(tracedo.originRamp) {
			this.tracedo.originRamp = ko.mapping.fromJS(tracedo.originRamp);
		}
		if(tracedo.originRamp) {
			this.tracedo.destinationRamp = ko.mapping.fromJS(tracedo.destinationRamp);
		}

		if(tracedo.checkpoints && tracedo.checkpoints.length > 0) {
			this.tracedo.checkpoints.removeAll();
			tracedo.checkpoints.forEach((cp: Checkpoint) => {
				// Je zastavka typova ?
				if(cp.typeId) {
					let c = this.addCheckpoint();
					c.addressbookId(cp.addressbookId);
					c.addressCountryId(cp.addressCountryId);
					c.addressName(cp.addressName);
					c.addressStreet(cp.addressStreet);
					c.addressStreet2(cp.addressStreet2);
					c.addressTown(cp.addressTown);
					c.addressZipCode(cp.addressZipCode);

					c.contactId(cp.contactId);
					c.contactFirstName(cp.contactFirstName);
					c.contactLastName(cp.contactLastName);
					c.contactPhone(cp.contactPhone);
					c.contactEmail(cp.contactEmail);

					c.typeId(cp.typeId);
					c.name(cp.name);
					c.rampId(cp.rampId);
					c.rampName(cp.rampName);

					if(cp.ramp) {
						c.ramp = ko.mapping.fromJS(cp.ramp);
					}

				}
			});
		}
		
		if(tracedo.fields) {
			this.tracedo.fields = ko.mapping.fromJS(tracedo.fields);
		}

		// PO Itemy neduplikovat
		// if(tracedo.poItems && tracedo.poItems.length > 0) {
		// 	this.poItems.removeAll();
		// 	tracedo.poItems.forEach((poItem: any) => {
		// 		this.poItems.push(ko.mapping.fromJS({
		// 			descr: poItem.descr,
		// 			ecrTimestamp: poItem.ecrTimestamp,
		// 			colliTypeId: poItem.colliTypeId,
		// 			stackable: poItem.stackable,
		// 			colli: poItem.colli,
		// 			length: poItem.length,
		// 			width: poItem.width,
		// 			height: poItem.height,
		// 			weight: poItem.weight
		// 		}))
		// 	});
		// }
	}

	/**
	 * Subscribe pro zmenu sablony checkpointu
	 */
	private subscribeCheckpointTemplateChanges(): void
	{
		this.tracedo.kindId.subscribe(() => this.readCheckpointTemplate());
		this.tracedo.roadTypeId.subscribe(() => this.readCheckpointTemplate());
	}

	/**
	 * Pokud je vybran kind (import/export) a roadType (treba Road Container), nacteme sablonu pro checkpointy
	 * @param forceChange Vynutit zmenu (zeptat se o nahrazeni), FALSE = pokud jsou jiz nastavene checkpointy, nic neudela
	 */
	private async readCheckpointTemplate(forceChange: boolean = true): Promise<void>
	{
		const kindId = this.tracedo.kindId();
		const roadTypeId = this.tracedo.roadTypeId();
		if(kindId && roadTypeId) {
			const template: any = await this.rpc.call('tracedo.getCheckpointTemplate', { 
				kindId: kindId, 
				roadTypeId: roadTypeId, 
				query: { 
					select: '*,items(*,type(*))',
					criteria: { 
						items: {
							sort: [{ field: 'posIndex', dir: 'asc' }]
						}
					}
				} 
			});
			// Pokud je pro zvolenou kombinaci k dispozici sablona checkpointu, zjistime, zda jiz uzivatel nejake pridal
			// Pokud ano, zeptame se ho, zda je chce nahradit ze sablony.
			// Pokud zatim zadne nepridal, nastavime je uzivateli automaticky
			if(template) {
				let checkpoints = this.tracedo.checkpoints();
				if(checkpoints.length > 0) {
					if(!forceChange) {
						return;
					}
					let replace = await this.confirmDialog(i18n.t('common.captions.replaceCheckpoints'), i18n.t('common.captions.checkpoints'));
					if(!replace) {
						return;
					}
				}

				let langSuffix = this.culture.localeShortCapitalized;

				this.tracedo.checkpoints.removeAll();
				template.items.forEach((item: any) => {
					this.addCheckpoint(item.type['name' + langSuffix], item.typeId);
				});
			}
		}
	}

	/**
	 * Pokud uzivatel prepne na zalozku s GPS logu
	 */
	private async kendoTabStripChanged(e: any)
	{
		if(e.item.id === 'gpsLog' && !this.mapGenerated) {
			this.mapGenerated = true;
			this.gpsMapViewModel = await this.loadViewFrame<GpsLog>(GpsLog, 'tracedo-gpslog', { tracedoId: this.settings.tracedoId });
		}
	}

	private async refreshGpsLog()
	{
		this.gpsMapViewModel.reload();
	}


	/**
	 * Otevre dialog pro licitaci o prodejni cene
	 */
	private async openSellPriceDetail(): Promise<PriceLog>
	{
		return await this.openPriceDetail('supplier');
	}

	/**
	 * Otevre dialog pro licitaci o nakupni cene
	 */
	private async openPurchasePriceDetail(): Promise<PriceLog>
	{
		return await this.openPriceDetail('customer');
	}

	/**
	 * Otevre dialog pro licitaci o cene (predaneho typu)
	 * @param type 'customer' / 'supplier'
	 * @returns 
	 */
	private async openPriceDetail(type: string): Promise<PriceLog>
	{
		let priceLogView = await this.loadViewFrame<PriceLog>(PriceLog, 'price-log', {
			tracedoId: this.tracedo.id(),
			type: type,
			dialog: {
				width: 630,
				height: 550,
				modal: true,
				title: i18n.t('common.captions.priceLog.' + type),
				buttons: (plVm: Edit, window: kendo.ui.Window) => {
					return [
						{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.close'), click: () => window.close() }
					];
				}
			}
		});

		// if price log is changed, refresh customer/supplier + sellPrice/purchasePrice
		priceLogView.onPriceLogSaved.attach((data: any) => {
			this.refreshSubjectsAndPrices(data.type);
		});
		priceLogView.onPriceLogApproved.attach((data: any) => {
			this.refreshSubjectsAndPrices(data.type);
		});		
		priceLogView.onPriceLogRejected.attach((data: any) => {
			this.refreshSubjectsAndPrices(data.type);
		});		

		return priceLogView;
	}

	/**
	 * Refresne ceny + status
	 * @param type 
	 */
	private async refreshSubjectsAndPrices(type: string): Promise<void>
	{
		let trc: Tracedo = await this.rpc.call('tracedo.getOne', {
			id: this.tracedo.id(),
			query: {
				select: '*,sellPriceCurrency(*),purchasePriceCurrency(*),myPassedFromStatus(*),myPassStatus(*)'
			}
		});

		if(type === 'supplier') {
			// dodavatel = prodejni cena
			this.tracedo.sellPrice(trc.sellPrice);
			KnockoutHelpers.replaceNested(this.tracedo, 'sellPriceCurrency', trc.sellPriceCurrency);
			this.tracedo.sellPriceCurrencyId(trc.sellPriceCurrencyId);
			// + status
			KnockoutHelpers.replaceNested(this.tracedo, 'myPassedFromStatus', trc.myPassedFromStatus);
			this.tracedo.myPassedFromStatusId(trc.myPassedFromStatusId);
		}
		else {
			// odberatel = nakupni cena
			this.tracedo.purchasePrice(trc.purchasePrice);
			KnockoutHelpers.replaceNested(this.tracedo, 'purchasePriceCurrency', trc.purchasePriceCurrency);
			this.tracedo.purchasePriceCurrencyId(trc.purchasePriceCurrencyId);
			// + status
			KnockoutHelpers.replaceNested(this.tracedo, 'myPassStatus', trc.myPassStatus);
			this.tracedo.myPassStatusId(trc.myPassStatusId);
		}	
	}


	/**
	 * When insert to DOM
	 */
	public async rendered()
	{
		await super.rendered();
		// je to editace - nacteme chat a datalog
		if(this.tracedo.id()) {

			if(this.user.isAllowed(this.resourceName, 'viewChat')) {
				this.loadViewFrame<Chat>(Chat, 'tracedo-chat', { tracedoId: this.settings.tracedoId });
			}

			if(this.user.isAllowed(this.resourceName, 'viewDataLog')) {
				this.loadViewFrame<DataLog>(DataLog, 'tracedo-datalog', { tracedoId: this.settings.tracedoId });
			}

			if(this.user.isAllowed(this.resourceName, 'viewFiles')) {
				this.loadViewFrame<FileManager>(FileManager, 'tracedo-files', { id: this.settings.tracedoId, type: 'tracedo' });
			}

			if(this.user.isAllowed(this.resourceName, 'viewCalculator')) {
				this.loadViewFrame<Calculator>(Calculator, 'calculator', {
					id: this.settings.tracedoId,
					roadTypeId: this.tracedo.roadTypeId,
					kindId: this.tracedo.kindId,
					readOnly: this.forcedReadOnly
				});
			}

			// editovat PO Items muze jen spediter, ktery ma pravo editovat usek
			const canEditPoItems = this.user.isAllowed(this.resourceName, 'edit') && this.tracedo.myField() == 'spediter';
			let vm = await this.loadViewFrame<PoTracedo>(PoTracedo, 'po-management', { 
				readOnly: !canEditPoItems,
				tracedoId: this.settings.tracedoId
			});

			// pri zmene PO Items obnovime colli,kgs,cbm
			vm.onRefreshDetail.attach(async () => {
				let refreshedTracedo: any = await this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId });
				this.tracedo.colli(refreshedTracedo.colli);
				this.tracedo.kgs(refreshedTracedo.kgs);
				this.tracedo.cbm(refreshedTracedo.cbm);
				this.tracedo.sumColli(refreshedTracedo.sumColli);
				this.tracedo.sumKgs(refreshedTracedo.sumKgs);
				this.tracedo.sumCbm(refreshedTracedo.sumCbm);
			});

			// muj subjekt je shodny s nejakym dopravcem na preprave>
			const isCarrier = this.tracedo.myField().substring(0, 7) === 'carrier';
			if(isCarrier) {
				// DESTINATION address + contact editor
				const cdd = this.tracedo.carriersDestinationData;
				this.destAddressEditor = await this.loadViewFrame<AddressContactEditor>(AddressContactEditor, 'destAddressEditor', {
					enableAddress: true,
					enableContact: false,
					address: {
						name: cdd && cdd.address ? cdd.address.name() : this.tracedo.destAddressName(),
						street: cdd && cdd.address ? cdd.address.street() : this.tracedo.destAddressStreet(),
						street2: cdd && cdd.address ? cdd.address.street2() : this.tracedo.destAddressStreet2(),
						city: cdd && cdd.address ? cdd.address.city() : this.tracedo.destAddressCity(),
						zipCode: cdd && cdd.address ? cdd.address.zipCode() : this.tracedo.destAddressZipCode(),
						countryId: cdd && cdd.address ? cdd.address.countryId() : this.tracedo.destAddressCountryId()
					}
				});
				this.destContactEditor = await this.loadViewFrame<AddressContactEditor>(AddressContactEditor, 'destContactEditor', {
					enableAddress: false,
					enableContact: true,
					contact: {
						lastName: cdd && cdd.contact ? cdd.contact.lastName() : this.tracedo.destinationContactLastName(),
						firstName: cdd && cdd.contact ? cdd.contact.firstName() : this.tracedo.destinationContactFirstName(),
						phone: cdd && cdd.contact ? cdd.contact.phone() : this.tracedo.destinationContactPhone(),
						email: cdd && cdd.contact ? cdd.contact.email() : this.tracedo.destinationContactEmail()
					}
				});

				// ORIGIN address + contact editor
				const cod = this.tracedo.carriersOriginData;
				this.origAddressEditor = await this.loadViewFrame<AddressContactEditor>(AddressContactEditor, 'origAddressEditor', {
					enableAddress: true,
					enableContact: false,
					address: {
						name: cod && cod.address ? cod.address.name() : this.tracedo.originAddressName(),
						street: cod && cod.address ? cod.address.street() : this.tracedo.originAddressStreet(),
						street2: cod && cod.address ? cod.address.street2() : this.tracedo.originAddressStreet2(),
						city: cod && cod.address ? cod.address.city() : this.tracedo.originAddressCity(),
						zipCode: cod && cod.address ? cod.address.zipCode() : this.tracedo.originAddressZipCode(),
						countryId: cod && cod.address ? cod.address.countryId() : this.tracedo.originAddressCountryId()
					}
				});
				this.origContactEditor = await this.loadViewFrame<AddressContactEditor>(AddressContactEditor, 'origContactEditor', {
					enableAddress: false,
					enableContact: true,
					contact: {
						lastName: cod && cod.contact ? cod.contact.lastName() : this.tracedo.originContactLastName(),
						firstName: cod && cod.contact ? cod.contact.firstName() : this.tracedo.originContactFirstName(),
						phone: cod && cod.contact ? cod.contact.phone() : this.tracedo.originContactPhone(),
						email: cod && cod.contact ? cod.contact.email() : this.tracedo.originContactEmail()
					}
				});

				// CHECKPOINTS address+ contact editor
				this.checkpointsEditors = {};
				this.tracedo.checkpoints().forEach(async (checkpoint: KnockoutObservableType<Checkpoint>) => {

					// ORIGIN address + contact editor
					const ccd = checkpoint.carriersData;
					this.checkpointsEditors[checkpoint.id()] = {
						address: await this.loadViewFrame<AddressContactEditor>(AddressContactEditor, 'checkpointAddressEditor_' + checkpoint.id(), {
							enableAddress: true,
							enableContact: false,
							address: {
								name: ccd && ccd.address ? ccd.address.name() : checkpoint.addressName(),
								street: ccd && ccd.address ? ccd.address.street() : checkpoint.addressStreet(),
								street2: ccd && ccd.address ? ccd.address.street2() : checkpoint.addressStreet2(),
								city: ccd && ccd.address ? ccd.address.city() : checkpoint.addressTown(),
								zipCode: ccd && ccd.address ? ccd.address.zipCode() : checkpoint.addressZipCode(),
								countryId: ccd && ccd.address ? ccd.address.countryId() : checkpoint.addressCountryId()
							}
						}),
						contact: await this.loadViewFrame<AddressContactEditor>(AddressContactEditor, 'checkpointContactEditor_' + checkpoint.id(), {
							enableAddress: false,
							enableContact: true,
							contact: {
								lastName: ccd && ccd.contact ? ccd.contact.lastName() : checkpoint.contactLastName(),
								firstName: ccd && ccd.contact ? ccd.contact.firstName() : checkpoint.contactFirstName(),
								phone: ccd && ccd.contact ? ccd.contact.phone() : checkpoint.contactPhone(),
								email: ccd && ccd.contact ? ccd.contact.email() : checkpoint.contactEmail()
							}
						})
					};
				});
			}
		}
		// else {
		// 	// zakladani noveho tracedo, pridame prvni polozku PO Item
		// 	if(!this.settings.duplicate) {
		// 		this.addPoItem();
		// 	} - 2022-03-09 - Nenabizet automaticky prazdnou PO Itemu - treba ji nechce vyplnovat
		// }
	}



    /**
     * Validace formulare pred ulozenim
     * @param tracedo Usek
     * @param poItems PO Items
     * @returns boolean TRUE / FALSE
     */
	protected validateForm(tracedo: any, poItems: any): boolean
	{
		// Pro editaci zakaznikem nebo dopravcem neni potreba validace, vse je nepovinne
		if(tracedo.id && tracedo.myField !== 'spediter') {
			return true;
		}
		// Kontrola na adresu + kontaktni osobu u checkpointu + ETA a ETD
		const invalidCheckpointAddress = () => {
			for(let i in tracedo.checkpoints) {
				let cp: any = tracedo.checkpoints[i];
				if(!cp.addressbookId && (jQuery.trim(cp.addressName) === '' || jQuery.trim(cp.addressTown) === '' || jQuery.trim(cp.addressZipCode) === '' || !cp.addressCountryId)) {
					this.alertDialog(i18n.t('system.alerts.invalidCheckpointAddress'));
					return true;
				}
			}
			return false;
		}

		// Kontrola na PO Items pri zakladani useku
		const invalidPoItems = () => {
			if(tracedo.id === null && poItems.length > 0) {
				let poItemsOk = true;
				poItems.forEach((poItem: any) => {
					if(jQuery.trim(poItem.descr) === '') {
						poItemsOk = false;
					}
				});
				if(!poItemsOk) {
					this.alertDialog(i18n.t('system.alerts.invalidPoItems'));
					return true;
				}
			} else {
				return false;
			}
		}
		const isValidated = {
			// Moje responsible person je povinna
            emptyMyPicUser: (!tracedo.myPicUserId || jQuery.trim(tracedo.myPicUserId) === '') ? true: false,
			// Musí být buď vybraná origin (pickup) a zároveň Destination (delivery) adresa, nebo vyplněná nová
			invalidAddress: ((!tracedo.pickupAddressId && (jQuery.trim(tracedo.originAddressCity) === '' || jQuery.trim(tracedo.originAddressZipCode) === '' || !tracedo.originAddressCountryId)) ? true : false) || ((!tracedo.deliveryAddressId && (jQuery.trim(tracedo.destAddressCity) === '' || jQuery.trim(tracedo.destAddressZipCode) === '' || !tracedo.destAddressCountryId)) ? true : false),
			// ETD from je povinne
			emptyETD: (!tracedo.etdFrom || jQuery.trim(tracedo.etdFrom) === '') ? true : false,
			// ETA from je povinne
			emptyETA: (!tracedo.etaFrom || jQuery.trim(tracedo.etaFrom) === '') ? true : false,
			// Customer je povinny
			emptyCustomer: (!tracedo.customerPartnerId || jQuery.trim(tracedo.customerPartnerId) === '') ? true : false,
			// Carrier je povinny
			emptyCarrier: (!tracedo.supplierPartnerId || jQuery.trim(tracedo.supplierPartnerId) === '') ? true : false,
			invalidCheckpointAddress: invalidCheckpointAddress,
			invalidPoItems: invalidPoItems
        }
		let errorMessages: string[] = [];

		Object.keys(isValidated).forEach((key) => {
			if(typeof((isValidated as any)[key]) === 'function' ? (isValidated as any)[key]() : (isValidated as any)[key]) {
				errorMessages.push(i18n.t(`system.alerts.${key}`));
			}
		});

		if(errorMessages.length > 0) {
			this.alertDialog(errorMessages.join("<br>\n"));
			return false;
		}
		return true;
	}

	/**
	 * Save tracedo and its items
	 */
	public async save(): Promise<boolean>
	{
		// Konverze do JS objektu z KnockoutJS observables
		let tracedo = ko.mapping.toJS(this.tracedo);
		let poItems = ko.mapping.toJS(this.poItems);

		const isCreating = tracedo.id === null;

		// kontrola před uložením
		if(!this.validateForm(tracedo, poItems)) {
			return false;
		}

		// mode Id podle roadTypeId
		tracedo.modeId = this.codelistManager.getOneRoadType(tracedo.roadTypeId, 'modeId');

		// nastaveni spravneho formatu date / datetime pro API
		this.formatDateFieldsForSave(tracedo);
		
	
		// pokud vytvarime novy usek, pridame PO Items pole, jinak se PO Items vyNULLuji (spravuji se na zvlast zalozce)
		tracedo.poItems = null;
		if(isCreating) {
			// Clear ID (it is preserved in createPosIndex)
			poItems.forEach(poItem => { poItem.id = null; });
			tracedo.poItems = poItems;
		}

		// Pokud mame zapnutou editaci destination address + contact a doslo ke zmene, posleme to na server
		if(this.destAddressEditor && this.destContactEditor) {
			if(this.destAddressEditor.isAddressModified() || this.destContactEditor.isContactModified()) {
				tracedo.carriersDestinationData = {
					address: this.destAddressEditor.data.address,
					contact: this.destContactEditor.data.contact
				};
			}
		}
		// Pokud mame zapnutou editaci origin address + contact a doslo ke zmene, posleme to na server
		if(this.origAddressEditor && this.origContactEditor) {
			if(this.origAddressEditor.isAddressModified() || this.origContactEditor.isContactModified()) {
				tracedo.carriersOriginData = {
					address: this.origAddressEditor.data.address,
					contact: this.origContactEditor.data.contact
				};
			}
		}
		// Pro kazdy checkpoint zkusime, zda je zapla editace address + contact a doslo ke zmene => posleme to na server
		tracedo.checkpoints.forEach((checkpoint) => {
			if(this.checkpointsEditors && this.checkpointsEditors[checkpoint.id]) {
				let cpEditor = this.checkpointsEditors[checkpoint.id];
				if(cpEditor.address.isAddressModified() || cpEditor.contact.isContactModified()) {
					checkpoint.carriersData = {
						address: cpEditor.address.data.address,
						contact: cpEditor.contact.data.contact
					};
				}
			}
		});

		// Ulozeni
		const saved: Tracedo = await this.rpc.call('tracedo.save', { tracedo: tracedo, query: { select: '*,checkpoints(*),poItems(*)' } });

		// Pokud se vytvarelo, nahradime nyni createPosIndex za realna ID
		if(isCreating) {
			// vytvorit hashmapu createPosIndex => id
			let poHash: {[key: number]: number} = {};
			saved.poItems.forEach((po: PoItem) => {
				poHash[po.createPosIndex] = po.id;
			});

			// Origin PO Items
			let newOriginPoItems: number[] = [];
			this.originPoItems().forEach(createPosIndex => {
				newOriginPoItems.push(poHash[createPosIndex]);
			})
			this.originPoItems(newOriginPoItems);

			// Destination PO Items
			let newDestinationPoItems: number[] = [];
			this.destinationPoItems().forEach(createPosIndex => {
				newDestinationPoItems.push(poHash[createPosIndex]);
			})
			this.destinationPoItems(newDestinationPoItems);

			// Checkpoints PO Items
			if(tracedo.checkpoints) {
				tracedo.checkpoints.forEach((cp, ix) => {
					let newCPPoItems: number[] = [];
					cp.poItems.forEach((createPosIndex: number) => {
						newCPPoItems.push(poHash[createPosIndex])
					});
					cp.poItems = newCPPoItems;
				});
			}
		}

		// Sestaveni MAPy pozice zastavky => ID zastavky
		let posIndexIdMap: { [key: number]: number } = {};
		saved.checkpoints.forEach((cp: Checkpoint) => {
			posIndexIdMap[cp.posIndex] = cp.id;
		});

		// Priprava connections PO Items na origin/destination/PO Item
		let connections: { poItemId: number, target: string|number }[] = [];
		this.originPoItems().forEach((poId: number) => {
			connections.push({ poItemId: poId, target: 'origin' });
		});
		this.destinationPoItems().forEach((poId: number) => {
			connections.push({ poItemId: poId, target: 'destination' });
		});
		if(tracedo.checkpoints) {
			tracedo.checkpoints.forEach((cp, ix) => {
				cp.poItems.forEach((poId: number) => {
					connections.push({ poItemId: poId, target: posIndexIdMap[ix] });
				});
			});
		}
		// Nastaveni PO Connections
		await this.rpc.call('poItem.setCheckpointConnections', { tracedoId: saved.id, connections });


		// Pokud neni pro aktualniho uzivatele zapnuto notifikovani o vsech tracedo prepravach, posleme nastaveni notifikaci k tomuto useku
		if(!this.hasSendToAllEnabled()) {
			this.rpc.notify(this.isNotificatedTracedo() ? 'tracedo.setMyNotificatedTracedo' : 'tracedo.unsetMyNotificatedTracedo', { id: saved.id });
		}

		return true;
	}

	/**
	 * Predat prepravu (prevzit ji jako dopravce)
	 */
	public async pass(): Promise<Pass>
	{
		return await this.passTracedo(this.tracedo.id(), false);
	}

	/**
	 * Zmenit predani prepravy na jineho dopravce
	 */
	public async changePass(): Promise<Pass>
	{
		return await this.passTracedo(this.tracedo.id(), true);
	}

	/**
	 * Postoupit prepravu
	 * @param tracedoId ID useku
	 * @param change Change
	 */
	public async passTracedo(tracedoId: number, change: boolean): Promise<Pass>
	{
		let passVM: Pass = await this.loadViewFrame<Pass>(Pass, 'pass-tracedo', {
			tracedoId: tracedoId,
			dialog: {
				width: 400,
				height: 270,
				modal: true,
				title: change ? i18n.t('common.captions.changeCarrier') : i18n.t('common.captions.pass'),
				buttons: (passVm: Pass, window: kendo.ui.Window) => {
					return [{
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.ok'),
						click: async () => {
							await passVm.save();
							//(vm as any).reload();
							window.close();
						}
					}, {
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.close'),
						click: () => {
							window.close();
						}
					}];
				}
			}
		});

		// when passed - update supplier + purchase price + pass status
		passVM.onPassed.attach(async (data: any) => {

			let trc: Tracedo = await this.rpc.call('tracedo.getOne', {
				id: data.tracedoId,
				query: {
					select: '*,purchasePriceCurrency(*),myPassStatus(*),supplierPartner(*),supplierPartnerPicUserId(*)'
				}
			});

			KnockoutHelpers.replaceNested(this.tracedo, 'supplierPartner', trc.supplierPartner);
			this.tracedo.supplierPartnerId(trc.supplierPartnerId);

			KnockoutHelpers.replaceNested(this.tracedo, 'supplierPartnerPicUser', trc.supplierPartnerPicUser);
			this.tracedo.supplierPartnerPicUserId(trc.supplierPartnerPicUserId);

			this.tracedo.supplierPartnerNumber(trc.supplierPartnerNumber);

			this.tracedo.purchasePrice(trc.purchasePrice);
			KnockoutHelpers.replaceNested(this.tracedo, 'purchasePriceCurrency', trc.purchasePriceCurrency);
			this.tracedo.purchasePriceCurrencyId(trc.purchasePriceCurrencyId);

			KnockoutHelpers.replaceNested(this.tracedo, 'myPassStatus', trc.myPassStatus);
			this.tracedo.myPassStatusId(trc.myPassStatusId);
		});

		return passVM;
	}
 



	public dialogTemplate = (): HTMLElement => (

		<div>

			<ko if="$root.trialCanCreate">

				<view-frame name="edit-checkpoint" />
				<view-frame name="pass-tracedo" />
				<view-frame name="price-log" />

				<div className="form-check form-switch float-end mt-2 me-2" style="position: relative; z-index: 1;">
					<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'isNotificatedTracedo', checked: $root.isNotificatedTracedo, disable: $root.hasSendToAllEnabled" />
					<label className="form-check-label" data-bind="i18n: 'common.captions.isOnNotifyListTrc', uniqueFor: 'isNotificatedTracedo'"></label>
				</div>

				<div data-bind="kendoTabStrip: { animation: false, select: $root.kendoTabStripChanged.bind($root)}">
					<ul>
						<li class="k-state-active"><span data-bind="i18n: 'trade.demand.edit.information'"></span></li>
						<ko if="$root.customFields().length > 0">
							<li><span data-bind="i18n: 'common.captions.additionalInformation'"></span></li>
						</ko>
						<ko if="$root.tracedo.id() === null">
							<li><span data-bind="i18n: 'trade.demand.edit.orderItems'"></span></li>
						</ko>
						<ko if="$root.tracedo.id() !== null">
							<li data-bind="i18n: 'common.captions.poItems'"></li>
							<ko if="$root.user.isAllowed($root.resourceName, 'viewChat')"> {/* Chat je mezi dispecerem dopravce a ridicem dopravce, tedy musim byt na preprave dopravcem */}
								<li data-bind="i18n: 'common.captions.chat'"></li>
							</ko>
							<ko if="$root.user.isAllowed($root.resourceName, 'viewDataLog')">
								<li data-bind="i18n: 'trade.demand.edit.dataLog'"></li>
							</ko>
							<li id="gpsLog" data-bind="i18n: 'common.captions.gps'"></li>
							<ko if="$root.user.isAllowed($root.resourceName, 'viewFiles')">
								<li data-bind="i18n: 'common.captions.documents'"></li>
							</ko>
							{/*<!--li data-bind="i18n: 'common.captions.userToNotify'"></li-->*/}
							<ko if="$root.user.isAllowed($root.resourceName, 'viewCalculator')">
								<li data-bind="i18n: 'common.captions.calculator'"></li>
							</ko>
						</ko>
					</ul>
					<div class="k-state-active">
						<ko with="$root.tracedo">

							{/* Vynuceno Readonly? = [Detail] */}
							<ko if="$root.forcedReadOnly || !$root.user.isAllowed($root.resourceName, 'edit')">
								<div class="row">
									{/* Subjects + PIC users + Main information */}
									<div class="col-md-4">
										<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerParties()}</ko>
										<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterParties()}</ko>
										<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierParties()}</ko>

										<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerIdentification()}</ko>
										<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterIdentification()}</ko>
										<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierIdentification()}</ko>

										<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerMainInfo()}</ko>
										<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterMainInfo()}</ko>
										<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierMainInfo()}</ko>
									</div>
									{/* Detaily pouze pro "road_container" prepravu */}
									<div class="col-md-4">
										<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerPrices()}</ko>
										<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterPrices()}</ko>
										<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierPrices()}</ko>
										
										<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerCargoInfo()}</ko>
										<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterCargoInfo()}</ko>
										<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierCargoInfo()}</ko>
									</div>
									{/* Cargo + Driver info + Prices */}
									<div class="col-md-4">
										<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerDriverInfo()}</ko>
										<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterDriverInfo()}</ko>
										<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierDriverInfo()}</ko>

										<div data-bind="visible: roadTypeId() == $root.roadTypesHashByIdent['road_container'].id">
											<ko if="$root.isCustomer">{ReadOnlyHeaderTemplate.customerContainerInfo()}</ko>
											<ko if="$root.isSpediter">{ReadOnlyHeaderTemplate.spediterContainerInfo()}</ko>
											<ko if="$root.isCarrier">{ReadOnlyHeaderTemplate.carrierContainerInfo()}</ko>
										</div>
									</div>
								</div>
								<ko if="$root.tracedo.cancelRequest() == '1' && $root.tracedoStatusIdent != 'canceled'">
									<div className="p-3 my-4"
										 style="background-color: rgb(255, 207, 206);border:1px solid #E4B2B5">
										<span style="font-size:1.5em;padding-right:5px">⚠</span>
										Bylo odesláno storno objednávky zákazníkem.
									</div>
								</ko>
								<ko if="$root.isCustomer">{ReadOnlyCheckpointsTemplate.customer()}</ko>
								<ko if="$root.isSpediter">{ReadOnlyCheckpointsTemplate.spediter()}</ko>
								<ko if="$root.isCarrier">{ReadOnlyCheckpointsTemplate.carrier()}</ko>
							</ko>
							<ko if="!$root.forcedReadOnly && $root.user.isAllowed($root.resourceName, 'edit')">
								<div class="row">
									{/* Subjects + PIC users + Main information */}
									<div class="col-md-4">
										<ko if="$root.isCustomer">{EditableHeaderTemplate.customerParties()}</ko>
										<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterParties()}</ko>
										<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierParties()}</ko>

										<ko if="$root.isCustomer">{EditableHeaderTemplate.customerIdentification()}</ko>
										<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterIdentification()}</ko>
										<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierIdentification()}</ko>

										<ko if="$root.isCustomer">{EditableHeaderTemplate.customerMainInfo()}</ko>
										<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterMainInfo()}</ko>
										<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierMainInfo()}</ko>
									</div>
									{/* Detaily pouze pro "road_container" prepravu */}
									<div class="col-md-4">
										<ko if="$root.isCustomer">{EditableHeaderTemplate.customerPrices()}</ko>
										<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterPrices()}</ko>
										<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierPrices()}</ko>

										<ko if="$root.isCustomer">{EditableHeaderTemplate.customerCargoInfo()}</ko>
										<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterCargoInfo()}</ko>
										<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierCargoInfo()}</ko>
									</div>
									{/* Cargo + Driver info + Prices */}
									<div class="col-md-4">
										<ko if="$root.isCustomer">{EditableHeaderTemplate.customerDriverInfo()}</ko>
										<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterDriverInfo()}</ko>
										<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierDriverInfo()}</ko>

										<div data-bind="visible: roadTypeId() == $root.roadTypesHashByIdent['road_container'].id">
											<ko if="$root.isCustomer">{EditableHeaderTemplate.customerContainerInfo()}</ko>
											<ko if="$root.isSpediter">{EditableHeaderTemplate.spediterContainerInfo()}</ko>
											<ko if="$root.isCarrier">{EditableHeaderTemplate.carrierContainerInfo()}</ko>
										</div>
									</div>
								</div>

								<ko if="$root.tracedo.cancelRequest() == '1' && $root.tracedoStatusIdent != 'canceled'">
									<div className="p-3 my-4"
										 style="background-color: rgb(255, 207, 206);border:1px solid #E4B2B5">
										<span style="font-size:1.5em;padding-right:5px">⚠</span>
										Bylo odesláno storno objednávky zákazníkem.
									</div>
								</ko>

								<ko if="$root.isCustomer">{EditableCheckpointsTemplate.customer()}</ko>
								<ko if="$root.isSpediter">{EditableCheckpointsTemplate.spediter()}</ko>
								<ko if="$root.isCarrier">{EditableCheckpointsTemplate.carrier()}</ko>								
							</ko>

						</ko>
					</div>

					{/* Custom Fields - viditelne pouze spediterovi */}
					<ko if="$root.customFields().length > 0">
						<div>
							<div className="row">
								<div className="col-md-5">
									<fieldset>
										<legend data-bind="i18n: 'common.captions.customFields'"></legend>
										<ko if="$root.forcedReadOnly || !$root.user.isAllowed($root.resourceName, 'edit')">
											{ReadOnlyCustomFields.template()}
										</ko>
										<ko if="!$root.forcedReadOnly && $root.user.isAllowed($root.resourceName, 'edit')">
											{EditableCustomFields.template()}
										</ko>
									</fieldset>
								</div>
							</div>
						</div>
					</ko>

					{/* zakladame novy usek => v zalozce PO Items bude formular pro vyplnovani PO Items */}
					<ko if="$root.tracedo.id() === null">
						{EditablePoItems.template()}
					</ko>

					<ko if="$root.tracedo.id() !== null">
						<div>
							<view-frame name="po-management" />
						</div>
						<ko if="$root.user.isAllowed($root.resourceName, 'viewChat')"> {/* Chat je mezi dispecerem dopravce a ridicem dopravce, tedy musim byt na preprave dopravcem */}
							<div>
								<view-frame name="tracedo-chat" />
							</div>
						</ko>
						<ko if="$root.user.isAllowed($root.resourceName, 'viewDataLog')">
							<div>
								<view-frame name="tracedo-datalog" />
							</div>
						</ko>
						<div>
							<view-frame name="tracedo-gpslog" />
							<button style="float: right;" type="button" class="k-button bottom-margin" data-bind="click: $root.refreshGpsLog.bind($root)"><i class="k-icon k-i-reload"></i></button>
						</div>
						<ko if="$root.user.isAllowed($root.resourceName, 'viewFiles')">
							<div>
								<view-frame name="tracedo-files" />
							</div>
						</ko>
						<ko if="$root.user.isAllowed($root.resourceName, 'viewCalculator')">
							<div>
								<view-frame name="calculator" />
							</div>
						</ko>
					</ko>

				</div>

			</ko>
			<ko ifnot="$root.trialCanCreate">
				
				<p style="text-align:center; padding:50px 0; font-size:18px" data-bind="i18n: 'trial.tracedoExceeded'"></p>
				
			</ko>

		</div>

	);

}