import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { BaseWidget } from "../../common/BaseWidget";
import { IDestinationSettings } from "./IDestinationSettings";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { inject, injectable } from "tsyringe";
import { DestinationSettings } from "./DestinationSettings";
import { BaseConfigurableWidget } from "../../common/BaseConfigurableWidget";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { TracedoHelpers } from "../../TrackAndTrace/TracedoHelpers";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";

/**
 * Destination settings
 */
@injectable()
export class Destination extends BaseConfigurableWidget
{
	/**
	 * Grid configurator ViewModel
	 */
 	protected gridConfiguratorViewModel: GridConfigurator = null;

 	/** GRID element */
 	protected gridElement: JQuery = null;
 	/** Kendo GRID */
 	protected grid: kendo.ui.Grid;
	
	// Grid Config
	protected gridConfig: kendo.ui.GridOptions;	 

 	//filtrace dle export/import
 	protected kindId: number = null;

	// Transport kinds
	private transportKinds: Array<{ kindId: number, name: string }> = [];

	// Invoicing statuses
	private invoicingStatuses: Array<{ statusId: number, ident: string, name: string }> = [];


 	// default sort
 	protected defaultSort = { field: "etaFrom", dir: "desc" };


	// Main subject ID prihlaseneho uzivatele
	protected mainSubjectId: number;

	// Max passed carriers count
	protected maxCarriers: number;

	/**
	 * Open widget settings
	 */
	public async openSettings(): Promise<void>
	{
		await this.openSettingsDialog<DestinationSettings>(DestinationSettings);
	} 

	/**
	 * Kendo GRID options
	 */
	protected configureGrid(): void
	{
		// pripravit toolbar
		let toolbarConfig = TracedoHelpers.tracedoGridCreateToolbar({
			'create': true,
			'edit': true,
			'duplicate': true,
			'detail': true,
			'setDriver': true,
			'setLoad': true,
			'setDischarge': true,
			'changeInvStatus': false,
			'suspend': true,
			// 'approve': true,
			// 'decline': true,
			'sellPriceDetail': true,
			'purchasePriceDetail': true,			
			'cancel': true,
			'kind': true,
			'generateTransportOrder': true,
			'generateCMR': true
		}, this.user, this.invoicingStatuses, this.transportKinds);
		// pripravit definici sloupcu s filtrama
		let gridColumnsDef = TracedoHelpers.tracedoGridDefineColumns(this.user, this.codelistManager);
		// pridat custom fields
		this.customFieldsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShort);
		// pridat typed checkpoints
		this.typedCheckpointsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShortCapitalized);

		// Kendo grid Schema model fields
		let schemaModelFields = TracedoHelpers.tracedoGridDefineSchema();
		// pridat do neho custom fields
	 	this.customFieldsHelper.appendSchemaFieldsTo(schemaModelFields);
		// pridat do nebo typed checkpoints fields
		this.typedCheckpointsHelper.appendSchemaFieldsTo(schemaModelFields);

		var widgetId = this.id;
		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
						options.data.search = (this.grid.dataSource as any).searchText;

						// filtrovat prepravy dle import export
						options.data = KendoHelpers.filterByKindId(options.data, this.kindId, 'kindId');

						let dateFields = jQuery.extend({},
							TracedoHelpers.tracedoGridDateColumns(),
							this.typedCheckpointsHelper.getDateFields(),
							this.customFieldsHelper.getDateFields('fields_')
						);						
						TracedoHelpers.compatibilizeRequestData(options.data, '__', dateFields);

						let response: any = await this.rpc.call('tracedo.getViewByWidgetId', {
							widgetId: this.id,
							query: {
								...options.data,
								select: TracedoHelpers.getSelect() +
										this.customFieldsHelper.getCustomFieldsSelect() + 
										this.typedCheckpointsHelper.getCheckpointTypesSelect()
							}
						});
						response.data = TracedoHelpers.processResponseData(response.data);
						response.data = KendoHelpers.tracedoSetRowColor(response.data);

						// TracedoHelpers.compatibilizeResponseData(response.data);

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: schemaModelFields
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: this.defaultSort,
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			columns: gridColumnsDef,
			detailInit: (e: kendo.ui.GridDetailInitEvent) => { TracedoHelpers.tracedoGridDetailInit(this.rpc, e, this.codelistManager); },
			dataBound: (e: kendo.ui.GridDataBoundEvent) => { TracedoHelpers.tracedoGridDataBound(this.grid, this.gridElement, e); },
			change: (e: kendo.ui.GridChangeEvent) => { TracedoHelpers.tracedoGridSelectionChanged(this.grid, this.gridElement, this.user, this.codelistManager); }
		};
	}

	/**
	 * Reread grid
	 */
	public reload()
	{
		this.grid.dataSource.read();
	}

    /**
     * Refresh widget (after settings are saved)
     */
	public refresh()
	{
		this.reload();
	}

	/**
	 * Startup widgetu
	 */
	public async startup()
	{
		// call parent startup
		await super.startup();

	 	// Pokud nemuze pouzit widget DESTINATION
	 	if(!this.user.isAllowed('widget.destination', 'use')) {
			throw new BadRequestError('Access denied', 403);
		}
		
		// Read Typed Checkpoints
		await this.typedCheckpointsHelper.loadCheckpointTypes();
	 	// Read custom fields
	 	await this.customFieldsHelper.loadCustomFields();

		// Read codelists
		let batch = this.rpc.batch();
		batch.call('mainSubject', 'subject.getMain');
		batch.call('maxCarriers', 'tracedo.getMaxCarrier');
		let batchResult: any = await batch.run();
		this.transportKinds = this.codelistManager.getKinds();
		this.invoicingStatuses = this.codelistManager.getInvoicingStatuses();
		this.mainSubjectId = batchResult['mainSubject'].subjectId;
		this.maxCarriers = batchResult['maxCarriers'];

		this.configureGrid();
	}

	/**
	 * Render
	 */
	public async rendered()
	{
		// grid element
		this.gridElement = this.element.find('div[data-grid=destination]');
		// initialize grid and save reference
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		TracedoHelpers.tracedoGridBindActions(this, this.grid, this.gridElement, this.user, this.tracedoManager);

		// Rychlý filtr pro IMPORT/EXPORT - nastavit kindId promennou a obnovit grid
		this.gridElement.on('change', '[data-action=selectKind]', (event: JQuery.ChangeEvent) => {
			let val = jQuery(event.currentTarget).val();
			this.kindId = val !== '-' ? parseInt(val as string, 10) : null;
			this.grid.dataSource.read();
		});

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			showButtonCaptions: false,
			grid: this.grid,
			name: 'Order.TrackAndTrace-' + this.id,
			exports: [{
				name: 'XLS export',
				method: 'tracedo.xlsExport',
                params: {
                    widgetId: this.id
                }
			}]
		} as IGridConfiguratorOptions);
		this.gridConfiguratorViewModel = vm;
	}


	public template = (): HTMLElement => (
		<div>
			<view-frame name="gridconf" className="mb-2" />
			<div data-grid="destination"></div>
			<view-frame name="actionDialog" />
			<view-frame name="widgetsettings" />
		</div>
	);

}