import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { ReadOnlyCheckpointsTemplate } from "./ReadOnlyCheckpointsTemplate";

export class EditableCheckpointsTemplate
{
	/**
	 * Customer v editaci ma pouze read-only checkpoints
	 */
	public static customer = (): HTMLElement => ReadOnlyCheckpointsTemplate.customer();

    /**
	 * Editable checkpoints, pri zakladani objednavky customerem
	 */
    public static customerOrder = (): HTMLElement => (

		<fieldset>
			<h3 className="d-inline-block me-2 align-baseline" data-bind="i18n: 'common.captions.checkpoints'"></h3>
			<button class="btn btn-outline-primary align-baseline" data-bind="click: $root.addEmptyCheckpoint.bind($root)"><i class="k-icon k-i-plus"></i> <span data-bind="i18n: 'common.captions.create'"></span></button>

			<div class="k-grid k-widget k-display-block" style="margin: 5px 0 0 0">
				<table className="table checkpoints-table">
					<thead>
						<tr>
							<th data-bind="i18n: 'common.captions.name'"></th>
							<th data-bind="i18n: 'common.captions.place'"></th>
							<th data-bind="i18n: 'common.captions.contact'"></th>
							<th data-bind="i18n: 'common.captions.estimatedTime'"></th>
							<th data-bind="i18n: 'common.captions.actualTime'"></th>
							<th data-bind="i18n: 'common.captions.reference'"></th>
							<th data-bind="i18n: 'common.captions.colli'" style="width:64px;"></th>
							<ko if="$root.colliCheckpointsEditable()">
								<th data-bind="i18n: 'common.captions.kgs'" style="width:75px;"></th>
								<th data-bind="i18n: 'edit.poItem.packaging'" style="width:120px;"></th>
							</ko>
							<th style="width: 140px"></th>
						</tr>
						<tr>
							<td data-bind="i18n: 'common.captions.origin'"></td>
							<td>
								<div class="carrier-overridable-address">
									<ko if="carriersOriginData && carriersOriginData.address">
										<div data-bind="kendoTooltip: { content: $root.buildCarriersAddress(carriersOriginData.address) }"><i className="icon-attention cell-attention"></i></div>
									</ko>
									<ko ifnot="originAddressName()">
										<em>-</em>
									</ko>
									<ko if="originAddressName()">
										<address>
											<span data-bind="text: originAddressName"></span><br />
											<span data-bind="text: originAddressStreet"></span><br />
											<ko if="jQuery.trim(originAddressStreet2()) !== ''">
												<span data-bind="text: originAddressStreet2"></span><br />
											</ko>
											<span data-bind="text: originAddressCity"></span> <span data-bind="text: originAddressZipCode"></span><br />
											<span data-bind="text: $root.countriesHash[originAddressCountryId()] ? $root.countriesHash[originAddressCountryId()] : ''"></span>
										</address>
										<ko if="originRampId">
											<span data-bind="i18n: 'common.captions.ramp'"></span>: <span data-bind="text: originRampName()"></span>
										</ko>
									</ko>
								</div>
							</td>
							<td>
								<div class="carrier-overridable-contact">
									<ko if="carriersOriginData && carriersOriginData.contact">
										<div data-bind="kendoTooltip: { content: $root.buildCarriersContact(carriersOriginData.contact) }"><i className="icon-attention cell-attention"></i></div>
									</ko>
									<ko ifnot="originContactLastName()">
										<em>-</em>
									</ko>
									<ko if="originContactLastName()">
										<p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: originContactFirstName"></span> <span data-bind="text:originContactLastName"></span></p>
										<p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: originContactPhone"></span></p>
										<p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: originContactEmail"></span></p>
									</ko>
								</div>
							</td>
							<td>
								<ko if="etdFrom()">
									<p><span data-bind="i18n: 'common.captions.etd'"></span> <span data-bind="i18n: 'common.captions.from'"></span>: <span data-bind="text: kendo.toString(etdFrom(), 'g')"></span></p>
								</ko>
								<ko if="etdTo()">
									<p><span data-bind="i18n: 'common.captions.etd'"></span> <span data-bind="i18n: 'common.captions.to'"></span>: <span data-bind="text: kendo.toString(etdTo(), 'g')"></span></p>
								</ko>
							</td>
							<td>
								<ko if="atd()">
									<p><span data-bind="i18n: 'common.captions.atd'"></span>: <span data-bind="text: kendo.toString(atd(), 'g')"></span></p>
								</ko>
							</td>
							<td data-bind="text: loadingReference"></td>

							<ko if="$root.colliCheckpointsEditable()">
								<td><input type="number" className="form-control" data-bind="value: $root.tracedo.originColli" /></td>
								<td><input type="number" className="form-control" data-bind="value: $root.tracedo.originKgs" /></td>
								<td><select className="form-select" data-bind="value: $root.tracedo.originColliTypeId, options: $root.colliTypes, optionsText: 'name', optionsValue: 'colliTypeId', optionsCaption: ''"></select></td>
							</ko>
							<ko ifnot="$root.colliCheckpointsEditable()">
								<td><input readonly="readonly" type="number" className="form-control" data-bind="value: $root.originPoItems() ? $root.originPoItems().length : 0" /></td>
								{/*<td><input readonly="readonly" type="number" className="form-control" data-bind="value: $root.originPoItems() ? 'kgs' : 0" /></td>
								<td><input readonly="readonly" type="text" className="form-control" data-bind="value: $root.originPoItems() ? 'colliType' : 0" /></td>*/}
							</ko>

							<td>
								<button class="btn btn-outline-primary w-100" data-bind="click: $root.editOriginCheckpoint.bind($root)"><i class="icon-edit"></i> <span data-bind="i18n: 'common.actions.edit'"></span></button>
							</td>
						</tr>
					</thead>
					<tbody data-bind="sortable: { data: checkpoints, options: { axis: 'y', placeholder: 'checkpoint-sortable-placeholder' }}">
						<tr>
							<td>
								<ko if="typeId()">
									<span data-bind="text: $root.checkpointTypesHash[typeId()].nameTranslated"></span>
								</ko>
								<ko ifnot="typeId()">
									<span data-bind="text: name"></span>
								</ko>
							</td>
							<td>
								<div class="carrier-overridable-address">
									<ko if="$data.carriersData && $data.carriersData.address">
										<div data-bind="kendoTooltip: { content: $root.buildCarriersAddress($data.carriersData.address) }"><i className="icon-attention cell-attention"></i></div>
									</ko>
									<ko ifnot="addressName()">
										<em>-</em>
									</ko>
									<ko if="addressName()">
										<address>
											<span data-bind="text: addressName"></span><br />
											<span data-bind="text: addressStreet"></span><br />
											<ko if="jQuery.trim(addressStreet2()) !== ''">
												<span data-bind="text: addressStreet2"></span><br />
											</ko>
											<span data-bind="text: addressTown"></span>, <span data-bind="text: addressZipCode"></span>, <span data-bind="text: $root.countriesHash[addressCountryId()] ? $root.countriesHash[addressCountryId()] : ''"></span>
										</address>
									</ko>
								</div>
								<ko if="rampId">
									<span data-bind="i18n: 'common.captions.ramp'"></span>: <span data-bind="text: rampName"></span>
								</ko>
							</td>
							<td>
								<div class="carrier-overridable-contact">
									<ko if="$data.carriersData && $data.carriersData.contact">
										<div data-bind="kendoTooltip: { content: $root.buildCarriersContact($data.carriersData.contact) }"><i className="icon-attention cell-attention"></i></div>
									</ko>									
									<ko ifnot="contactLastName()">
										<em>-</em>
									</ko>
									<ko if="contactLastName()">
										<p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: contactFirstName"></span> <span data-bind="text: contactLastName"></span></p>
										<p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: contactPhone"></span></p>
										<p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: contactEmail"></span></p>
									</ko>
								</div>
							</td>
							<td>
								<ko if="eta()">
									<p><span data-bind="i18n: 'common.captions.eta'"></span>: <span data-bind="text: kendo.toString(eta(), 'g')"></span></p>
								</ko>
								<ko if="etd()">
									<p><span data-bind="i18n: 'common.captions.etd'"></span>: <span data-bind="text: kendo.toString(etd(), 'g')"></span></p>
								</ko>
							</td>
							<td>
								<ko if="ata()">
									<p><span data-bind="i18n: 'common.captions.ata'"></span>: <span data-bind="text: kendo.toString(ata(), 'g')"></span></p>
								</ko>
								<ko if="ata()">
									<p><span data-bind="i18n: 'common.captions.atd'"></span>: <span data-bind="text: kendo.toString(atd(), 'g')"></span></p>
								</ko>
							</td>
							<td data-bind="text: reference"></td>

							<ko if="$root.colliCheckpointsEditable()">
								<td><input type="number" className="form-control" data-bind="value: $data.colli" /></td>
								<td><input type="number" className="form-control" data-bind="value: $data.kgs" /></td>
								<td><select className="form-select" data-bind="value: $data.colliTypeId, options: $root.colliTypes, optionsText: 'name', optionsValue: 'colliTypeId', optionsCaption: ''"></select></td>
							</ko>
							<ko ifnot="$root.colliCheckpointsEditable()">
								<td><input readonly="readonly" type="number" className="form-control" data-bind="value: $data.poItems() ? $data.poItems().length : 0" /></td>
								{/*<td><input readonly="readonly" type="number" className="form-control" data-bind="value: $data.poItems() ? 'kgs' : 0" /></td>
								<td><input readonly="readonly" type="text" className="form-control" data-bind="value: $data.poItems() ? 'colliType' : 0" /></td>*/}
							</ko>								

							<td>
								<button class="btn btn-outline-primary w-100 mb-1" data-bind="click: $root.editStandardCheckpoint.bind($root, $data)"><i class="icon-edit"></i> <span data-bind="i18n: 'common.actions.edit'"></span></button>
								<button class="btn btn-outline-danger w-100" data-bind="click: $root.deleteCheckpoint.bind($root, $data)"><i class="icon-trash"></i> <span data-bind="i18n: 'common.actions.delete'"></span></button>
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td data-bind="i18n: 'common.captions.destination'"></td>
							<td>
								<div class="carrier-overridable-address">
									<ko if="carriersDestinationData && carriersDestinationData.address">
										<div data-bind="kendoTooltip: { content: $root.buildCarriersAddress(carriersDestinationData.address) }"><i className="icon-attention cell-attention"></i></div>
									</ko>								
									<ko ifnot="destAddressName()">
										<em>-</em>
									</ko>
									<ko if="destAddressName()">
										<address>
											<span data-bind="text: destAddressName"></span><br />
											<span data-bind="text: destAddressStreet"></span><br />
											<ko if="jQuery.trim(destAddressStreet2()) !== ''">
												<span data-bind="text: destAddressStreet2"></span><br />
											</ko>
											<span data-bind="text: destAddressCity"></span> <span data-bind="text: destAddressZipCode"></span><br />
											<span data-bind="text: $root.countriesHash[destAddressCountryId()] ? $root.countriesHash[destAddressCountryId()] : ''"></span>
										</address>
										<ko if="destinationRampId">
											<span data-bind="i18n: 'common.captions.ramp'"></span>: <span data-bind="text: destinationRampName()"></span>
										</ko>
									</ko>
								</div>
							</td>
							<td>
								<div class="carrier-overridable-contact">
									<ko if="carriersDestinationData && carriersDestinationData.contact">
										<div data-bind="kendoTooltip: { content: $root.buildCarriersContact(carriersDestinationData.contact) }"><i className="icon-attention cell-attention"></i></div>
									</ko>
									<ko ifnot="destinationContactLastName()">
										<em>-</em>
									</ko>
									<ko if="destinationContactLastName()">
										<p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: destinationContactFirstName"></span> <span data-bind="text:destinationContactLastName"></span></p>
										<p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: destinationContactPhone"></span></p>
										<p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: destinationContactEmail"></span></p>
									</ko>
								</div>
							</td>
							<td>
								<ko if="etaFrom()">
									<p><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.from'"></span>: <span data-bind="text: kendo.toString(etaFrom(), 'g')"></span></p>
								</ko>
								<ko if="etaTo()">
									<p><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.to'"></span>: <span data-bind="text: kendo.toString(etaTo(), 'g')"></span></p>
								</ko>
							</td>
							<td>
								<ko if="ata()">
									<p><span data-bind="i18n: 'common.captions.ata'"></span>: <span data-bind="text: kendo.toString(ata(), 'g')"></span></p>
								</ko>												
							</td>
							<td data-bind="text: unloadingReference"></td>

							<ko if="$root.colliCheckpointsEditable()">
								<td><input type="number" className="form-control" data-bind="value: $root.tracedo.destinationColli" /></td>
								<td><input type="number" className="form-control" data-bind="value: $root.tracedo.destinationKgs" /></td>
								<td><select className="form-select" data-bind="value: $root.tracedo.destinationColliTypeId, options: $root.colliTypes, optionsText: 'name', optionsValue: 'colliTypeId', optionsCaption: ''"></select></td>
							</ko>
							<ko ifnot="$root.colliCheckpointsEditable()">
								<td><input readonly="readonly" type="number" className="form-control" data-bind="value: $root.destinationPoItems() ? $root.destinationPoItems().length : 0" /></td>
								{/*<td><input readonly="readonly" type="number" className="form-control" data-bind="value: $root.destinationPoItems() ? 'kgs' : 0" /></td>
								<td><input readonly="readonly" type="text" className="form-control" data-bind="value: $root.destinationPoItems() ? 'colliType' : 0" /></td>*/}
							</ko>

							<td>
								<button class="btn btn-outline-primary w-100" data-bind="click: $root.editDestinationCheckpoint.bind($root)"><i class="icon-edit"></i> <span data-bind="i18n: 'common.actions.edit'"></span></button>
							</td>
						</tr>
					</tfoot>

				</table>
			</div>
		</fieldset>
	);

	/**
	 * Spediter ma stejnou editaci jako zakaznik pri objednavani prepravy
	 */
	public static spediter = (): HTMLElement => EditableCheckpointsTemplate.customerOrder();

	/**
	 * Carrier muze editovat poznamky k checkpointum + originu a destinationu
	 * a dale muze editovat pole REFERENCE a adresy + kontaktni osoby (otisk na preprave)
	 */
	public static carrier = (): HTMLElement => (

        <fieldset>
            <h3 className="d-inline-block me-2 align-baseline" data-bind="i18n: 'common.captions.checkpoints'"></h3>

            <div class="k-grid k-widget k-display-block" style="margin: 5px 0 0 0">
                <table className="table checkpoints-table">
                    <thead>
                        <tr>
                            <th data-bind="i18n: 'common.captions.name'"></th>
                            <th data-bind="i18n: 'common.captions.place'"></th>
                            <th data-bind="i18n: 'common.captions.contact'"></th>
                            <th data-bind="i18n: 'common.captions.estimatedTime'"></th>
                            <th data-bind="i18n: 'common.captions.actualTime'"></th>
                            <th data-bind="i18n: 'common.captions.reference'"></th>
                            <th data-bind="i18n: 'common.captions.remark'"></th>
                        </tr>
                        <tr>
                            <td data-bind="i18n: 'common.captions.origin'"></td>
                            <td>
								<view-frame name="origAddressEditor"></view-frame>
                                {/*<ko ifnot="originAddressName()">
                                    <em>-</em>
                                </ko>
                                <ko if="originAddressName()">
                                    <address>
                                        <span data-bind="text: originAddressName"></span><br />
                                        <span data-bind="text: originAddressStreet"></span><br />
                                        <ko if="jQuery.trim(originAddressStreet2()) !== ''">
                                            <span data-bind="text: originAddressStreet2"></span><br />
                                        </ko>
                                        <span data-bind="text: originAddressCity"></span> <span data-bind="text: originAddressZipCode"></span><br />
                                        <span data-bind="text: $root.countriesHash[originAddressCountryId()] ? $root.countriesHash[originAddressCountryId()] : ''"></span>
                                    </address>
								</ko>*/}
                            </td>
                            <td>
								<view-frame name="origContactEditor"></view-frame>
                                {/*<ko ifnot="originContactLastName">
                                    <em>-</em>
                                </ko>
                                <ko if="originContactLastName">
                                    <p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: originContactFirstName"></span> <span data-bind="text:originContactLastName"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: originContactPhone"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: originContactEmail"></span></p>
								</ko>*/}
                            </td>
                            <td style="min-width: 230px;">
								<div class="row mb-2">
									<div class="col-auto" style="min-width: 80px">
										<label className="col-form-label"><span data-bind="i18n: 'common.captions.etd'"></span> <span data-bind="i18n: 'common.captions.from'"></span></label>
									</div>
									<div class="col">
										<input type="text" data-bind="kendoDateTimePicker: { value: etdFrom }" className="w-100" />
									</div>
								</div>
								<div class="row">
									<div class="col-auto" style="min-width: 80px">
										<label className="col-form-label"><span data-bind="i18n: 'common.captions.etd'"></span> <span data-bind="i18n: 'common.captions.to'"></span></label>
									</div>
									<div class="col">
										<input type="text" data-bind="kendoDateTimePicker: { value: etdTo }" className="w-100" />
									</div>
								</div>
                            </td>
                            <td>
                                <ko if="atd()">
                                    <p><span data-bind="i18n: 'common.captions.atd'"></span>: <span data-bind="text: kendo.toString(atd(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td>
								<input type="text" data-bind="value: loadingReference" className="form-control" />
							</td>
							<td>
								<textarea data-bind="value: originNote" className="form-control"></textarea>
							</td>
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: checkpoints">
                        <tr>
                            <td>
                                <ko if="typeId()">
                                    <span data-bind="text: $root.checkpointTypesHash[typeId()].nameTranslated"></span>
                                </ko>
                                <ko ifnot="typeId()">
                                    <span data-bind="text: name"></span>
                                </ko>
                            </td>
                            <td>
								<view-frame name="tbd" data-bind="attr: { 'data-view-frame': 'checkpointAddressEditor_' + $data.id() }"></view-frame>
                                {/*<ko ifnot="addressName()">
                                    <em>-</em>
                                </ko>
                                <ko if="addressName()">
                                    <address>
                                        <span data-bind="text: addressName"></span><br />
                                        <span data-bind="text: addressStreet"></span><br />
                                        <ko if="jQuery.trim(addressStreet2()) !== ''">
                                            <span data-bind="text: addressStreet2"></span><br />
                                        </ko>
                                        <span data-bind="text: addressTown"></span>, <span data-bind="text: addressZipCode"></span>, <span data-bind="text: $root.countriesHash[addressCountryId()] ? $root.countriesHash[addressCountryId()] : ''"></span>
                                    </address>
								</ko>*/}
                            </td>
                            <td>
								<view-frame name="tbd" data-bind="attr: { 'data-view-frame': 'checkpointContactEditor_' + $data.id() }"></view-frame>
								{/*<ko ifnot="contactLastName()">
                                    <em>-</em>
                                </ko>
                                <ko if="contactLastName()">
                                    <p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: contactFirstName"></span> <span data-bind="text: contactLastName"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: contactPhone"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: contactEmail"></span></p>
                                </ko>
								*/}
                            </td>
                            <td style="min-width: 230px;">
								<div class="row mb-2">
									<div class="col-auto" style="min-width: 80px">
										<label className="col-form-label" data-bind="i18n: 'common.captions.eta'"></label>
									</div>
									<div class="col">
										<input type="text" data-bind="kendoDateTimePicker: { value: eta }" className="w-100" />
									</div>
								</div>
								<div class="row">
									<div class="col-auto" style="min-width: 80px">
										<label className="col-form-label" data-bind="i18n: 'common.captions.etd'"></label>
									</div>
									<div class="col">
										<input type="text" data-bind="kendoDateTimePicker: { value: etd }" className="w-100" />
									</div>
								</div>
                                {/*<ko if="eta()">
                                    <p><span data-bind="i18n: 'common.captions.eta'"></span>: <span data-bind="text: kendo.toString(eta(), 'g')"></span></p>
                                </ko>
                                <ko if="etd()">
                                    <p><span data-bind="i18n: 'common.captions.etd'"></span>: <span data-bind="text: kendo.toString(etd(), 'g')"></span></p>
                                </ko>*/}
                            </td>
                            <td>
                                <ko if="ata()">
                                    <p><span data-bind="i18n: 'common.captions.ata'"></span>: <span data-bind="text: kendo.toString(ata(), 'g')"></span></p>
                                </ko>
                                <ko if="ata()">
                                    <p><span data-bind="i18n: 'common.captions.atd'"></span>: <span data-bind="text: kendo.toString(atd(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td>
								<input type="text" data-bind="value: reference" className="form-control" />
							</td>
							<td>
								<textarea data-bind="value: note" className="form-control"></textarea>
							</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td data-bind="i18n: 'common.captions.destination'"></td>
                            <td>
								<view-frame name="destAddressEditor"></view-frame>
                                {/*<ko ifnot="destAddressName()">
                                    <em>-</em>
								</ko>
                                <ko if="destAddressName()">
                                    <address>
                                        <span data-bind="text: destAddressName"></span><br />
										<input type="text" data-bind="value: destAddressName" />
                                        <span data-bind="text: destAddressStreet"></span><br />
                                        <ko if="jQuery.trim(destAddressStreet2()) !== ''">
                                            <span data-bind="text: destAddressStreet2"></span><br />
                                        </ko>										
                                        <span data-bind="text: destAddressCity"></span> <span data-bind="text: destAddressZipCode"></span><br />
                                        <span data-bind="text: $root.countriesHash[destAddressCountryId()] ? $root.countriesHash[destAddressCountryId()] : ''"></span>
                                    </address>
								</ko> */}
                            </td>
                            <td>
								<view-frame name="destContactEditor"></view-frame>
                                {/*<ko ifnot="destinationContactLastName()">
                                    <em>-</em>
                                </ko>
                                <ko if="destinationContactLastName()">
                                    <p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: destinationContactFirstName"></span> <span data-bind="text:destinationContactLastName"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: destinationContactPhone"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: destinationContactEmail"></span></p>
								</ko>*/}
                            </td>
                            <td style="min-width: 230px;">
								<div class="row mb-2">
									<div class="col-auto" style="min-width: 80px">
										<label className="col-form-label"><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.from'"></span></label>
									</div>
									<div class="col">
										<input type="text" data-bind="kendoDateTimePicker: { value: etaFrom }" className="w-100" />
									</div>
								</div>
								<div class="row">
									<div class="col-auto" style="min-width: 80px">
										<label className="col-form-label"><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.to'"></span></label>
									</div>
									<div class="col">
										<input type="text" data-bind="kendoDateTimePicker: { value: etaTo }" className="w-100" />
									</div>
								</div>
                                {/*<ko if="etaFrom()">
                                    <p><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.from'"></span>:
									<span data-bind="text: kendo.toString(etaFrom(), 'g')"></span></p>
                                </ko>
                                <ko if="etaTo()">
                                    <p><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.to'"></span>:
									<span data-bind="text: kendo.toString(etaTo(), 'g')"></span></p>
								</ko>*/}
                            </td>
                            <td>
                                <ko if="ata()">
                                    <p><span data-bind="i18n: 'common.captions.ata'"></span>: <span data-bind="text: kendo.toString(ata(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td>
								<input type="text" data-bind="value: unloadingReference" className="form-control" />
							</td>
							<td>
								<textarea data-bind="value: destinationNote" className="form-control"></textarea>
							</td>
                        </tr>
                    </tfoot>

                </table>
            </div>
        </fieldset>
    );

}