import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../common/BaseViewModel";
import { CultureSwitch } from "../components/CultureSwitch";
import { PoTracedo } from "../PoManagement/PoTracedo";
import { GpsLog } from "./GpsLog";

/**
 * Tracedo detail for unlogged guest
 */
@injectable()
export class DetailGuest extends BaseViewModel<any>
{
	// Tracedo data
	private tracedo: KnockoutObservableType<any> = null;

	// GPS Log map is generated
	private mapGenerated: boolean = false;	

	// GPS log ViewModel
	private gpsMapViewModel: any = null;	

	/**
	 * Is user logged in ?
	 */
	private isLoggedIn: KnockoutObservable<boolean>;

	/**
	 * ViewModel Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		if(!this.router.query['token']) {
			throw new BadRequestError('Missing or invalid token', 403);
		}

		let tracedo = await this.rpc.call('tracedo.getOneByToken', {
			token: this.router.query['token']
		});
		this.tracedo = ko.mapping.fromJS(tracedo);

		this.isLoggedIn = ko.observable(await this.user.isLoggedIn());
	}

	/**
	 * Refresh GPS log
	 */
	private refreshGpsLog() {
		this.gpsMapViewModel.reload();
	}

	/**
	 * Pokud uzivatel prepne na zalozku s GPS logu
	 */
	private async kendoTabStripChanged(e: any)
	{
		// GPS
		if(e.item.id === 'gpsLog' && !this.mapGenerated) {
			this.mapGenerated = true;
			this.gpsMapViewModel = await this.loadViewFrame<GpsLog>(GpsLog, 'tracedo-gpslog', { tracedoId: this.tracedo.id() });
		}
	}	


	/**
	 * Render method
	 */
	public async rendered(): Promise<any>
	{
		return this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures');
	}

	public template = (): HTMLElement => (

		<div className="centered-form container h-expand pt-4" style="max-width: 1280px">
				
			<view-frame name="cultures" className="float-end" />

			<h1 className="mb-2 text-primary">TRACEDO</h1>

			<div className="panel radius panel-big-margin p-3 border-box">

				<div data-bind="kendoTabStrip: { animation: false, select: $root.kendoTabStripChanged.bind($root) }">
					<ul>
						<li className="k-state-active"><span data-bind="i18n: 'common.captions.information'"></span></li>
						<li><span data-bind="i18n: 'common.captions.poItems'"></span></li>
						<li id="gpsLog"><span data-bind="i18n: 'common.captions.gps'"></span></li>
					</ul>

					<div className="k-state-active pt-4">
						<ko with="$root.tracedo">

							<ko if="$data.myNumber !== undefined && $data.myNumber() !== null">

								<fieldset>
									<legend data-bind="i18n: 'common.captions.identificationAndSubjects'"></legend>

									<div className="row border-box">
										<div className="col-md-4">
											<table className="table table-bordered info-table th-170">
											<tbody>
												<ko if="myField() != 'customer'">
													<tr>
														<th data-bind="i18n: 'common.captions.customerPartnerNumber'"></th>
														<td>
															<ko if="customerPartnerNumber()">
																<span data-bind="text: customerPartnerNumber"></span>
															</ko>
															<ko ifnot="customerPartnerNumber()">
																<span>&mdash;</span>
															</ko>
														</td>
													</tr>
												</ko>
												<tr>
													<th data-bind="i18n: 'common.captions.number'"></th>
													<td>
														<ko if="myNumber()">
															<span data-bind="text: myNumber"></span>
														</ko>
													</td>
												</tr>
												<ko if="supplierPartnerId()">
													<tr>
														<th data-bind="i18n: 'common.captions.supplierPartnerNumber'"></th>
														<td>
															<ko if="supplierPartnerNumber()">
																<span data-bind="text: supplierPartnerNumber"></span>
															</ko>
														</td>
													</tr>
												</ko>
											</tbody>
											</table>
										</div>
										<div className="col-md-4">
											<table className="table table-bordered info-table th-170">
											<tbody>
												<ko if="myField() != 'customer'">
													<tr>
														<th data-bind="i18n: 'common.captions.customerPartner'"></th>
														<td>
															<ko if="customerPartnerId()">
																<span data-bind="text: customerPartner.name()"></span>
															</ko>
														</td>
													</tr>
												</ko>
												<tr>
													<th data-bind="i18n: 'common.captions.mySubject'"></th>
													<td>
														<ko if="mySubjectId()">
															<span data-bind="text: mySubject.name()"></span>
														</ko>
													</td>
												</tr>
												<ko if="supplierPartnerId()">
													<tr>
														<th data-bind="i18n: 'common.captions.supplierPartner'"></th>
														<td>
															<span data-bind="text: supplierPartner.name()"></span>
														</td>
													</tr>
												</ko>
											</tbody>
											</table>
										</div>
										<div className="col-md-4">
											<table className="table table-bordered info-table th-170">
											<tbody>
												<ko if="myField() != 'customer'">
													<tr>
														<th data-bind="i18n: 'common.captions.customerPartnerPicUser'"></th>
														<td>
															<ko if="customerPartnerPicUserId()">
																<span data-bind="text: customerPartnerPicUser.realName()"></span>
															</ko>
														</td>
													</tr>
												</ko>
												<tr>
													<th data-bind="i18n: 'common.captions.contactPerson'"></th>
													<td>
														<ko if="myPicUserId()">
															<span data-bind="text: myPicUser.realName()"></span>
														</ko>
													</td>
												</tr>
												<ko if="supplierPartnerId()">
													<tr>
														<th data-bind="i18n: 'common.captions.supplierPartnerPicUser'"></th>
														<td>
															<ko if="supplierPartnerPicUserId()">
																<span data-bind="text: supplierPartnerPicUser.realName()"></span>
															</ko>
														</td>
													</tr>
												</ko>
											</tbody>
											</table>
										</div>
									</div>
								</fieldset>


								<hr className="wide" />
							</ko>


							<fieldset>
								<legend data-bind="i18n: 'trade.demand.edit.information'"></legend>
								<div className="row border-box">
									<div className="col-md-4">

										<table className="table table-bordered info-table th-170">
										<tbody>
											<tr>
												<th data-bind="i18n: 'common.captions.status'"></th>
												<td data-bind="text: trcStatusId() ? trcStatus.name : ''"></td>
											</tr>											
											<tr>
												<th data-bind="i18n: 'common.captions.mode'"></th>
												<td data-bind="text: modeId() ? mode.name : ''"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.roadType'"></th>
												<td data-bind="text: roadTypeId() ? roadType.name : ''"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.kind'"></th>
												<td data-bind="text: kindId() ? kind.name : ''"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.clientRef'"></th>
												<td data-bind="text: clientRef"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.clientRef2'"></th>
												<td data-bind="text: clientRef2"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.clientRef3'"></th>
												<td data-bind="text: clientRef3"></td>
											</tr>
										</tbody>
										</table>
									</div>
									<div className="col-md-4">

										<table className="table table-bordered info-table th-170">
										<tbody>
											<tr>
												<th data-bind="i18n: 'common.captions.colli'"></th>
												<td data-bind="text: colli"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.kgs'"></th>
												<td data-bind="text: kgs"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.cbm'"></th>
												<td data-bind="text: cbm"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.ldm'"></th>
												<td data-bind="text: ldm"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.adr'"></th>
												<td data-bind="i18n: 'common.captions.' + (adr() ? 'yes' : 'no')"></td>
											</tr>
											<tr>
												<th data-bind="i18n: 'common.captions.cargoDescription'"></th>
												<td data-bind="text: cargoDescription"></td>
											</tr>
										</tbody>
										</table>

									</div>
									<div className="col-md-4">

										<table className="table table-bordered info-table th-170">
										<tbody>
											<ko if="driverUserId">
												<tr>
													<th data-bind="i18n: 'common.captions.driver'"></th>
													<td data-bind="text: driverUserId ? driverUser.firstName() + ' ' + driverUser.lastName() : ''"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.driverInfo'"></th>
													<td data-bind="text: driverInfo"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.spz'"></th>
													<td data-bind="text: spz"></td>
												</tr>
												<tr>
													<th><span data-bind="i18n: 'common.captions.truckType'"></span></th>
													<td data-bind="if: truckType"><span data-bind="text: truckType.name"></span></td>
												</tr>
											</ko>
										</tbody>
										</table>

									</div>									

								</div>


								<hr className="wide" />

	
								<div className="row border-box">
									<ko if="bdpCntrTypeId">
										<div className="col-md-4">	
											<fieldset>
												<legend data-bind="i18n: 'common.captions.containerInformation'"></legend>
											
												<table className="table table-bordered info-table th-170">
												<tbody>
													<tr>
														<th data-bind="i18n: 'common.captions.cntrType'"></th>
														<td data-bind="text: bdpCntrType.estoneCode"></td>
													</tr>
													<tr>
														<th data-bind="i18n: 'common.captions.cntrNr'"></th>
														<td data-bind="text: cntrNr"></td>
													</tr>
													<tr>
														<th data-bind="i18n: 'common.captions.sealNr'"></th>
														<td data-bind="text: sealNr"></td>
													</tr>
												</tbody>
												</table>												
											</fieldset>
										</div>
									</ko>
									<div className="col-md-4">
										<fieldset>
											<legend data-bind="i18n: 'common.captions.origin'"></legend>

											<table className="table table-bordered info-table th-170">
											<tbody>
												<tr>
													<th data-bind="i18n: 'common.captions.etd'"></th>
													<td>
														<span data-bind="text: etdFrom() ? moment(etdFrom()).format(i18n.t('common.formats.momentDate')) : ''"></span>
														&nbsp;
														<span data-bind="text: etdFrom() && moment(etdFrom()).format('HH:mm') !== '00:00' ? moment(etdFrom()).format(i18n.t('common.formats.momentTime')) : ''"></span><span data-bind="text: etdTo() && moment(etdTo()).format('HH:mm') !== '00:00'  ? ' - ' + moment(etdTo()).format(i18n.t('common.formats.momentTime')) : ''"></span>
													</td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.atdReady'"></th>
													<td>
														<span data-bind="text: atdReady() ? moment(atdReady()).format(i18n.t('common.formats.momentDate')) : ''"></span>
														&nbsp;
														<span data-bind="text: atdReady() && moment(atdReady()).format('HH:mm') !== '00:00' ? moment(atdReady()).format(i18n.t('common.formats.momentTime')) : ''"></span>
													</td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.atd'"></th>
													<td>
														<span data-bind="text: atd() ? moment(atd()).format(i18n.t('common.formats.momentDate')) : ''"></span>
														&nbsp;
														<span data-bind="text: atd() && moment(atd()).format('HH:mm') !== '00:00' ? moment(atd()).format(i18n.t('common.formats.momentTime')) : ''"></span>
													</td>
												</tr>
												<tr>
													<th data-bind="i18n: 'trade.demand.edit.pickup'"></th>
													<td data-bind="text: originAddressName()"></td>
												</tr>
												<tr>
													<th></th>
													<td data-bind="text: originAddressName() +' '+ originAddressZipCode() +' '+  originAddressCity() +' '+ (originAddressCountryId() ? originAddressCountry.iso() : '')"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.contactPerson'"></th>
													<td data-bind="text: originContactPerson"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.contactPhone'"></th>
													<td data-bind="text: originContactPhone"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.remark'"></th>
													<td data-bind="text: originNote"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.loadingReference'"></th>
													<td data-bind="text: loadingReference"></td>
												</tr>
											</tbody>
											</table>
										</fieldset>

									</div>
									<div className="col-md-4">
										<fieldset>
											<legend data-bind="i18n: 'common.captions.destination'"></legend>
											<table className="table table-bordered info-table th-170">
											<tbody>											
												<tr>
													<th data-bind="i18n: 'common.captions.eta'"></th>
													<td>
														<span data-bind="text: etaFrom() ? moment(etaFrom()).format(i18n.t('common.formats.momentDate')) : ''"></span>
														&nbsp;
														<span data-bind="text: etaFrom() && moment(etaFrom()).format('HH:mm') !== '00:00'  ? moment(etaFrom()).format(i18n.t('common.formats.momentTime')) : ''"></span><span data-bind="text: etaTo() && moment(etaTo()).format('HH:mm') !== '00:00'  ? ' - ' + moment(etaTo()).format(i18n.t('common.formats.momentTime')) : ''"></span>
													</td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.ataReady'"></th>
													<td>
														<span data-bind="text: ataReady() ? moment(ataReady()).format(i18n.t('common.formats.momentDate')) : ''"></span>
														&nbsp;
														<span data-bind="text: ataReady() && moment(ataReady()).format('HH:mm') !== '00:00'  ? moment(ataReady()).format(i18n.t('common.formats.momentTime')) : ''"></span>
													</td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.ata'"></th>
													<td>
														<span data-bind="text: ata() ? moment(ata()).format(i18n.t('common.formats.momentDate')) : ''"></span>
														&nbsp;
														<span data-bind="text: ata() && moment(ata()).format('HH:mm') !== '00:00'  ? moment(ata()).format(i18n.t('common.formats.momentTime')) : ''"></span>
													</td>
												</tr>
												<tr>
													<th data-bind="i18n: 'trade.demand.edit.delivery'"></th>
													<td data-bind="text: destAddressName()"></td>
												</tr>
												<tr>
													<th></th>
													<td data-bind="text: destAddressName() +' '+ destAddressZipCode() +' '+  destAddressCity() +' '+ (destAddressCountryId() ? destAddressCountry.iso() : '')"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.contactPerson'"></th>
													<td data-bind="text: destinationContactPerson"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.contactPhone'"></th>
													<td data-bind="text: destinationContactPhone"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.remark'"></th>
													<td data-bind="text: destinationNote"></td>
												</tr>
												<tr>
													<th data-bind="i18n: 'common.captions.unloadingReference'"></th>
													<td data-bind="text: unloadingReference"></td>
												</tr>
												</tbody>
											</table>
										</fieldset>

									</div>
								</div>


							</fieldset>
						</ko>
					</div>

					<div className="pt-4">
						<ko with="$root.tracedo">
							<table className="table table-bordered info-table">
								<thead>
									<tr>
										<th>PN1</th>
										<th>PN2</th>
										<th>PN3</th>
										<th>Stackable</th>
										<th data-bind="i18n: 'common.captions.cargoValue'"></th>
										<th data-bind="i18n: 'common.captions.colli'"></th>
										<th data-bind="i18n: 'common.captions.type'"></th>
										<th>PCS</th>
										<th data-bind="i18n: 'edit.poItem.length'"></th>
										<th data-bind="i18n: 'edit.poItem.width'"></th>
										<th data-bind="i18n: 'edit.poItem.height'"></th>
										<th data-bind="i18n: 'edit.poItem.weight'"></th>
										<th data-bind="i18n: 'common.captions.description'"></th>
										<th data-bind="i18n: 'common.captions.ecr'"></th>
										<th data-bind="i18n: 'common.captions.hsCode'"></th>
									</tr>
								</thead>
								<tbody>
									<ko foreach="poItems">
										<tr>
											<td data-bind="text: pn1"></td>
											<td data-bind="text: pn2"></td>
											<td data-bind="text: pn3"></td>
											<td data-bind="text: stackable"></td>
											<td data-bind="text: cargoValue"></td>
											<td data-bind="text: colli"></td>
											<td data-bind="text: colliType.name"></td>
											<td data-bind="text: pcs"></td>
											<td data-bind="text: kendo.toString(length() * 100, 'n2')"></td>
											<td data-bind="text: kendo.toString(width() * 100, 'n2')"></td>
											<td data-bind="text: kendo.toString(height() * 100, 'n2')"></td>
											<td data-bind="text: kendo.toString(weight(), 'n2')"></td>
											<td data-bind="text: descr"></td>
											<td data-bind="text: kendo.toString(kendo.parseDate(ecrTimestamp()),'g')"></td>
											<td data-bind="text: hsCode"></td>
										</tr>
									</ko>
								</tbody>
							</table>
						</ko>

					</div>
					<div className="pt-4">

						<view-frame name="tracedo-gpslog" />

						<button style="float: right;" type="button" className="k-button bottom-margin" data-bind="click: $root.refreshGpsLog.bind($root)"><i className="k-icon k-i-reload"></i></button>

					</div>
				</div>

			</div>

		</div>
	);

}
