import { injectable } from "tsyringe";

import { h } from "../../tracejs/src/utils/JSXFactory";

import { BaseViewModel } from "./common/BaseViewModel";

/**
 * Old browser used
 */
@injectable()
export class OldBrowser extends BaseViewModel<any> {

	public template = (): HTMLElement => (

		<div class="obsolete-message panel">
	        <h2 data-bind="i18n: 'oldBrowser.caption'"></h2>
	        <p data-bind="i18n: 'oldBrowser.message'"></p>
        </div>

    );

}
