import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { IDialogSettings } from "../common/IDialogSettings";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { SyncEvent } from "ts-events";
import { injectable } from "tsyringe";

/**
 * Add Dashboard Widget
 */
@injectable()
export class AddWidget extends BaseDialogViewModel<IDialogSettings>
{
    /**
     * Pole dostupnych widgetu
     */
    protected availableWidgets: KnockoutObservableArray<any> = ko.observableArray([]);

    /**
     * Zvoleny Widget
     */
    protected selectedWidget: KnockoutObservable<any> = ko.observable(null);


    public onSelected: SyncEvent<any> = new SyncEvent<any>();

    public onCancelled: SyncEvent<void> = new SyncEvent<void>();


    /**
     * Widget selector startup method 
     */
    public async startup(): Promise<void>
    {
        await super.startup();
        let response: any = await this.rpc.call('dashboard.getAvailable');
        this.availableWidgets(response);
    }
    
    /**
     * Widget selector submitted
     */
    public select()
    {
        this.onSelected.post(this.selectedWidget());
    }

    /**
     * Cancel action
     */
    public close()
    {
        this.onCancelled.post();
    }

    
    public dialogTemplate = (): HTMLElement => (

        <div>

            <div class="row">
                <div class="col-auto">
                    <label class="col-form-label" data-bind="
                        uniqueFor: 'widget',
                        i18n: 'dashboard.chooseWidget'
                    "></label>
                </div>
                <div class="col">
                    <select class="form-select" data-bind="
                        uniqueId: 'widget',
                        value: $root.selectedWidget,
                        options: $root.availableWidgets,
                        optionValue: 'ident',
                        optionsText: 'name',
                        optionsCaption: '-'
                    "></select>
                </div>
            </div>

        </div>

    );

}