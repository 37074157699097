import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../common/BaseViewModel";
import { injectable } from "tsyringe";
import { KendoHelpers } from "../../model/KendoHelpers";
import { i18n } from "i18next-ko";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../components/GridConfigurator/IGridConfiguratorOptions";
import { GridConfigurator } from "../components/GridConfigurator/GridConfigurator";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { CodelistManager } from "../../model/CodelistManager";
import { TracedoHelpers } from "../TrackAndTrace/TracedoHelpers";
import { TracedoView } from "../../entities/TracedoView";
import { CalculatorStatus } from "../../entities/CalculatorStatus";

/**
 * Revenues
 */
@injectable()
export class Revenues extends BaseViewModel<any>
{
		
	// GRID element
	private gridElement: JQuery = null;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

    // Codelist manager
    private codelistManager: CodelistManager;

	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param codelistManager Codelist manager
	 */
    constructor(rpc: Client, codelistManager: CodelistManager)
    {
        super(rpc);
        this.codelistManager = codelistManager; 
    }

	/**
	 * Sestavi HTML select z predanych revenue statusu
	 * @param statuses 
	 */
    public buildChangeRevenueStatusSelect(statuses: CalculatorStatus[]): string
    {
        let select = '<div class="btn-group change-revenue-status-menu">' +
            '<button class="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">' +
                i18n.t('common.actions.changeRevenueStatus') +
            '</button>' +
            '<ul class="dropdown-menu" aria-labelledby="changeInvoicingStatusDropDown">';
        statuses.forEach(status => {
            select += '<li><a class="dropdown-item" href="\\#" data-status-id="' + status.id + '">' + i18n.t('calculatorStatuses.' + status.ident) + '</a></li>';
        });
        select += '</ul></div>';
        return select;
    }

	/**
	 * Configure kendo GRID
	 */
	private configureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		let toolbarConfig: kendo.ui.GridToolbarItem[] = [];

        // Zmena stavu fakturace polozek vynosu
        toolbarConfig.push({ template: this.buildChangeRevenueStatusSelect(this.codelistManager.getRevenueStatuses()) });
        toolbarConfig.push({ template: '<span data-selected-count="1"></span>' });

		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
						(options.data as any).search = (this.grid.dataSource as any).searchText;
						let response: any = await this.rpc.call('calculator.getAllRevenuesView', { query: {
							...options.data,
							//select: '*',
                            select: 'calculatorTracedoId,calculatorId,calculatorCreatedStamp,calculatorDescription,calculatorAmount,calculatorUnit,calculatorUnitPrice,calculatorPrice,calculatorCurrencyIso,calculatorPriceCzk,' + 
                                'calculatorFeeNameCs,calculatorFeeNameEn,calculatorSubjectName,calculatorStatusIdent,myNumber,originAddressName,destAddressName,supplierPartnerName,customerPartnerName,myUzp,' + 
                                'sellPrice,sellPriceCurrencyId,purchasePrice,purchasePriceCurrencyId,' +
								'carriersOriginData,carriersDestinationData'
						}});
						options.success(response);
					}
				},
				schema: {
					model: {
						id: 'calculatorId',
						fields: {
							'calculatorTracedoId': { editable: false, type: "number" },
							'calculatorId': { editable: false, type: "number" },
							'calculatorCreatedStamp': { editable: false, type: "date" },
							'calculatorDescription': { editable: false, type: "string" },
							'calculatorAmount': { editable: false, type: "number" },
							'calculatorUnit': { editable: false, type: "string" },
							'calculatorUnitPrice': { editable: false, type: "number" },
							'calculatorPrice': { editable: false, type: 'number' },
							'calculatorCurrencyIso': { editable: false, type: "string" },
							'calculatorPriceCzk': { editable: false, type: "string" },
							['calculatorFeeName' + this.culture.localeShortCapitalized]: { editable: false, type: "string" },
							'calculatorSubjectName': { editable: false, type: "string" },
							'calculatorStatusIdent': { editable: false, type: "string" },
                            'myNumber': { editable: false, type: 'string' },
                            'originAddressName': { editable: false, type: 'string' },
                            'destAddressName': { editable: false, type: 'string' },
                            'purchasePrice': { editable: false, type: 'string' },
                            'purchasePriceCurrencyId': { editable: false, type: 'number' },
                            'sellPrice': { editable: false, type: 'string' },
                            'sellPriceCurrencyId': { editable: false, type: 'number' },
                            'supplierPartnerName': { editable: false, type: 'string' },
                            'customerPartnerName': { editable: false, type: 'string' },
                            "myUzp": { editable: false, type: 'date' }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "calculatorCreatedStamp", dir: "desc" },
				pageSize: 100,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			//selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
            pageable: {
                alwaysVisible: true,
                refresh: true,
                pageSizes: [5, 10, 20, 50, 100, 200, 500]
            },
            persistSelection: true,
			toolbar: toolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.grid);
                this.gridElement.find('.change-revenue-status-menu > button').prop('disabled', true);
			},
			change: (arg: kendo.ui.GridChangeEvent) => {
				let selected = this.grid.select().length > 0;
				this.gridElement.find('.change-revenue-status-menu > button').prop('disabled', !selected);
                // how many selected
                let skn = this.grid.selectedKeyNames();
                this.gridElement.find('span[data-selected-count=1]').html(
                    '<strong>' + (skn && skn.length > 0 ? i18n.t('common.captions.selectedCount') + ': ' + skn.length : '') + '</strong>'
                );
			},
			columns: [{
                selectable: true,
                width: '30px'
            }, {
                field: "calculatorStatusIdent",
                title: i18n.t('common.captions.status'),
                width: 110,
                template: (data: any) => i18n.t('calculatorStatuses.' + data.calculatorStatusIdent),
				filterable: {
					ui: (element: JQuery) => this.codelistManager.createRevenueStatusFilter(element, 'ident', 'name')
				}
            }, {
				field: "calculatorCreatedStamp",
				title: i18n.t('common.captions.createdAt'),
                format: i18n.t('common.formats.dateTime'),
				width: 110
			}, { 
				field: "calculatorDescription",
				title: i18n.t('common.captions.description')
			}, { 
				field: "calculatorAmount",
				title: i18n.t('common.captions.amount'),
				width: 110,
                format: '{0:n0}',
                headerAttributes: { 'class': 'text-end' },
                attributes: { 'class' : 'text-end' }
			}, { 
				field: "calculatorUnit",
				title: i18n.t('common.captions.unit'),
				width: 70
			}, {
				field: "calculatorUnitPrice",
				title: i18n.t('common.captions.unitPrice'),
				width: 110,
                format: '{0:n2}',
                headerAttributes: { 'class': 'text-end' },
                attributes: { 'class' : 'text-end' }
			}, {
				field: "calculatorPrice",
				title: i18n.t('common.captions.price'),
				width: 110,
                format: '{0:n2}',
                headerAttributes: { 'class': 'text-end' },
                attributes: { 'class' : 'text-end' }
			}, {
				field: "calculatorCurrencyIso",
				title: i18n.t('common.captions.currency'),
				width: 60
			}, {
				field: "calculatorFeeName"  + this.culture.localeShortCapitalized,
				title: i18n.t('common.captions.fee'),
				width: 150
			}, {
				field: "calculatorSubjectName",
				title: i18n.t('common.captions.subject'),
				width: 150
			}, {
				field: "myNumber",
				title: i18n.t('common.captions.tracedoItem'),
				width: 110,
                template: (data: any) => '<a href="#" data-tracedo-detail="' + data.calculatorTracedoId + '">' + data.myNumber + '</a>'
			}, {
				field: "originAddressName",
				title: i18n.t('common.captions.origin'),
				width: 200,
				template: (data: TracedoView) => {
					return data.carriersOriginData && data.carriersOriginData.address ? 
						data.carriersOriginData.address.name : data.originAddressName;
				}
			}, {
                field: "destAddressName",
				title: i18n.t('common.captions.destination'),
				width: 200,
				template: (data: TracedoView) => {
					return data.carriersDestinationData && data.carriersDestinationData.address ? 
						data.carriersDestinationData.address.name : data.destAddressName;
				}
			}, {
                field: "sellPrice",
				title: i18n.t('common.captions.sellPrice'),
                format: '{0:n2}',
                headerAttributes: { 'class': 'text-end' },
                attributes: { 'class' : 'text-end' },
                width: 100
			}, {
                field: "sellPriceCurrencyId",
				title: i18n.t('common.captions.currency'),
				width: 80,
                template: (data: any) => data.sellPriceCurrencyId ? this.codelistManager.getOneCurrency(data.sellPriceCurrencyId, 'iso') : ''
            }, {
                field: "supplierPartnerName",
                title: i18n.t('common.captions.carrier'),
                width: 200
            }, {
                field: "customerPartnerName",
                title: i18n.t('common.captions.customer'),
                width: 200
            }, {
                field: "myUzp",
                title: i18n.t('common.captions.uzp'),
                format: i18n.t('common.formats.date'),
                width: 80
            }]
        }
	}


	/**
	 * ViewModel startup
	 */
	public async startup(): Promise<any>
	{
		// Pokud nemuze do teto sekce, nepustime ho
		if(!this.user.isAllowed('section.finances', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		await super.startup();
		// Configure GRID
		this.configureGrid();
	}


	public async rendered(): Promise<any>
	{
		// grid element
		this.gridElement = this.element.find('[data-grid=revenues]');
		// initialize grid and store reference
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		// BIND action buttons
		this.gridElement.on('change', 'input[type=checkbox][data-calculator-id][data-tracedo-id]', this.toggleRealCost.bind(this));
		this.gridElement.on('click', '[data-tracedo-detail]', (e) => {
            e.preventDefault();
            if(this.user.isAllowed('entity.tracedo', 'view')) {
                let uid = jQuery(e.target).closest('tr').data('uid');
                let calc: any = this.grid.dataSource.getByUid(uid);
			    TracedoHelpers.detailTracedo(this, 'actionDialog', this.user, {
                    myField: calc.myField,
                    myNumber: calc.myNumber,
                    id: calc.calculatorTracedoId
                } as TracedoView);
            }
		});

        // nastaveni revenue statusu pro zvolene vynosy
		this.gridElement.on('click', '.change-revenue-status-menu a', async (event: JQuery.ClickEvent) => {
			let val = jQuery(event.currentTarget).data('status-id');
			let statusId = val ? parseInt(val as string, 10) : null;
            let skn = this.grid.selectedKeyNames();
            
            let response: any = await this.rpc.call('calculator.setRevenueStatuses', {
                statusId: statusId,
                calculatorIds: skn
            });
            
            // clear selection in grid
            this.grid.clearSelection();

            // refresh grid
            this.grid.dataSource.read();
		});


		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Finances.Revenues',
			exports: [{
				name: i18n.t('common.actions.exportExcel'),
				method: 'calculator.getRevenuesXlsx'
			}, {
				name: i18n.t('common.captions.invoiceBasis'),
				method: 'calculator.getInvoiceBasis'
			}]
		} as IGridConfiguratorOptions);
	}

    /**
     * Prepne stav u nakladu mezi 'expected' a 'real'
     */
    private async toggleRealCost(e: JQuery.ChangeEvent)
    {
        let $input = jQuery(e.target);
        let calculatorId = $input.data('calculator-id');
        let tracedoId = $input.data('tracedo-id');
        let statusIdent = $input.is(':checked') ? 'real' : 'expected';

        await this.rpc.call('calculator.setStatus', { tracedoId: tracedoId, id: calculatorId, status: statusIdent });
    }

    
	public template = (): HTMLElement => (

		<div>
			
			<h2 data-bind="i18n: 'nav.revenues'"></h2>

			<view-frame name="gridconf" className="mb-2" />

			<div data-grid="revenues" className="grid-autoheight"></div>

			<view-frame name="actionDialog" />

		</div>
	);

}