import { IIdentity } from './IIdentity';
import { IAuthenticator } from './IAuthenticator';
import { IAuthorizator } from './IAuthorizator';

import { SyncEvent } from 'ts-events';

/**
 * TraceJS User
 */
export class User
{

	public onLogin: SyncEvent<IIdentity> = new SyncEvent<IIdentity>();

	public onLogout: SyncEvent<IIdentity> = new SyncEvent<IIdentity>();


	/** default role for unauthenticated user */
	public guestRole: string = 'guest';

	/** default role for authenticated user without own identity */
	public authenticatedRole: string = 'authenticated';


	/**
	 * Authorizator
	 */
	private _authorizator: IAuthorizator;

	/**
	 * Authenticator
	 */
	private _authenticator: IAuthenticator;


	/**
	 * Identity
	 */
	private _identity: IIdentity;

	/**
	 * Credentials passed to login function
	 */
	private credentials: IArguments;


	/**
	 * Authorizator getter
	 */
	public get authorizator(): IAuthorizator
	{
		return this._authorizator;
	}

	/**
	 * Authorizator setter
	 */
	public set authorizator(value: IAuthorizator)
	{
		this._authorizator = value;
	}

	/**
	 * Authenticator getter
	 */
	public get authenticator(): IAuthenticator
	{
		return this._authenticator;
	}

	/**
	 * Authenticator setter
	 */
	public set authenticator(value: IAuthenticator)
	{
		this._authenticator = value;
	}


	/**
	 * Logged user's identity (null when not logged in)
	 * Doesn't try to load the Identity from Authenticator if empty
	 * To get Identity with fallback from Authenticator use getIdentity instead.
	 */
	public get identity(): IIdentity
	{
		return this._identity;
	}


	/**
	 * ID of logged user. If user is not logged in, returns NULL
	 */
	public get id(): string|number|null
	{
		return this._identity ? this._identity.getId() : null;
	}
 
	 /**
	  * Returns a list of roles that a user has been granted.
	  */
	public get roles(): string[]
	{
		if (this._identity) {
			var roles = this._identity.getRoles();
			if (roles && roles.length > 0) {
				return roles;
			}
			return [this.authenticatedRole];
		}
		return [this.guestRole];
	 }	


	/**
	 * Konstruktor
	 */
	constructor()
	{
		this._identity = null;
		this.credentials = null;
	}

	/**
	 * User login
	 */
	public async login(id: string, password: string, ...others: any[]): Promise<IIdentity>
	{
		if (!this._authenticator) {
			throw new Error("Authenticator is not set. Unable to login.");
		}
		this.credentials = arguments;
		let identity = await this._authenticator.login(...this.credentials);
		this._identity = identity;
		this.onLogin.post(this._identity);
		return this._identity;
	}

	/**
	 * Logout user
	 */
	public async logout(): Promise<void>
	{
		if (!this._authenticator) {
			throw new Error("Authenticator is not set. Unable to logout.");
		}

		await this._authenticator.logout();

		let backupIdentity = this._identity;

		this._identity = null;
		this.credentials = null;

		this.onLogout.post(backupIdentity);
	}

	/**
	 * Is user logged in?
	 */
	public async isLoggedIn(): Promise<boolean>
	{
		let identity = await this.getIdentity();
		return identity ? true : false;
	}

	/**
	 * Returns Promise for IIdentity of currently logged user.
	 * If user is not logged in, resolves with NULL
	 * If identity is not yet loaded from authenticator, it tries to load it
	 */
	public async getIdentity(): Promise<IIdentity>
	{
		// check for authenticator set
		if (!this._authenticator) {
			throw new Error("Authenticator is not set. Unable to get identity.");
		}
		// if we have already an Identity, user is logged in
		if (this._identity) {
			return this._identity;
		}

		let identity: IIdentity = await this._authenticator.getIdentity();

		if (!identity) {
			this._identity = null;
			return this._identity;
		}

		this._identity = identity;
		return this._identity;
	}


	/**
	 * Is a user in the specified role?
	 * @param role
	 * @return boolean
	 */
	public isInRole(role: string): boolean
	{
		return jQuery.inArray(role, this.roles) > -1;
	}

	/**
	 * Can user perform an action with the resource?
	 * @param resource
	 * @param action
	 * @return bool
	 */
	public isAllowed(resource: string, action: string): boolean
	{
		if (!this._authorizator) {
			throw new Error("No authorizator was set!");
		}
		var roles = this.roles;
		for (var i in roles) {
			var role = roles[i];
			if (this._authorizator.isAllowed(role, resource, action)) {
				return true;
			}
		}
		return false;
	}

}
