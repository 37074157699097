import { i18n } from "i18next-ko";
import moment from "moment";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { BaseViewModel } from "../../common/BaseViewModel";
import { IUploaderSettings } from "./IUploaderSettings";
import { IFileUserType } from "./IFileUserType";
import { IUploaderResult } from "./IUploaderResult";
import { SyncEvent } from "ts-events";
import { ITypedFile } from "./ITypedFile";


/**
 * Uploader
 */
@injectable()
export class Uploader extends BaseViewModel<IUploaderSettings>
{

	/**
	 * ViewModel settings
	 */
	protected _settings: IUploaderSettings = {
		type: null,
		chunkSize: 131072,
		multiple: true,
		userTypes: null
	};

	/**
	 * Upload element
	 */
	private uploadElement: JQuery;

	/**
	 * Selected file 
	 */
	private files: KnockoutObservableArray<ITypedFile> = ko.observableArray([]);

	/**
	 * Upload progress
	 */
	private fileProgress: KnockoutObservable<string> = ko.observable('0%');

	/**
	 * Total progress
	 */
	private totalProgress: KnockoutObservable<string> = ko.observable('0%');

	/**
	 * Flag - whether to show upload progressbar
	 */
	private uploading: KnockoutObservable<boolean> = ko.observable(false);

	/**
	 * Uploaded flag
	 */
	private uploaded: KnockoutObservable<boolean> = ko.observable(false);

	/**
	 * UserTypes for given type that current user has right to upload 
	 */
	private userTypes: KnockoutObservableArray<IFileUserType> = ko.observableArray([]);


	/**
	 * On upload progress
	 */
	public onProgress: SyncEvent<{ file: File, userTypeId: number, percent: number, percentString: string }> = new SyncEvent<{ file: File, userTypeId: number, percent: number, percentString: string }>();

	/**
	 * When single file is uploaded
	 */
	public onUploaded: SyncEvent<{ file: File, userTypeId: number, percent: number, percentString: string }> = new SyncEvent<{ file: File, userTypeId: number, percent: number, percentString: string }>();

	/**
	 * When all files are uploaded
	 */
	public onDone: SyncEvent<IUploaderResult[]> = new SyncEvent<IUploaderResult[]>();

	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		// Fill UserTypes
		this.userTypes.removeAll();
		this.settings.userTypes.forEach((ut: any) => {
			ut.identTranslated = i18n.t('common.captions.userTypes.' + ut.ident);
			this.userTypes.push(ut);
		});
	}

	/**
	 * render
	 */
	public async rendered(): Promise<any>
	{
		this.uploadElement = this.element.find('input[type=file]');
		this.uploadElement.kendoUpload({
			async: true,
			multiple: this.settings.multiple,
			showFileList: false,
			select: (e: kendo.ui.UploadSelectEvent) => {
				if (!this.uploaded() && !this.uploading()) {
					// if multiple files selection is allowed, we must clear the files queue
					if (!this.settings.multiple) {
						this.files.removeAll();
					}
					// add newly selected files to the queue
					if (e.files.length > 0) {
						e.files.forEach((file: any) => {
							this.files.push({
								file: file.rawFile,
								userTypeId: ko.observable(this.userTypes().length == 1 ? this.userTypes()[0].userTypeId : null)
							});
						});
					}
				}
			}
		} as any);		
	}

	/**
	 * Remove file from upload queue
	 */
	private removeFile(file: any)
	{
		if (!this.uploading()) {
			this.files.remove(file);
		}
	}

	/**
	 * Refresh uploader
	 */
	public refreshUploader()
	{
		this.files([]);
		this.uploaded(false);
		this.uploading(false);
		this.fileProgress('0%');
		this.totalProgress('0%');
	}

	/**
	 * Upload selected files and return promise with uploaded File[]s array
	 */
	public async upload(): Promise<IUploaderResult[]|null>
	{
		// switch on uploading flag
		this.uploading(true);
		// Uploaded hash ( "fid" => File ) which will be returned in promise
		var uploadedArray: IUploaderResult[] = [];

		// for each file ... check for userTypeId
		let eachHasUserType = true;
		this.files().forEach((file: ITypedFile) => {

			console.log(file, file.userTypeId());

			if (!file.userTypeId()) {
				eachHasUserType = false;
			}
		});
		if (!eachHasUserType) {
			this.alertDialog(i18n.t("common.captions.selectUserType"));
			return null;
		}

		// for each file ...
		for(let fileIndex = 0; fileIndex < this.files().length; fileIndex++) {
			let file = this.files()[fileIndex];
			let uploadedResult: IUploaderResult = await this.uploadFile(file, fileIndex);
			uploadedArray.push(uploadedResult);
		}

		// switch off uploading flag and switch on uploaded flag
		this.uploading(false);
		this.uploaded(true);

		// trigger done event and resolve
		this.onDone.post(uploadedArray);

		this.refreshUploader();

		return uploadedArray;
	}

	/**
	 * Upload one file chunk by chunk
	 */
	private async uploadFile(file: ITypedFile, fileIndex: number): Promise<IUploaderResult>
	{
		// chunk size, dfd
		let chunkSize = this.settings.chunkSize;
		// Upload this particular file
		let fileSize = file.file.size;
		// chunk count
		let chunkCount = Math.ceil(fileSize / chunkSize);

		let fid: string = null;
		let re = new RegExp('^data:([^;]+)?;base64,');

		for(let i = 1; i <= chunkCount; i++) {

			// update file progress
			let fProgress = Math.ceil((i / chunkCount) * 100);
			this.fileProgress(fProgress.toString() + '%');
			this.onProgress.post({ file: file.file, userTypeId: file.userTypeId(), percent: fProgress, percentString: this.fileProgress() });

			// update total progress
			if (fProgress >= 100) {
				let tProgress = Math.ceil(((fileIndex + 1) / this.files().length) * 100);
				this.totalProgress(tProgress.toString() + '%');
				this.onUploaded.post({ file: file.file, userTypeId: file.userTypeId(), percent: tProgress, percentString: this.totalProgress() });
			}

			// read chunk
			let startByte = (i - 1) * chunkSize;
			let endByte = startByte + chunkSize;
			let blob = file.file.slice(startByte, endByte);
			let blobData = await this.readBlob(blob) as string;

			let srch = re.exec(blobData);
			let isLast = i >= chunkCount;
			if(!(srch && srch[0])) {
				this.alertDialog('Error reading file data!'); // fixme: missing translation
				throw new Error('Upload error');
			}

			let baseData = blobData.substr(srch[0].length);
			let fileFid: string = await this.rpc.call('file.upload', { 'data': baseData, 'isLast': isLast, 'fid': fid });
			fid = fileFid;
		}

		return {
			fid: fid,
			file: file.file,
			type: this.settings.type,
			userTypeId: file.userTypeId()
		};
	}

	/**
	 * Read blob data as Data URL
	 */
	private async readBlob(blob: Blob): Promise<string|ArrayBuffer>
	{
		return new Promise((resolve, reject) => {
			var fr = new FileReader();  
			fr.onload = (event: ProgressEvent<FileReader>) => {
				resolve(event.target.result);
			};
			fr.onerror = reject;
			fr.readAsDataURL(blob);
		});
	}


	/**
	 * Template
	 */
	public template = (): HTMLElement => (

		<div>
			<ko if="$root.userTypes().length > 0">

				<div className="mb-4">
					<input type="file" />
				</div>

				<ko if="$root.files().length == 0">
					<div className="text-center"><em data-bind="i18n: 'system.tools.uploader.noFilesSelected'"></em></div>
				</ko>

				<ko with="$root.files">
					<ul data-bind="foreach: $root.files" className="list-group mb-4">
						<li className="list-group-item border-0">

							<button type="button" data-bind="click: $root.removeFile.bind($root)" className="btn btn-outline-danger me-4 align-baseline">
								<i className="icon-trash"></i>
								<span data-bind="i18n: 'common.actions.remove'"></span>
							</button>

							<ko if="$root.userTypes().length > 1">
								<select className="form-select me-4 d-inline-block w-auto align-baseline" data-bind="
										value: userTypeId,
										options: $root.userTypes,
										optionsCaption: i18n.t('common.captions.selectType'),
										optionsText: 'identTranslated',
										optionsValue: 'userTypeId'"></select>
							</ko>

							<div className="d-inline-block align-baseline">
								<strong data-bind="text: $data.file.name"></strong>
								(<span data-bind="filesize: $data.file.size"></span>)
							</div>
						</li>
					</ul>
				</ko>

				<div className="progress" style="height: 5px;">
					<div className="progress-bar" role="progressbar" data-bind="style: { width: $root.fileProgress }"></div>
				</div>
				<ko if="$root.settings.multiple">
					<div className="progress mt-2" style="height: 20px;">
						<div className="progress-bar" role="progressbar" data-bind="style: { width: $root.totalProgress }"></div>
					</div>
				</ko>

			</ko>
		</div>

	);
}
