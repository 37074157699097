import { locale } from "numeral";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { ReadOnlyHeaderTemplate } from "./ReadOnlyHeaderTemplate";

export class EditableHeaderTemplate
{

    // #region Customer

    /**
     * Jedinne, co muze customer upravit je sva PIC,  ostatni casti jsou pouze read-only
     */
    public static customerParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="i18n: 'common.captions.customer'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <span data-bind="text: $root.mainSubject.name" className="col-form-label d-inline-block"></span>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myPicUserId', i18n: 'common.captions.customerPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                            data: $root.mainUsers,
                            dataTextField: 'realName',
                            dataValueField: 'userId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: myPicUserId,
                            animation: false 
                        }" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="i18n: 'common.captions.carrier'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <span data-bind="text: supplierPartner.name" className="col-form-label d-inline-block"></span>
                </div>
            </div>
        </fieldset>
    );

    public static customerMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerMainInfo(l, v);
    public static customerContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerContainerInfo(l, v);
    public static customerDriverInfo = (): HTMLElement => ReadOnlyHeaderTemplate.customerDriverInfo();
    public static customerCargoInfo = (): HTMLElement => ReadOnlyHeaderTemplate.customerCargoInfo();
    public static customerPrices = (): HTMLElement => EditableHeaderTemplate.spediterPrices();

    // #endregion



    // #region Customer - Create Order Only

    public static customerOrderParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="i18n: 'common.captions.customer'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <span data-bind="text: $root.mainSubject.name" className="col-form-label d-inline-block"></span>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myPicUserId', i18n: 'common.captions.customerPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                            data: $root.mainUsers,
                            dataTextField: 'realName',
                            dataValueField: 'userId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: myPicUserId,
                            animation: false 
                        }" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'supplierPartnerId', i18n: 'common.captions.carrier'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'supplierPartnerId', kendoDropDownList: {
                            data: $root.suppliers,
                            dataTextField: 'name',
                            dataValueField: 'subjectId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: supplierPartnerId,
                            animation: false 
                        }" />
                </div>
            </div>
        </fieldset>
    );
    
    public static customerOrderMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9], showUzp: boolean = false): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.mainInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'kindId', i18n: 'trade.demand.edit.kind'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select className="form-select" data-bind="
                        uniqueId: 'kindId',
                        options: $root.transportKinds,
                        optionsText: 'name',
                        optionsValue: 'kindId',
                        value: kindId
                    "></select>
                </div>
            </div>	
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'roadTypeId', i18n: 'trade.demand.edit.mode'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select data-bind="uniqueId: 'roadTypeId', options: $root.roadTypes, optionsText: 'name', optionsValue: 'id', value: roadTypeId" className="form-select"></select>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'clientRef', i18n: 'common.captions.clientRef'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'clientRef', value: clientRef" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'clientRef2', i18n: 'common.captions.clientRef2'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'clientRef2', value: clientRef2" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'clientRef3', i18n: 'common.captions.clientRef3'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'clientRef3', value: clientRef3" className="form-control" />
                </div>
            </div>
            {showUzp ? 
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myUzp', i18n: 'common.captions.myUzp'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="date" data-bind="uniqueId: 'myUzp', kendoDatePicker: { animation: false, value: myUzp }" className="w-100" />
                </div>
            </div> : ''}
        </fieldset>
    );

    public static customerIdentification = (): HTMLElement => (
        <div></div>
    );

    public static customerOrderContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.containerInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'sealNr', i18n: 'common.captions.sealNr'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'sealNr', value: sealNr" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'cntrNr', i18n: 'common.captions.cntrNr'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'cntrNr', value: cntrNr" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'bdpCntrTypeId', i18n: 'common.captions.cntrType'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select className="form-select" data-bind="
                        uniqueId: 'bdpCntrTypeId',
                        options: $root.bdpContainerTypes,
                        optionsText: 'estoneCode',
                        optionsValue: 'bdpContainerTypeId',
                        optionsCaption: '-',
                        value: bdpCntrTypeId
                    "></select>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'rejdar', i18n: 'common.captions.rejdar'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'rejdar', value: rejdar" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'vessel', i18n: 'common.captions.vessel'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'vessel', value: vessel" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'voyageNr', i18n: 'common.captions.voyageNr'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'voyageNr', value: voyageNr" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'mbl', i18n: 'common.captions.mbl'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'mbl', value: mbl" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'customOffice', i18n: 'common.captions.customOffice'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'customOffice', value: customOffice" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'shipperName', i18n: 'common.captions.shipper'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'shipperName', value: shipperName" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'consigneeName', i18n: 'common.captions.consignee'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'consigneeName', value: consigneeName" className="form-control" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label pe-0`} data-bind="uniqueFor: 'depotId', i18n: 'common.captions.returnPickupDepot'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select className="form-select" data-bind="
                        options: $root.depots,
                        optionsValue: 'depotId',
                        optionsText: 'name',
                        optionsCaption: '-',
                        value: depotId,
                        uniqueId: 'depotId'
                    "></select>
                </div>
            </div>

        </fieldset>
    );

    public static customerOrderCargoInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9], chargableVolume: boolean = false): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.cargoInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'colli', i18n: 'common.captions.colli'"></label>
                <div className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)}`}>
                    <input type="number" min="0"  data-bind="uniqueId: 'colli', kendoNumericTextBox: { value: colli, format: 'n0', decimals: 0, enable: $root.editableDimensions }" className="w-100" />
                </div>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} inline col-form-label ps-0`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <strong data-bind="text: kendo.toString($data.sumColli(), 'n0')"></strong>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'kgs', i18n: 'common.captions.kgs'"></label>
                <div className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)}`}>
                    <input type="number" min="0"  data-bind="uniqueId: 'kgs', kendoNumericTextBox: { value: kgs, format: 'n3', decimals: 3, enable: $root.editableDimensions }" className="w-100" />
                </div>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} inline col-form-label ps-0`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <strong data-bind="text: kendo.toString($data.sumKgs(), 'n2')"></strong>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'cbm', i18n: 'common.captions.cbm'"></label>
                <div className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)}`}>
                    <input type="number" min="0"  data-bind="uniqueId: 'cbm', kendoNumericTextBox: { value: cbm, format: 'n2', decimals: 2, enable: $root.editableDimensions }" className="w-100" />
                </div>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} inline col-form-label ps-0`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <strong data-bind="text: kendo.toString($data.sumCbm(), 'n2')"></strong>
                </label>
            </div>

            {chargableVolume ? 
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'chargeableVolume', i18n: 'common.captions.chargeableVolume'"></label>
                    <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                        <input type="number" min="0" data-bind="uniqueId: 'chargeableVolume', value: chargeableVolume" className="form-control" />
                    </div>
                </div> 
            : ''}

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'ldm', i18n: 'common.captions.ldm'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="number" min="0" data-bind="uniqueId: 'ldm', value: ldm" className="form-control" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'cargoDescription', i18n: 'common.captions.cargoDescription'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <textarea maxlength="400" data-bind="uniqueId: 'cargoDescription', value: cargoDescription, style: { 'min-height': '46px'}" className="form-control"></textarea>
                </div>
            </div>
            <div className="row mb-2">
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} offset-lg-${l[0]} offset-xl-${l[1]} offset-xxl-${l[2]}`}>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" data-bind="uniqueId: 'adr', checked: adr" />
                        <label className="form-check-label" data-bind="i18n: 'common.captions.adr', uniqueFor: 'adr'"></label>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'adrClass', i18n: 'common.captions.adrClass'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'adrClass', value: adrClass" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'un', i18n: 'common.captions.un'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'un', value: un" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'packingGroup', i18n: 'common.captions.packingGroup'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select className="form-select" data-bind="
                        uniqueId: 'packingGroup',
                        options: $root.packingGroups,
                        optionsText: 'code',
                        optionsValue: 'code',
                        optionsCaption: '',
                        value: packingGroup
                    "></select>
                </div>
            </div>

        </fieldset>
    );
    public static customerOrderDriverInfo = (): HTMLElement => (<div></div>);
    public static customerOrderPrices = (): HTMLElement => (<div></div>);    

    // #endregion



    // #region Spediter

    public static spediterParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} for="customerPartnerIdInput" data-bind="i18n: 'common.captions.customerPartner'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" id="customerPartnerIdInput" data-bind="kendoDropDownList: {
                        data: $root.customers,
                        dataTextField: 'name',
                        dataValueField: 'subjectId',
                        optionLabel: '-',
                        filter: 'contains',
                        value: customerPartnerId,
                        animation: false
                    }" /> {/* , enable: id() === null */}
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'customerPartnerPicUserId', i18n: 'common.captions.customerPartnerPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    {/* Pokud se usek zaklada spediterem nebo se edituje a zaroven je customer neregistrovany (nema do systemu pristup), muze za neho spediter zvolit PIC usera */}
                    {/*<ko if="id() === null || !customerPartner.isRegistered()">*/}
                        <input className="w-100" data-bind="
                            uniqueId: 'customerPartnerPicUserId',
                            value: customerPartnerPicUserId,
                            kendoDropDownList: $root.customerPartnerPicUserComboConfig
                        " />
                    {/*</ko>*/}
                    {/*<ko ifnot="id() === null || !customerPartner.isRegistered()">*/}
                        {/* V opacnem pripade je PIC user jen pro cteni */}
                        {/*<label className={`col-md-${valueMd} col-form-label`} data-bind="text: (customerPartnerPicUserId() ? customerPartnerPicUser.realName() : '')"></label>*/}
                    {/*</ko>*/}
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mySubject'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                    <ko if="id() === null"><span data-bind="text: $root.mainSubject.name"></span></ko>
                    <ko if="id() !== null"><span data-bind="text: mySubject.name"></span></ko>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                        data: $root.mainUsers,
                        dataTextField: 'realName',
                        dataValueField: 'userId',
                        optionLabel: '-',
                        filter: 'contains',
                        value: myPicUserId,
                        animation: false 
                    }" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} for="supplierPartnerIdInput" data-bind="i18n: 'common.captions.carrier'"></label>
                <ko if="id() === null">
                    <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                        <input className="w-100" id="supplierPartnerIdInput" data-bind="kendoDropDownList: {
                            data: $root.carriers,
                            dataTextField: 'name',
                            dataValueField: 'subjectId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: supplierPartnerId,
                            animation: false
                        }" />
                    </div>
                </ko>
                <ko if="id() !== null">
                    <label className={`col col-form-label`} data-bind="text: supplierPartnerId() ? supplierPartner.name() : ''"></label>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="click: $root.changePass.bind($root), i18n: 'common.captions.change'"></button>
                    </div>
                </ko>
            </div>
            <ko if="supplierPartnerId() && supplierPartnerId() != mySubjectId()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'supplierPartnerPicUserId', i18n: 'common.captions.carrierPicUser'"></label>
                    {/* Pokud se usek zaklada spediterem nebo se edituje a zaroven je carrier neregistrovany (nema do systemu pristup), muze za neho spediter zvolit PIC usera */}
                    {/*<ko if="id() === null || !supplierPartner.isRegistered()">*/}
                        <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                            <input className="w-100" data-bind="
                                uniqueId: 'supplierPartnerPicUserId',
                                value: supplierPartnerPicUserId,
                                kendoDropDownList: $root.supplierPartnerPicUserComboConfig
                            " />                        
                        </div>
                    {/*</ko>*/}
                    {/*<ko ifnot="id() === null || !supplierPartner.isRegistered()">*/}
                        {/* V opacnem pripade je PIC user jen pro cteni */}
                        {/*<label className={`col-md-${valueMd} col-form-label`} data-bind="text: (supplierPartnerPicUserId() ? supplierPartnerPicUser.realName() : '')"></label>*/}
                    {/*</ko>*/}
                </div>
            </ko>

        </fieldset>
    );

    /**
     * Spditer ma k editaci totez, co zakaznik pri objednavce
     */
    public static spediterMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => EditableHeaderTemplate.customerOrderMainInfo(l, v, true);
    

    public static spediterIdentification = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.identification'"></legend>

            {/*<div className="row mb-2">
                <label className="col-md-5 col-form-label" data-bind="i18n: 'common.captions.status'"></label>
                <label className="col-md-7 col-form-label" data-bind="text: trcStatusId() ? $root.statusesHash[trcStatusId()].name : '-'"></label>
            </div>
            <div className="row mb-2">
                <label className="col-md-5 col-form-label" data-bind="i18n: 'common.captions.invoicingStatus'"></label>
                <label className="col-md-7 col-form-label" data-bind="text: myInvoicingStatusId() ? $root.invoicingStatusesHash[myInvoicingStatusId()].name : '-'"></label>
            </div>*/}
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'seasonId', i18n: 'common.captions.season'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'seasonId', kendoDropDownList: {
                            data: $root.seasons,
                            dataTextField: 'name',
                            dataValueField: 'seasonId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: mySeasonId,
                            animation: false
                        }" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.number'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                    <ko if="id() !== null"><span data-bind="text: myNumber"></span></ko>
                    <ko if="id() === null"><em data-bind="i18n: 'common.captions.willBeGeneratedAfterSave'"></em></ko>
                </label>
            </div>
            {/*<ko if="customerPartnerId()">
                <div className="row mb-2">
                    <label className="col-md-5 col-form-label" data-bind="i18n: 'common.captions.customerPartnerNumber'"></label>
                    <label className="col-md-7 col-form-label" data-bind="text: customerPartnerNumber"></label>
                </div>
            </ko>
            <ko if="supplierPartnerId() && supplierPartnerNumber() != myNumber()">
                <div className="row mb-2">
                    <label className="col-md-5 col-form-label" data-bind="i18n: 'common.captions.supplierPartnerNumber'"></label>
                    <label className="col-md-7 col-form-label" data-bind="text: supplierPartnerNumber"></label>
                </div>
            </ko>*/}

        </fieldset>
    );

    /**
     * Spditer ma k editaci totez, co zakaznik pri objednavce
     */    
    public static spediterContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => EditableHeaderTemplate.customerOrderContainerInfo(l, v);


    public static spediterCargoInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => EditableHeaderTemplate.customerOrderCargoInfo(l, v, true);

    /**
     * Spditer ma k editaci totez, co dopravce
     */
    public static spediterDriverInfo = (): HTMLElement => EditableHeaderTemplate.carrierDriverInfo();

    /**
     * Ceny jsou v editaci vzdy ke cteni
     */
    public static spediterPrices = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.pricesInformation'"></legend>

            <ko if="myField() !== 'customer'">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'sellPrice', i18n: 'common.captions.sellPrice'"></label>
                    <ko if="id() === null || (myPassedFromStatusId() !== null && $root.passStatusesHash[myPassedFromStatusId()].ident === 'approved')">
                        <div className="col">
                            <input type="number" min="0" data-bind="uniqueId: 'sellPrice', value: sellPrice" className="form-control" />
                        </div>
                        <div className="col-auto">
                            <select data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: sellPriceCurrencyId" className="form-select"></select>
                        </div>
                    </ko>
                    <ko if="id() !== null && (myPassedFromStatusId() === null || $root.passStatusesHash[myPassedFromStatusId()].ident !== 'approved')">
                        <label className="col col-form-label">
                            <ko if="sellPriceCurrencyId() && sellPrice() !== null">
                                <strong data-bind="text: kendo.toString(sellPrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: sellPriceCurrency.iso"></strong>&nbsp;
                            </ko>
                            <ko if="myPassedFromStatusId()">
                                <span className="float-end" data-bind="
                                    css: {
                                        'text-warning': $root.passStatusesHash[myPassedFromStatusId()].ident === 'inprogress',
                                        'text-success': $root.passStatusesHash[myPassedFromStatusId()].ident === 'approved',
                                    },
                                    text: $root.passStatusesHash[myPassedFromStatusId()].nameTranslated
                                "></span>
                            </ko>
                        </label>
                    </ko>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="
                            i18n: 'common.captions.detail',
                            click: $root.openSellPriceDetail.bind($root),
                            enable: id() !== null
                        "></button>
                    </div>
                </div>
            </ko>

            <ko if="mySubjectId() != supplierPartnerId() && supplierPartnerId() !== null">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.purchasePrice'"></label>
                    <ko if="id() === null || (myPassStatusId() !== null && $root.passStatusesHash[myPassStatusId()].ident === 'approved')">
                        <div className="col">
                            <input type="number" data-bind="uniqueId: 'purchasePrice', value: purchasePrice" className="form-control" />
                        </div>
                        <div className="col-auto">
                            <select data-bind="options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', value: purchasePriceCurrencyId" className="form-select"></select>
                        </div>
                    </ko>
                    <ko if="id() !== null && (myPassStatusId() === null || $root.passStatusesHash[myPassStatusId()].ident !== 'approved')">
                        <label className="col col-form-label">
                            <ko if="purchasePriceCurrencyId() && purchasePrice() !== null">
                                <strong data-bind="text: kendo.toString(purchasePrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: purchasePriceCurrency.iso"></strong>&nbsp;
                            </ko>
                            <ko if="myPassStatusId()">
                                <span className="float-end" data-bind="
                                    css: {
                                        'text-warning': $root.passStatusesHash[myPassStatusId()].ident === 'inprogress',
                                        'text-success': $root.passStatusesHash[myPassStatusId()].ident === 'approved',
                                    },
                                    text: $root.passStatusesHash[myPassStatusId()].nameTranslated
                                "></span>
                            </ko>
                        </label>
                    </ko>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="
                            i18n: 'common.captions.detail',
                            click: $root.openPurchasePriceDetail.bind($root),
                            enable: id() !== null
                        "></button>
                    </div>
                </div>
            </ko>

        </fieldset>
    );
    

    // #endregion

    

    // #region Carrier

    public static carrierParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartner'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: customerPartner.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartnerPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (customerPartnerPicUserId() ? customerPartnerPicUser.realName() : '')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mySubject'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mySubject.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myPicUser'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input className="w-100" data-bind="uniqueId: 'myPicUserId', kendoDropDownList: {
                            data: $root.mainUsers,
                            dataTextField: 'realName',
                            dataValueField: 'userId',
                            optionLabel: '-',
                            filter: 'contains',
                            value: myPicUserId,
                            animation: false 
                        }" />                                    
                </div>
            </div>
            <ko if="supplierPartnerId() !== null">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.carrier'"></label>
                    <label className={`col col-form-label`}><span data-bind="text: supplierPartner.name"></span></label>
                    <div className="col-auto">
                        <button type="button" className="btn btn-secondary" data-bind="click: $root.changePass.bind($root), i18n: 'common.captions.change'"></button>
                    </div>
                </div>
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.carrierPicUser'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (supplierPartnerPicUserId() ? supplierPartnerPicUser.realName() : '')"></label>
                </div>
            </ko>
            <ko if="supplierPartnerId() === null && $root.canPass">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.carrier'"></label>
                    <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                        <button type="button" className="btn btn-secondary" data-bind="click: $root.pass.bind($root), i18n: 'common.captions.pass'"></button>
                    </div>
                </div>
            </ko>

        </fieldset>
    );

    public static carrierMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.mainInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'trade.demand.edit.kind'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: kind.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'trade.demand.edit.mode'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: roadType.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef2'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef2"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'myUzp', i18n: 'common.captions.myUzp'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="date" data-bind="uniqueId: 'myUzp', kendoDatePicker: { animation: false, value: myUzp }" className="w-100" />
                </div>
            </div>
        </fieldset>        
    ); // ReadOnlyHeaderTemplate.carrierMainInfo(labelMd, valueMd);
    
    public static carrierIdentification = (): HTMLElement => EditableHeaderTemplate.spediterIdentification();

    public static carrierContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.carrierContainerInfo(l, v);

    // Cargo information - Informace o nákladu
    public static carrierCargoInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.cargoInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.colli'"></label>
                <label className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)} col-form-label`} data-bind="text: kendo.toString(colli(), 'n0')"></label>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} col-form-label`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <span data-bind="text: kendo.toString(sumColli(), 'n0')"></span>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.kgs'"></label>
                <label className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)} col-form-label`} data-bind="text: kendo.toString(kgs(), 'n2')"></label>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} col-form-label`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <span data-bind="text: kendo.toString(sumKgs(), 'n2')"></span>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.cbm'"></label>
                <label className={`col-lg-${Math.ceil(v[0]/2)} col-xl-${Math.ceil(v[1]/2)} col-xxl-${Math.ceil(v[2]/2)} col-form-label`} data-bind="text: kendo.toString(cbm(), 'n2')"></label>
                <label className={`col-lg-${Math.floor(v[0]/2)} col-xl-${Math.floor(v[1]/2)} col-xxl-${Math.floor(v[2]/2)} col-form-label`}>
                    <span data-bind="i18n: 'common.captions.packingList'"></span>: <span data-bind="text: kendo.toString(sumCbm(), 'n2')"></span>
                </label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.chargeableVolume'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="number" value="0" min="0" style="border: 5px solid red" data-bind="value: chargeableVolume" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.ldm'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="number" value="0" min="0" data-bind="value: ldm" className="form-control" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.cargoDescription'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: cargoDescription"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.adr'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="bool: adr"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.adrClass'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: adrClass"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.un'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: un"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.packingGroup'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: packingGroup"></label>
            </div>

        </fieldset>
    );

    public static carrierDriverInfo = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.driverInfo'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'truckType', i18n: 'common.captions.truckType'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <select data-bind="options: $root.truckTypes, optionsText: 'name', optionsValue: 'truckTypeId', optionsCaption: '-', value: truckTypeId" className="form-select"></select>
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'spz', i18n: 'common.captions.spz'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="value: spz" className="form-control" />
                </div>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'driverUserId', i18n: 'common.captions.driver'"></label>
                <div className="col">
                    <input type="text" data-bind="uniqueId: 'driverUserid', value: (driverUserId() ? driverUser.realName() : '')" readonly="readonly" className="form-control" />
                </div>
                <label className="inline col-auto col-form-label ps-0 pe-0" data-bind="uniqueFor: 'driverPhone'"><i className="icon-phone"></i></label>
                <div className="col">
                    <input type="text" data-bind="uniqueId: 'driverPhone', value: (driverUserId() ? (driverUser.mobile() ? driverUser.mobile() : driverUser.phone() ) : '')" readonly="readonly" className="form-control" />
                </div>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: 'driverInfo', i18n: 'common.captions.driverInfo'"></label>
                <div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>
                    <input type="text" data-bind="uniqueId: 'driverInfo', value: driverInfo" readonly="readonly" className="form-control" />
                </div>
            </div>
        </fieldset>
    );

    public static carrierPrices = (): HTMLElement => EditableHeaderTemplate.spediterPrices();

    // #endregion 
}