
import { h } from "../../../tracejs/src/utils/JSXFactory";
import moment from "moment";
import { i18n } from "i18next-ko";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";

/**
 * PO Management - Edit
 */
@injectable()
export class PoEdit extends BaseDialogViewModel<any> {

	/**
	 * Knockout Observable for poItem
	 */
	private poItem: KnockoutObservable<any> = ko.observable(ko.mapping.fromJS({
		'cargoValue': null,
		'cargoValueCurrId': null,
		'cbm': null,
		'cntrnr': null,
		'colli': 1,
		'colliTypeId': null,
		'contactPersonEmail': null,
		'descr': null,
		'ecrTimestamp': null,
		'height': null,
		'hsCode': null,
		'id': null,
		'isParent': true,
		'length': null,
		'metAtb': null,
		'metOriginCountryId': null,
		'oogcntr': null,
		'pcs': null,
		'pn1': null,
		'pn2': null,
		'pn3': null,
		'stackable': "NO",
		'status': null,
		'subjectId': null,
		'updatetd': null,
		'weight': null,
		'width': null
	}));

	/**
	 * Packagings
	 */
	private colliTypes: KnockoutObservableArray<any> = ko.observableArray(null);

	/**
	 * Typ konteineru
	 */
	private currencies: KnockoutObservableArray<any> = ko.observableArray();

	/**
	 * Státy
	 */
	private countries: KnockoutObservableArray<any> = ko.observableArray();

	/**
	 * ViewModel Startup
	 * 
	 * @param orderId Order ID
	 * @param poItemId PO item ID
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		// Opravneni na view / edit
		if(this.settings.poItemId && !this.user.isAllowed('entity.tracedo', 'edit')) {
			throw new BadRequestError('Access denied - cannot edit PO Item', 403);
		}
		if(!this.settings.poItemId && !this.user.isAllowed('entity.tracedo', 'create')) {
			throw new BadRequestError('Access denied - cannot create PO Item', 403);
		}

		// Batch na ciselniky + PO Item k editaci
		var batch = this.rpc.batch();
		batch.call('mainSubject', 'subject.getMain');
		batch.call('colliTypes', 'colliType.get');
		batch.call('currencies', 'currency.get', { query: { sort: [{ field: 'iso', dir: 'asc'}] } });
		batch.call('countries', 'country.get', { query: { select: 'countryId,descr,nameEn', sort: [{ field: 'descr', dir: 'asc' }] } });
		if (this.settings.poItemId) {
			batch.call('poItem', 'poItem.getOne', { id: this.settings.poItemId });
		}
		let response: any = await batch.run();

		// naplnime ciselniky
		this.currencies(response['currencies']);
		this.countries(response['countries']);
		this.colliTypes(response['colliTypes']);
		// a take PO Item
		if (response['poItem']) {
			let  pi = response['poItem'];
			pi.width = Math.round(pi.width * 100);
			pi.length = Math.round(pi.length * 100);
			pi.height = Math.round(pi.height * 100);

			pi.ecrTimestamp = pi.ecrTimestamp ? kendo.parseDate(pi.ecrTimestamp) : null;
			if (this.settings.poItemId !== null) {
				pi.subjectId = response['mainSubject'].subjectId;
			}

			this.poItem(ko.mapping.fromJS(pi));
		}
	}


	// ulozi nastaveni
	public async save(): Promise<any>
	{
		// pack do javascript objectu
		let poItem: any = ko.mapping.toJS(this.poItem);
		// validace dat
		if (poItem.colli == null || poItem.colli == 0 || isNaN(poItem.colli)) {
			poItem.colli = 1;
		}
		// defaultni pro pcs je NULL
		if (poItem.pcs == null || poItem.pcs == 0 || isNaN(poItem.pcs)) {
			poItem.pcs = null;
		}
		poItem.ecrTimestamp = poItem.ecrTimestamp === null || poItem.ecrTimestamp == '' ? null : moment(poItem.ecrTimestamp).format();
		poItem.width = poItem.width == null || poItem.width == 0 || isNaN(poItem.width) ? null : poItem.width / 100;
		poItem.length = poItem.length == null || poItem.length == 0 || isNaN(poItem.length) ? null : poItem.length / 100;
		poItem.height = poItem.height == null || poItem.height == 0 || isNaN(poItem.height) ? null : poItem.height / 100;
		
		//nikdy nemenit extId z dialogu k vůli premazani extid z synchronizace
		delete poItem.extId;

		// ulozit k tracedo
		if(this.settings.tracedoId) {
			return await this.rpc.call('poItem.saveToTracedoId', { tracedoId: this.settings.tracedoId, poItem: poItem });
		}

		// ulozit samotny po item
		return await this.rpc.call('poItem.save', { poItem: poItem });
	}


	public dialogTemplate = (): HTMLElement => (

		<div>

			<div class="row">
				<div class="col-md-6">
					{/*<!-- ko if: $root.orderItem -->
						<!-- ko with: $root.orderItem -->
						<fieldset>
							<legend data-bind="i18n: 'common.captions.poHeader'"></legend>
							<div class="row">
								<div class="large-4 columns">
									<p class="inline"><span data-bind="i18n: 'common.captions.metPin'"></span>:</p>
								</div>
								<div class="large-8 columns">
									<p class="inline" data-bind="text: metPin"></p>
								</div>
							</div>
							<div class="row">
								<div class="large-4 columns">
									<p class="inline"><span data-bind="i18n: 'common.captions.metAtb'"></span>:</p>
								</div>
								<div class="large-8 columns">
									<p class="inline" data-bind="text: metAtb"></p>
								</div>
							</div>
						</fieldset>
						<!-- /ko -->
					<!-- /ko -->*/}

					<ko with="$root.poItem">
						<fieldset>
							<legend data-bind="i18n: 'edit.poItem.baseInfo'"></legend>

							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'pn1'">PN1</label>
								<div className="col-md-8">
									<input type="text" className="form-control" data-bind="value: pn1, uniqueId: 'pn1'" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'pn2'">PN2</label>
								<div className="col-md-8">
									<input type="text" className="form-control" data-bind="value: pn2, uniqueId: 'pn2'" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'pn3'">PN3</label>
								<div className="col-md-8">
									<input type="text" className="form-control" data-bind="value: pn3, uniqueId: 'pn3'" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'packaging', i18n: 'edit.poItem.packaging'"></label>
								<div className="col-md-8">
									<select className="form-select" data-bind="uniqueId: 'packaging', value: colliTypeId, options: $root.colliTypes, optionsText: 'name', optionsValue: 'colliTypeId', optionsCaption: ''"></select>
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'stackable', i18n: 'edit.poItem.stackable'"></label>
								<div className="col-md-8">
									<select className="form-select" data-bind="uniqueId: 'stackable', value: stackable">
										<option value="NO" data-bind="i18n: 'common.captions.no'"></option>
										<option value="YES" data-bind="i18n: 'common.captions.yes'"></option>										
									</select>
								</div>
							</div>

							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'hsCode', i18n: 'common.captions.hsCode'"></label>
								<div className="col-md-8">
									<input type="text" className="form-control" data-bind="uniqueId: 'hsCode', value: hsCode" />
								</div>
							</div>							
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'colli', i18n: 'edit.poItem.colli'"></label>
								<div className="col-md-8">
									<input type="number" className="form-control" data-bind="uniqueId: 'colli', value: colli" />
								</div>
							</div>							

							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'cargoValue', i18n: 'common.captions.cargoValue'"></label>
								<div className="col-md-8">
									<div className="row">
										<div className="col pe-0"><input type="number" className="form-control" data-bind="uniqueId: 'cargoValue', value: cargoValue" /></div>
										<div className="col-auto ps-1"><select className="form-select" data-bind="uniqueId: 'currency.iso', options: $root.currencies, optionsValue: 'currencyId', optionsText: 'iso', optionsCaption: '', value: cargoValueCurrId" /></div>
									</div>
								</div>
							</div>							

							{/*<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'contactPersonEmail', i18n: 'edit.poItem.contactEmail'"></label>
								<div className="col-md-8">
									<input type="text" className="form-control" data-bind="uniqueId: 'contactPersonEmail', value: contactPersonEmail" />
								</div>
							</div>*/}


							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'metOriginCountry', i18n: 'edit.poItem.metOriginCountry'"></label>
								<div className="col-md-8">
									<input type="text" className="w-100" data-bind="
											uniqueId: 'metOriginCountry', 
											kendoComboBox: {
												value: metOriginCountryId, 
												placeholder: i18n.t('common.captions.metOriginCountry'),
												dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
												dataValueField: 'countryId',
												filter: 'contains',
												autoBind: true,
												minLength: 3,
												data: $root.countries												
											}
										" />
								</div>
							</div>
							
						</fieldset>
						<fieldset>
							<legend data-bind="i18n: 'common.captions.dates'"></legend>

							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'ecr', i18n: 'common.captions.ecr'"></label>
								<div className="col-md-8">
									<input type="text" className="w-100" data-bind="uniqueId: 'ecr', kendoDateTimePicker: ecrTimestamp" />
								</div>
							</div>
						</fieldset>
					</ko>
				</div>
				<div class="col-md-6">
					<ko with="$root.poItem">
						<fieldset>
							<legend data-bind="i18n: 'edit.poItem.perColli'"></legend>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'pcs', i18n: 'edit.poItem.pcs'"></label>
								<div className="col-md-8">
									<input type="number" className="form-control" data-bind="uniqueId: 'pcs', value: pcs" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'length', i18n: 'edit.poItem.length'"></label>
								<div className="col-md-8">
									<input type="number" className="form-control" data-bind="uniqueId: 'length', value: length" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'width', i18n: 'edit.poItem.width'"></label>
								<div className="col-md-8">
									<input type="number" className="form-control" data-bind="uniqueId: 'width', value: width" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'height', i18n: 'edit.poItem.height'"></label>
								<div className="col-md-8">
									<input type="number" className="form-control" data-bind="uniqueId: 'height', value: height" />
								</div>
							</div>
							<div className="row mb-2">
								<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'weight', i18n: 'edit.poItem.weight'"></label>
								<div className="col-md-8">
									<input type="number" className="form-control" data-bind="uniqueId: 'weight', value: weight" />
								</div>
							</div>
						</fieldset>


						<label data-bind="uniqueFor: 'descr', i18n: 'edit.poItem.descr'" className="mb-1"></label>
						<textarea data-bind="uniqueId: 'descr', value: descr" className="form-control" style="min-height:100px"></textarea>

					</ko>
				</div>
			</div>

		</div>

	);		


}