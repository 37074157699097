import { i18n } from "i18next-ko";
import { AnyEvent } from "ts-events";
import { injectable } from "tsyringe";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BaseConfigurableWidget } from "../common/BaseConfigurableWidget";

import { BaseViewModel } from "../common/BaseViewModel";
import { BaseWidget } from "../common/BaseWidget";
import { IWidgetBaseSettings } from "../common/IWidgetBaseSettings";
import { IWidgetData } from "../common/IWidgetData";
import { AddWidget } from "./AddWidget";

/**
 * Application Dashboard
 */
@injectable()
export class Dashboard extends BaseViewModel<any>
{

	/**
	 * Dashboard grid (layout)
	 */
	protected grid: KnockoutObservable<any> = ko.observable(null);

	/**
	 * Widgets
	 */
	protected widgets: any[];

	/**
	 * All available Widgets as associative object
	 */
	protected available: { [key: string]: any };

	/**
	 * Widget instances
	 */
	protected instances: KnockoutObservable<any> = ko.observable({});


	/**
	 * Startup
	 */
	public async startup()
	{
		await super.startup();
		await this.loadDashboard();
	}

	/**
	 * Read Dashboard grid and widgets
	 */
	public async loadDashboard(): Promise<void>
	{
		let batch = this.rpc.batch();
		// get my dashboard layout
		batch.call('grid', 'dashboard.getMyGrid');
		// get all my dashboard widgets
		batch.call('widgets', 'dashboard.get');
		// get default dashboard layout (in case user doesn't have one)
		batch.call('defaultGrid', 'dashboard.getDefaultGrid');
		// get all available widgets
		batch.call('available', 'dashboard.getAvailableAssoc');

		let response = await batch.run<{[key:string]:any}>();

		// Available Widgets associative object
		this.available = response['available'];

		// parse and set grid (if user has no grid, use the default one)
		let grid = response['grid'] === null ? response['defaultGrid'] : response['grid'];
		grid.json = JSON.parse(grid.json);
		this.grid(grid);

		// set widget configurations
		this.widgets = response['widgets'];

		// load and instantiate all widgets, resolve when done
		return await this.createWidgets();
	}

	/**
	 * Render
	 */
	public async rendered()
	{
		await super.rendered();
		// Init kendoSortable (drag and drop)
		let width = 0;
		let cloned: JQuery = null;
		this.element.find('ul.content-placeholder').kendoSortable({
			connectWith: '.content-placeholder',
			handler: '.widget-handle',
			ignore: 'table,thead,tbody,tr,td,th,input,select,button,.inline-form,.dashboard-widget,.dashboard-widget>div,.dashboard-widget>div>div',
			placeholder: jQuery('<li class="drop-placeholder">Drop here!</li>'),
			start: (e: kendo.ui.SortableStartEvent) => {
				width = e.sender.element.width();
				cloned.css('width', width);
			},
			hint: (element: JQuery) => {
				cloned = element.clone(false);
				cloned.css('width', width);
				return cloned;
			},
			move: () => {
				//cloned.css('width', e.target.width());
			},
			change: (e: any) => {
				width = 0;
				cloned = null;
				this.updatePlaceholder.apply(this, [e]);
			}
		});
	}


	/**
	 * Create widgets
	 */
	protected async createWidgets(): Promise<any>
	{
		let promises: Promise<any>[] = [];

		// temporary instances
		let tempInstances: { [key: string]: { [key: number]: string|IWidgetData } } = {};
		let tempInstancesFinal: { [key: string]: KnockoutObservableArray<string|IWidgetData> } = {};

		// pripravit placeholders pro widgety, ktere se budou vytvaret
		this.preparePlaceholderObjects(tempInstances, this.grid().json);
		this.preparePlaceholders(tempInstancesFinal, this.grid().json);

		// loop through all widgets and instantiate them
		this.widgets.forEach((widget: any, index: number) => {
			// key = placeholder, value = array of widgets
			((widg: any) => {
				if(this.available[widg.ident]) {
					// nacteme viewmodel widgetu
					let promise = this.loadViewFrame('Widget__' + widg.ident, null, {
						widget: ko.mapping.fromJS(widg),
						settings: jQuery.extend({}, this.available[widg.ident].defaults, JSON.parse(widg.settings)),
						definition: this.available[widg.ident]
					});
					promises.push(promise);
					// nez se nacte instance widgetu
					tempInstances[widg.placeholder][widg.posIndex] = 'creating instance...';
					// a kdyz je hotovyy, nahradime "creating instance..." za widget samotny
					promise.then((vm: BaseWidget<any>) => {
						tempInstances[widg.placeholder][widg.posIndex] = {
							caption: ko.computed<string>(() => {
								return vm.record.userCaption() ? vm.record.userCaption() : vm.definition.name;
							}),
							icon: ko.computed<string>(() => {
								return vm.definition.icon ? 'icon-' + vm.definition.icon : '';
							}),
							viewModel: vm
						};
					});
				}
			})(widget);
		});

		// When all widgets are loaded, resolve deferred object
		await Promise.all(promises);

		// z objektove podoby je dame do observable arrays podoby
		jQuery.each(tempInstances, (wPlaceholderName: string, wInstHash: { [key: number]: string|IWidgetData }) => {
			jQuery.each(wInstHash, (wPosIndex: number, wInstance: string|IWidgetData) => {
				tempInstancesFinal[wPlaceholderName].push(wInstance);
			});
		});

		// set instances
		this.instances(tempInstancesFinal);
	}



	/**
	 * Prepare placeholders for widgets
	 * @param instances Widget instances hash-map
	 * @param nodes GRID
	 */
	protected preparePlaceholderObjects(instances: { [key: string]: any }, nodes: any[])
	{
		nodes.forEach(node => {
			if (node.items) {
				this.preparePlaceholderObjects(instances, node.items);
			}
			if (node.placeholder) {
				instances[node.placeholder] = {};
			}
		});
	}

	/**
	 * Prepare placeholders for widgets
	 * @param instances Widget instances hash-map
	 * @param nodes GRID
	 */
	protected preparePlaceholders(instances: { [key: string]: KnockoutObservableArray<any> }, nodes: any[])
	{
		nodes.forEach(node => {
			if(node.items) {
				this.preparePlaceholders(instances, node.items);
			}
			if (node.placeholder) {
				instances[node.placeholder] = ko.observableArray([]);
			}
		});
	}


	/**
	 * Widget has been moved to new destination, save the position
	 */
	public updatePlaceholder(event: any)
	{
		var widgetId = event.item.data('widget-id');
		var placeholder = event.item.closest('ul').data('placeholder');
		var posIndex = event.newIndex;
		if (posIndex > -1) {
			this.rpc.notify('dashboard.move', {
				id: widgetId,
				placeholder: placeholder,
				posIndex: posIndex
			});
		}
	}

	/**
	 * Edit widget caption
	 */
	public widgetRename(widgetData: IWidgetData)
	{
		widgetData.viewModel.openRename();
	}

	/**
	 * Opens widget settings
	 */
	public widgetSettings(widgetData: IWidgetData) {
		if(widgetData.viewModel.configurable) {
			(widgetData.viewModel as BaseConfigurableWidget).openSettings();
		}
	}

	/**
	 * Add new widget
	 */
	public async addWidget(gridNode: { placeholder: string, size: number, type: string }) {

		let win: kendo.ui.Window = null;
		let vm = await this.loadViewFrame<AddWidget>(AddWidget, 'dashboardDialog', {
			dialog: {
				width: 450,
				height: 220,
				modal: true,
				title: i18n.t('dashboard.placeNewWidget'),
				buttons: (vm: AddWidget, window: kendo.ui.Window) => {
					win = window;
					return [
						{ align: 'right', cls: 'btn-link', label: i18n.t('common.actions.cancel'), click: () => window.close() },
						{ align: 'right', cls: 'btn-primary', label: i18n.t('common.actions.save'), click: () => vm.select() }
					];
				}
			}
		});

		// when widget type is selected => save (place) new widget and refresh the grid
		vm.onSelected.attach(async (widget: any) => {
			await this.rpc.call('dashboard.place', { ident: widget.ident, placeholder: gridNode.placeholder, posIndex: null });
			win.close();
			this.flash.success(i18n.t('dashboard.widgetPlaced'));
			this.loadDashboard();
		});
	}

	/**
	 * Removes a widget from Dashboard
	 * @param widgetData IWidgetData
	 */
	public async removeWidget(widgetData: IWidgetData)
	{
		let result = await this.confirmDialog(i18n.t('dashboard.removeWidgetQuestion'));
		if(result) {
			// delete on server			
			await this.rpc.call('dashboard.delete', { id: widgetData.viewModel.id });
			// delete from dashboard
			let placeholder = widgetData.viewModel.element.closest('ul[data-placeholder]').data('placeholder');
			this.instances()[placeholder].remove(widgetData);
		}
	}








	public template = (): HTMLElement => (
		<div>
			<ko with="$root.instances">
				<h1>Dashboard</h1>
				<div class="dashboard" data-bind="template: { name: 'dashboardGrid', foreach: $root.grid().json }"></div>
			</ko>

			<view-frame name="dashboardDialog" />

		</div>
	);

	public externalTemplates = (): { [key: string]: HTMLElement } => ({
		'dashboardGrid': 

			<div data-bind="attr: { 'class': $data.type == 'c' ? 'col-md-' + $data.size : ($data.type == 'r' ? 'row mb-2' : '') }">
				<ko if="$data.items && $data.items.length > 0">
					<ko template="{ name: 'dashboardGrid', foreach: $data.items }"></ko>
				</ko>
				<ko if="$data.placeholder">
					<div class="panel">
						<ul data-bind="foreach: $root.instances()[$data.placeholder], attr: { 'data-placeholder': $data.placeholder }" class="content-placeholder">
							<li class="dashboard-widget-li" data-bind="attr: { 'data-widget-id': $data.viewModel.id }">
								<div class="dashboard-widget">
									<span className="widget-handle"></span>
									<div class="row mb-2">
										<div className="col">
											<h2><i data-bind="attr: { class: $data.icon }"></i><span data-bind="text: $data.caption"></span></h2>
										</div>
										<div className="col-auto">
											<div class="widget-icons">
												<div className="btn-group logged-user-dropdown">
													<button type="button" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i class="icon-cog"></i></button>
													<ul className="dropdown-menu dropdown-menu-end">
														<li><button className="dropdown-item" type="button" data-bind="click: $root.widgetRename.bind($root)"><i class="icon-edit"></i> <span data-bind="i18n: 'dashboard.widgetRename'"></span></button></li>
														<ko if="$data.viewModel.configurable">
															<li><button className="dropdown-item" type="button" data-bind="click: $root.widgetSettings.bind($root)"><i class="icon-edit"></i> <span data-bind="i18n: 'dashboard.widgetSettings'"></span></button></li>
														</ko>
														<li><button className="dropdown-item" type="button" data-bind="click: $root.removeWidget.bind($root)"><i class="icon-cancel"></i> <span data-bind="i18n: 'dashboard.widgetRemove'"></span></button></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div data-bind="appendElement: $data.viewModel.element"></div>
									</div>
								</div>
							</li>
						</ul>
						<a href="#" class="add-new-widget btn btn-outline-primary" data-bind="click: $root.addWidget.bind($root), attr: { title: i18n.t('dashboard.addNewTooltip') }"><i class="icon-plus"></i></a>
					</div>
				</ko>
			</div>
	})

}
