import { FeeGroup } from './../entities/FeeGroup.d';
import { i18n } from "i18next-ko";
import { singleton } from "tsyringe";
import { Culture } from "../../tracejs/src/application/Culture";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";

import { BdpContainerType } from "../entities/BdpContainerType";
import { CheckpointType } from "../entities/CheckpointType";
import { ColliType } from "../entities/ColliType";
import { Country } from "../entities/Country";
import { Currency } from "../entities/Currency";
import { Depot } from "../entities/Depot";
import { InvoicingStatus } from "../entities/InvoicingStatus";
import { Kind } from "../entities/Kind";
import { LogAction } from "../entities/LogAction";
import { Mode } from "../entities/Mode";
import { PackingGroup } from "../entities/PackingGroup";
import { PassStatus } from "../entities/PassStatus";
import { RoadType } from "../entities/RoadType";
import { Season } from "../entities/Season";
import { Stackable } from "../entities/Stackable";
import { Status } from "../entities/Status";
import { TruckType } from "../entities/TruckType";
import { Fee } from '../entities/Fee';
import { CalculatorStatus } from '../entities/CalculatorStatus';
import { Calculator } from '../views/components/Calculator/Calculator';

@singleton()
export class CodelistManager
{

	/**
	 * RPC Client
	 */
    private rpc: Client;

    /**
     * Culture
     */
    private culture: Culture;


    /**
     * Kinds (Import / Export)
     */
    private kinds: Kind[];

    /**
     * Modes (Road / Sea / Rail ...)
     */
    private modes: Mode[];

    /**
     * Road classic, Road Container
     */
    private roadTypes: RoadType[];

    /**
     * Tracedo statuses (New, Accepted, Loaded, Suspended ...)
     */
    private statuses: Status[];

    /**
     * Pending, Approved, Rejected
     */
    private invoicingStatuses: InvoicingStatus[];

    /**
     * Stavy postoupeni prepravy
     */
    private passStatuses: PassStatus[];

    /**
     * Ciselnik zemi
     */ 
    private countries: Country[];

    /**
     * Meny
     */ 
    private currencies: Currency[];

    /**
     * PICKUP, VAN, 1t, ...
     */
    private truckTypes: TruckType[];

    /**
     * 2021, 2022 ...
     */
    private seasons: Season[];

    /**
     * BAG, BOX
     */
    private colliTypes: ColliType[];

    /**
     * 40TA, 20OT ...
     */
    private bdpCntrTypes: BdpContainerType[];

    /**
     * Log actions
     */
    private logActions: LogAction[];

    /**
     * Depots
     */
    private depots: Depot[];

    /**
     * Checkpoint types
     */
    private checkpointTypes: CheckpointType[];

    /**
     * Calculator Fees
     */
    private fees: Fee[];

    /**
     * Calculator Fee Groups
     */
    private feeGroups: FeeGroup[];

    /**
     * Cost statuses
     */
    private costStatuses: CalculatorStatus[];

    /**
     * Revenue statuses
     */
    private revenueStatuses: CalculatorStatus[];

    /**
     * Stohovatelnost
     */
    private stackables: Stackable[] = [ 
        { id: 0, name: i18n.t('common.captions.no') }, 
        { id: 1, name: i18n.t('common.captions.yes') }
    ];

    /**
     * Packing groups
     */
    private packingGroups: PackingGroup[] = [
        { code: 'I' },
        { code: 'II' },
        { code: 'III' }
    ];


    /**
     * Max carrier number
     */
    private maxCarrier: number;


    /**
     * Constructor
     * @param rpc RPC
     */
    constructor(rpc: Client, culture: Culture)
    {
        this.rpc = rpc;
        this.culture = culture;
    }

    /**
     * Nacte ciselniky do pameti
     */
    public async cacheCodelists(): Promise<void>
    {
        const response: any = await this.rpc.batch()
            .call('kinds', 'transportKind.get', { 
                query: { sort: [{ field: 'name', dir: 'asc' }] } 
            })
            .call('modes', 'transportMode.get')
            .call('roadTypes', 'roadType.get', { 
                query: { sort: [{ field: 'name', dir: 'asc' }] } 
            })
            .call('statuses', 'tracedoStatus.get')
            .call('invoicingStatuses', 'tracedoInvoicingStatus.get', { 
                query: { select: 'statusId,ident,name,post' } 
            })
            .call('passStatuses', 'tracedoPassStatus.get')
            .call('countries', 'country.get', {
                query: { select: 'countryId,descr,iso,isoCode,nameEn,isEu', sort: [{ field: 'descr', dir: 'asc' }] } 
            })
            .call('currencies', 'currency.get', {
                query: { select: 'currencyId,descr,iso,post,postClient', sort: [{ field: 'post', dir: 'asc' }] } 
            })
            .call('truckTypes', 'truckType.get', {
                query: { sort: [{ field: 'name', dir: 'asc' }] } 
            })
            .call('seasons', 'season.get', { 
                query: { sort: [{ field: "seasonId", dir: "desc" }] } 
            })
            .call('colliTypes', 'colliType.get', { 
                query: { sort: [{ field: 'name', dir: 'asc' }] }
            })
            .call('bdpCntrTypes', 'bdpContainerType.get', { 
                query: { sort: [{ field: "post", dir: "asc" }] } 
            })
            .call('logActions', 'logAction.get')
            .call('depots', 'depot.get', {
                query: { sort: [{ field: 'name', dir: 'asc' }] }
            })
            .call('checkpointTypes', 'tracedo.getCheckpointTypes', { 
                query: { select: 'id,ident,nameCs,nameEn', sort: [{ field: 'nameCs', dir: 'asc' }] }
            })
            .call('feeGroups', 'calculator.getFeeGroups', { 
                query: { select: '*', sort: [{ field: 'name', dir: 'asc' }] }
            })
            .call('fees', 'calculator.getFees', { 
                query: { select: '*', sort: [{ field: 'nameCs', dir: 'asc' }] }
            })
            .call('costStatuses', 'calculator.getCostStatuses', { 
                query: { select: '*', sort: [{ field: 'posIndex', dir: 'asc' }] }
            })
            .call('revenueStatuses', 'calculator.getRevenueStatuses', { 
                query: { select: '*', sort: [{ field: 'posIndex', dir: 'asc' }] }
            })
            .call('maxcarrier', 'tracedo.getMaxCarrier')
            .run();

        this.kinds = response['kinds'];
        this.modes = response['modes'];
        this.roadTypes = response['roadTypes'];
        this.statuses = response['statuses'];
        this.invoicingStatuses = response['invoicingStatuses'];
        this.fees = response['fees'];
        this.costStatuses = response['costStatuses'];
        this.revenueStatuses = response['revenueStatuses'];
        this.feeGroups = response['feeGroups'];

        this.passStatuses = response['passStatuses'];
        this.passStatuses.forEach((passStatus: PassStatus) => { passStatus.nameTranslated = this.culture.localeShort == 'cs' ? passStatus.nameCs : passStatus.nameEn });

        this.countries = response['countries'];
        this.countries.forEach((country: Country) => { country.nameTranslated = this.culture.localeShort == 'cs' ? country.descr : country.nameEn });

        this.currencies = response['currencies'];
        this.truckTypes = response['truckTypes'];
        this.seasons = response['seasons'];
        this.colliTypes = response['colliTypes'];
        this.bdpCntrTypes = response['bdpCntrTypes'];
        this.depots = response['depots'];
        this.checkpointTypes = response['checkpointTypes'];
        this.maxCarrier = response['maxcarrier'];
    }



    /**
     * Get transport kinds
     */
    public getKinds(): Kind[]
    {
        return this.kinds;
    }

    /**
     * Get one king
     * @param id Kind ID
     * @param valueField
     * @returns 
     */
    public getOneKind(id: number, valueField: string = null): Kind|any
    {
        let kinds = this.kinds.filter((kind: Kind) => kind.kindId == id);
        if(kinds.length <= 0) {
            return null;
        }
        return valueField ? (kinds[0] as any)[valueField] : kinds[0];        
    }




    /**
     * Get Tracedo statuses
     */
    public getStatuses(): Status[]
    {
        return this.statuses;
    }

    /**
     * Get one tracedo status by id
     * @param id 
     * @param valueField 
     * @returns 
     */
    public getOneStatus(id: number, valueField: string = null): Status|any
    {
        let statuses = this.statuses.filter((status: Status) => status.id == id);
        if(statuses.length <= 0) {
            return null;
        }
        return valueField ? (statuses[0] as any)[valueField] : statuses[0];
    }



    /**
     * Get countries
     * @param select Which columns to select (null = all) 
     */
    public getCountries(): Country[]
    {
        return this.countries;
    }

    /**
     * Get one country by ID
     * @param id 
     * @param valueField
     * @returns 
     */
    public getOneCountry(id: number, valueField: string = null): Country|any
    {
        let countries = this.countries.filter((country: Country) => country.countryId == id);
        if(countries.length <= 0) {
            return null;
        }
        return valueField ? (countries[0] as any)[valueField] : countries[0];
    }



    /**
     * Get road types
     */
    public getRoadTypes(): RoadType[]
    {
        return this.roadTypes;
    }

    /**
     * Get one road type by ID
     * @param id 
     * @param valueField 
     */
    public getOneRoadType(id: number, valueField: string = null): RoadType|any
    {
        let rts = this.roadTypes.filter((rt: RoadType) => rt.id == id);
        if(rts.length <= 0) {
            return null;
        }
        return valueField ? (rts[0] as any)[valueField] : rts[0];
    }


    /**
     * Get invoicing statuses
     */
    public getInvoicingStatuses(): InvoicingStatus[]
    {
        return this.invoicingStatuses;
    }

    /**
     * Get one invoicing status
     * @param id 
     * @param valueField 
     * @returns 
     */
    public getOneInvoicingStatus(id: number, valueField: string = null): InvoicingStatus|any
    {
        let iss = this.invoicingStatuses.filter((is: InvoicingStatus) => is.statusId == id);
        if(iss.length <= 0) {
            return null;
        }
        return valueField ? (iss[0] as any)[valueField] : iss[0];
    }    
    

    /**
     * Get Fee Groups
     */
    public getFeeGroups(): FeeGroup[]
    {
        return this.feeGroups;
    }

    /**
     * Get Fees
     */
    public getFees(): Fee[]
    {
        return this.fees;
    }

    /**
     * Get one fee group
     * @param id 
     * @param valueField 
     * @returns 
     */
    public getOneFeeGroup(id: number, valueField: string = null): FeeGroup|any
    {
        let fgs = this.feeGroups.filter((is: FeeGroup) => is.id == id);
        if(fgs.length <= 0) {
            return null;
        }
        return valueField ? (fgs[0] as any)[valueField] : fgs[0];
    }  

    /**
     * Get one fee
     * @param id 
     * @param valueField 
     * @returns 
     */
    public getOneFee(id: number, valueField: string = null): Fee|any
    {
        let fees = this.fees.filter((is: Fee) => is.id == id);
        if(fees.length <= 0) {
            return null;
        }
        return valueField ? (fees[0] as any)[valueField] : fees[0];
    }    
     


    /**
     * Get log actions
     */
    public getLogActions(): LogAction[]
    {
        return this.logActions;
    }

    /**
     * Get depots
     */
    public getDepots(): Depot[]
    {
        return this.depots;
    }

    /**
     * Get checkpoint types
     */
    public getCheckpointTypes(): CheckpointType[]
    {
        return this.checkpointTypes;
    }

    /**
     * Stackables codelist
     */
    public getStackables(): Stackable[]
    {
        return this.stackables;
    }

    /**
     * Packing groups
     */
    public getPackingGroups(): PackingGroup[]
    {
        return this.packingGroups;
    }

    /**
     * Colli types
     */
    public getColliTypes(): ColliType[]
    {
        return this.colliTypes;
    }

    /**
     * BDP Container types
     */
    public getBdpCntrTypes(): BdpContainerType[]
    {
        return this.bdpCntrTypes;
    }

    /**
     * Get one BTP container type by ID
     * @param id 
     * @param valueField 
     */
    public getOneBdpCntrType(id: number, valueField: string = null): BdpContainerType|any
    {
        let bdpcts = this.bdpCntrTypes.filter((bdpct: BdpContainerType) => bdpct.bdpContainerTypeId == id);
        if(bdpcts.length <= 0) {
            return null;
        }
        return valueField ? (bdpcts[0] as any)[valueField] : bdpcts[0];
    }    

    /**
     * Currencies
     */
    public getCurrencies(): Currency[]
    {
        return this.currencies;
    }

    /**
     * Get one currency by ID
     * @param id 
     * @param valueField 
     */
    public getOneCurrency(id: number, valueField: string = null): Currency|any
    {
        let cs = this.currencies.filter((cur: Currency) => cur.currencyId == id);
        if(cs.length <= 0) {
            return null;
        }
        return valueField ? (cs[0] as any)[valueField] : cs[0];
    }

    /**
     * Get one currency by ID
     * @param iso
     * @param valueField 
     */
    public getOneCurrencyByIso(iso: string, valueField: string = null): Currency|any
    {
        let cs = this.currencies.filter((cur: Currency) => cur.iso.toLowerCase() == iso.toLowerCase());
        if(cs.length <= 0) {
            return null;
        }
        return valueField ? (cs[0] as any)[valueField] : cs[0];
    }

    /**
     * Truck types
     */
    public getTruckTypes(): TruckType[]
    {
        return this.truckTypes;
    }

    /**
     * Seasons
     */
    public getSeasons(): Season[]
    {
        return this.seasons;
    }

    /**
     * Get one season
     */
    public getOneSeason(id: number, valueField: string = null): Season|any
    {
        let oss = this.seasons.filter((os: Season) => os.seasonId == id);
        if(oss.length <= 0) {
            return null;
        }
        return valueField ? (oss[0] as any)[valueField] : oss[0];
    }



    /**
     * Stavy postoupeni prepravy
     */
    public getPassStatuses(addNull: boolean = false): PassStatus[]
    {
        if(!addNull) {
            return this.passStatuses;
        }
        let passStatuses = JSON.parse(JSON.stringify(this.passStatuses));
        passStatuses.unshift({
            ident: "empty",
            nameCs: "-",
            nameEn: "-",
            nameTranslated: "-",
            statusId: null
        });
        return passStatuses;
    }

    /**
     * Get one pass status
     * @param id 
     * @param valueField 
     * @returns 
     */
    public getOnePassStatus(id: number, valueField: string = null): PassStatus|any
    {
        let pss = this.passStatuses.filter((ps: PassStatus) => ps.statusId == id);
        if(pss.length <= 0) {
            return null;
        }
        return valueField ? (pss[0] as any)[valueField] : pss[0];
    }

    /**
     * Get cost statuses
     */
    public getCostStatuses(): CalculatorStatus[]
    {
        return this.costStatuses;
    }

    /**
     * Get revenue statuses
     */
    public getRevenueStatuses(): CalculatorStatus[]
    {
        return this.revenueStatuses;
    }

    

    /**
     * Max carrier
     */
    public getMaxCarrier(): number
    {
        return this.maxCarrier;
    }



    // -------------------------------------------------------------------------------------



    /**
     * Create DropDownList filter for TransportKinds (Import/Export) on the given element
     */
    public createKindFilter(element: JQuery<HTMLElement>, valueField: string = 'kindId'): void
    {
        let kinds = this.getKinds();
        element.kendoDropDownList({
            dataSource: kinds,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: 'name',
            dataValueField: valueField
        });
    }

    /**
     * Create DropDownList filter for Tracedo statuses (New,Accepted,Set to driver...) on the given element
     */
    public createTracedoStatusFilter(element: JQuery<HTMLElement>, valueField: string = 'trcStatusId'): void
    {
        let statuses = this.getStatuses();
        element.kendoDropDownList({
            dataSource: statuses,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: 'name',
            dataValueField: valueField
        });
    }

    /**
     * Create DropDownList filter for Tracedo Pass statuses ()
     * @param element 
     * @param JQuery 
     * @param 
     * @param HTMLElement 
     */
    public createPassStatusFilter(element: JQuery<HTMLElement>, valueField: string = 'statusId'): void
    {
        let statuses = this.getPassStatuses();
        element.kendoDropDownList({
            dataSource: statuses,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: 'nameTranslated',
            dataValueField: valueField
        });
    }
 
    /**
     * Create DropDownList filter for Countries on the given element
     */
    public createCountryFilter(element: JQuery<HTMLElement>, valueField: string = 'countryId', textField: string = 'descr'): void
    {
        let countries = JSON.parse(JSON.stringify(this.countries));
        countries.sort((a: any, b: any) => {
            return a[textField] < b[textField] ? -1 :(a[textField] > b[textField] ? 1 : 0);
        });
        element.kendoDropDownList({
            dataSource: countries,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: textField,
            dataValueField: valueField
        });
    }
 
    /**
     * Create DropDownList filter for RoadTypes on the given element
     */
    public createRoadTypeFilter(element: JQuery<HTMLElement>, valueField: string = 'id'): void
    {
        let roadTypes = this.getRoadTypes();
        element.kendoDropDownList({
            dataSource: roadTypes,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: 'name',
            dataValueField: valueField
        });
    }

    /**
     * Create DropDownList filter for Invoicing statuses on the given element
     */
    public createTracedoInvoicingStatusFilter(element: JQuery<HTMLElement>, valueField: string = 'statusId'): void
    {
        let invStatuses = this.getInvoicingStatuses();
        // Translate status name
        invStatuses.forEach((invoiceStatus) => { 
            invoiceStatus.name = i18n.t("common.captions." + invoiceStatus.ident) 
        });
        element.kendoDropDownList({
            dataSource: invStatuses,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: 'name',
            dataValueField: valueField
        });
    }
    
    /**
     * Create DropDownList filter Log Actions on the given element
     */
    public createLogActionFilter(element: JQuery<HTMLElement>, valueField: string = 'id'): void
    {
        let actions = this.getLogActions();
        // Translate log action name
        actions.forEach((type) => {
            type.name = i18n.t('common.actions.' + type.nameTranslation);
        });
        element.kendoDropDownList({
            dataSource: actions,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: 'name',
            dataValueField: valueField
        });
    }

    /**
     * Create container type filter
     */
    public createBdpCntrTypeFilter(element: JQuery<HTMLElement>, valueField: string = 'bdpContainerTypeId', nameField: string = 'estoneCode'): void
    {
        let types = this.getBdpCntrTypes();
        element.kendoDropDownList({
            dataSource: types,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: nameField,
            dataValueField: valueField
        });
    }
    
    /**
     * Create season filter
     * @param element 
     * @param valueField 
     * @param nameField 
     */
    public createSeasonFilter(element: JQuery<HTMLElement>, valueField: string = 'seasonId', nameField: string = 'name'): void
    {
        let seasons = this.getSeasons();
        element.kendoDropDownList({
            dataSource: seasons,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: nameField,
            dataValueField: valueField
        });
    }
    
    /**
     * Create fee filter
     * @param element 
     * @param valueField 
     * @param nameField 
     */
    public createFeeFilter(element: JQuery<HTMLElement>, valueField: string = 'feeId', nameField: string = 'nameCs'): void
    {
        let fees = this.getFees();
        element.kendoDropDownList({
            dataSource: fees,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: nameField,
            dataValueField: valueField
        });
    }

    /**
     * Create calculator-revenue status filter
     * @param element 
     * @param valueField 
     * @param nameField 
     */
    public createRevenueStatusFilter(element: JQuery<HTMLElement>, valueField: string = 'id', nameField: string = 'name'): void
    {
        let statuses = this.getRevenueStatuses();
        element.kendoDropDownList({
            dataSource: statuses,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: nameField,
            dataValueField: valueField
        });
    }

    /**
     * Create calculator-cost status filter
     * @param element
     * @param valueField
     * @param nameField
     */
    public createCostStatusFilter(element: JQuery<HTMLElement>, valueField: string = 'id', nameField: string = 'name'): void
    {
        let statuses = this.getCostStatuses();
        element.kendoDropDownList({
            dataSource: statuses,
            optionLabel: i18n.t('common.captions.dropDownSelectValue'),
            dataTextField: nameField,
            dataValueField: valueField
        });
    }


}