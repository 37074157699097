import { injectable } from "tsyringe";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { BaseViewModel } from "../common/BaseViewModel";
import { Users } from "./Users/Users";
import { Addresses } from "./Addresses/Addresses";
import { Carriers } from "./Carriers/Carriers";
import { Fields } from "./Fields/Fields";
import { Spediters } from "./Spediters/Spediters";
import { Customers } from "./Customers/Customers";
import { IRoute } from "../../../tracejs/src/application/IRoute";

/**
 * Settings 
 */
@injectable()
export class Settings extends BaseViewModel<any>
{

	/**
	 * Setup Routes
	 */
	protected configureRoutes = (): IRoute[] => [

		{ name: 'index', pattern: '/$' },
		{ name: 'users', pattern: '/users$' },
		{ name: 'customers', pattern: '/customers$' },
		{ name: 'carriers', pattern: '/carriers$' },
		{ name: 'spediters', pattern: '/set-spediters$' },
		{ name: 'addresses', pattern: '/addresses$' },
		{ name: 'fields', pattern: '/fields$' }
	];	

	/**
	 * Routed
	 * @param route Route
	 * @param Object params Parameters
	 */
	public async defaultRoute(route: string): Promise<any>
	{
		switch(route) {
			case 'index': 
				return await this.routeTo('users');

			case 'users':
				return await this.loadRouteFrame<Users>(Users);

			case 'carriers':
				return await this.loadRouteFrame<Carriers>(Carriers);

			case 'spediters':
				return await this.loadRouteFrame<Spediters>(Spediters);

			case 'customers':
				return await this.loadRouteFrame<Customers>(Customers);

			case 'addresses':
				return await this.loadRouteFrame<Addresses>(Addresses);

			case 'fields':
				return await this.loadRouteFrame<Fields>(Fields);
		}
	}

	public template = (): HTMLElement => (

		<div>

			<nav className="navbar navbar-expand-lg navbar-light bg-gray-200 sub-menu mb-3">

				<div class="container-fluid ps-0 pe-0">

					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSettings" aria-controls="navbarSettings" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>

					<div class="collapse navbar-collapse" id="navbarSettings">
						<ul class="navbar-nav me-auto mb-2 mb-lg-0 main-menu">
							<ko if="$root.user.isAllowed('section.settingsUsers','enter')">
								<li class="nav-item">
									<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('users'), css: { 'active': $root.currentRoute() == 'users' }">
										<i className="icon-users"></i>
										<span data-bind="i18n: 'nav.userManagement'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.settingsCarriers','enter')">
								<li class="nav-item">
									<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('carriers'), css: { 'active': $root.currentRoute() == 'carriers' }">
										<i className="icon-bus"></i>
										<span data-bind="i18n: 'nav.carriers'"></span>
									</a>						
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.settingsSpediters','enter')">
								<li class="nav-item">
									<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('spediters'), css: { 'active': $root.currentRoute() == 'spediters' }">
										<i className="icon-bus"></i>
										<span data-bind="i18n: 'nav.spediters'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.settingsCustomers','enter')">
								<li class="nav-item">
									<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('customers'), css: { 'active': $root.currentRoute() == 'customers' }">
										<i className="icon-users"></i>
										<span data-bind="i18n: 'nav.customers'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.settingsAddresses','enter')">
								<li class="nav-item">
									<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('addresses'), css: { 'active': $root.currentRoute() == 'addresses' }">
										<i className="icon-doc-text"></i>
										<span data-bind="i18n: 'nav.addresses'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.settingsFields','enter')">
								<li class="nav-item">
									<a href="#" className="nav-link" data-bind="click: () => $root.routeTo('fields'), css: { 'active': $root.currentRoute() == 'fields' }">
										<i className="icon-doc-text"></i>
										<span data-bind="i18n: 'nav.fields'"></span>
									</a>
								</li>
							</ko>
						</ul>
					</div>
				</div>
			</nav>

			<route-frame className="panel" />

		</div>

	);


} 