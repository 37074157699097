import { SyncEvent } from 'ts-events';

import * as i18nextko from 'i18next-ko';

import { singleton } from 'tsyringe';

import { ICultureSettings } from './ICultureSettings';

import Cookies from 'js-cookie';
import moment from 'moment';
import numeral from 'numeral';

/**
 * Culture
 */
@singleton()
export class Culture
{
    public onLocaleChanged: SyncEvent<{ locale: string, short: string }> = new SyncEvent<{ locale: string, short: string }>();

    private static defaults: ICultureSettings = {
        locales: {
            "cs-CZ": {
                name: "Čeština"
            },
            "en-US": {
                name: "English"
            }
        },
        cookieExpires: 365,
        translations: null
    };

	/**
	 * Culture settings
	 */
	private settings: ICultureSettings;

    /**
     * Currently set culture
     */
    private currentLocale: string;

    private initialized: boolean;


    public get localesArray() {
        return Object.keys(this.settings.locales);
    }

    public get defaultLocale() {
        let first = null;
        for(let culture in this.settings.locales) {
            if(first === null) {
                first = culture;
            }
            if(this.settings.locales.default) {
                return culture;
            }
        }
        return first;
    }

    public get locale() {
        return this.currentLocale;
    }

    public get localeShort() {
        return this.currentLocale.split('-')[0];
    }   
    
    public get localeShortCapitalized() {
        let ls = this.localeShort;
        return ls.charAt(0).toLocaleUpperCase() + ls.substr(1);
    }    
    


    /**
     * Constructor
     * @param settings 
     */
    public constructor(settings: ICultureSettings)
    {
        this.settings = jQuery.extend({}, Culture.defaults, settings);
        this.initialized = false;
        this.setLocale();
    }

    /**
     * Set culture
     * @param string culture en-US, cs-CZ
     * @return Promise
     */
    public async setLocale(locale: string = null): Promise<void>
    {
        let locArr = this.localesArray;

        // Setup culture on app startup
        // set cookie only if is selected by user, not by default value
        if (locale === null) {
            locale = Cookies.get('app-locale');
        }
        if (!locale || jQuery.inArray(locale, locArr) < 0) {
            locale = this.defaultLocale;
        }

        // save lang to cookie - with one-year expiration
        Cookies.set('app-locale', locale, { path: '/', expires: this.settings.cookieExpires });
        this.currentLocale = locale;

        // set to moment & numeral
        moment.locale(this.localeShort);
        numeral.locale(this.localeShort);

        // i18n
        if(!this.initialized) {
            this.initialized = true;
            i18nextko.init(this.settings.translations, this.localeShort, ko as any);
            i18nextko.i18n.setDefaultNamespace('translation');
        }
        else {
            i18nextko.setLanguage(locale);
        }

		// fire locale changed event
		this.onLocaleChanged.post({ locale: this.currentLocale, short: this.localeShort });
    }

}