import "reflect-metadata";

import { injectable, singleton } from "tsyringe";

import { h } from "../../tracejs/src/utils/JSXFactory";
import { AppAuthorizator } from "../model/AppAuthorizator";

import { BaseViewModel } from "./common/BaseViewModel";
import { CultureSwitch } from "./components/CultureSwitch";
import { Offcanvas } from "./components/Offcanvas";
import { Dashboard } from "./Dashboard/Dashboard";
import { TrackAndTrace } from "./TrackAndTrace/TrackAndTrace";
import { Profile } from "./User/Profile";
import { Settings } from "./Settings/Settings";

import {Md5} from "md5-typescript";
import { i18n } from "i18next-ko";
import { PoManagement } from "./PoManagement/PoManagement";
import { CompanyProfile } from "./User/CompanyProfile";
import moment from "moment";
import { Subjects } from "./Manage/Subjects/Subjects";
import { RolePermissions } from "./Manage/Permissions/RolePermissions";
import { Orders } from "./TrackAndTrace/Orders";
import { CodelistManager } from "../model/CodelistManager";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";
import { IRoute } from "../../tracejs/src/application/IRoute";
import { Finances } from "./Finances/Finances";

/**
 * Main window of the application
 */
@injectable()
export class Main extends BaseViewModel<any> {

	protected loggedUser: KnockoutObservable<any>;

	protected loggedUserAvatar: KnockoutComputed<string>;

	protected mainSubject: any = null;

	protected cultureSwitch: CultureSwitch = null;

	protected userRolesString: string;

	protected codelistManager: CodelistManager;


	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param codelistManager Codelist manager
	 */
	constructor(rpc: Client, codelistManager: CodelistManager)
	{
		super(rpc);
		this.codelistManager = codelistManager;

		this.loggedUser = ko.observable(null);
		this.loggedUserAvatar = ko.computed(() => {
			return this.loggedUser() ? 'https://www.gravatar.com/avatar/' + Md5.init(this.loggedUser().email) + '?s=48' : null;
		});
	}


	/**
	 * Setup Routes
	 */
	protected configureRoutes = (): IRoute[] => [
		{ name: 'index', pattern: '/$' },
		{ name: 'dashboard', pattern: '/dashboard$' },
		{ name: 'orders', pattern: '/orders$' },
		{ name: 'tracedo', pattern: '/track-and-trace$' },
		{ name: 'poManagement', pattern: '/po-management$' },
		{ name: 'finances', pattern: '/finances' },
		{ name: 'settings', pattern: '/settings' },
		{ name: 'subjects', pattern: '/subjects$' },
		{ name: 'rolePermissions', pattern: '/role-permissions$' }
	];


	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();
		
		// logged user
		this.loggedUser(this.user.identity.data);
		
		// User Roles
		let userRoles: string[] = [];
		this.loggedUser().userRoles.forEach((ur: any) => {
			userRoles.push(ur.role.name ? ur.role.name : ur.role.ident);
		});
		this.userRolesString = userRoles.join(', ');
		
		// Main subject
		const mainSubject = await this.rpc.call('subject.getMain', { userId: this.user.id });
		this.mainSubject = ko.mapping.fromJS(mainSubject);

		// read ACL from the server on Main startup (after login)
		await (this.user.authorizator as AppAuthorizator).init();
		// Cache codelists
		await this.codelistManager.cacheCodelists();
	}


	/**
	 * Routed
	 * @param route Route
	 * @param Object params Parameters
	 */
	public async defaultRoute(route: string): Promise<any>
	{
		// set active
		//this.element.find('.main-sub-nav [data-menu-vm]').removeClass('active');
		//this.element.find('.main-sub-nav [data-menu-vm="' + route + '"]').addClass('active');
		// load view model
		switch(route) {
			case 'index':
				return await this.routeTo('dashboard');
			case 'dashboard':
				return await this.loadRouteFrame<Dashboard>(Dashboard);
			case 'orders':
				return await this.loadRouteFrame<Orders>(Orders);
			case 'tracedo':
				return await this.loadRouteFrame<TrackAndTrace>(TrackAndTrace);
			case 'settings':
				return await this.loadRouteFrame<Settings>(Settings);
			case 'poManagement':
				return await this.loadRouteFrame<PoManagement>(PoManagement);
			case 'subjects':
				return await this.loadRouteFrame<Subjects>(Subjects);
			case 'rolePermissions':
				return await this.loadRouteFrame<RolePermissions>(RolePermissions);
			case 'finances':
				return await this.loadRouteFrame<Finances>(Finances);
		}
		//
		/*(<any>this.loadViewFrame(route, [])).done((vm: BaseViewModel, el: JQuery) => {
			el.addClass('hexpand');
		});*/
	}


	/**
	 * Edit user profile
	 */
	public async profileClicked()
	{
		// load view model
		await this.loadViewFrame<Profile>(Profile, 'profile', {
			dialog: {
				width: 950,
				height: 600,
				modal: true,
				title: i18n.t('system.user.profile.editProfile'),
				buttons: (vm: Profile, window: kendo.ui.Window) => {
					return [{
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.cancel'),
						click: () => window.close() 
					}, {
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.save'),
						click: async () => {
							let res = await vm.save();
							if(res) {
								window.close();
							}
						}
					}];
				}
			}
		});
	}

	/**
	 * Edit company profile (administrator role only)
	 */
	public async companyClicked()
	{
		// load view model
		await this.loadViewFrame<CompanyProfile>(CompanyProfile, 'companyProfile', {
			dialog: {
				width: 840,
				height: 600,
				modal: true,
				title: i18n.t('nav.editCompany'),
				buttons: (vm: Profile, window: kendo.ui.Window) => {
					return [{
						align: 'right',
						cls: 'btn-link',
						label: i18n.t('common.actions.cancel'),
						click: () => window.close() 
					}, {
						align: 'right',
						cls: 'btn-primary',
						label: i18n.t('common.actions.save'),
						click: async () => {
							let res = await vm.save();
							if(res) {
								window.close();
							}
						}
					}];
				}
			}
		});
	}


	public async rendered()
	{
		let results = await Promise.all([
			this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures'),
			this.loadViewFrame<Offcanvas>(Offcanvas, 'offcanvas')
		]);

		this.cultureSwitch = results[0];
		
		// Bind events
		jQuery(window).on('resize', this.adjustAutoheightGrid);
		window.setInterval(this.adjustAutoheightGrid, 500);
	}


	protected adjustAutoheightGrid()
	{
		let grids = jQuery('.grid-autoheight');
		let winHeight = window.innerHeight;
		grids.each((i, grid) => {
			let $g = jQuery(grid).find('.k-grid-content');
			if($g.length > 0) {
				let h = winHeight - $g.offset().top - 50;
				h = Math.max(h, 150); // minimalni vyska gridu 150px
				$g.css({ height: h.toString() + 'px', overflow: 'auto' });
			}
		});
	}


	public template = (): HTMLElement => (
		
		<div class="h-expand">
			<style>
				{`
					.k-window-action[aria-label="Close"]::after {
					content: "${i18n.t('common.actions.close')}";
					}
				`}
			</style>
			<div className="top-line container-fluid bg-primary text-white">
				<div className="row">
					<div className="col">
						<h1 className="d-inline-block align-middle mb-2"><a href="#" data-bind="click: () => $root.routeTo('dashboard')">TRACEDO</a></h1>
					</div>
					<div className="col text-end">
						<button className="btn btn-primary btn-sm text-white float-end align-middle ms-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
							<i className="icon-menu"></i>
						</button>

						<view-frame name="cultures" className="float-end align-middle" />

					</div>
				</div>
			</div>

			<nav className="navbar navbar-expand-lg navbar-light bg-light pb-0 pt-0 mb-2">
				<div className="container-fluid ps-0 pe-0">
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav me-auto mb-2 mb-lg-0 main-menu">
							<ko if="$root.user.isAllowed('section.dashboard','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('dashboard'), css: { 'active': $root.currentRoute() == 'dashboard' }">
										<i className="icon-desktop"></i><span data-bind="i18n: 'nav.dashboard'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.orders','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('orders'), css: { 'active': $root.currentRoute() == 'orders' }">
										<i className="icon-doc-text-inv"></i><span data-bind="i18n: 'nav.orders'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.trackAndTrace','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('tracedo'), css: { 'active': $root.currentRoute() == 'tracedo' }">
										<i className="icon-bus"></i><span data-bind="i18n: 'nav.tracedo'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.poManagement','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('poManagement'), css: { 'active': $root.currentRoute() == 'poManagement' }">
										<i className="icon-box"></i><span data-bind="i18n: 'nav.poManagement'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.finances','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('finances'), css: { 'active': $root.currentRoute() == 'finances' }">
										<i className="icon-banknote"></i><span data-bind="i18n: 'nav.finances'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.settings','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('settings'), css: { 'active': $root.currentRoute() == 'settings' }">
										<i className="icon-cog"></i>
										<span data-bind="i18n: 'nav.settings'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.subjects','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('subjects'), css: { 'active': $root.currentRoute() == 'subjects' }">
										<i className="icon-cog"></i>
										<span data-bind="i18n: 'nav.subjects'"></span>
									</a>
								</li>
							</ko>
							<ko if="$root.user.isAllowed('section.rolePermissions','enter')">
								<li>
									<a className="nav-link" href="#" data-bind="click: () => $root.routeTo('rolePermissions'), css: { 'active': $root.currentRoute() == 'rolePermissions' }">
										<i className="icon-user"></i>
										<span data-bind="i18n: 'nav.rolePermissions'"></span>
									</a>
								</li>
							</ko>
						</ul>

						<ko with="$root.loggedUser">
							<div className="btn-group logged-user-dropdown">
								<button type="button" className="btn btn-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
									<div className="row g-0">
										<div className="col">
											<div className="me-2 text-end"><strong data-bind="text: firstName + ' ' + lastName"></strong></div>
											<div className="me-2 text-end"><span data-bind="text: $root.userRolesString"></span></div>
											<div className="me-2 text-end"><span data-bind="text: $root.mainSubject.name"></span></div>
										</div>
										<div className="col-auto">
											<img data-bind="attr: { 'src': $root.loggedUserAvatar }" alt="Avatar" />
										</div>
									</div>
								</button>
								<ul className="dropdown-menu dropdown-menu-end">
									<li>
										<button className="dropdown-item" type="button" data-bind="click: async () => await $root.profileClicked()">
											<i className="icon-user"></i>
											<span data-bind="i18n: 'nav.editProfile'"></span>
										</button>
									</li>
									<ko if="$root.user.isAllowed('section.ownSubject','enter')">
										<li>
											<button className="dropdown-item" type="button" data-bind="click: async () => await $root.companyClicked()">
												<i className="icon-edit"></i>
												<span data-bind="i18n: 'nav.editCompany'"></span>
											</button>
										</li>
									</ko>
									<li>
										<button className="dropdown-item" type="button" data-bind="click: async () => await $root.user.logout()">
											<i className="icon-logout"></i>
											<span data-bind="i18n: 'nav.logout'"></span>
										</button>
									</li>
								</ul>
							</div>
						</ko>

					</div>
				</div>
			</nav>

			<div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
				<div className="offcanvas-header">
					<button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
				</div>
				<div className="offcanvas-body">

					<view-frame name="offcanvas" className="ms-auto" />

				</div>
			</div>

			<div className="container-fluid h-expand-inner">

				<route-frame class="h-expand-p" />

			</div>

			<view-frame name="profile" />
			<view-frame name="companyProfile" />

		</div>
	);

}
