import { h } from "../../../../tracejs/src/utils/JSXFactory";


export class EditableCustomFields
{

	public static template = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (

		<ko foreach="$root.customFields">

			<div className="row mb-2">

				<label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} inline col-form-label`} data-bind="uniqueFor: $data.name, html: $data.labels[$root.culture.localeShort]"></label>
				<div className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]}`}>

					<ko if="$data.type === 'number'">
						<input type="number" className="w-100" data-bind="
							uniqueId: $data.name,
							kendoNumericTextBox: {
								value: $root.tracedo.fields[$data.name],
								format: 'n' + $data.settings.decimals
							}
						" />
					</ko>
					<ko if="$data.type === 'string'">
						<ko if="$data.settings.multiline">
							<textarea className="form-control" data-bind="uniqueId: $data.name, value: $root.tracedo.fields[$data.name]"></textarea>
						</ko>
						<ko ifnot="$data.settings.multiline">
							<input type="text" className="form-control" data-bind="uniqueId: $data.name, value: $root.tracedo.fields[$data.name]" />
						</ko>
					</ko>
					<ko if="$data.type === 'date'">
						<input type="text" class="w-100" data-bind="uniqueId: $data.name, kendoDatePicker: { value: $root.tracedo.fields[$data.name] }" />
					</ko>
					<ko if="$data.type === 'datetime'">
						<input type="text" class="w-100" data-bind="uniqueId: $data.name, kendoDateTimePicker: { value: $root.tracedo.fields[$data.name] }" />
					</ko>
					<ko if="$data.type === 'bool'">
						<div class="form-check form-switch col-form-label">
							<input class="form-check-input" type="checkbox" data-bind="uniqueId: $data.name, checked: $root.tracedo.fields[$data.name]" />
						</div>
						{/*<input type="checkbox" data-bind="uniqueId: $data.name, checked: $root.tracedo.fields[$data.name]" />*/}
					</ko>
					<ko if="$data.type === 'select'">
						<select class="form-select" data-bind="
								options: $data.settings.options,
								optionsValue: 'value',
								optionsText: 'text',
								optionsCaption: '',
								value: $root.tracedo.fields[$data.name]
							"></select>
					</ko>
					
				</div>
			</div>

		</ko>
	);

}