import { i18n } from "i18next-ko";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";

import moment from "moment";
import { TracedoView } from "../entities/TracedoView";

export class KendoHelpers {

	// public static GridFilter: kendo.ui.GridFilterable = { // texty jsou nastaveny v BaseViewModel
	// 	extra:true,
	// 	operators: {
	// 		date: <any>{
	// 			eq: i18n.t('kendo.filterMenuOperators.date.eq'), // "EQ"
	// 			neq: i18n.t('kendo.filterMenuOperators.date.neq'), // "NOT EQ",
	// 			gte: i18n.t('kendo.filterMenuOperators.date.gte'), // "QE THAN",
	// 			gt: i18n.t('kendo.filterMenuOperators.date.gt'), // "Q THAN",
	// 			lte: i18n.t('kendo.filterMenuOperators.date.lte'), // "LE THAN",
	// 			lt: i18n.t('kendo.filterMenuOperators.date.lt'), // "L THAN",
	// 			isnull: i18n.t('kendo.filterMenuOperators.date.isnull'), // "NULL",
	// 			isnotnull: i18n.t('kendo.filterMenuOperators.date.isnotnull'), // "NOT NULL",
	// 			isweek: i18n.t('kendo.filterMenuOperators.date.isweek'), // "IN WEEK", //our extended logic
	// 			thisweek: i18n.t('kendo.filterMenuOperators.date.thisweek') // "THIS WEEK" //our extended logic
	// 		}
	// 	}
	// };

	private static customFilterHandlersRegistered: boolean = false;

	/**
	 * Registrace obsluhy pro CUSTOM filtry (treba today)
	 */
	public static registerCustomFilterHandlers()
	{
		// pokud se zvoli ve filter menu today - schovat pole s datumem a predvyplnit dneskem
		if(!KendoHelpers.customFilterHandlersRegistered) {
			KendoHelpers.customFilterHandlersRegistered = true;
			jQuery('body').on('change', '.k-filter-menu-container select', (e: JQuery.ChangeEvent) => {
				let select = $(e.target);
				let operator = select.val();
				let datePicker  = select.closest('.k-dropdown').next('.k-datepicker');
				let datePickerInput = datePicker.find('input');
				if(datePicker.length > 0) {
					setTimeout(() => { 
						if(operator == 'today' || operator == 'thisweek') {
							//datePicker.hide();
							datePicker.addClass('no-height');
							datePickerInput.val(kendo.toString(new Date(), 'd'));
						}
						else {
							//datePicker.show();
							datePicker.removeClass('no-height');
							datePickerInput.val(null);
						}
						datePickerInput.trigger('click');
						datePickerInput.trigger('focus');
						datePickerInput.trigger('change');
					}, 10);
				}
			});
		}
	}

	public static gridFilterMenuOpen(e: kendo.ui.GridFilterMenuOpenEvent): void
	{
		// TODO - pokud neni zapnute columnMenu, vola se toto misto gridColumnMenuOpen - muze byt potreba schovat datumove pole pro today
	}

	/**
	 * Po otevreni Column Filtru v kendo GRIDu je potreba poschovavat hodnoty u today filtru
	 */
	public static gridColumnMenuOpen(e: kendo.ui.GridColumnMenuOpenEvent): void
	{
		e.container.find('select option[value=today],select option[value=thisweek]').each((i, option) => {
			let select = jQuery(option).closest('select');
			let dropdown = jQuery(option).closest('.k-dropdown');
			let datePicker = dropdown.next('.k-datepicker');
			let operator = select.val();
			if(operator == 'today' || operator == 'thisweek') {
				datePicker.addClass('no-height');
			}
			else {
				datePicker.removeClass('no-height');
			}
		});
	}

	public static getGridFilter(): kendo.ui.GridFilterable
	{
		return { // texty jsou nastaveny v BaseViewModel
			extra:true,
			operators: {
				date: <any>{
					eq: i18n.t('kendo.filterMenuOperators.date.eq'), // "EQ"
					neq: i18n.t('kendo.filterMenuOperators.date.neq'), // "NOT EQ",
					gte: i18n.t('kendo.filterMenuOperators.date.gte'), // "QE THAN",
					gt: i18n.t('kendo.filterMenuOperators.date.gt'), // "Q THAN",
					lte: i18n.t('kendo.filterMenuOperators.date.lte'), // "LE THAN",
					lt: i18n.t('kendo.filterMenuOperators.date.lt'), // "L THAN",
					isnull: i18n.t('kendo.filterMenuOperators.date.isnull'), // "NULL",
					isnotnull: i18n.t('kendo.filterMenuOperators.date.isnotnull'), // "NOT NULL",
					isweek: i18n.t('kendo.filterMenuOperators.date.isweek'), // "IN WEEK", //our extended logic
					thisweek: i18n.t('kendo.filterMenuOperators.date.thisweek'), // "THIS WEEK" //our extended logic
					today: i18n.t('kendo.filterMenuOperators.date.today') // "TODAY //
				}
			}
		};
	}

	/**
	 * Slozi Date objekt z datumu a casu predaneho v textove podobe
	 */
	public static composeDataTime(date: string, time: string = null): Date
	{
		if(date === null) {
			return null;
		}
		if(time === null) {
			return kendo.parseDate(date.substr(0, 11) + '00:00:00' + date.substr(19));
		}
		return kendo.parseDate(date.substr(0, 11) + time.substr(11, 8) + date.substr(19));
	}

	/**
	 * Databound method calling in all kendo grids
	 * @param user User
	 * @return 
	 */
	public static highlightColumn(grid: kendo.ui.Grid)
	{
		/**
		 * Zjisti, ze kterych sloupcu se filtruje
		 * return array
		 */
		let setFilteredMembers = function(filter: any, members: any) {
			if (filter.filters) {
				for (var i = 0; i < filter.filters.length; i++) {
					setFilteredMembers(filter.filters[i], members);
				}
			}
			else {
				members[filter.field] = true;
			}
		}

		//nastavit tridu "k-state-active" sloupcum, ktere maji nastavene criteria pomoci filtru
		var filter = grid.dataSource.filter();
		grid.thead.find("th[data-field].k-state-active").removeClass("k-state-active");
		if (filter) {
			var filteredMembers: any = {};
			setFilteredMembers(filter, filteredMembers);
			grid.thead.find("th[data-field]").each(function () {
				var cell = jQuery(this);
				var filtered = filteredMembers[cell.data("field")];
				if (filtered) {
					cell.addClass("k-state-active");
				}
			});
		}


		//odstraní class 'touch-action:none' z řádků, jinak nefunguje scroll na mobilním zařízení
		grid.table.css("touch-action", "initial");
	}



	/**
	 * dropDown for filtering seasons
	 */
	public static filterByKindId(criteria: any, kindId: number, columnName = 'order.kindId')
	{
		if (kindId) {
			var kindFilter = { logic: 'and', filters: [{ field: columnName, operator: 'eq', value: kindId }] };
			if (criteria.filter !== undefined) {
				kindFilter.filters.push(criteria.filter);
			}
			criteria.filter = kindFilter;
		}
		return criteria;
	}

	/**
	 * dropDown for filtering driver
	 */
	public static filterDriverPlanDay(criteria: any, driverId: number, date: any, columnDriver = 'driverUserId', columnDate = 'etdFrom')
	{
		if (driverId && date) {
			var dayStart = date;
			var dayEnd = new Date(date);
			dayEnd.setDate(date.getDate() + 1);
			var driverFilter = {
				logic: 'and',
				filters:
					[
						{ field: columnDate, operator: 'gte', value: dayStart },
						{ field: columnDate, operator: 'lt', value: dayEnd },
						{ field: columnDriver, operator: 'eq', value: driverId }
					]
			};
			// if (criteria.filter !== undefined) {
			// 	driverFilter.filters.push(criteria.filter);
			// }
			criteria.filter = driverFilter;
		}
		return criteria;
	}

	/**
	 * dropDown for filtering driver
	 */
	public static filterDriverPlanDayAll(criteria: any, driverId: number, date: any, columnDriver = 'driverUserId', columnDate1 = 'etdFrom', columnDate2 = 'etaFrom')
	{
		if (driverId && date) {
			var dayStart = date;
			var dayEnd = new Date(date);
			dayEnd.setDate(date.getDate() + 1);
			var driverFilter = {
				logic: 'and',
				filters:
					[
						{ field: columnDriver, operator: 'eq', value: driverId },
						{
								logic: 'or',
								filters: [
									{
											logic: 'and',
											filters: [
												{ field: columnDate1, operator: 'gte', value: dayStart },
												{ field: columnDate1, operator: 'lt', value: dayEnd },
											]

									},
									{
											logic: 'and',
											filters: [
												{ field: columnDate2, operator: 'gte', value: dayStart },
												{ field: columnDate2, operator: 'lt', value: dayEnd },
											]

									}
								]

						}
					]
			};
			// if (criteria.filter !== undefined) {
			// 	driverFilter.filters.push(criteria.filter);
			// }
			criteria.filter = driverFilter;
		}
		return criteria;
	}

	// /**
	//  * dropDown for filtering driver
	//  */
	// static filterByDay(criteria, date, columnName = 'date') {
	// 	if (date) {

	// 		var dayStart = date;

	// 		var dayEnd = new Date(date);
	// 		dayEnd.setDate(date.getDate() + 1);

	// 		console.log(kendo.parseDate(dayEnd.toLocaleString()));
	// 		var driverFilter = {
	// 			logic: 'and',
	// 			filters:
	// 				[
	// 					{ field: columnName, operator: 'gte', value: dayStart },
	// 					{ field: columnName, operator: 'lt', value: dayEnd }
	// 				]
	// 		}; // vetsi

	// 		if (criteria.filter !== undefined) {
	// 			driverFilter.filters.push(criteria.filter);
	// 		}
	// 		criteria.filter = driverFilter;
	// 	}
	// 	return criteria;
	// }

	/**
	 * If (isempty, isnotempty) is selected, fill input value to kendo accept filtering
	 * @param element Grid
	 * @return 
	 */
	public static allowFilterEmpty(grid: JQuery<HTMLElement>)
	{
		grid.find('.k-header-column-menu').on('click', function () {
			jQuery('.k-filter-menu').each(function() {
				let select = jQuery(this).find('select');
				if (!select.attr('metodGenerated')) {
					//console.log('metod generated for ' + index);
					select.attr('metodGenerated', 'true');
					select.on('change', (e) => {
						if (select.val() == 'isempty' || select.val() == 'isnotempty') {

							var done = false;
							if (jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoNumericTextBox')) {
								jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoNumericTextBox').value(1);
								jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoNumericTextBox').trigger('change');
								done = true;
							}
							if (jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoDatePicker')) {
								jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoDatePicker').value('26. 8. 2015');
								jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoDatePicker').trigger('change');
								done = true;
							}
							if (jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoDropDownList')) {
								jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoDropDownList').select(1);
								jQuery(this).find('input[data-bind="value:filters[0].value"]').data('kendoDropDownList').trigger('change');
								done = true;
							}
							if(done === false){
								jQuery(this).find('input[data-bind="value:filters[0].value"]').val('-');
								jQuery(this).find('input[data-bind="value:filters[0].value"]').trigger('change');
							}
							
						}
					});
				}
			});
		});
	}

	/**
	 * Add into object property with class of row in kendo grid
	 * @param object data
	 * @return object - modified data
	 */
	public static tracedoSetRowColor(data: TracedoView[])
	{
		data.forEach((tracedo: TracedoView) => {
			tracedo.color = 'trc-green';
			//ecpected date Departure is in past and statusId != 4,6(on way, unloaded == actual date is not set)
			//ecpected date Arrival is in past and statusId != 6(unloaded == actual date is not set)
			//should already start or be delivered
			if(tracedo.trcStatusId === 8) {
				// cancelled => gray
				tracedo.color = 'trc-gray'
			}
			else if(tracedo.trcStatusId === 5) {
				// suspended => no color
				tracedo.color = '';
			}
			else if((moment(tracedo.etdTo).format() < moment().format() && (tracedo.trcStatusId !== 4 && tracedo.trcStatusId !== 6)) || (moment(tracedo.etaTo).format() < moment().format() && tracedo.trcStatusId !== 6)) {
				tracedo.color = 'trc-red';
			}
			else if((moment(tracedo.etdTo).format() < moment().add(30, 'minutes').format() && (tracedo.trcStatusId !== 4 && tracedo.trcStatusId !== 6)) || (moment(tracedo.etaTo).format() < moment().add(30, 'minutes').format() && tracedo.trcStatusId !== 6 )) {
				//if time for ETD or ETA almost run out (-30 min)
				tracedo.color = 'trc-orange';
			}
			else if(tracedo.trcStatusId === 1 || tracedo.trcStatusId === 2) {
				//new, driver not set - white
				tracedo.color = '';
			}
			else if(tracedo.trcStatusId !== 6 && tracedo.issueExists) {
				// Pokud existuji problemy na preprave, ktera neni vylozena (6)
				tracedo.color = 'trc-orange';
			}
		});
		return data;
	}

	/**
	 * Replace request fields
	 * @param request data
	 */
	public static replaceRequestData(data: any, glue: string)
	{
		// replace in filters
		if (data.filter) {
			var replaceFilters = function (filter: any) {
				if (filter.filters) {
					jQuery.each(filter.filters, (i: number, item: any) => {
						replaceFilters(item);
					});
				}
				else {
					if (filter.field.substr(0, 8) === 'fields__') {
						filter.field = "fields->>" + filter.field.substr(8);
					}
					else {
						filter.field = filter.field.split(glue).join('.');
					}
				}
			};
			replaceFilters(data.filter);
		}
		
		// replace in sorting
		if (data.sort) {
			jQuery.each(data.sort, (i, item) => {
				if (item.field.substr(0, 8) === 'fields__') {
					item.field = "fields->>" + item.field.substr(8);
				}
				else {
					item.field = item.field.split(glue).join('.');
				}
			});
		}
	}

}