import "reflect-metadata";
import { inject, injectable } from "tsyringe";

import { IViewModelSettings } from "../../../tracejs/src/application/IViewModelSettings";
import { ViewModel } from "../../../tracejs/src/application/ViewModel";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";

/**
 * Base ViewModel for all ViewModels
 */
export abstract class BaseViewModel<T extends IViewModelSettings> extends ViewModel<T> {
	
	/**
	 * RPC Client
	 */
	protected rpc: Client;

	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 */
	constructor(@inject(Client) rpc: Client) {
		super();
		this.rpc = rpc;
	}

	/**
	 * Alert dialog shortcut
	 * 
	 * @param message 
	 * @param title 
	 * @param width
	 */
	public alertDialog(message: string, title: string = null, width: number = null)
	{
		jQuery("<div></div>").kendoAlert({ 
			title: title === null ? '' : title,
			content: message,
			width: width === null ? 300 : width
		} as any).data("kendoAlert").open();
	}

	/**
	 * Prompt dialog shortcut
	 * 
	 * @param message 
	 * @param defaultValue 
	 * @param title 
	 * @param width 
	 * @returns 
	 */
	public async promptDialog(message: string, defaultValue: string, title: string = null, width: number = null): Promise<string>
	{
		return new Promise<string>((resolve: any) => {
			let prompt = jQuery("<div></div>").kendoPrompt({
				title: title === null ? '' : title,
				value: defaultValue,
				content: message,
				width: width === null ? 300 : width
			} as any).data("kendoPrompt").open();
			(prompt as any).result.promise()
				.then((newValue: string) => resolve(newValue))
				.catch(() => resolve(null));			
		});
	}

	/**
	 * Confirm dialog shortcut
	 * 
	 * @param message 
	 * @param title 
	 * @param width 
	 */
	public async confirmDialog(message: string, title: string = null, width: number = null): Promise<boolean>
	{
		return new Promise<boolean>((resolve: any) => {
			let prompt = jQuery("<div></div>").kendoConfirm({
				title: title === null ? '' : title,
				content: message,
				width: width === null ? 300 : width
			} as any).data("kendoConfirm").open();
			(prompt as any).result.promise()
				.then(() => resolve(true))
				.catch(() => resolve(false));
		});
	}

}