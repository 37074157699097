import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";

import { BaseWidgetSettings } from "../../common/BaseWidgetSettings";
import { IDriverPlanSettings } from "./IDriverPlanSettings";


/**
 * Track and Trace - DriverPlan - Settings
 */
@injectable()
export class DriverPlanSettings extends BaseWidgetSettings<IDriverPlanSettings>
{

    /**
     * Settings template
     * @returns 
     */
    public dialogTemplate = (): HTMLElement => (

        <div data-bind="with: $root.settings.widgetSettings">
                SETTINGS
        </div>
    );

}




