import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";


import { CultureSwitch } from "../components/CultureSwitch";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";

@injectable()
export class Forgot extends BaseViewModel<any>
{

	private email: KnockoutObservable<string> = ko.observable('');

	private errorMessage: KnockoutObservable<string> = ko.observable('');


	public onBackClicked: SyncEvent<void> = new SyncEvent<void>();


	/**
	 * Back to login
	 */
	public backToLogin()
	{
		this.onBackClicked.post();
	}

    /**
     * Odeslan formular pro zapomenute heslo
     */
	public async forgotFormSubmitted()
	{
		let email = this.email();
        if(jQuery.trim(email) === '') {
            kendo.alert(i18n.t('forgot.fillEmail'));
            return;
        }

		let params = { 
			email: email,
			culture: this.culture.localeShort 
		};
		let response: any = await this.rpc.call('user.forgottenPassword', params);
		if(response === null) {
			this.flash.success(i18n.t('forgot.resultMessage'));
		}
	}

	/**
	 * On render -> load cultures switch
	 */	
	public async rendered()
	{
		await this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures');
	}

	public template = (): HTMLElement => (

		<div class="centered-form container h-expand d-flex flex-column justify-content-center">
			<div class="row justify-content-md-center">
				<div style="max-width: 450px">

					<view-frame name="cultures" class="float-end" />

					<h1 class="mb-2 text-primary">TRACEDO</h1>

					<div class="panel radius panel-big-margin p-4 pb-2">

						<h2 data-bind="i18n: 'forgot.heading'" class="mb-3"></h2>

						<form method="post" data-bind="submit: $root.forgotFormSubmitted">

							<ko if="$root.errorMessage() !== ''">
								<div class="alert-box alert radius" data-bind="text: $data"></div>
							</ko>

							<div class="mb-3">
								<label for="login-email" class="form-label" data-bind="i18n: 'forgot.email'"></label>
								<input type="email" id="login-email" data-bind="value: $root.email" class="form-control" />
							</div>							

							<div class="row mb-3">
								<div class="col-auto ms-auto">
									<button type="button" class="btn btn-link" data-bind="i18n: 'forgot.backToLogin', click: $root.backToLogin.bind($root, null)"></button>
								</div>
								<div class="col-auto">
									<button type="submit" class="btn btn-primary" data-bind="i18n: 'forgot.send'"></button>
								</div>
							</div>

						</form>

					</div>

				</div>
			</div>
		</div>

	);    

}