import { i18n } from "i18next-ko";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { h } from "../../../../tracejs/src/utils/JSXFactory";

import { BaseViewModel } from "../../common/BaseViewModel";
import { IField } from "./IField";
import { IFieldType } from "./IFieldType";


/**
 * Custom Fields for tracedo
 */
@injectable()
export class Fields extends BaseViewModel<any>
{

	private fields: KnockoutObservableArray<KnockoutObservableType<IField>> = ko.observableArray([]);

	private types: KnockoutObservableArray<IFieldType> = ko.observableArray([]);

	private shortLangs: string[] = [];
	private labelsDefaults: { [key: string]: any } = {};

	/**
	 * ViewModel startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		if (!this.user.isAllowed('section.settingsFields', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		var batch = this.rpc.batch();
		batch.call('fields', 'fields.getMy');
		batch.call('types', 'fields.getTypes');

		//stažení všech poli pro notifikace
		let response: any = await batch.run();

		this.types(response['types']);

		// Labels podle locales
		this.culture.localesArray.forEach((locale) => {
			let locShort = locale.split('-')[0];
			this.labelsDefaults[locShort] = null;
			this.shortLangs.push(locShort);
		});

		// Fields
		response['fields'].forEach((field: IField) => {

			field.labels = jQuery.extend({}, this.labelsDefaults, field.labels);
			field.settings = ko.observable(field.settings);

			let f: KnockoutObservableType<IField> = ko.mapping.fromJS(field);
			this.adjustOptions(f);
			this.fields.push(f);
		});
	}


	private adjustOptions(item: KnockoutObservableType<IField>)
	{
		let settings = item.settings() ? item.settings() : { };
		switch (item.type()) {
			case 'number':
				settings = jQuery.extend({}, { decimals: ko.observable(0) }, settings);
				break;
			case 'string':
				settings = jQuery.extend({}, { multiline: ko.observable(false) }, settings);
				break;
			case 'select':
				settings = jQuery.extend({}, { options: ko.observableArray([]) }, settings);
				break;
		}
		item.settings(ko.mapping.fromJS(settings));
	}

	/**
	 * Add new field
	 */
	private add(): void
	{
		let item = ko.mapping.fromJS({
			name: '',
			type: '',
			extName: '',
			labels: { ...this.labelsDefaults },
			settings: null
		} as IField);
		item.type.subscribe(() => { this.adjustOptions(item) });
		this.fields.push(item);
	}

	/**
	 * Remove field
	 * @param field 
	 */
	private remove(field: KnockoutObservableType<IField>)
	{
		this.fields.remove(field);
	}

	/**
	 * Save custom fields
	 */
	public async save(): Promise<any>
	{
		let fields: any = ko.mapping.toJS(this.fields);
		jQuery.each(fields, (i: number, field: IField) => {
			field.posIndex = i;
			if(field.settings.__ko_mapping__) {
				delete field.settings.__ko_mapping__;
			}
		});

		await this.rpc.call('fields.saveMy', { fields: fields });

		this.flash.success(i18n.t('common.captions.saved'));
	}


	/**
	 * Add selectable item to given select field
	 * @param item 
	 */
	private addSelectItem(item: IField)
	{
		item.settings().options.push(ko.mapping.fromJS({ text: '', value: '' }));
	}

	/**
	 * Remove selectable item from given select field
	 * @param item 
	 * @param selectItem 
	 */
	private removeSelectItem(item: IField, selectItem: any)
	{
		item.settings().options.remove(selectItem);
	}

	/**
	 * Template
	 */
	public template = (): HTMLElement => (

		<div>

			<h1 data-bind="i18n: 'nav.fields'"></h1>

			<div className="mb-2">
				<ko if="$root.user.isAllowed('customfields','manage')">
					<button type="button" className="btn btn-primary" data-bind="i18n: 'common.actions.create', click: $root.add.bind($root)"></button>
				</ko>
			</div>

			<ko if="$root.fields().length > 0">

				<hr />

				<table>
					<thead>
						<tr>
							<th></th>
							<th data-bind="i18n: 'common.captions.name'"></th>
							<ko foreach="$root.shortLangs">
								<th><span data-bind="i18n: 'common.captions.caption'"></span> - <span data-bind="i18n: 'common.captions.langs.' + $data"></span></th>
							</ko>
							<th data-bind="i18n: 'common.captions.type'"></th>
							<th data-bind="i18n: 'common.captions.extName'"></th>
							<th data-bind="i18n: 'common.captions.settings'"></th>
							<th></th>
						</tr>
					</thead>
					<tbody data-bind="sortable: { data: $root.fields, options: { handle: '.move-handle' } }">
						<tr>
							<td>
								<div className="move-handle"></div>
							</td>								
							<td className="align-top">
								<input type="text" data-bind="value: name" className="form-control" />
							</td>
							<ko foreach="$root.shortLangs">
								<td className="align-top">
									<input type="text" data-bind="value: $parent.labels[$data]" className="form-control" />
								</td>
							</ko>
							<td className="align-top">
								<select data-bind="options: $root.types, optionsValue: 'type', optionsText: 'caption', optionsCaption: '-', value: type" className="form-select"></select>
							</td>
							<td className="align-top">
								<input type="text" data-bind="value: extName" className="form-control" />
							</td>
							<td className="align-middle">
								<ko if="$data.type() == 'number'">
									<label><span data-bind="i18n: 'common.captions.decimals'"></span>: <input type="number" data-bind="value: $data.settings().decimals" style="display: inline-block; width: 50px;" className="form-control" /></label>
								</ko>
								<ko if="$data.type() == 'string'">
									<label><input type="checkbox" data-bind="checked: $data.settings().multiline" /> <span data-bind="i18n: 'common.captions.multiline'"></span></label>
								</ko>
								<ko if="$data.type() == 'select'">
								<table className="input-table align-top pt-0">
									<tbody>
										<tr>
											<td className="align-top">
												<button type="button" className="btn btn-outline-primary" data-bind="click: $root.addSelectItem.bind($root, $data)"><span className="k-icon k-i-plus"></span></button>
											</td>
											<td className="align-top">
												<ul data-bind="foreach: $data.settings().options" style="margin: 0; list-style: none">
													<li style="margin-bottom: 4px;">
														<input type="text" className="form-control" placeholder="label" data-bind="value: text" style="display: inline-block; width: 80px;" />
														&rarr;
														<input type="text" className="form-control" placeholder="value" data-bind="value: value" style="display: inline-block; width: 80px;" />
														<button className="btn btn-outline-danger" data-bind="click: $root.removeSelectItem.bind($root, $parent, $data)"><span className="k-icon k-i-delete"></span></button>
													</li>
												</ul>
											</td>
										</tr>
									</tbody>
								</table>
								</ko>
							</td>
							<td className="align-top">
								<ko if="$root.user.isAllowed('customfields','manage')">
									<button type="button" className="btn btn-outline-danger" data-bind="i18n: 'common.actions.remove', click: $root.remove.bind($root)"></button>
								</ko>
							</td>
						</tr>
					</tbody>
				</table>
			</ko>

			<hr />

			<div className="md-2">
				<ko if="$root.user.isAllowed('customfields','manage')">
					<button type="button" className="btn btn-success" data-bind="i18n: 'common.actions.save', click: $root.save.bind($root)"></button>
				</ko>
			</div>

		</div>

	)

}
