import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { ISubjectData } from "./ISubjectData";


/**
 * Add new subject - wizard
 */
@injectable()
export class SubjectWizard extends BaseViewModel<any>
{
    // on step changed
    public onStepChanged: SyncEvent<{ step: string, buttons: Array<{ label: string, method: Function }> }> = new SyncEvent<{ step: string, buttons: Array<{ label: string, method: Function }> }>();
    
    // On wizard finished
    public onFinished: SyncEvent<ISubjectData> = new SyncEvent<ISubjectData>();

    // current step
    private currentStep: KnockoutObservable<string> = ko.observable('ico');

    // registration data
    private subjectData: KnockoutObservableType<ISubjectData> = ko.mapping.fromJS({
        email: null,
        phone: null,

        id: null,

        firmIco: null,
        firmName: null,
        firmDic: null,
        firmStreet: null,
        firmZipCode: null,
        firmCity: null,
        firmCountryId: null,

        askForConnection: false,
        askForRegistration: false
    });

    // codelists
	private countries: KnockoutObservableArray<any> = ko.observableArray();
    

    // Loaded by CRN
    private loadedByCrn: KnockoutObservable<boolean> = ko.observable(false);
    // Found by CRN in application database
    private foundLocallyByCrn: KnockoutObservable<boolean> = ko.observable(false);
    // Is company, that is found locally, registered (id_registered)
    private locallyFoundIsRegistered: KnockoutObservable<boolean> = ko.observable(false);

    // zda je mozne pridavanou firmu pozadat o registrovani do systemu (posle se pozvanka) - firma jeste neni v systemu nebo je tam jako isRegistered = FALSE
    private canAskForRegistration: KnockoutObservable<boolean> = ko.observable(false);
    private askForRegistrationEditable: KnockoutObservable<boolean> = ko.observable(false);

    // zda je mozne pridavanou firmu pozadat o spojeni (je jiz registrovana a ma uzivatele, ktery spojeni muze potvrdit)
    private canAskForConnection: KnockoutObservable<boolean> = ko.observable(false);
    private askForConnectionEditable: KnockoutObservable<boolean> = ko.observable(false);


    /**
     * Startup
     */
    public async startup()
    {
    	await super.startup();
        this.generateCountries()
        // pri zmene ICO nastavime priznak nacteni dle ICO na false
        this.subjectData.firmIco.subscribe(() => {
            this.loadedByCrn(false);
            this.foundLocallyByCrn(false);
            this.locallyFoundIsRegistered(false);

            this.canAskForConnection(false);
            this.canAskForRegistration(false);
            this.askForConnectionEditable(false);
            this.askForRegistrationEditable(false);
        });
    }
    
    /**
     * Load company information by CRN
     */
    public async loadByCrn(): Promise<void>
    {
        if(this.loadedByCrn()) {
            return;
        }

        var ico = this.subjectData.firmIco();
        let response: any = await this.rpc.call('subject.getByCrn', { crn: ico });
        if(response) {
            this.subjectData.email(response.email);
            this.subjectData.phone(response.phone);

            this.subjectData.id(response.id);

            this.subjectData.firmIco(response.firmIco);
            this.subjectData.firmName(response.firmName);
            this.subjectData.firmDic(response.firmDic);
            this.subjectData.firmStreet(response.firmStreet);
            this.subjectData.firmZipCode(response.firmZipCode);
            this.subjectData.firmCity(response.firmCity);
            this.subjectData.firmCountryId(response.firmCountryId);
            if(response.id) {
                this.foundLocallyByCrn(true);
                if(response.isRegistered) {
                    this.locallyFoundIsRegistered(true);
                }
            }

            this.subjectData.askForConnection(false);
            this.subjectData.askForRegistration(false);
        }

        // ARES nic nevratil NEBO firma neni vubec zalozena NEBO je jen zalozena nekym jinym bez registrace
        if(response === null || response.id === null || !response.isRegistered) {

            this.subjectData.askForRegistration(true);
            this.canAskForRegistration(true);
            this.askForRegistrationEditable(true);

            this.subjectData.askForConnection(false);
            this.canAskForConnection(false);
            this.askForConnectionEditable(false);
        }
        else if(response.isRegistered) {

            this.subjectData.askForConnection(true);
            this.canAskForConnection(true);
            this.askForConnectionEditable(false);

            this.subjectData.askForRegistration(false);
            this.canAskForRegistration(false);
            this.askForRegistrationEditable(false);
        }
        this.loadedByCrn(true);
    }

    public async generateCountries(){
        let result: any[] = await this.rpc.call('country.get', 
            { 
                query: {
                     sort: [{ field: 'descr', dir: 'asc' }] }, 
                     select: 'countryId,descr,nameEn,iso' 
            });
        this.countries(result);
    }

    /**
     * Fire adjust buttons event
     */
    public adjustButtons()
    {
        switch(this.currentStep())
        {
            case 'ico':
                this.onStepChanged.post({
                    step: this.currentStep(),
                    buttons: [
                        { label: i18n.t('common.captions.next') + ' &raquo;', method: this.next.bind(this) }
                    ] 
                });
                break;

            case 'info':
                this.onStepChanged.post({
                    step: this.currentStep(),
                    buttons: [
                        { label: '&laquo; ' + i18n.t('common.captions.prev'), method: this.prev.bind(this) },
                        { label: i18n.t('common.captions.finish'), method: this.next.bind(this) }
                    ] 
                });
                break;
        }
    }


    /**
     * Zvaliduje data z formulare
     * @param data 
     * @returns 
     */
    public validateInfo(data: any): string[]|true
    {
        let result: string[] = [];

    	if(jQuery.trim(data.email) === '') {
            result.push(i18n.t('system.alerts.emptyEmail'));
    	}
    	else if(!/[^\s@]+@[^\s@]+\.[^\s@]+/.test(data.email)) {
            result.push(i18n.t('system.alerts.notValidEmail'));
    	}

    	if(jQuery.trim(data.firmName) === '') {
            result.push(i18n.t('system.alerts.firstName'));
    	}

        if(jQuery.trim(data.firmIco) === '') {
            result.push(i18n.t('system.alerts.firmIco'));
    	}
        return result.length > 0 ? result : true;
    }

    /**
     * Dalsi
     */
    public async next(): Promise<any>
    {
        let subjectData = ko.mapping.toJS(this.subjectData);
        switch(this.currentStep()) {
            case 'ico':
                // Load company by CRN ONLY IF the company is in the Czech Republic
                const findCzechia = this.countries().find(country => country.iso === 'CZ');
                if(findCzechia.countryId == subjectData.firmCountryId){
                    await this.loadByCrn();
                };
                this.currentStep('info');
                break;
            
            case 'info':
                let validationResult = this.validateInfo(subjectData);
                if(validationResult !== true) {
                    this.alertDialog(validationResult.join("<br>"), i18n.t('common.error'));
                }
                else {
                    this.onFinished.post(subjectData);
                }

                break;
        }
        this.adjustButtons();        
    }

    /**
     * Zpet
     */
    public async prev(): Promise<any>
    {
        switch(this.currentStep()) {
            case 'info':
                this.currentStep('ico');
                break;
        }
        this.adjustButtons();
    }


    public templateIco = (): HTMLElement => (
        <div data-bind="with: $root.subjectData">

            <p data-bind="i18n: 'system.tools.subjectWizard.enterCrnText'"></p>

            <div className="row mb-2">
                <label className="inline col-md-2 col-form-label fw-bold" data-bind="uniqueFor: 'firmIco', i18n: 'common.captions.companyIc'"></label>
                <div className="col-md-5">
                    <input type="text" className="form-control" data-bind="value: $root.subjectData.firmIco, uniqueId: 'firmIco'" />
                </div>
            </div>
            <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="i18n: 'registration.firmCountry', uniqueFor: 'firmCountry'"></label>
                    <div className="col-md-5">
                        <input data-combobox="kendoCombobox" id="firmCountry" type="text" className="w-100" data-bind="
                            kendoDropDownList: {
                                data: $root.countries,
                                dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
                                dataValueField: 'countryId',
                                optionLabel: i18n.t('common.captions.chooseState'),
                                filter: 'contains',
                                value: $root.subjectData.firmCountryId,
                                animation: false
                            }
                        " />
                    </div>
                </div>
        </div>
    )

    public templateInfo = (): HTMLElement => (
        <div data-bind="with: $root.subjectData">

            <p data-bind="i18n: 'system.tools.subjectWizard.fillInfoText'"></p>

            <fieldset>
                <legend data-bind="i18n: 'system.tools.subjectWizard.company'"></legend>

                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label fw-bold" data-bind="uniqueFor: 'firmIco', i18n: 'registration.firmIco'"></label>
                    <div className="col-md-5">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.firmIco, uniqueId: 'firmIco', disable: true" />
                    </div>
                    <label className="inline col-md-1 col-form-label" data-bind="i18n: 'registration.firmDic', uniqueFor: 'firmDic'"></label>
                    <div className="col-md-4">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.firmDic, uniqueId: 'firmDic'" />
                    </div>
                </div>

                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'firmName', i18n: 'registration.firmName'"></label>
                    <div className="col-md-10">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.firmName, uniqueId: 'firmName'" />
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="i18n: 'registration.firmStreet', uniqueFor: 'firmStreet'"></label>
                    <div className="col-md-10">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.firmStreet, uniqueId: 'firmStreet'" />
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="i18n: 'registration.firmCity', uniqueFor: 'firmCity'"></label>
                    <div className="col-md-5">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.firmCity, uniqueId: 'firmCity'" />
                    </div>
                    <label className="inline col-md-1 col-form-label text-end" data-bind="i18n: 'registration.firmZipCode', uniqueFor: 'firmZipCode'"></label>
                    <div className="col-md-4">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.firmZipCode, uniqueId: 'firmZipCode'" />
                    </div>
                </div>
                
            </fieldset>

            <fieldset>
                <legend data-bind="i18n: 'system.tools.subjectWizard.contact'"></legend>

                <div className="row mb-2">
                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'email', i18n: 'registration.email'"></label>
                    <div className="col-md-5">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.email, uniqueId: 'email'" />
                    </div>
                    <label className="inline col-md-1 col-form-label text-end" data-bind="uniqueFor: 'phone', i18n: 'registration.phone'"></label>
                    <div className="col-md-4">
                        <input type="text" className="form-control" data-bind="value: $root.subjectData.phone, uniqueId: 'phone'" />
                    </div>
                </div>
            </fieldset>

            <div className="row mb-2">
                <label className="inline col-md-2 col-form-label" data-bind="i18n: 'registration.firmCountry', uniqueFor: 'firmCountry'"></label>
                <div className="col-md-10">
                    <input disabled data-combobox="kendoCombobox" id="firmCountry" type="text" className="w-100" data-bind="
                        kendoDropDownList: {
                            data: $root.countries, 
                            placeholder: i18n.t('common.captions.chooseState'),
                            dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
                            dataValueField: 'countryId',
                            value: $root.subjectData.firmCountryId, 
                            disable: true
                        }
                    " />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-10 offset-md-2">
                    <ko if="$root.canAskForRegistration">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" data-bind="
                                uniqueId: 'askForRegistration',
                                checked: askForRegistration,
                                enable: $root.askForRegistrationEditable,
                            " />
                            <label class="form-check-label" data-bind="uniqueFor: 'askForRegistration', i18n: 'common.actions.askForRegistration'"></label>
                        </div>
                        <ko if="askForRegistration">
                            <p className="mb-0 mt-3" data-bind="i18n: 'system.tools.subjectWizard.willContactRegistrationText'"></p>
                        </ko>
                    </ko>
                    <ko if="$root.canAskForConnection">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" data-bind="
                                uniqueId: 'askForConnection',
                                checked: askForConnection,
                                enable: $root.askForConnectionEditable,
                            " />
                            <label class="form-check-label" data-bind="uniqueFor: 'askForConnection', i18n: 'common.actions.askForConnection'"></label>
                        </div>
                        <ko if="askForConnection">
                            <p className="mb-0 mt-3" data-bind="i18n: 'system.tools.subjectWizard.willContactConnectionText'"></p>
                        </ko>
                    </ko>
                </div>
            </div>

        </div>
    )


    public template = (): HTMLElement => (
        <div>

            <ko if="$root.currentStep() === 'ico'">
                {this.templateIco()}
            </ko>

            <ko if="$root.currentStep() === 'info'">
                {this.templateInfo()}
            </ko>

        </div>
    )

}