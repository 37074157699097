import { i18n } from "i18next-ko";
import moment from "moment";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { Objects } from "../../../tracejs/src/utils/Objects";
import { KendoHelpers } from "../../model/KendoHelpers";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { BaseViewModel } from "../common/BaseViewModel";
import { GpsLog } from "./GpsLog";

/**
 * Tracedo chat
 */
@injectable()
export class Chat extends BaseViewModel<any>
{
	
	/**
	 * Chat grid element
	 */
	private chatGridElement: JQuery = null;

	/**
	 * Chat  grid
	 */
	private chatGrid: kendo.ui.Grid;

	/**
	 * Text zpravy
	 */
	private newMessageText: KnockoutObservable<any> = ko.observable(null);

	/**
	 * PO Management Grid Options
	 */
	private gridConfig: kendo.ui.GridOptions;

	// private changes: KnockoutObservable<any> = ko.observable(null);

	/**
	 * confing form multiselect
	 */
	// emailsConfig: kendo.ui.MultiSelectOptions;

	// loaded: boolean = false;
	// loadedData: Array<any> = [];

	// emails: KnockoutObservable<any> = ko.observable(null);

	// newItem: string = null;

	/**
	 * Konfigurace gridu
	 */
	private configureGrid()
	{
		// Toolbar Config
		let toolbarConfig: any = [];

		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportReadOptions) => {
						KendoHelpers.replaceRequestData(options.data, '__');

						let response: any = await this.rpc.call('tracedoChat.getByTracedoId', { 
							id: this.settings.tracedoId, 
							query: {
								...options.data,
								select: '*,text,user(firstName,lastName),createdBy(*),createdTimestamp'
							} 
						});

						response.data = Objects.flatten(response.data, '__');

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: {
							'id': { editable: false, type: "number" }
						}
					},
					data: function (d: any) { return d.data; },
					total: function (d: any) { return d.total; }
				},
				sort: { field: "id", dir: "desc" },
				pageSize: 8,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: false,
			columnMenu: true,
			resizable: false,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: false,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			columns: [{
				field: "createdTimestamp", 
				title: i18n.t('common.captions.createdTimestamp'),
				headerAttributes: { style: "display: none" }, 
				width: 105,
				template: '#= createdBy ? createdBy : user__realName # <br> #= moment(createdTimestamp).format("DD.MM YYYY HH:mm") #' 
			}, {
				field: "text",
				title: i18n.t('common.captions.messageText'), 
				headerAttributes: {style: "display: none"}
			}]
		};
	}

	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		let self = this;

		//deliveryId/pickupId insert of message isnt required

		// this.emailsConfig = {
		// 	placeholder: 'Custom emails',
		// 	dataTextField: 'email',
		// 	dataValueField: 'userId',
		// 	value: this.emails, 
		// 	dataSource: {
		// 		serverFiltering: true,
		// 		transport: {
		// 			read: (options: kendo.data.DataSourceTransportReadOptions) => {
		// 				if (this.loaded) {
		// 					var exist = false;
		// 					if (options.data.filter.filters.length == 0) {
		// 						$.each(this.loadedData, (i, val) => {
		// 							if (val.userId === 'new') {
		// 								this.loadedData = this.loadedData.slice(1, this.loadedData.length);
		// 							}
		// 						});
		// 					} else {
		// 						if (options.data.filter.filters[0].value == '') {
		// 							$.each(this.loadedData, (i, val) => {
		// 								if (val.userId === 'new') {
		// 									this.loadedData = this.loadedData.slice(1, this.loadedData.length);
		// 								}
		// 							});
		// 						} else {
		// 							$.each(this.loadedData, (i, val) => {
		// 								if (val.userId === 'new') {
		// 									exist = true;
		// 									val.email = options.data.filter.filters[0].value + ' (Add New)';
		// 								}
		// 							});
		// 							if (!exist) {
		// 								this.loadedData.unshift({ userId: 'new', email: options.data.filter.filters[0].value + ' (Add New)' })
		// 							}
		// 						}
		// 					}
		// 					options.success(this.loadedData);
		// 				}else{
		// 					var batch = this.rpc.batch();
		// 					batch.call('emails', 'system.user.getUsersForChat', {
		// 						orderId: id
		// 					});
		// 					batch.run().done((response) => {
		// 						this.loaded = true;
		// 						this.loadedData = response['emails'];
		// 						options.success(response['emails']);
		// 					});
		// 				}
		// 			}
		// 		}
		// 	},
		// 	select: function (e) {
		// 		var dataItem = this.dataSource.view()[e.item.index()]
		// 		if (dataItem.userId === 'new' ) {
		// 			dataItem.email = dataItem.email.replace(" (Add New)", "");
		// 			dataItem.userId = dataItem.email;
		// 			self.loadedData.push(dataItem);
		// 		}
		// 	}
		// };

		this.configureGrid();
	}

	/**
	 * Render method
	 */
	public async rendered(): Promise<any>
	{
		/// initialize grid
		this.chatGridElement = this.element.find('[data-grid=chat-messages]');
		this.chatGridElement.kendoGrid(this.gridConfig);
		// BIND action buttons

		this.chatGrid = this.chatGridElement.data('kendoGrid');
		// read data
		this.chatGrid.dataSource.read();
	}

	/**
	 * Add message
	 */
	private async saveMessage(): Promise<any>
	{
		if(this.newMessageText()) {
			
			await this.rpc.call('tracedoChat.save', { 
				tracedoId: this.settings.tracedoId,
				text: this.newMessageText()
			});

			this.newMessageText(null);
			this.chatGrid.dataSource.read();

			this.flash.success(i18n.t('system.question.messageInserted'));

		} else {

			this.alertDialog(i18n.t('system.alerts.emptyMessageText'));
		}
	}


	public template = (): HTMLElement => (
		<div>

			{/*<ko if="$root.changes">
				<fieldset>
					<legend data-bind="i18n:'common.captions.changes'"></legend>
					<table class="edit-table">
						<tr>
							<th data-bind="i18n:'common.captions.field'"></th>
							<th data-bind="i18n:'common.captions.oldValue'"></th>
							<th data-bind="i18n:'common.captions.newValue'"></th>
						</tr>
						<ko foreach="$root.changes">
							<tr>
								<td data-bind="i18n: 'common.captions.'+$data.label" ></td>
								<td data-bind="text:$data.oldValue"></td>
								<td data-bind="text:$data.newValue"></td>
							</tr>
						</ko>
					</table>
				</fieldset>
				<h2 data-bind="i18n: 'common.captions.addComment'"></h2>
			</ko>*/}

			<fieldset>
				<legend data-bind="i18n: 'common.captions.newMessage'"></legend>

				<textarea data-bind="value: $root.newMessageText, uniqueId: 'remarks'" style="height:60px" className="form-control mb-2"></textarea>

				{/*<div class="row">
					<div class="large-10 small-12 columns">
						<ko if="$root.chatType == 'order'">
							<input type="text" id="emails" class="expand" data-bind="kendoMultiSelect: $root.emailsConfig" />
						</ko>
					</div>
					<div class="large-2 small-12 columns">
					</div>
				</div>*/}

				<div>
					<button data-bind="click: $root.saveMessage.bind($root) " class="btn btn-primary"><i class="icon-edit"></i> <span data-bind="i18n: 'common.actions.insert'"></span></button>
				</div>
			</fieldset>
			
			<div data-grid="chat-messages"></div>
		
		</div>
	);



}
