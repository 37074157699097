import { i18n } from "i18next-ko";
import moment from "moment";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { Checkpoint } from "../../entities/Checkpoint";
import { Tracedo } from "../../entities/Tracedo";
import { BaseDialogViewModel } from "../common/BaseDialogViewModel";

/**
 * Generate CMR
 */
@injectable()
export class GenerateCMR extends BaseDialogViewModel<any>
{

    /**
     * Tracedo object
     */
    private tracedo: MappedType<Tracedo>;

    /**
     * Checkpoints
     */
    private routeList: { checked: KnockoutObservable<boolean>, ident: string, to: number|string, title: string }[];

    /**
     * ViewModel Startup
     */
    public async startup()
    {
        await super.startup();

        if (!this.user.isAllowed('entity.tracedo', 'generateCMR')) {
            throw new BadRequestError('Access denied', 403);
        }

        let tracedo: Tracedo = await this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId, query: { select: "*,checkpoints(*)" } });
        this.tracedo = ko.mapping.fromJS(tracedo);

        this.routeList = [];
        tracedo.checkpoints.forEach((cp: Checkpoint) => {
            this.routeList.push({
                checked: ko.observable(true),
                to: cp.id,
                ident: 'cp_' + cp.id,
                title: cp.carriersData && cp.carriersData.address ? 
                        cp.carriersData.address.name + ' ' + cp.carriersData.address.city :
                        cp.addressName + ' ' + cp.addressTown
            });
        });
        this.routeList.push({
            checked: ko.observable(true),
            to: 'destination',
            ident: 'destination',
            title: tracedo.carriersDestinationData && tracedo.carriersDestinationData.address ?
                    tracedo.carriersDestinationData.address.name + ' ' + tracedo.carriersDestinationData.address.city :
                    tracedo.destAddressName + ' ' + tracedo.destAddressCity
        });
    }

    /**
     * Generovat
     */
    public async save(): Promise<boolean>
    {
        let routes: { from: string|number, to: string|number }[] = [];
        this.routeList.forEach((r) => {
            if(r.checked()) {
                routes.push({
                    from: 'origin', // zatim natvrdo vse z originu a vykladame postupne
                    to: r.to
                });
            }
        });

        // brutal platform detection START - platform (win or mac) (FIXME linux = win)
        let defaultPlatform = 'win';
        let platformString = defaultPlatform;
        if(navigator as any) {
            platformString = navigator.userAgent ?? defaultPlatform;
            if((navigator as any).userAgentData && (navigator as any).userAgentData.platform) {
                platformString = (navigator as any).userAgentData.platform;
            }
            else if(navigator.platform) {
                platformString = navigator.platform;
            }
        }
        let winOrMac = (platformString ?? (navigator.userAgent ?? defaultPlatform)).toLowerCase().match(/mac/) ? 'mac' : 'win';
        // brutal platform detection END

        let result: string = await this.rpc.call('tracedo.generateCMR', {
            id: this.settings.tracedoId,
            routes: routes,
            lang: this.culture.localeShort,
            platform: winOrMac,
            multiple: 'unloads' // zatim natvrdo vicevykladka
        });

        if(result) {
            location.href = result;
            return true;
        }

        return false;
    }

	public dialogTemplate = (): HTMLElement => (

        <div className="p-3">

            <p data-bind="i18n: 'common.captions.generateCMRPrompt'"></p>

            {/* Origin = nakladka vseho */}
            <p>
                <strong data-bind="i18n: 'common.captions.load'"></strong><br />
                <ko if="$root.tracedo.carriersOriginData && $root.tracedo.carriersOriginData.address">
                    <span data-bind="text: $root.tracedo.carriersOriginData.address.name"></span>, <span data-bind="text: $root.tracedo.carriersOriginData.address.city"></span>
                </ko>
                <ko ifnot="$root.tracedo.carriersOriginData && $root.tracedo.carriersOriginData.address">
                    <span data-bind="text: $root.tracedo.originAddressName"></span>, <span data-bind="text: $root.tracedo.originAddressCity"></span>
                </ko>
            </p>

            <p className="mb-1"><strong data-bind="i18n: 'common.captions.unloads'"></strong></p>
            <ul className="list-unstyled">
                {/* Vykladky = Checkpointy, destination je take vykladka */}
                <ko foreach="$root.routeList">
                    <li>
                        <div class="form-check ps-0">
                            <input class="form-check-input fs-1 ms-0" type="checkbox" data-bind="checked: $data.checked, uniqueId: $data.ident" />
                            <label class="form-check-label p-1 ps-0" data-bind="uniqueFor: $data.ident">
                                <span data-bind="text: $data.title" className="ps-2"></span>
                            </label>
                        </div>
                    </li>
                </ko>

            </ul>

        </div>

	);

}
