import { inject, singleton } from 'tsyringe';

import { Identity } from './../../tracejs/src/security/Identity';
import { IIdentity } from './../../tracejs/src/security/IIdentity';
import { Client } from '../../tracejs/src/net/jsonrpc/Client';
import { IAuthenticator } from './../../tracejs/src/security/IAuthenticator';

@singleton()
export class ApiAuthenticator implements IAuthenticator {

	/**
	 * RPC Client
	 */
	private rpc: Client;

	/**
	 * Constructor
	 * @param rpc RPC
	 */
	constructor(rpc: Client) {
		this.rpc = rpc;
	}

	/**
	 * Creates identity object from user entity
	 * @param user User
	 * @return 
	 */
	private createIdentity(user: any): IIdentity {
		let roles: string[] = [];
		user.userRoles.forEach((userRole: any) => {
			roles.push(userRole.role.ident);
		});
		return new Identity(user.userId, roles, user);
	}

	/**
	 * Performs user authentication
	 */
	public async login(username: string, password: string, remember: boolean): Promise<IIdentity> {
		let loginResponse: any = await this.rpc.call('user.login', {
				username: username,
				password: password,
				remember: remember,
				query: { select: '*,userRoles(role(name,ident))' }
			});
		if(loginResponse) {
			
			localStorage.setItem('tracedo_api_token', loginResponse.token);

			return this.createIdentity(loginResponse.user);
		}
		return null;
	}

	/**
	 * User logout
	 */
	public async logout(): Promise<void> {
		return await this.rpc.call<void>('user.logout');
	}

	/**
	 * Get logged user's identity
	 */
	public async getIdentity(): Promise<IIdentity> {
		// load logged user from API
		let user = await this.rpc.call('user.getLoggedUser', { query: { select: '*,userRoles(role(name,ident))' } });
		if(!user) {
			return null;
		}
		return this.createIdentity(user);
	}

}