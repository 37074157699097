import { IApplicationACL } from './IApplicationACL';
import { inject, singleton } from 'tsyringe';

import { Client } from '../../tracejs/src/net/jsonrpc/Client';
import { IAuthorizator } from './../../tracejs/src/security/IAuthorizator';
import { Permission } from './../../tracejs/src/security/Permission';

/**
 * Estone Authorizator
 */
@singleton()
export class AppAuthorizator implements IAuthorizator {

	/**
	 * RPC
	 */
	private rpc: Client;

	/**
	 * Permission
	 */
	private permission: Permission;

	/**
	 * Initialized
	 */
	private initialized: boolean;

	/**
	 * Constructor - takes RPC Client
	 */
	constructor(rpc: Client)
	{
		this.initialized = false;
		this.rpc = rpc;
		this.permission = new Permission();
	}

	/**
	 * Prepare ACL
	 */
	public async init(): Promise<any>
	{
		if(this.initialized) {
			return;
		}
		this.initialized = true;

		let acl = await this.rpc.call<IApplicationACL>('acl.get', {});

		// Roles
		acl.roles.forEach((r: any) => this.permission.addRole(r.role, r.parent));

		// Resources
		acl.resources.forEach((r: any) => this.permission.addResource(r.resource, r.parent));

		// Privileges
		acl.privileges.forEach((p: any) => {
			p.allowed ? 
				this.permission.allow(p.role, p.resource, p.action) : 
				this.permission.deny(p.role, p.resource, p.action);
		});
	}

	/**
	 * Performs a role-based authorization.
	 * @param role
	 * @param resource
	 * @param privilege
	 * @return boolean
	 */
	public isAllowed(role: any, resource: any, privilege: string): boolean
	{
		return this.permission.isAllowed(role, resource, privilege);
	}
}
