import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";


import { CultureSwitch } from "../components/CultureSwitch";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";

@injectable()
export class Activate extends BaseViewModel<any>
{

    private email: KnockoutObservable<string> = ko.observable('');

    private token: KnockoutObservable<string> = ko.observable('');

    private activating: KnockoutObservable<boolean> = ko.observable(true);

    private activationResult: KnockoutObservable<boolean> = ko.observable(null);

    public onLoginClicked: SyncEvent<void> = new SyncEvent<void>();


    public async startup()
    {
        await super.startup();
        this.email(this.router.query['email'] as string);
        this.token(this.router.query['token'] as string);
    }


    public goToLogin()
    {
        this.onLoginClicked.post();
    }

	/**
	 * On render -> load cultures switch
	 */	
	public async rendered()
	{
		await this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures');

        // Activate
        let params = {
            email: this.email(),
            token: this.token() 
        };
        this.rpc.call('user.activate', params).then((result: boolean) => {
            this.activating(false);
            this.activationResult(result);
        });
	}

	public template = (): HTMLElement => (

		<div class="centered-form container h-expand d-flex flex-column justify-content-center">
			<div class="row justify-content-md-center">
				<div style="max-width: 450px">

					<view-frame name="cultures" class="float-end" />

					<h1 class="mb-2 text-primary">TRACEDO</h1>

					<div class="panel radius panel-big-margin p-4 pb-2">

                        <ko if="$root.activating">

                            <div class="alert alert-light text-center mt-3 mb-3" data-bind="i18n: 'activate.activating'"></div>

                        </ko>
                        <ko ifnot="$root.activating">
                            
                            <ko if="$root.activationResult">
                                <div class="alert alert-success text-center mt-3 mb-3" data-bind="i18n: 'activate.success'"></div>
                            </ko>
                            <ko ifnot="$root.activationResult">
                                <div class="alert alert-danger text-center mt-3 mb-3" data-bind="i18n: 'activate.fail'"></div>
                            </ko>

                            <div class="mt-3 mb-3 text-center">
                                <button type="button" class="btn btn-primary" data-bind="click: $root.goToLogin.bind($root), i18n: 'activate.goToLogin'"></button>
                            </div>

                        </ko>

					</div>

				</div>
			</div>
		</div>

	);    

}