﻿export let en = {
  "forbidden": {
    "caption": "Forbidden",
    "message": "You are not allowed to enter this section."
  },
  "oldBrowser": {
    "caption": "Your web browser is obsolete",
    "message": "This application cannot be run in your internet browser. Please, use at least Microsoft Internet Explorer 10 or latest version of Google Chrome or Mozilla Firefox. Thanks for your understanding and sorry for incovenience."
  },
  "trial": {
    "tracedoExceeded": "The maximum amount of allowed transports was exceeded. If you want to continue please contact <a href=\"mailto:hello@tracedo.com\">hello@tracedo.com</a>."
  },  
  "common": {
    "formats": {
      "date": "{0:dd.MM.yyyy}",
      "dateTime": "{0:dd.MM.yyyy HH:mm}",
      "kendoDate": "dd.MM.yyyy",
      "kendoDateTime": "dd.MM.yyyy HH:mm",
      "kendoTime": "t",
      "momentTime": "h:mm a",
      "momentDate": "Do MMM"
    },
    "captions": {
      "reallyDeleteApiKey": "Are you sure you want to remove this API key? Any applications that currently use this key will not function properly.",
      "createdStamp": "Created at",
      "apiKey": "API key",      
      "apiKeys": "API keys",
      "apiKeyInfo": "<h1>This is your new API key</h1><p>Please copy this key and save it somewhere safe. For security reasons, we cannot show it to you again.</p>",

      "attributes": "Attributes",

      "autogeneratedUnlocked": "Automatically generated, not synchronized, unlocked for editing",
      "autogeneratedLocked": "Automatically generated, synchronized, locked for editing",
      
      "reallyLockCost": "If you lock the cost for editing, it will be automatically synchronized with the transport purchase price. Do you want to continue?",
      "reallyUnlockCost": "If you unlock the cost for editing, it will no longer be automatically synchronized with the transport purchase price. Do you want to continue?",
      "reallyLockRevenue": "If you lock the revenue for editing, it will be automatically synchronized with the transport sell price. Do you want to continue?",
      "reallyUnlockRevenue": "If you unlock the revenue for editing, it will no longer be automatically synchronized with the transport purchase price. Do you want to continue?",

      "autogenerated": "Generated automatically",

      "truncatedOutput": "Output was truncated. Please make the filter more specific! Only <strong>%sent%</strong> records of total <strong>%total%</strong> returned.",
      "generate": "Generate",
      "invoiceBasis": "Invoice basis",
      "selectedCount": "Selected records count",
      "realCost": "Real cost",
      "fee": "Fee",
      "tracedoItem": "Transport",

      "load": "Load",
      "loads": "Loads",
      "unload": "Unload",
      "unloads": "Unloads",

      "generateCMR": "Generate CMR",
      "generateCMRPrompt": "Select Checkpoints for which CMR will be generated.",

      "calculatorRequired_subjectId": "Select subject",
      "calculatorRequired_feetId": "Select fee",
      "calculatorRequired_invoicedCurrencyId": "Select invoicing currency",
      "calculatorRequired_currencyId": "Select currency",
      "enterAtLeastLastName": "Enter at least last name for contact person",

      "customFee": "Custom",
      "inTemplate": "Default",
      "inTemplateCosts": "Default<br>(costs)",
      "inTemplateRevenues": "Default<br>(revenues)",
      "inTemplatePosIndex": "Position",
      "reallyDeleteFee": "Really delete this fee?",
      "feeAssign": "Fee assignment",
      "nameAndGroup": "Name and Group",
      "checkCostsRevenues": "Check either costs or revenues or both",

      "customFeeEdit": "Edit custom fee",
      "customFeeCreate": "Create custom fee",

      "reallyDeleteRevenue": "Really delete this revenue?",
      "reallyDeleteCost": "Really delete this cost?",
      "exchangeUsed": "Used Exchange rate",
      "tradeCurrencyUsed": "Used Trade currency: ",
      "noExchangeRate": "No Exchange Rate Found!",
      "amount": "Unit amount",

      "calculator": "Calculator",
      "calculatorCreateCost": "Create cost",
      "calculatorCreateRevenue": "Create revenue",
      "calculatorEditCost": "Edit cost",
      "calculatorEditRevenue": "Edit revenue",

      "calculatorReal": "Real",
      "calculatorExpected": "Expected",
      "calculatorTotal": "Total",
      "costs": "Costs",
      "revenues": "Revenues",
      "netRevenue": "Net revenue",
      "profitability": "Profitability",   
      "actualCosts": "Actual costs",
      "remainingCosts": "Remaining costs",
      "totalCosts": "Total coscs",
      "actualRevenues": "Actual revenues",
      "remainingRevenues": "Remaining revenues",
      "totalRevenues": "Total revenues",
      "netRevenues": "Net revenue",
      
      "selectSubject": "Select subject",
      "selectFee": "Select Fee",
      "feeCost": "Cost",
      "feeRevenue": "Revenue",
      "feeGroup": "Fee Group",
      "feeGroupCost": "Cost Type",
      "feeGroupRevenue": "Revenue Type",

      "subject": "Subject",
      "subjects": "Subjects",
      "users": "Users",
      "costItem": "Cost item",
      "revenueItem": "Revenue item",
      "costType": "Cost type",
      "revenueType": "Revenue type",
      "unit": "Unit",
      "unitAmount": "Unit amount",
      "unitPrice": "Unit price",
      "totalPrice": "Total",
      "currency": "Currency",
      "invoicedCurrency": "Invoice currency",
      "invoicedPrice": "Invoice price",
      "priceCzk": "Equivalent in CZK",
      "description": "Description",
      "status": "Status",

      "defaultNote": "Default Note",

      "sellPriceDetail": "Sell Price",
      "purchasePriceDetail": "Purchase Price",

      "loading": "Loading...",
      "authorNote": "Author's note",
      "proposePrice": "Propose price",

      "willBeGeneratedAfterSave": "will be generated after save",
      "detail": "Detail",
      "note": "Note",
      "noLogPrices": "No príces...",
      "priceLogCaption": "Price history",
      "priceLog": {
        "supplier": "Sell price",
        "customer": "Purchase price"
      },
      "acceptedByCustomer": "Accepted by<br/>Customer",
      "acceptedBySupplier": "Accepted by<br/>Carrier",
      "acceptedByYou": "Accepted by<br/>You",

      "mainInformation": "Main information",
      "identification": "Identification",
      "identificationAndSubjects": "Identification and subjects",
      "containerInformation": "Container information",
      "cargoInformation": "Cargo information",
      "driverInformation": "Driver information",
      "parties": "Subjects",
      "pricesInformation": "Price",

      "cannotSetDriver": "You cannot assign a driver. You are not a carrier.",

      "subjectRole": {
        "customer": "Customer",
        "spediter": "Spediter",
        "mediator": "Mediator",
        "carrier": "Carrier"
      },
      "subjectsSubjectsRole": {
        "partner": "Partner",
        "carrier": "Dopravce"
      },
      "autoApprovePrice": "Auto Approve Price",
      "connectWithSubject": "Relation with subject",
      "active" : "Active",

      "role": "Role",
      "pass": "Take over",
      "changeCarrier": "Change carrier",
      "change": "Change",
      "passStatus": "Carrier status",
      "passedFromStatus": "Customer status",

      "customerPicUser": "Resp. person of customer",
      "carrierPicUser": "Resp. person of carrier",
      "spediterPicUser": "Resp. person of spediter",

      "contactInfo": "Contact information",
      "otherInfo": "Other information",
      "subjectCode": "Subject code",
      "vipCode": "Custom code",

      "customValue": "Custom",
      "newCheckpoint": "New checkpoint",
      "replaceCheckpoints": "There is a Checkpoints preset for currently selected combination of \"Import/Export\" + \"Transport mode\". Do you want to apply it and replace the current checkpoint set?",
      "editOrigin": "Origin",
      "editDestination": "Destination",
      "editCheckpoint": "Checkpoint",
      "removeCheckpoint": "Really remove this checkpoint?",

      "returnPickupDepot": "Return/Pick-up depot",
      "dropDownSelectValue": "- Select value -",

      "next": "Next",
      "prev": "Back",
      "finish": "Finish",

      "packingList": "Packing list",

      "otherSettings": "Other setttings",
      "reportsSettings": "Reports settings",
      "reportsHeader": "Reports header",
      "calculatorSettings": "Calculator settings",
      "calculatorFees": "Calculator fees",

      "messageLog": "Message Log",
      "companyIc": "Company CRN",

      "spediter": "Spediter",
      "isRegistered": "Registered",
      "approved": "Approved",
      "usersCount": "Users count",
      "tracedoCount": "Transport count (as spediter)",

      "place": "Place",
      "estimatedTime": "Planned time",
      "actualTime": "Actual time",
      "arrival": "Arrival",
      "departure": "Departure",
      "person": "Person",
      "phone": "Phone",
      "email": "Email",

      "selectUserType": "Select document type for each uploaded file",
      "reallyDeleteFile": "Do you really want to delete this file?",
      "selectType": "- Select type -",
      "userTypes": {
        "cd": "Customer document",        
        "private": "Private",
        "internal": "Internal",
        "business": "Business",
        "public": "Public"
      },
      "langs": {
        "en": "English",
        "cs": "Czech"
      },
      "ok": "OK",
      "settings": "Settings",
      "saved": "Data was successfully saved",
      "failed": "Operation failed",

      "reference": "Reference",

      "additionalInformation": "Additional Information",
      "customFields": "Custom fields",
      "multiline": "Multiline field",
      "decimals": "Decimals",

      "caption": "Caption",
      "chooseThis": "Choose...",
      "poHeader": "Header",
      "contactPerson": "Contact person",
      "contactPhone": "Contact phone",
      "contactEmail": "Contact email",

      "planForDay": "Plan for day:",
      "planStatus": "Phase:",
      "planKindLoad": "Loading",
      "planKindUnLoad": "Unloading",
      "planKindAll": "All",

      "showHasDocuments": "Show only records:",
      "hasDocuments": "with attached documents",
      "hasNoDocuments": "without attached documents",
      "withExcarrier": "Show also transport of external carrier",

      "todoList": "Todo",

      "invoicingStatus": "Invoicing status",
      "pending": "Pending for approval",
      "approved": "Approved",
      "rejected": "Rejected",

      "confirmNoFilter": "You have not set any filter, do really you want to continue?",

      "driverNote": "Driver's note",

      "airTransport": "Air transport",
      "seaTransport": "Sea transport",
      "roadTransport": "Road transport",
      "railTransport": "Rail transport",

      "containers": "Items",
      "trucks": "Items",
      "orderItemVaz": "Relation on item",
      "tracedoVaz": "Relation on Track&Trace",

      "roadType": "Type",
      "mode": "Transport mode",
      "information": "Information",
      "transportItem": "Transport item",
      "order": "Order",
      "orderItems": "Items",
      "customsInstruction": "Customs instruction",
      "poItems": "Packing list",
      "invoices": "Invoices",
      "invoice": "Invoice",
      "documents": "Documents",
      "chat": "Chat",
      "gps": "GPS log",
      "dates": "Dates",
      "isOnNotifyList": "Receive notification of changes",
      "isOnNotifyListTrc": "Receive notification",

      "partner": "Partner",

      "mySubject": "My subject",
      "myPicUser": "My PIC user",

      "customerPartner": "Invoice partner",
      "customerPartnerPicUser": "Invoice partner PIC",
      "customerPartnerNumber": "Order number",
      
      "supplierPartner": "Carrier partner",
      "supplierPartnerPicUser": "Carrier partner PIC",
      "supplierPartnerNumber": "Carrier's number",

      "invoicePrice": "Invoice price",
      "purchasePrice": "Purchasing price",
      "sellPrice": "Selling price",
      "company": "Company",
      "coordinator": "Coordinator",
      "contractor": "Contact person",
      "agent": "Agent",
      "season": "Season",
      "carrierSeason": "Carrier's season",

      "orderNumber": "Order number",
      "number": "Number",
      "carrierNumber": "Carrier's number",
      "oldNumber": "Old Number",
      "status": "Status",
      "trcStatus": "Transport status",
      "kind": "Export/import",
      "type": "Type",
      "route": "Route",
      "cee": "CEE",
      "vipFlag": "VIP",
      "letterOfCredit": "Letter of credit",
      "carrier": "Carrier",
      "service": "Type of service",
      "dateOfOrder": "Date of Order",

      "etd": "ETD",
      "atd": "ATD",
      "atdReady": "ATD ready",
      "eta": "ETA",
      "ata": "ATA",
      "ataReady": "ATA ready",
      "uzp": "UZP",
      "uzpFrom": "UZP since",
      "uzpTo": "UZP until",      
      "myUzp": "My UZP",
      "supplierPartnerUzp": "Carrier's UZP",      
      "ecr": "ECR",
      "ecrFrom": "ECR od",
      "ecrTo": "ECR do",
      "acr": "ACR",
      "rpu": "RPU",
      "epu": "EPU",
      "apu": "APU",
      "etdOrig": "EPU",
      "atdOrig": "APU",
      "rdl": "RDL",
      "rdlFrom": "RDL od",
      "rdlTo": "RDL do",
      "rtaDest": "RDL",
      "rtaDestTo": "RDL do",
      "edl": "EDL",
      "etaDest": "EDL",
      "ataDest": "ADL",
      "adl": "ADL",
      "distributionDate": "Deadline",
      "crd": "Customs release",
      "cutomReleaseDate": "Customs release date:",
      "dutyPayment": "Duty payment order",
      "showIssued": "Show with customs release",
      "showDuty": "Show with duty payment",
      "jsdDocs": "JSD documents",
      "jsdDocsText": "Text for uploaded files:",
      "dateListed": "Date listed",
      "dateDue": "Date due",
      "to": "to",
      "from": "from",

      "clientRef": "Client. ref.",
      "clientRef2": "Client. ref. 2",
      "clientRef3": "Client. ref. 3",
      "clientRef4": "Client. ref. 4",
      "clientRef5": "Client. ref. 5",
      "incoterms": "Incoterms 2000",
      "shipper": "Shipper",
      "consignee": "Consignee",
      "externalCarrier": "External carrier",
      "pickup": "Pickup",
      "pickupAddress": "Pickup place",
      "pickupContact": "Pickup contact",
      "pickupContactPlaceholder": "Last name First Name",
      "delivery": "Delivery",
      "deliveryAddress": "Delivery place",
      "deliveryContact": "Delivery contact",
      "deliveryContactPlaceholder": "Last name First Name",
      "insurance": "Insurance",
      "adr": "DGR Cargo",
      "adrClass": "ADR class",
      "remark": "Remark",
      "newMessage": "New message",
      "newMessageRequested": "New message is requested.",
      "address": "Address",

      "truckType": "Truck Type",
      "orderedTruckType": "Ordered Truck Type",
      "truckNr": "Truck Nr.",
      "colli": "Colli",
      "cbm": "CBM",
      "kgs": "KGS",
      "chargeWeight": "Charge weight",

      "sumColli": "Colli (Packing list)",
      "sumCbm": "CBM (Packing list)",
      "sumKgs": "KGS (Packing list)",

      "width": "Width",
      "length": "Length",
      "height": "Height",

      "inland": "Inland",
      "inland2": "Inland 2",

      "goods": "Goods",
      "customs": "Customs",
      "price": "Price",
      "priceCarrier": "Price for carrier",
      "originalPrice": "Original price",
      "commodity": "Commodity",
      "un": "UN code",
      "packingGroup": "Packing class",
      "commodityValue": "Commodity value",
      "hsCode": "HS Code",
      "nettoKgs": "Netto kgs",
      "pod": "POD",
      "jsd": "CP/VDD",
      "cargoValue": "Commodity value",
      "currency": "Currency",
      "transportPrice": "Transport price",

      "documentName": "Document",
      "fileType": "File type",
      "fileName": "File name",
      "fileSize": "File size",
      "fileVersion": "File version",
      "zeroDuty": "Zero duty",
      "documentExist": "Docs",

      "createdBy": "Created by",
      "createdAt": "Vytvořeno",

      "vessel": "Vessel",
      "rejdar": "SS Line",
      "voyageNr": "Voyage Nr.",
      "portOfLoading": "Port of loading",
      "gateOfLoading": "Gate of loading",
      "portRailOrigin": "Origin gateway",
      "portOfDischarge": "Port of discharge",
      "gateOfDischarge": "Gate of discharge",
      "portRailDestination": "Destination gateway",
      "pickupDepot": "Return / Pickup depot",
      "mbl": "MBL",
      "portToPortTime": "Port to Port Time",
      "metPin": "Pin",
      "metAtb": "ATB",
      "hbl": "HBL",
      "bl": "B/L",
      "express": "Express",
      "transport": "Transport",
      "transportInfo": "Transport units",
      "transportDescription": "In case you know dimensions or sub-items please it insert bellow.",
      "bdpCntrType": "BDP container",
      "cntrType": "Container type",
      "cntrNr": "Container nr.",
      "sealNr": "Seal nr.",
      "custom": "Customs broker",
      "customOffice": "Custom office",
      "declarant": "Declarant",
      "custom2": "Customs broker #2",
      "datePortPickup": "Port pickup date",
      "etaDepot": "ETA depot",
      "ataDepot": "ATA depot",
      "gjsd": "MRN",
      "mrn": "MRN",
      "spz": "SPZ",
      "metTranshipmentDepot": "Transhipment depot",
      "trainNr": "Train nr.",
      "hblSend": "HBL send",
      "hblDelivered": "HBL delivered",
      "firstTransferToBdp": "First transfer to BDP",
      "lastTransferToBdp": "Last transfer to BDP",

      "flightNr": "Flight nr.",
      "airlinePrefix": "Airline prefix",
      "airportOrigin": "Airport origin",
      "airportDest": "Airport destination",
      "mawb": "MAWB",
      "hawb": "HAWB",
      "chbw": "CHBW",
      "bttoKgs": "BTTO KGS",
      "bttoLbs": "BTTO LBS",
      "totalPcs": "Total PCS",
      "readyForCarriage": "Ready for Carriage",
      "prealertToDest": "Pre Alert to Dest.",
      "clientNotified": "Client Notified",
      "docReceived": "Doc. received",
      "docToBroker": "Doc. to Broker",
      "docToAnBroker": "Doc. to an. Broker",
      "orderId": "ID",

      "milestonesExport": "Milestones - Export",
      "milestonesImport": "Milestones - Import",

      "basicInfo": "Basic information",
      "document": "Document",
      "serie": "Document serie",
      "createdTimestamp": "Created at",
      "group": "Group",
      "contact": "Contact",
      "contact2": "Contact 2",
      "name": "Name",
      "description": "Description",
      "notePractice": "Note from practice",
      "offerValidity": "Offer validity",
      "offerNumber": "Offer number",
      "offer": "Offer",
      "offerPlaceholder": "Choose offer number",
      "demand": "Demand",
      "fromDate": "Since date",
      "publicFrom": "Public from",
      "publicTo": "Public to",
      "toDate": "Till date",
      "sentDate": "Sent date",
      "presumedAmount": "Presumed amount",
      "realAmount": "Real amount",
      "accepted": "Accepted",
      "acceptedDate": "Accepted/Rejected date",
      "lostReason": "Rejection reason",
      "declineReason": "Reason for rejection",
      "author": "Author",
      "result": "Result",
      "customer": "Customer",
      "demandType": "Demand type",
      "messageText": "Text of message",

      "yes": "Yes",
      "no": "No",

      "destination": "Destination",
      "dispatch": "Dispatch",

      "destinationCountry": "Destination country",
      "originCountry": "Dispatch country",

      "shipmentNotify": "Shipment notify",
      "cutOff": "Cut off",
      "m3": "Total(m<sup>3</sup>)",
      "m2": "Total(m<sup>2</sup>)",
      "chooseOriginAirport": "Choose origin airport...",
      "chooseDestAirport": "Choose 'destination airport'...",
      "choosePortOfDischarge": "Choose port of discharge",
      "chooseGateOfDischarge": "Choose gate of discharge",
      "choosePortOfLoading": "Choose port of loading",
      "chooseGateOfLoading": "Choose gate  of loading",
      "metOriginCountry": "Choose origin country of goods",
      "chooseOriginGateway": "Choose 'gateway of loading'...",
      "chooseDestinationGateway": "Choose 'gateway of discharge'...",
      "chooseRejdar": "Choose SS Line...",
      "chooseVessel": "Choose vessel...",
      "chooseState": "Choose state...",
      "noDocuments": "No documents available",
      "basicOffer": "Basic view",
      "action": "Action",
      "changes": "Changes",
      "CZKCurrency": "Currency in CZK",
      "foreignCurrency": "Foreign currency",
      "notFollow": "Electronic",
      "field": "Field",
      "oldValue": "Old value",
      "newValue": "New value",
      "addComment": "Please add comment",
      "changedBy": "Changed by",
      "changedAt": "Time of change",
      "vgm": "Verified Gross Mass (VGM)",
      "vgmPerson": "VGM Person",
      "vgmMethod": "VGM Method",
      "vgmDate": "VGM Date",
      "vgmCalculation": "Method 2 - Calculation",
      "vgmWeigh": "Method 1 - Weigh",

      "phaseName": "Phase",
      "driver": "Driver: ",
      "driverInfo": "Driver info",
      "company": "Company",
      "authorization": "Authorization",
      "delay": "Delay",
      "tookOverFromPerson": "Took over from",
      "handedOverToPerson": "Handed over to",
      "cargoDescription": "Cargo description",
      "ldm": "LDM",
      "lm": "Loading meters",
      "preliminaryDuty": "Preliminary duty",
      "preliminaryDeclaration": "Preliminar declaration",
      "track": "Track",
      "origin": "Origin",
      "loadingReference": "Loading reference",
      "unloadingReference": "Unloading reference",
      "userToNotify": "User to notify",
      "companyFunction": "Position",

      "chargeableVolume": "Chargeable volume",

      "extName": "External name",

      "checkpoints": "Checkpoints",
      "create": "Create",
      "responsiblePerson" : "Responsible person",
      "ramp": "Ramp",
      "containerType": "Container type",
    },
    "actions": {

      "lock": "Lock",
      "unlock": "Unlock",

      "generate": "Generate",
      "generateCMR": "Generate CMR",
      "refresh": "Refresh",
      "ok": "OK",
      "saveAsPdf": "Save as PDF",
      "generateTransportOrder": "Transport order",

      "downloadPdf": "Download order for carrier",
      "editAndDownloadPdf": "Edit and download order for carrier",
      "downloadOrderReceivedPdf": "Download received order",
      "editAndDownloadOrderReceivedPdf": "Edit and download received order",

      "askForConnection": "Ask company for connection",
      "askForRegistration": "Ask company for registration and connection",

      "changeRevenueStatus": "Change invoicing status",
      "changeCostStatus": "Change invoicing status",
      "changeInvStatus": "Change invoicing status",
      
      "create": "Create",
      "createOrder": "Create order",
      "edit": "Edit",
      "editPartner": "Edit partner",
      "detail": "Detail",
      "duplicate": "Duplicate",
      "duplicateTransport": "Copy transport",
      "saveFile": "Save files",
      "save": "Save",
      "continue": "Continue to the next step",
      "continue1": "Continue",
      "saveAndSend": "Save and send demand",
      "saveAndOrder": "Save and order",
      "saveClose": "Save and close",
      "rename": "Rename",
      "saveAs": "Save as",
      "delete": "Delete",
      "remove": "Remove",
      "search": "Search",
      "setAsDefault": "Set as default",
      "cancel": "Cancel",
      "exportExcel": "XLS export",
      "exportExcelHistory": "XLS export with order history",
      "findOnTracedo": "Search in transport",
      "linkExisting": "Link existing",
      "link": "Link",
      "unlink": "Unlink",
      "unlinkTracedo": "Unlink",
      "approve": "Approve",
      "decline": "Decline",
      "accept": "Accept",
      "reject": "Reject",
      "rejectTransport": "Reject transport",
      "rejectConfirmation": "Really reject this transport? You will not be able to accept it later on.",
      "rejectedByYou": "This transport was rejected by you",
      "rejectedBySupplier": "This transport was rejected by carrier",
      "accepted": "Accepted",
      "rejected": "Rejected",
      "new": "New",
      "download": "Download",
      "downloadAll": "Download zip with all files",
      "display": "Display",
      "pin": "Pin",
      "unpin": "Unpin",
      "newItem": "New item",
      "close": "Close",
      "sendOrder": "Send order",
      "offerAcceptCreate": "Accept offer and create order",
      "setDeadline": "Set deadline",
      "addDocs": "Add commercial documents",
      "addCommercialOrigin": "Add commercial documents origin",
      "addCommercialDestination": "Add commercial documents destination",
      "addHbl": "Add House B/L, HAWB",
      "addMbl": "Add Master B/L, MAWB",
      "addAgentInvoice": "Add agent invoice",
      "setCargo": "Set cargo ready",
      "setADL": "Set ADL",
      "setECR": "Update ECR",
      "setAPU": "Set APU",
      "changeRecord": "Set information",
      "confirmLoad": "Confirm loading",
      "confirmDischarge": "Confirm discharging",
      "createOrder": "Create order",
      "createNewOrder": "Booking of the new shipment",
      "createOrderTitle": "Create order from demand (without offer)",
      "offer": "Offer",
      "upload": "Upload",
      "insert": "Insert message.",
      "preview": "preview",
      "setVgm": "Set VGM",
      "setDriver": "Set driver",
      "demandSend": "Demand send",
      "offerAttach": "Offer attach",
      "demandChanged": "Demand changed",
      "offerDeclined": "Offer declined",
      "offerApproved": "Offer approved",
      "orderCreated": "Order created",
      "traceCreated": "Tracedo created",
      "traceRejected": "Tracedo rejected",
      "traceAccepted": "Tracedo accepted",
      "driverSet": "Driver set",
      "driverRemoved": "Driver removed",
      "loadingSet": "Loading set",
      "loadingDelay": "Delay of loading",
      "loadingCnaceled": "Loading canceled",
      "dischargeSet": "Discharge set",
      "dischargeDelay": "Delay of discharge",
      "dischargeCanceled": "Discharge canceled",
      "interruption": "Interruption",
      "loadingArrival": "Arrival at loading place",
      "dischargeArrival": "Arrival at discharge place",
      "issue": "Issue reported",
      "update": "Change information",
      "showOnMap": "Show route on the map",
      "suspend": "Interrupt",
      "connectToNewCarrier": "Connect with new carrier",
      "createNewCarrier": "Create new carrier",
      "connectToNewSpediter": "Connect with new spediter",
      "connectToNewCustomer": "Connect with new customer",
      "connectToSubject": "Connect with Subject",
      "send": "Send connect request"
    }
  },
  "kendo": {
    "columnMenu": {
      "columns": "Columns",
      "done": "Done",
      "filter": "Filter",
      "lock": "Lock",
      "settings": "Column Settings",
      "sortAscending": "Sort Ascending",
      "sortDescending": "Sort Descending",
      "unlock": "Unlock"
    },
    "groupable": {
      "empty": "Drag a column header and drop it here to group by that column"
    },
    "filterMenu": {
      "info": "Show items with value that:",
      "filter": "Filter",
      "clear": "Clear filters",
      "isTrue": "is true",
      "isFalse": "is false",
      "and": "And",
      "or": "Or",
      "selectValue": "-Select value-",
      "operator": "Operator",
      "value": "Value",
      "cancel": "Cancel"
    },
    "filterMenuOperators": {
      "string": {
        "eq": "Is equal to",
        "neq": "Is not equal to",
        "startswith": "Starts with",
        "contains": "Contains",
        "doesnotcontain": "Does not contain",
        "endswith": "Ends with",
        "isEmpty": "Is empty",
        "isNotEmpty": "Is not empty"
      },
      "number": {
        "eq": "Is equal to",
        "neq": "Is not equal to",
        "gte": "Is greater than or equal to",
        "gt": "Is greater than",
        "lte": "Is less than or equal to",
        "lt": "Is less than"
      },
      "date": {
        "eq": "Is equal to",
        "neq": "Is not equal to",
        "gte": "Is after or equal to",
        "gt": "Is after",
        "lte": "Is before or equal to",
        "lt": "Is before",
        "isweek": "Is equal to week number",
        "thisweek": "Is in this week",
        "today": "Is today",
        "isnull": "Is empty",
        "isnotnull": "Is not empty"
      },
      "enums": {
        "eq": "Is equal to",
        "neq": "Is not equal to"
      }
    },
    "pager": {
      "display": "Showing {0} - {1} of {2} items",
      "empty": "No items to display",
      "first": "Go to the first page",
      "itemsPerPage": "items per page",
      "last": "Go to the last page",
      "morePages": "More pages",
      "next": "Go to the next page",
      "of": "of {0}",
      "page": "Page",
      "previous": "Go to the previous page",
      "refresh": "Refresh"
    },
    "validator": {
      "required": "{0} is required",
      "pattern": "{0} is not valid",
      "min": "{0} should be greater than or equal to {1}",
      "max": "{0} should be smaller than or equal to {1}",
      "step": "{0} is not valid",
      "email": "{0} is not valid email",
      "url": "{0} is not valid URL",
      "date": "{0} is not valid date"
    },
    "imageBrowser": {
      "uploadFile": "Upload",
      "orderBy": "Sort by",
      "orderByName": "Name",
      "orderBySize": "Size",
      "directoryNotFound": "A directory with this name was not found.",
      "emptyFolder": "Empty Folder",
      "deleteFile": "Are you sure you want to delete \"{0}\"?",
      "invalidFileType": "The selected file \"{0}\" is not valid. Supported file types are {1}.",
      "overwriteFile": "A file with name \"{0}\" already exists in the current directory. Do you want to overwrite it?",
      "dropFilesHere": "Drop files here to upload",
      "search": "Search"
    },
    "editor": {
      "addColumnLeft": "Add column on the left",
      "addColumnRight": "Add column on the right",
      "addRowAbove": "Add row above",
      "addRowBelow": "Add row below",
      "backColor": "Background color",
      "bold": "Bold",
      "createLink": "Insert hyperlink",
      "createTable": "Create table",
      "deleteColumn": "Delete column",
      "deleteFile": "Are you sure you want to delete \"{0}\"?",
      "deleteRow": "Delete row",
      "dialogButtonSeparator": "or",
      "dialogCancel": "Cancel",
      "dialogInsert": "Insert",
      "dialogUpdate": "Update",
      "directoryNotFound": "A directory with this name was not found.",
      "emptyFolder": "Empty Folder",
      "fontName": "Select font family",
      "fontNameInherit": "(inherited font)",
      "fontSize": "Select font size",
      "fontSizeInherit": "(inherited size)",
      "foreColor": "Color",
      "formatBlock": "Format",
      "formatting": "Format",
      "imageAltText": "Alternate text",
      "imageHeight": "Height (px)",
      "imageWebAddress": "Web address",
      "imageWidth": "Width (px)",
      "indent": "Indent",
      "insertHtml": "Insert HTML",
      "insertImage": "Insert image",
      "insertOrderedList": "Insert ordered list",
      "insertUnorderedList": "Insert unordered list",
      "invalidFileType": "The selected file \"{0}\" is not valid. Supported file types are {1}.",
      "italic": "Italic",
      "justifyCenter": "Center text",
      "justifyFull": "Justify",
      "justifyLeft": "Align text left",
      "justifyRight": "Align text right",
      "linkOpenInNewWindow": "Open link in new window",
      "linkText": "Text",
      "linkToolTip": "ToolTip",
      "linkWebAddress": "Web address",
      "orderBy": "Sort by:",
      "orderByName": "Name",
      "orderBySize": "Size",
      "outdent": "Outdent",
      "overwriteFile": "A file with name \"{0}\" already exists in the current directory. Do you want to overwrite it?",
      "strikethrough": "Strikethrough",
      "style": "Styles",
      "subscript": "Subscript",
      "superscript": "Superscript",
      "underline": "Underline",
      "unlink": "Remove hyperlink",
      "uploadFile": "Upload",
      "viewHtml": "View HTML"
    },
    "numericTextBox": {
      "upArrowText": "Increase value",
      "downArrowText": "Decrease value"
    },
    "upload": {
      "select": "Select files...",
      "cancel": "Cancel",
      "retry": "Retry",
      "remove": "Remove",
      "uploadSelectedFiles": "Upload files",
      "dropFilesHere": "Drop files here to upload",
      "statusUploading": "Uploading",
      "statusUploaded": "Uploaded",
      "statusWarning": "Warning",
      "statusFailed": "Failed",
      "headerStatusUploading": "Uploading...",
      "headerStatusUploaded": "Done"
    }
  },
  "dashboard": {
    "chooseWidget": "Select widget",
    "placeNewWidget": "Place new widget on the dashboard",
    "widgetPlaced": "Widget has been successfully placed",
    "widgetRename": "Rename",
    "widgetSettings": "Settings",
    "widgetRemove": "Remove",
    "removeWidgetQuestion": "Do you really want to remove this widget?",
    "addNewTooltip": "Add new widget"
  },
  "login": {
    "userLogin": "User login",
    "user": "Username:",
    "password": "Password:",
    "permanentLogin": "Permanent login",
    "login": "Sign in",
    "forgottenPassword": "Forgotten password",
    "emailSend": "Email was successfully sent to your email address.",
    "userNotExist": "User with this login name or email was not found.",
    "fillLoginName": "Please fill fieal 'Username' by your login name or email.",
    "errorCode1": "Invalid username or password",
    "errorCode2": "Invalid username or password",
    "errorCode3": "Login error. Please try again later",
    "errorCode4": "Your account is not approved yet",
    "errorCode5": "Your subjet is not registered",
    "errorCode6": "This account is not allowed to sign in to web appliaction",
    "errorCode7": "Your account is not assigned with any subject"
  },
  "forgot": {
    "heading": "Forgotten password",
    "backToLogin": "Back to login",
    "email": "Email",
    "send": "Send",
    "fillEmail": "Enter yout email",
    "resultMessage": "If the email you've provided has been found in our database, we've sent you the instructions to set a new password."
  },
  "setPassword": {
    "heading": "Set password",
    "email": "Email",
    "realname": "Your name",    
    "password": "Password",
    "password2": "Password check",
    "btnSetPassword": "Set password",
    "fillPassword": "Enter your new password",
    "noMatch": "Entered passwords do not match. Write them again.",
    "invalidRequest": "Invalid request. Token has probably expired. Try to repeat the password reset process again.",
    "success": "Password has been successfully set. You can now sign in.",
    "failure": "Password has not been set. Token has probably expired. Try to repeat the password reset process again."
  },  
  "registration": {
    "numberCode": "Code for transport numbering",
    "backToLogin": "Back to login",
    "registration": "Registration",
    "register": "Register",
    "firstName": "First name",
    "lastName": "Last name",
    "firmName": "Firm name",
    "email": "Email",
    "password": "Password",
    "passwordCheck": "Password again",
    "phone": "Phone",
    "language": "Language",
    "firmIco": "CRN",
    "firmIcoInfo": "Company registration nunber",
    "firmDic": "VAT ID",
    "firmStreet": "Street",
    "firmZipCode": "ZIP",
    "firmCity": "City",
    "firmCountry": "Country",
    "firmTradeCurrency": "Trade Currency",
    "firmTradeCurrencyTipText": "Surcharge to the CNB exchange rate used for costs and revenues. In percent.",
    "firm": "Company",
    "user": "User",
    "connectProcesInfo": "To connect with the carrier, it is necessary to send him an invitation and ask for a connection. By click on button 'send' all required information will be send to carrier (your email, firm name and Company registration nunber). In case the company is already registered in the system, the connection confirmation is sent to another email!",
    "connectProcesInfo2": "To connect with the spediter, it is necessary to send him an invitation and ask for a connection. By click on button 'send' all required information will be send to spediter (your email, firm name and Company registration nunber). In case the company is already registered in the system, the connection confirmation is sent to another email!"
  },
  "activate": {
    "activating": "Activating your account....",
    "success": "Your account has been successfully activated. Now you can sign in.",
    "fail": "Activation token is no longer valid.",
    "goToLogin": "Proceed to sign in"
  },
  "trackAndTrace": {
    "reallySuspend": "Do you really want to suspend this transport?",
    "reallyCancel": "Do you really want to cancel this transport?",
    "edit": {
      "selectAddress": "- select address -",
      "selectContactPerson": "- select contact person -"
    }
  },
  "nav": {
    "transportResults": "Results",
    "logout": "Logout",
    "driverPlan": "Driver Plan",
    "editProfile": "Edit profile",
    "editCompany": "Edit company",    
    "airfreight": "Airfreight",
    "oceanfreight": "Oceanfreight",
    "roadfreight": "Roadfreight",
    "railfreight": "Railfreight",
    "otherfreight": "Other services",
    "dashboard": "Dashboard",
    "poManagement": "Packing list",
    "reports": "Reports",
    "newDemand": "New demand",
    "editDemand": "Edit demand",
    "demands": "Demands",
    "offers": "Offers",
    "orders": "Orders",
    "offerDetail": "Offer detail",
    "demandDetail": "Demand detail",
    "offerAccept": "Accept offer",
    "offerDecline": "Decline offer",
    "createOrder": "Save and accept order",
    "poItemCreate": "Create packing item",
    "poItemEdit": "Edit packing item",
    "duplicateDemand": "Duplication of demand",
    "setRDL": "Set RDL",
    "setECR": "Set ECR",
    "setADL": "Set ADL",
    "setAPU": "Set APU",
    "addCommercial": "Add commercial documents",
    "addCommercialOrigin": "Add commercial documents origin",
    "addCommercialDestination": "Add commercial documents destination",
    "addHbl": "Add House B/L, HAWB",
    "addMbl": "Add Master B/L, MAWB",
    "addAgentInvoice": "Add agent invoice",
    "setDuty": "Set Duty Payment Order",
    "cleared": "Clear with customs",
    "setDeclarant": "Set declarant",
    "invoices": "Invoices",
    "files": "Files",
    "changeRecord": "Set information",
    "help": "Help",
    "setVgm": "Set VGM",
    "userManagement": "User management",
    "driverManagement": "Driver management",
    "subjectVazManagement": "Subject relation management",
    "tracedo": "Track&Trace",
    "preliminaryDuty": "Preliminary duty",
    "downloadCustoms": "Generate custom clearance",
    "createTracedo": "Create Track&Trace transport",
    "editTracedo": "Edit Track&Trace transport",
    "settings": "Settings",
    "customers": "Customers",
    "addresses": "Addresses",
    "carriers": "Carriers",
    "spediters": "Spediters",
    "carrier": "Carrier",
    "customer": "Customer",
    "spediter": "Spediter",
    "users": "Users",
    "fields": "Custom fields",
    "subjects": "Subjects",
    "rolePermissions": "Roles permissions",
    "finances": "Finances",
    "costs": "Costs",
    "revenues": "Revenues"
  },
  "calculatorStatuses": {
    "planned": "Plan",
    "received": "Invoice received",
    "prepared": "Prepared for invoicing",
    "tobeinvoiced": "To be invoiced",
    "invoiced": "Invoiced",
    "paid": "Paid",
    "expected": "Expected",
    "real": "Real"
  },  
  "tracedo": {
    "carrierPrice": {
      "confirmMessage": "Do you want to confirm the transport with that carrier price?"
    }
  },  
  "system": {
    "subjects": {
      "linkExpired": "Link has expired",
      "connectionRequestRejected": "Connection request has been rejected",
      "connectionRequestApproved": "Connection request has been approved",
      "registrationInvitationSent": "Invitation for registration has been successfully sent",
      "connectionInvitationSent": "Invitation for connection has been successfully sent",
      "carrierHasBeenAdded": "Carrier has been successfully added",
      "customerHasBeenAdded": "Customer has been successfully added"
    },    
    "tools": {
      "gridConfigurator": {
        "presets": "Presets",
        "selectPreset": "Select preset...",
        "enterPresetName": "Enter the preset name:",
        "overwrite": "Do you want to overwrite preset \"__name__\" with current values?",
        "deletePresetQuestion": "Do you want to delete currently selected preset?",
        "graphs": "Graphs",
        "selectGraph": "Select graph...",
        "showGraph": "Show",
        "excarrierKgs": "Chart of weight of goods transported per external carriers",
        "countryKgs": "Chart of weight of goods transported per country",
        "amountKgs": "Chart of the ratio of the number of consignments to weight catteries"
      },
      "uploader": {
        "noFilesSelected": "No files to upload..."
      },
      "specificFiltration": {
        "label": "Specific filtration",
        "hideOld": "Hide old",
        "hideOldTitle": "Hide item with last connection on Import transport with ADL = now-150 days"
      },
      "subjectWizard": {
        "company": "Company",
        "contact": "Contact",
        "enterCrnText": "Enter CRN of the company you want to add and then click to \"Next\" button.",
        "fillInfoText": "Fill the information about the company and contact person and then click to \"Next\" button.",
        "willContactRegistrationText": "The company will be contacted to email you've entered with an invitation to register and connect with your company.",
        "willContactConnectionText": "The company will be contacted with a request for connection with your company."
      }
    },
    "user": {
      "profile": {
        "editProfile": "Edit profile",
        "addUser": "User creation",
        "personalInfo": "Personal information",
        "firstName": "First name:",
        "lastName": "Last name:",
        "email": "E-mail:",
        "phone": "Phone:",
        "mobile": "Mobile:",
        "intraline": "Intraline:",
        "sms": "SMS:",
        "companyFunction": "Company function:",
        "notes": "Notes:",
        "loginInfo": "Login information",
        "username": "Username:",
        "oldPassword": "Current password:",
        "newPassword": "New password:",
        "confirmPassword": "Confirm password:",
        "noModifyPasswordNotification": "If you don't want to change your password, leave all three fields empty.",
        "notification": "Notification",
        "active": "Active",
        "watch": "Watch field",
        "sendToAllOrders": "Send notification about changes for all orders",
        "sendToAllOrdersByContact": "Send notification about changes for all orders, where you are set as Contact person.",
        "sendToAllTrcOrders": "Send notifications for all transports",
        "sendToContactTrcOrders": "Send notifications for all transports where I figure as responsible person",
        "watchedFields": "Watched fields",
        "notificationLanguange": "Language of notifications:",
        "spz": "SPZ:",
        "signature": "Signature",
        "logo": "Firm logo for email notification",
        "logoSize": "Maximum dimensions allowed: 400x150px",
        "downloadSignature": "Download signature",
        "downloadLogo": "Download logo"
      },
      "edit": {
        "editUser": "Edit user",
        "userInfo": "User information",
        "userLogInInfo": "Credentials",
        "ident": "Ident:",
        "realname": "Real name:",
        "firstName": "First name:",
        "lastName": "Last name:",        
        "loginname": "Login name:",
        "password": "Password:",
        "passwordAgain": "Password for control:",
        "disabled": "Deleted",
        "valid": "Allow login",
        "portal": "Portal name",
        "set": "Set",
        "action": "Action",
        "setRoles": "Set roles",
        "addSubject": "Add company",
        "unlinkSubject": "Unlink",
        "roleName": "Role name",
        "duplicateFromUser": "Copy settings from user (roles,widgets,presets)"
      }
    },
    "partner": {
      "partner": "Partner contact",
      "bdp": "BDP - Wakestone",
      "attributes": "Attributes",
      "news": "News",
      "position": "Position",
      "fullName": "FullName",
      "mobil": "Mobil",
      "email": "Email",
      "dealer": "Dealer",
      "town": "Town",
      "primContact": "Primary contact",
      "ContactPerson": "Contact persons"
    },
    "question": {
      "confirmSaveChanges": "Do you want save changes? Otherwise changes will be lost.",
      "confirmSaveChanges2": "To save changes, fill reason is required, otherwise save will be canceled.",
      "deleteItem": "Last item cant be deleted.",
      "confirmDeleteItem": "Do you really want to delete this item?",
      "cancelOrder": "Do you really want to cancel order?",
      "declaratnSetSuccess": "Declarant has been successfully set",
      "preliminaryDutySucces": "Preliminary duty has been successfully set.",
      "setRDLSuccess": "Requested delivery date has been successfully set.",
      "setReleaseDateSuccess": "Customs release date has been successfully set",
      "docsUploaded": "Documents were uploaded",
      "setECRSuccess": "ECR date has been successfully set",
      "setADLSuccess": "ADL date has been successfully set",
      "setAPUSuccess": "APU has been successfully saved.",
      "vgmAgree": "Sender (responsible person) claims that he provides the correct figure VGM for enterprise BDP-Wakestone, in accordance with the requirements of SOLAS Chapter VI. Further he agrees that he replace any damage that might be caused the shipper of BDP-Wakestone in case incorrect information VGM, and that he will provide all necessary assistance to the consequences of such action for shippers were minimized.",
      "agree": "I agree",
      "setDutySuccess": "Duty Payment Order has been successfully saved.",
      "clearedSuccess": "Successfully cleared with customs",
      "cancelDemand": "Do you really want to cancel selected demand?",
      "deletePo": "Do you really want to delete selected item?",
      "deletePoVaz": "Do you really want to unlink selected item from order?",
      "acceptInvoice": "Do you really want to approve this document?",
      "declineInvoice": "Do you really want to decline this document?",
      "createOrderWithoutOffer": "Do you really want to create order from selected demand, which have no offer?",
      "dataSaved": "Data was saved.",
      "offerSaved": "Offer was saved.",
      "messageInserted": "Message was inserted.",
      "setVGMSuccess": "VGM information has been successfully saved.",
      "approveTracedo": "Do you really want to accept selected tracedo?",
      "tracedoApproved": "Tracedo was accepted.",
      "deleteUser": "Do you really want to delete selected user?",
      "deleteSubjectConnection": "Do you really want to delete selected connection with subject?",
      "deleteAddress": "Do you really want to delete selected address from your list?",
      "activationEmailSent": "An activation email has been sent to your email.",
      "registeredSuccessfully": "You were successfully registered.",
      "registrationConfirmationPending": "Your registration must now be approved by responsible person. You will be notified by e-mail.",
      "deleteCustomer": "Do you really want to delete selected Customer?"
    },
    "alerts": {
      "error": "The action was not successful.",
      "emptyEmail": "Email must be filled.",
      "emptyETD": "ETD must be filled.",
      "emptyETA": "ETA must be filled.",
      "emptyATD": "ATD must be filled.",
      "emptyAPU": "APU must be filled.",
      "emptyATA": "ATA must be filled.",
      "emptySpediter": "Spediter must be selected.",
      "emptyCustomer": "Customer must be selected.",
      "emptyCarrier": "Carrier must be selected.",
      "emptyPicUser": "Responsible person must be selected.",
      "emptyCustomerPicUser": "Customer's responsible person must be selected.",
      "emptyMyPicUser": "Responsible person must be selected.",
      "notValidEmail": "Wrong format of email.",
      "passMismatched": "New passwords must be same.",
      "fillPassword": "Fill password.",
      "fillCarrier": "Fill carrier",
      "fillRequiredFields": "Fill required fields.",
      "fillPrice": "Fill price",
      "fillTruckType": "Fill Truck type",
      "vgmAgree": "You have to agreed with condition.",
      "updateDisabled": "Update is disabled for order in this status.",
      "emptyMessageText": "To insert message fill text first.",
      "selectItem": "Please, select Order Item",
      "selectTracedo": "Please, select Track&Trace transportation. Define transportation, which will handle Item.",
      "oldClientUrl": "The old client interface is now available on url: ",
      "selectShipper": "Shipper is empty, please select shipper before creation of demand.",
      "invalidAddress": "New address is not correctly filled in.",
      "invalidContact": "New contact is not correctly filled in.",
      "alertUrlChanged": "25.5. 20:00 - 23:00 system will be unavailable due to scheduled maintenance.",
      "fillTransportPrice": "Transport price can´t be empty.",
      "fillDeliveryInformation": "There is empty date in delivery section.",
      "fillDeliveryAddress": "Delivery address is empty.",
      "invalidCharacter": "Save has failed. Immpossible to save inserted character (Not Czech character)",
      "dataInUse": "Save has failed. The record is currently being used by another user or application. Please try to save it later.",
      "invalidUsername": "The username is incorrect.",
      "wrongPassword": "The password is incorrect.",
      "emailDuplicity": "E-mail is already in use by another user!",
      "signatureMustBeJpg": "Signature must be type of jpg",
      "wrongSignatureSize": "Signature must have dimension approximately 300x250",
      "wrongLogoSize": "Logo must have dimension up to 400x150",
      "driverCantLogIn": "Driver cannot log in into web aplication",
      "lastName": "Fill Last name",
      "firstName": "Fill First name",
      "firmName": "Fill Firm name",
      "firmIco": "Fill CRN",
      "firmDic": "Fill VAT ID",
      "firmStreet": "Fill street",
      "firmZipCode": "Fill ZIP",
      "firmCity": "Fill City",
      "firmCountryId": "Fill Country",
      "firmTradeCurrency": "Fill Trade currency",
      "requestSend": "was successfully contacted.",
      "insufficientRight": "You have insufficient rights on this operation",
      "invalidCheckpointAddress": "New checkpoint address is not correctly filled in",
      "invalidCheckpointContact": "New checkpoint contact is not correctly filled in",
      "invalidPoItems": "Name for the PO item must be filled in",
      "invalidPickupContact": "Origin contact is not correctly filled in",
      "invalidDestinationContact": "Destination contact is not correctly filled in"
    }
  },
  "widget": {
    "no-settings": "Widget has no settings.",
    "settingsSaved": "Widget configuration has been successfully saved",
    "customsDeclarationExport": {
      "descr": "Displays (export) orders according to date EPU (EPU is between dates 'today - EPUminus from settings' and 'today + EPUplus from settings') and date Customs release must be empty. If EPU is empty, EDL date will be used. Orders with empty EPU and EDL will not be displayed."
    },
    "customsDeclarationImport": {
      "descr": "Displays (import) Airfreight and Oceanfreight orders according to date ETA (ETA is between dates 'today - ETAminus from settings' and 'today + ETAplus from settings') and Roadfreight orders according to date EDL (EDL is between dates 'today - ETAminus from settings' and 'today + ETAplus from settings') and 'release duty payment order issued' must be empty"
    },
    "dispatchWarehouse": {
      "showSetEcr": "Show items which have ECR set",
      "showSetApu": "Show items which have APU set",
      "descr": "Displays (export) orders according to date ECR (ECR is between dates 'today - ECRminus from settings' and 'today + ECRplus from settings') and  APU, ADL must be empty."
    },
    "importWarehouse": {
      "descr": "Displays (import) orders according to date EDL (EDL is between dates 'today - EDLminus from settings' and 'today + EDLplus from settings') and  ADL must be empty."
    },
    "logisticExportDestination": {
      "descr": "Displays (export) orders according to date EDL (EDL is between dates 'today - EDLminus from settings' and 'today + EDLplus from settings') and ADL must be empty or ALD >= (today - 'ADLminus' from settings)",
      "descr2": "shipment CFR and CIF dont have date EDL (ussualy AIR or SEA). These shipment are filterable via date ETA and disappears acording to date ATA."
    },
    "logisticExportOrigin": {
      "descr": "Displays (export) orders where date ECR, ACR or EPU is between ('today - ECRminus from settings' and 'today + ECRplus from settings') and ADL must be empty or ALD >= (today - 'ATDminus' from settings)",
      "descr2": "shipment CFR and CIF dont have date ADL (ussualy AIR or SEA). These transport disappears acording to date ATD (after 'took off'/'sail away') ."
    },
    "logisticImportDestination": {
      "descr": "Displays (import) orders according to date ETA (ETA is between dates 'today - ETAminus from settings' and 'today + ETAplus from settings') and ADL must be empty or ALD >= (today - 'ADLminus' from settings)",
      "descr2": "shipment CFR and CIF dont have date EDL (ussualy AIR or SEA). These shipment are filterable via date ETA and disappears acording to date ATA."
    },
    "logisticImportOrigin": {
      "showAddCargoReady": "Show 'Add Cargo Ready' items",
      "showAddDeadline": "Show 'Add Deadline' items",
      "showAddCommercialDocuments": "Show items without Commercial documents",
      "descr": "Displays (import) orders where date ECR, ACR or EPU is between ('today - ECRminus from settings' and 'today + ECRplus from settings' ) and  ADL must be empty or ALD >= (today - 'ATDminus' from settings)"
    },
    "purchasingDepartment": {
      "descr": "Displays (import) orders according to date ECR (ECR is between dates 'today - ECRminus from settings' and 'today + ECRplus from settings' )"
    },
    "salesDepartment": {
      "descr": "Displays (export) orders according to date ECR (ECR is between dates 'today - ECRminus from settings' and 'today + ECRplus from settings' )"
    },
    "invoicesToApprove": {
      "descr": "Displays only electronic, nonapproved invoices according to date DUE (DUE is between dates 'today - DUEminus from settings' and 'today + DUEplus from settings' )."
    },
    "externalCarrierOrder": {
      "descr": "Show orders, which have filled external carrier and status isn`t cancel. Record disappears acording to date ADL."
    },
    "externalCarrierDemand": {
      "descr": "Show demands, which have filled external carrier and status isn`t 'cancel' or 'not send'. Record disappears by creating order from demand."
    },
    "consignee": {
      "descr": "Displays (export) orders according to date EDL (EDL is between dates 'today - EDLminus from settings' and 'today + EDLplus from settings') and ADL must be empty.",
      "descr2": "shipment CFR and CIF dont have date EDL (ussualy AIR or SEA). These shipment are filterable via date ETA and disappears acording to date ATA."
    },
    "warehousePoItems": {
      "descr": "Display order parts, which has already arrived into selected delivery place and aren't assigned to another transport."
    },
    "progressGraph": {
      "fieldTitles": "Columns titles"
    },
    "trackAndTrace": {
      "descr": "Displays records with ETD (ETD is between dates 'today - ETD minus from settings' a 'today + ETD plus from settings') and ATA must be empty or ATA >= today + ATAPlus",
      "descr2": "Displays transportations upcoming in the next week (ETD plus) and a transportations in progress(ETD minus) and a transportations finished a few days ago (ATA plus).",
      "colors": "Description of colors",
      "descrRed": "RED - ETD or ETA has expired or transportation is in status suspended.",
      "descrOrange": "ORANGE - ETD or ETA expires within 30 min or some issue exist on the transportation (traffic jam, accident, delay...).",
      "descrGreen": "GREEN - there is no time problem.",
      "descrWhite": "WHITE - new transportation or transportation without driver.",
      "showEmergency": "Show only emergency row (red, orange)"
    },
    "origin": {
      "descr": "Displays records with ETD (ETD is between dates 'today - ETD minus from settings' a 'today + ETD plus from settings') and ATA must be empty or ATA >= today + ATAPlus",
      "descr2": "Displays transportations upcoming in the next week (ETD plus) and a transportations in progress(ETD minus) and a transportations finished a few days ago (ATA plus).",
      "colors": "Description of colors",
      "descrRed": "RED - ETD or ETA has expired or transportation is in status suspended.",
      "descrOrange": "ORANGE - ETD or ETA expires within 30 min or some issue exist on the transportation (traffic jam, accident, delay...).",
      "descrGreen": "GREEN - there is no time problem.",
      "descrWhite": "WHITE - new transportation or transportation without driver.",
      "showEmergency": "Show only emergency row (red, orange)"
    },
    "destination": {
      "descr": "Displays records with ETA (ETA is between dates 'today - ETA minus from settings' a 'today + ETA plus from settings') and ATA must be empty or ATA >= today + ATAPlus",
      "descr2": "Displays transportations upcoming in the next week (ETA plus) and a transportations in progress(ETD minus) and a transportations finished a few days ago (ATA plus).",
      "colors": "Description of colors",
      "descrRed": "RED - ETD or ETA has expired or transportation is in status suspended.",
      "descrOrange": "ORANGE - ETD or ETA expires within 30 min or some issue exist on the transportation (traffic jam, accident, delay...).",
      "descrGreen": "GREEN - there is no time problem.",
      "descrWhite": "WHITE - new transportation or transportation without driver.",
      "showEmergency": "Show only emergency row (red, orange)"
    }
  },
  "trade": {
    "demand": {
      "edit": {
        "containers": "Item",
        "trucks": "Item",
        "unloadingPlace": "Delivery",
        "poItems": "Packing list",
        "dimensions": "dimensions",
        "poItem": "Packing item",
        "information": "Information",
        "orderItems": "Items",
        "mode": "Transport mode",
        "partner": "Partner",
        "kind": "Export/import",
        "document": "Request reference",
        "status": "Status",
        "clientRef": "Client ref.",
        "clientRef2": "Client ref. 2",
        "clientRef3": "Client ref. 3",
        "clientRef4": "Client ref. 4",
        "clientRef5": "Client ref. 5",
        "incoterms": "Incoterms",
        "incotermsPlace": "Incoterms place",
        "service": "Service",
        "express": "Direct truck",
        "insurance": "Insurance",
        "temperatureTransport": "Temperature controlled transport",
        "adrRoad": "DGR Cargo",
        "adrSea": "DGR Cargo",
        "adrAir": "DGR Cargo",
        "customsOrigin": "Customs clearance origin",
        "customsDest": "Customs clearance destination",
        "pickup": "Pick up place address",
        "delivery": "Delivery place address",
        "shipper": "Shipper",
        "consignee": "Consignee",
        "portSea": "Port",
        "portAir": "Airport",
        "portRail": "Gateway",
        "portRailOrigin": "Origin gateway",
        "portRailDestination": "Destination gateway",
        "door": "Door",
        "another": "Another",
        "addressName": "Name",
        "addressStreet": "Address",
        "addressStreet2": "Address 2",
        "addressCity": "City",
        "addressCountry": "Country",
        "addressZipCode": "Zip",
        "note": "Note",
        "ecr": "ECR",
        "rdl": "RDL",
        "rrtt": "Requested response time till",
        "loadType": "Load type",
        "addContainer": "Add item",
        "addTruck": "Add truck",
        "addCar": "Add wagon",
        "addPoItem": "Add packing item",
        "addUnloadingPlace": "Add delivery",
        "dataLog": "Datalog",
        "rdlRequired": "Fill date RDL.",
        "ecrRequired": "Fill date ECR.",
        "owner": "Responsible person",
        "saveCanceled": "Demand was not changed, because it has been already processed. Contact dealer for more s information."
      }
    }
  },
  "edit": {
    "poItem": {
      "name": "Name",
      "baseInfo": "Basic information",
      "perColli": "Per colli",
      "subjectId": "Subject:",
      "oogcntr": "Relation type:",
      "packaging": "Packaging:",
      "descr": "Description:",
      "colli": "Colli:",
      "pcs": "Pieces:",
      "length": "Length(cm):",
      "width": "Width(cm):",
      "height": "Height(cm):",
      "lengthM": "Length(m):",
      "widthM": "Width(m):",
      "heightM": "Height(m):",
      "weight": "Gross weight(kg):",
      "stackable": "Stackable",
      "orderItem": "Item:",
      "kgs": "Weight(kg)",
      "countOrder": "Total relations",
      "contactEmail": "Contact person email",
      "metOriginCountry": "Origin country",
      "transported": "Transported",
      "inProgress": "On the way",
      "metOriginCountryName": "nameEn",
      "tracedoNumber": "Track&Trace number"
    }
  },
  "others": {
    "filename": {
      "gridSettings": "User settings of tables."
    }
  },
  "codelists": {
    "demandTemplateType": {
      "normal": "Standard",
      "multipleDelivery": "Multiple delivery"
    }
  },
  "settings": {
    "addresses": {
      "edit": "Edit address",
      "editContactPerson": "Edit contact persons",
      "saveOrLoseEdit": "Save the edited data, otherwise it will be discarded.",
      "noChanges": "No changes are made.",
      "successfulSave": "Changes have been saved successfully.",
      "saveFailed": "Changes could not be saved. Please try again.",
      "mergeDuplicities": "Merge duplicate persons",
      "mergeSuccess": "The merge was successful.",
      "noContactsMess": "We did not find any contact information for this address.",
      "selectContact": "Select the person to merge.",
      "ramps": {
        "title": "Ramps",
        "name": "Ramp",
        "alertUsed": "This ramp can't be deleted. It is used somewhere!"
      }
    }
  },
  "checkpoints": {
    "typed": {
      "cargo_load_etd": "Cargo ETD",
      "cargo_load_atd": "Cargo ATD",
      "cargo_load_address_full": "Cargo load",
      "cargo_unload_eta": "Cargo ETA",
      "cargo_unload_ata": "Cargo ATA",
      "cargo_unload_address_full": "Cargo unload"
    }
  },
  "rolePermissions": {
    "availableActions": "Actions available",
    "availableResources": "Resources available",
    "paths": {
      "null": "Unsorted",
      "app": "Application",
      "section": "Section",
      "widget": "Widget",
      "entity": {
        "tracedos": "Entity - Track & Trace (subject role)",
        "tracedo": "Entity - Track & Trace",
        "user" : "Entity - User",
        "address": "Entity - adress",
        "subject": "Entity - subject",
        "subjects": "Subjects in general"
      },
      "customfields": "Custom fields",
      "rolePermissions": "Roles permissions"
    }   
  }
}