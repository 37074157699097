import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { IDialogSettings } from "../../common/IDialogSettings";
import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { SubjectWizard } from "../../components/SubjectWizard/SubjectWizard";
import { ISubjectData } from "../../components/SubjectWizard/ISubjectData";

export interface IConnectWithSpediterSettings extends IDialogSettings
{
}

/**
 * Connect with spediter
 */
@injectable()
export class ConnectWithSpediter extends BaseDialogViewModel<IConnectWithSpediterSettings>
{
    public onDone: SyncEvent<any> = new SyncEvent<any>();

    // Data o kontaktovanem spediterovi
    public spediterData: KnockoutObservableType<any> = ko.mapping.fromJS({
        email: null,
        firmName: null,
        firmIco: null
    });

    /**
     * Startup
     */
    public async startup()
    {
        await super.startup();

        if(!this.user.isAllowed('entity.spediter', 'connect')) {
            throw new BadRequestError('Access denied', 403);
        }        
    }

    /**
     * Render
     */
    public async rendered(): Promise<void>
    {
    }

    /**
     * Send connection request to spediter
     */
    public async connectWithSpediter(): Promise<boolean>
    {
        let spediterData = ko.mapping.toJS(this.spediterData);
    	let saved = await this.rpc.call('spediter.connectWithSpediter', { subjectData: spediterData });
        this.onDone.post(saved);
        return 
    }


    public dialogTemplate = (): HTMLElement => (
        <div>

            <div id='registerTabStrip' data-bind="kendoTabStrip: { animation: false }">
                <ul>
                    <li data-bind="css: 'k-state-active', i18n: 'common.captions.information'"></li>
                </ul>
                <div class="k-state-active">
                    <ko with="$root.spediterData">
                        <div>
                            <fieldset class="lowPadding">
                                <p data-bind="i18n: 'registration.connectProcesInfo2'"></p>
                            </fieldset>
                            <fieldset class="lowPadding">
                                <legend data-bind="i18n: 'nav.spediter'"></legend>
                                <div className="row mb-2">
                                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'email', i18n: 'registration.email'"></label>
                                    <div className="col-md-10">
                                        <input type="text" className="form-control" data-bind="value: email, uniqueId: 'email'" />
                                    </div>
                                </div>                    
                                <div className="row mb-2">
                                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'firmIco', i18n: 'registration.firmIco'"></label>
                                    <div className="col-md-10">
                                        <input type="text" className="form-control" data-bind="value: firmIco, uniqueId: 'firmIco'" />
                                    </div>
                                </div>                    
                                <div className="row mb-2">
                                    <label className="inline col-md-2 col-form-label" data-bind="uniqueFor: 'firmName', i18n: 'registration.firmName'"></label>
                                    <div className="col-md-10">
                                        <input type="text" className="form-control" data-bind="value: firmName, uniqueId: 'firmName'" />
                                    </div>
                                </div>                    
                            </fieldset>
                        </div>
                    </ko>
                </div>
            </div>

        </div>    
    )

}