import { SyncEvent } from "ts-events";

import { BaseDialogViewModel } from "../common/BaseDialogViewModel";
import { IDialogSettings } from "./IDialogSettings";


export interface IWidgetDialogSettings extends IDialogSettings
{
    widgetSettings: any,
    widgetId: number;
}

/**
 * Base class for all Widgets settings dialogs
 */
export abstract class BaseWidgetSettings<T extends IWidgetDialogSettings> extends BaseDialogViewModel<T>
{

    /**
     * On settings saved
     */
    public onSaved: SyncEvent<any> = new SyncEvent<any>();

    /**
     * On cancelled
     */
    public onCancelled: SyncEvent<void> = new SyncEvent<void>();


    /**
     * Save widget settings. Returns a Promise. Triggers 'saved' event
     * @return Promise
     */
    public async saveSettings(): Promise<any>
    {
        let settings = ko.mapping.toJS(this.settings.widgetSettings);
        await this.rpc.call('dashboard.saveWidgetSettings', {
            id: this.settings.widgetId,
            settings: JSON.stringify(settings)
        });
        this.onSaved.post(settings);
        return settings;
    }    


    /**
     * Trigger cancelled event
     */
    public cancel()
    {
        this.onCancelled.post();
    }

}