import { injectable } from "tsyringe";
import { h } from "../../../../tracejs/src/utils/JSXFactory";

import { BaseWidgetSettings } from "../../common/BaseWidgetSettings";
import { ITrackAndTraceSettings } from "./ITrackAndTraceSettings";

/**
 * Track and Trace Settings
 */
@injectable()
export class TrackAndTraceSettings extends BaseWidgetSettings<ITrackAndTraceSettings>
{

    /**
     * Settings template
     * @returns 
     */
    public dialogTemplate = (): HTMLElement => (

        <div data-bind="with: $root.settings.widgetSettings">
            <div class="row">
                <div class="col-md-4">
                    <div className="row mb-2">
                        <label className="inline col-md-6 col-form-label" data-bind="uniqueFor: 'etdMinus'">ETD minus (days)</label>
                        <div className="col-md-6">
                            <input type="number" className="form-control" data-bind="value: filters.etdMinus, uniqueId: 'etdMinus'" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="inline col-md-6 col-form-label" data-bind="uniqueFor: 'etdPlus'">ETD plus (days)</label>
                        <div className="col-md-6">
                            <input type="number" className="form-control" data-bind="value: filters.etdPlus, uniqueId: 'etdPlus'" />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="inline col-md-6 col-form-label" data-bind="uniqueFor: 'ataPlus'">ATA plus (days)</label>
                        <div className="col-md-6">
                            <input type="number" className="form-control" data-bind="value: filters.ataPlus, uniqueId: 'ataPlus'" />
                        </div>
                    </div>
                </div>
                <div class="col-md-8">

                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" data-bind="checked: filters.showEmergency, uniqueId: 'showEmergency'" />
                        <label class="form-check-label" data-bind="uniqueFor: 'showEmergency'"><span data-bind="i18n: 'widget.origin.showEmergency'"></span></label>
                    </div>

                </div>
            </div>

            <hr />

            <fieldset>
                <legend data-bind="i18n: 'widget.origin.colors'"></legend>
                <ul>
                    <li data-bind="i18n: 'widget.origin.descrWhite'"></li>
                    <li data-bind="i18n: 'widget.origin.descrGreen'"></li>
                    <li data-bind="i18n: 'widget.origin.descrOrange'"></li>
                    <li data-bind="i18n: 'widget.origin.descrRed'"></li>
                </ul>
            </fieldset>

            <fieldset>
                <legend data-bind="i18n: 'common.captions.description'"></legend>
                <p data-bind="i18n: 'widget.origin.descr'"></p>
            </fieldset>

            <fieldset>
                <legend data-bind="i18n: 'common.captions.notePractice'"></legend>
                <p data-bind="i18n: 'widget.origin.descr2'"></p>
            </fieldset>

        </div>
    );


}