import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";


import { CultureSwitch } from "../components/CultureSwitch";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";

@injectable()
export class Register extends BaseViewModel<any>
{

    private token: KnockoutObservable<string> = ko.observable('');

    // codelists
    private countries: KnockoutObservableArray<any> = ko.observableArray();
    private languages: KnockoutObservableArray<any> = ko.observableArray();

    // registration data
    private registrationData: KnockoutObservable<any> = ko.observable(ko.mapping.fromJS({
        lastName: null,
        firstName: null,
        password: null,
        passwordCheck: null,
        notificationLanguageId: null,
        email: null,
        phone: null,
        firmName: null,
        firmIco: null,
        firmDic: null,
        firmStreet: null,
        firmZipCode: null,
        firmCity: null,
        firmCountryId: null
    }));

    private countriesConfig: kendo.ui.ComboBoxOptions;


	public onBackClicked: SyncEvent<void> = new SyncEvent<void>();

    // Dokoncena registrace ?
    private registered: KnockoutObservable<boolean> = ko.observable(false);

    // Byl po registraci odeslan aktivacni email ?
    private activationEmailSent: KnockoutObservable<boolean> = ko.observable(false);


    public async startup()
    {
        await super.startup();

        this.token(this.router.query.token as string);
        if(!this.token()) {
            throw new BadRequestError('Missing token', 401);
        }

        let batch = this.rpc.batch();
        batch.call('languages', 'language.get', { query: { select: 'languageId,iso,name' } });
        batch.call('countries', 'country.get', { query: { sort: [{ field: 'descr', dir: 'asc' }], select: 'countryId,descr,nameEn' } });
        batch.call('subject', 'subject.getInfoByToken', { token: this.token() });
        let response: any = await batch.run();

        this.languages(response['languages']);
        this.countries(response['countries']);        

        if(response['subject']) {
            var s = response['subject'];
            this.registrationData().email(s.email);
            this.registrationData().phone(s.phone);
            this.registrationData().firmName(s.name);
            this.registrationData().firmIco(s.ic);
            this.registrationData().firmDic(s.dic);
            this.registrationData().firmStreet(s.address1);
            this.registrationData().firmZipCode(s.postalCode);
            this.registrationData().firmCity(s.city);
            this.registrationData().firmCountryId(s.countryId);
        }
    }


	/**
	 * Back to login
	 */
     public backToLogin()
     {
         this.onBackClicked.post();
     }


    /**
     * Odeslan registracni formular
     */
    public async registerFormSubmitted()
    {
        let data = ko.mapping.toJS(this.registrationData);

        if(data.passwordNew !== data.passwordAgain) {
            kendo.alert(i18n.t('system.alerts.passMismatched'));
            return;
        }
        if(data.passwordNew == '' || data.passwordAgain == '') {
            kendo.alert(i18n.t('system.alerts.fillPassword'));
            return;
        }
        if(jQuery.trim(data.email) === '') {
            kendo.alert(i18n.t('system.alerts.emptyEmail'));
            return;
        }

        var re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
        if(!re.test(data.email)) {
            kendo.alert(i18n.t('system.alerts.notValidEmail'));
            return;
        }
        if(jQuery.trim(data.lastName) === '') {
            kendo.alert(i18n.t('system.alerts.lastName'));
            return;
        }
        if(jQuery.trim(data.firstName) === '') {
            kendo.alert(i18n.t('system.alerts.firstName'));
            return;
        }
        if(jQuery.trim(data.firmName) === '') {
            kendo.alert(i18n.t('system.alerts.firmName'));
            return;
        }
        if(jQuery.trim(data.firmIco) === '') {
            kendo.alert(i18n.t('system.alerts.firmIco'));
            return;
        }

        if(jQuery.trim(data.firmStreet) === '') {
            kendo.alert(i18n.t('system.alerts.firmStreet'));
            return;
        }
        if(jQuery.trim(data.firmZipCode) === '') {
            kendo.alert(i18n.t('system.alerts.firmZipCode'));
            return;
        }
        if(jQuery.trim(data.firmCountryId) === '') {
            kendo.alert(i18n.t('system.alerts.firmCountry'));
            return;
        }
        if(jQuery.trim(data.firmCity) === '') {
            kendo.alert(i18n.t('system.alerts.firmCity'));
            return;
        }			
        data.connectionToken = this.token ? this.token : null;

        let response: any = await this.rpc.call('user.register', { 
            subject: {
                'name': data.firmName,
                'ic': data.firmIco,
                'dic': data.firmDic,
                'address1': data.firmStreet,
                'city': data.firmCity,
                'countryId': data.firmCountryId,
                'postalCode': data.firmZipCode
            },
            user: {
                'firstName': data.firstName,
                'lastName': data.lastName,
                'email': data.email,
                'password': data.password,
                'phone': data.phone,
                'notificationLanguageId': data.notificationLanguageId
            },
            token: this.token()
        });
        if(response.error) {
            kendo.alert(response.error.message);
        }
        else {
            this.activationEmailSent(response.activationEmailSent);
            this.registered(true);
        }
    }


	public async rendered()
	{
		await this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures');
	}

	public template = (): HTMLElement => (

		<div className="centered-form container h-expand d-flex flex-column justify-content-start mt-4" style="max-width: 800px">
			<div className="row justify-content-md-center">
                <div>
                    <view-frame name="cultures" className="float-end" />
                    <h1 className="mb-2 text-primary">TRACEDO</h1>

                    <div className="panel radius panel-big-margin p-4 pb-2">

                        <ko if="$root.registered">

                            <h2 className="text-center" data-bind="i18n: 'system.question.registeredSuccessfully'"></h2>
                            <p className="text-center" data-bind="i18n: 'system.question.registrationConfirmationPending'"></p>
                            <ko if="$root.activationEmailSent">
                                <p className="text-center" data-bind="i18n: 'system.question.activationEmailSent'"></p>
                            </ko>

                        </ko>
                        <ko ifnot="$root.registered">

                            <h2 data-bind="i18n: 'registration.registration'" className="mb-3"></h2>
                            <form method="post" data-bind="submit: $root.registerFormSubmitted">

                                <ko with="$root.registrationData">

                                    <div className="row">
                                        <div className="col-md-6">
                                            <fieldset className="lowPadding">
                                                <legend data-bind="i18n: 'registration.firm'"></legend>

                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmIco', uniqueFor: 'firmIco', attr: {title: i18n.t('registration.firmIcoInfo')}"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firmIco', value: firmIco" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmName', uniqueFor: 'firmName'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firmName', value: firmName" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmDic', uniqueFor: 'firmDic'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firmDic', value: firmDic" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmStreet', uniqueFor: 'firmStreet'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firmStreet', value: firmStreet" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmZipCode', uniqueFor: 'firmZipCode'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firmZipCode', value: firmZipCode" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmCity', uniqueFor: 'firmCity'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firmCity', value: firmCity" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firmCountry', uniqueFor: 'firmCountry'"></label>
                                                    <div className="col-md-8">
                                                        <input data-combobox="kendoCombobox" id="firmCountry" type="text" className="w-100" data-bind="
                                                            kendoDropDownList: {
                                                                value: firmCountryId, 
                                                                placeholder: i18n.t('common.captions.chooseState'),
                                                                dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
                                                                dataValueField: 'countryId',
                                                                filter: 'contains',
                                                                minLength: 0,
                                                                data: $root.countries()
                                                            }
                                                        " />
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-md-6">
                                            <fieldset className="lowPadding">
                                                <legend data-bind="i18n: 'registration.user'"></legend>

                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.email', uniqueFor: 'email'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'email', value: email" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.password', uniqueFor: 'password'"></label>
                                                    <div className="col-md-8">
                                                        <input type="password" data-bind="uniqueId: 'password', value: password" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.passwordCheck', uniqueFor: 'passwordCheck'"></label>
                                                    <div className="col-md-8">
                                                        <input type="password" data-bind="uniqueId: 'passwordCheck', value: passwordCheck" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.firstName', uniqueFor: 'firstName'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'firstName', value: firstName" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.lastName', uniqueFor: 'lastName'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'lastName', value: lastName" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.phone', uniqueFor: 'phone'"></label>
                                                    <div className="col-md-8">
                                                        <input type="text" data-bind="uniqueId: 'phone', value: phone" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <label className="col-md-4 col-form-label" data-bind="i18n: 'registration.language', uniqueFor: 'languageId'"></label>
                                                    <div className="col-md-8">
                                                        <select className="form-control" data-bind="
                                                                uniqueId: 'languageId',
                                                                options: $root.languages,
                                                                optionsText: 'name',
                                                                optionsValue: 'languageId',
                                                                value: notificationLanguageId
                                                            "></select>
                                                    </div>
                                                </div>
                                                
                                            </fieldset>
                                        </div>
                                    </div>

                                    <div className="text-end mt-3">
                                        <button type="button" className="btn btn-link" data-bind="i18n: 'registration.backToLogin', click: $root.backToLogin.bind($root)"></button>
                                        <button type="submit" className="btn btn-primary" data-bind="i18n: 'registration.register'"></button>
                                    </div>

                                </ko>

                            </form>
                        </ko>
                    </div>
                </div>
            </div>

		</div>

	);    

}