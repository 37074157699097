import { injectable } from "tsyringe";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { IRoute } from "../../../tracejs/src/application/IRoute";

import { BaseViewModel } from "../common/BaseViewModel";
import { Costs } from "./Costs";
import { Revenues } from "./Revenues";
import { CodelistManager } from "../../model/CodelistManager";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { RoadType } from "../../entities/RoadType";
import { BdpContainerType } from "../../entities/BdpContainerType";
import { Kind } from "../../entities/Kind";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { i18n } from "i18next-ko";
import moment from "moment";
import { TracedoHelpers } from "../TrackAndTrace/TracedoHelpers";
import { TracedoView } from "../../entities/TracedoView";
import { Addressbook } from "../../entities/Addressbook";
import {Tracedo} from "../../entities/Tracedo";

/**
 * Transport Results
 */
@injectable()
export class Results extends BaseViewModel<any>
{
    // Codelist manager
    private codelistManager: CodelistManager;

    private resultsGrid: kendo.ui.Grid;

    private roadTypes: RoadType[];

    private bdpContainerTypes: BdpContainerType[];

    private kinds: Kind[];

    private responsiblePersons: KnockoutObservable<object> = ko.observable(null);
    private truncated: KnockoutObservable<boolean> = ko.observable(false);
    private truncatedTotal: KnockoutObservable<number> = ko.observable(0);
    private truncatedSent: KnockoutObservable<number> = ko.observable(0);
    private truncatedOutputMessage: KnockoutObservable<string> = ko.observable('');

    private generated: KnockoutObservable<boolean> = ko.observable(false);

    private isOrIsNot: KnockoutObservableArray<{ value: boolean, text: string }> = ko.observableArray([{ value: false, text: 'je'}, { value: true, text: 'není' }]);

    private filter: MappedType<{
        roadTypeIds?: number[],
        roadTypeIdsNot: boolean,
        bdpContainerTypeIds?: number[],
        bdpContainerTypeIdsNot: boolean,
        kindIds?: number[],
        kindIdsNot: boolean,
        supplierIds?: number[],
        supplierIdsNot: boolean,
        customerIds?: number[],
        customerIdsNot: boolean,
        responsiblePersonIds?: number[],
        responsiblePersonIdsNot: boolean,
        originIds?: number[],
        originIdsNot: boolean,
        destinationIds?: number[],
        destinationIdsNot: boolean,
        search?: string,
        uzpFrom?: Date,
        uzpTo?: Date,
    }> = ko.mapping.fromJS({
        roadTypeIds: [],
        roadTypeIdsNot: false,
        bdpContainerTypeIds: [],
        bdpContainerTypeIdsNot: false,
        kindIds: [],
        kindIdsNot: false,
        supplierIds: [],
        supplierIdsNot: false,
        customerIds: [],
        customerIdsNot: false,
        responsiblePersonIds: [],
        responsiblePersonIdsNot: false,
        originIds: [],
        originIdsNot: false,
        destinationIds: [],
        destinationIdsNot: false,
        search: null,
        uzpFrom: null,
        uzpTo: null
    });

    private resultsGridConfig: kendo.ui.GridOptions = {
        toolbar: ["excel"],
        excel: {
            fileName: "transport-results.xlsx",
            filterable: true
        },        
        autoBind: false,
        dataSource: {
            transport: {
                read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                
                    this.generated(true);

                    let uzpFrom = (this.filter as any).uzpFrom();
                    let uzpTo = (this.filter as any).uzpTo();

                    let uzpFromString = uzpFrom ? moment(uzpFrom).format() : null;
                    let uzpToString = uzpTo ? moment(uzpTo).format() : null;

                    let response: any = await this.rpc.call('tracedo.getTransportResults', {
                        roadTypeIds: this.filter.roadTypeIds(),
                        bdpContainerTypeIds: this.filter.bdpContainerTypeIds(),
                        kindIds: this.filter.kindIds(),
                        supplierIds: this.filter.supplierIds(),
                        customerIds: this.filter.customerIds(),
                        responsiblePersonIds: this.filter.responsiblePersonIds(),
                        originIds: this.filter.originIds(),
                        destinationIds: this.filter.destinationIds(),
                        search: this.filter.search(),
                        uzpFrom: uzpFromString,
                        uzpTo: uzpToString,
                            // not
                        roadTypeIdsNot: this.filter.roadTypeIdsNot(),
                        bdpContainerTypeIdsNot: this.filter.bdpContainerTypeIdsNot(),
                        kindIdsNot: this.filter.kindIdsNot(),
                        supplierIdsNot: this.filter.supplierIdsNot(),
                        customerIdsNot: this.filter.customerIdsNot(),
                        responsiblePersonIdsNot: this.filter.responsiblePersonIdsNot(),
                        originIdsNot: this.filter.originIdsNot(),
                        destinationIdsNot: this.filter.destinationIdsNot()
                    });
                    // console.log(response);

                    this.truncated(response.truncated);
                    this.truncatedSent(response.sent);
                    this.truncatedTotal(response.total);
                    this.truncatedOutputMessage(
                        i18n.t('common.captions.truncatedOutput')
                            .replace('%sent%', response.sent)
                            .replace('%total%', response.total)
                    );

                    options.success(response);
                }
            },
            schema: {
                model: {
                    id: 'id',
                    fields: {
                        'id': { type: "number" },
                        'number': { type: 'string' },
                        'roadTypeId': { type: 'number' },
                        'roadTypeName': { type: 'string' },
                        'bdpContainerTypeId': { type: 'number' },
                        'bdpContainerTypeName': { type: 'string' },
                        'picUserId': { type: 'number' },
                        'picUserRealName': { type: 'string' },
                        'supplierPartnerId': { type: 'number' },
                        'supplierPartnerName': { type: 'string' },
                        'cbm': { type: 'number' },
                        'chargeableVolume': { type: 'number' },
                        'colli': { type: 'number' },
                        'customerId': { type: 'number' },
                        'customerName': { type: 'string' },
                        'originAddressName': { type: 'string' },
                        'destAddressName': { type: 'string' },
                        'expectedCosts': { type: 'number' },
                        'expectedRevenues': { type: 'number' },
                        'kgs': { type: 'number' },
                        'ldm': { type: 'number' },
                        'netRevenues': { type: 'number' },
                        'profitability': { type: 'number' },
                        'realCosts': { type: 'number' },
                        'realRevenues': { type: 'number' },
                        'supplierId': { type: 'number' },
                        'supplierName': { type: 'string' },
                        'totalCosts': { type: 'number' },
                        'totalRevenues': { type: 'number' },
                        'uzp': { type: 'date' }
                    }
                },
                data: (d: any) => d.data,
            },
            sort: { field: "id", dir: "desc" },
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false
        },
        selectable: 'row',
        scrollable: true,
        reorderable: false,
        columnMenu: false,
        resizable: true,
        filterable: false,
        sortable: true,
        pageable: false,
        columns: [{
                field: 'number',
                title: i18n.t('common.captions.number'),
                width: 90,
                template: (data: any) => {
                    return '<a href="#" data-tracedo-detail="' + data.id + '">' + data.number + '</a>';
                }
            },
            { field: 'roadTypeName', title: i18n.t('common.captions.roadType'), width: 100 },
            { field: 'bdpContainerTypeName', title: i18n.t('common.captions.containerType'), width: 100 },
            { field: 'picUserRealName', title: i18n.t('common.captions.responsiblePerson'), width: 130 },
            { field: 'supplierPartnerName', title: i18n.t('common.captions.carrier'), width: 130 },
            { field: 'customerName', title: i18n.t('common.captions.customer'), width: 140 },
            { field: 'originAddressName', title: i18n.t('common.captions.origin'), width: 140 },
            { field: 'destAddressName', title: i18n.t('common.captions.destination'), width: 140 },
            { field: 'uzp', title: i18n.t('common.captions.uzp'), format: '{0:d}', width: 80 },
            { field: 'realCosts', title: i18n.t('common.captions.actualCosts'), width: 80 },
            { field: 'expectedCosts', title: i18n.t('common.captions.remainingCosts'), width: 80 },
            { field: 'totalCosts', title: i18n.t('common.captions.totalCosts'), width: 80 },
            { field: 'realRevenues', title: i18n.t('common.captions.actualRevenues'), width: 80 },
            { field: 'expectedRevenues', title: i18n.t('common.captions.remainingRevenues'), width: 80 },
            { field: 'totalRevenues', title: i18n.t('common.captions.totalRevenues'), width: 80 },
            { field: 'netRevenues', title: i18n.t('common.captions.netRevenues'), width: 80 },
            { field: 'profitability', title: i18n.t('common.captions.profitability'), width: 80 },
            { field: 'colli', title: i18n.t('common.captions.colli'), width: 80 },
            { field: 'kgs', title: i18n.t('common.captions.kgs'), width: 80 },
            { field: 'chargeableVolume', title: i18n.t('common.captions.chargeableVolume'), width: 80 },
            { field: 'cbm', title: i18n.t('common.captions.cbm'), width: 80 },
            { field: 'ldm', title: i18n.t('common.captions.ldm'), width: 80 }
        ]
    };
    
    /**
     * Suppliers data source
     */
    private suppliersDataSource: kendo.data.DataSourceOptions = {
        serverFiltering: true,
        transport: {
            read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                let carriers: any = await this.rpc.call('carrier.getMy', {
                    query: {
                        ...options.data,
                        select: 'subjectId,name',
                        pageSize: 500,
                        page: 1
                    }
                });
                options.success(carriers.data);
            }
        },
        requestStart: function(e) {
            if(!e.sender.filter() || e.sender.filter().filters.length === 0){
                e.preventDefault();
            }
        }
    };

    /**
     * Customer data source
     */
    private customersDataSource: kendo.data.DataSourceOptions = {
        serverFiltering: true,
        transport: {
            read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                let customers: any = await this.rpc.call('customer.getMy', {
                    query: {
                        ...options.data,
                        select: 'subjectId,name',
                        pageSize: 500,
                        page: 1
                    }
                });
                options.success(customers.data);
            }
        },
        requestStart: function(e) {
            if(!e.sender.filter() || e.sender.filter().filters.length === 0){
                e.preventDefault();
            }
        }
    };


    /**
     * Origins / Destinations data source
     */
    private originsDestinationsDataSource: kendo.data.DataSourceOptions = {
        serverFiltering: true,
        transport: {
            read: async (options: kendo.data.DataSourceTransportReadOptions) => {
                let origins: any = await this.rpc.call('addressbook.get', {
                    query: {
                        ...options.data,
                        select: 'addressbookId,name',
                        page: 1,
                        pageSize: 500,
                        sort: [{ field: 'name', dir: 'asc' }]
                    }
                });

                origins.data.forEach((addr: Addressbook) => {
                    (addr as any).info = addr.name + 
                        (addr.town ? ', ' + addr.town : '') + 
                        (addr.zipCode ? ', ' + addr.zipCode : '') + 
                        (addr.countryId ? ', ' + addr.country.iso : '');
                });

                options.success(origins.data);
            }
        },
        requestStart: function(e) {
            if(!e.sender.filter() || e.sender.filter().filters.length === 0){
                e.preventDefault();
            }
        }
    };


	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param codelistManager Codelist manager
	 */ 
    constructor(rpc: Client, codelistManager: CodelistManager) {
        super(rpc);
        this.codelistManager = codelistManager; 
    }    

	/**
	 * ViewModel startup
	 */
    public async startup(): Promise<any>
    {
        // Pokud nemuze do teto sekce, nepustime ho
        if(!this.user.isAllowed('section.finances', 'enter')) {
            throw new BadRequestError('Access denied', 403);
        }
        await super.startup();
        // read full codelists
        this.roadTypes = this.codelistManager.getRoadTypes();
        this.bdpContainerTypes = this.codelistManager.getBdpCntrTypes();
        this.kinds = this.codelistManager.getKinds();
    
        // prefill all values for roadTypes and kinds
        this.filter.roadTypeIds.removeAll();
        this.roadTypes.forEach((rt) => {
            this.filter.roadTypeIds.push(rt.id);
        });
        this.bdpContainerTypes.forEach((bct) => {
            this.filter.roadTypeIds.push(bct.bdpContainerTypeId);
        });
        this.filter.kindIds.removeAll();
        this.kinds.forEach((k) => {
            this.filter.kindIds.push(k.kindId);
        });
        this.responsiblePersons = await this.rpc.call('user.getMain', {
            query: {
                select: 'userId,lastName,firstName,realName',
                pageSize: 500,
                page: 1,
                sort: [{field: 'realName', dir: 'asc'}]
            }
        });

    }

    public async rendered(): Promise<any>
	{
        let gridElement = this.element.find('.results-grid');
        this.resultsGrid = gridElement.kendoGrid(this.resultsGridConfig).data('kendoGrid');
		this.element.on('click', 'a[data-tracedo-detail]', (e) => {
            e.preventDefault();
            if(this.user.isAllowed('entity.tracedo', 'view')) {
                let uid = jQuery(e.target).closest('tr').data('uid');
                let row: any = this.resultsGrid.dataSource.getByUid(uid);
			    TracedoHelpers.detailTracedo(this, 'actionDialog', this.user, {
                    myField: row.myField,
                    myNumber: row.number,
                    id: row.id
                } as TracedoView);
            }
		});        
    }

    /**
     * Generovani reportu (obnova gridu)
     */
    private generate()
    {
        this.resultsGrid.dataSource.read();
    }

	public template = (): HTMLElement => (

		<div>

            <h2 data-bind="i18n: 'nav.transportResults'"></h2>  

            <div className="row">

                <div className="col-xl-3 col-lg-4 col-md-4">

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'roadTypes', i18n: 'common.captions.roadType'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.roadTypeIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'roadTypes', kendoMultiSelect: {
                                placeholder: '-',
                                dataTextField: 'name',
                                dataValueField: 'id',
                                dataSource: $root.roadTypes,
                                value: $root.filter.roadTypeIds,
                                autoClose: false
                            }" className="w-100" />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'containerType', i18n: 'common.captions.containerType'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.bdpContainerTypeIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'kinds', kendoMultiSelect: {
                                placeholder: '-',
                                dataTextField: 'estoneCode',
                                dataValueField: 'bdpContainerTypeId',
                                dataSource: $root.bdpContainerTypes,
                                value: $root.filter.bdpContainerTypeIds,
                                autoClose: false
                            }" className="w-100" />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'kinds', i18n: 'common.captions.kind'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.kindIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'kinds', kendoMultiSelect: {
                                placeholder: '-',
                                dataTextField: 'name',
                                dataValueField: 'kindId',
                                dataSource: $root.kinds,
                                value: $root.filter.kindIds,
                                autoClose: false
                            }" className="w-100" />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'suppliers', i18n: 'common.captions.carrier'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.supplierIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'suppliers', kendoMultiSelect: {
                                placeholder: '-',
                                filter: 'contains',
                                dataTextField: 'name',
                                dataValueField: 'subjectId',
                                dataSource: $root.suppliersDataSource,
                                value: $root.filter.supplierIds,
                                autoClose: false
                            }" className="w-100" />                    
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'customers', i18n: 'common.captions.customer'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.customerIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'customers', kendoMultiSelect: {
                                placeholder: '-',
                                filter: 'contains',
                                dataTextField: 'name',
                                dataValueField: 'subjectId',
                                dataSource: $root.customersDataSource,
                                value: $root.filter.customerIds,
                                autoClose: false
                            }" className="w-100" />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'responsiblePersons', i18n: 'common.captions.responsiblePerson'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.responsiblePersonIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'responsiblePersons', kendoMultiSelect: {
                                placeholder: '-',
                                filter: 'contains',
                                dataTextField: 'realName',
                                dataValueField: 'userId',
                                dataSource: $root.responsiblePersons.data,
                                value: $root.filter.responsiblePersonIds,
                                autoClose: false,
                            }" className="w-100" />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'origins', i18n: 'common.captions.origin'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.originIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'origins', kendoMultiSelect: {
                                placeholder: '-',
                                filter: 'contains',
                                dataTextField: 'name',
                                dataValueField: 'addressbookId',
                                dataSource: $root.originsDestinationsDataSource,
                                value: $root.filter.originIds,
                                autoClose: false
                            }" className="w-100" />                    
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'destinations', i18n: 'common.captions.destination'"></label>
                        <div className="col-auto pe-1">
                            <select className="form-select" data-bind="value: $root.filter.destinationIdsNot, options: $root.isOrIsNot, optionsValue: 'value', optionsText: 'text'"></select>
                        </div>
                        <div className="col ps-0">
                            <input data-bind="uniqueId: 'destinations', kendoMultiSelect: {
                                placeholder: '-',
                                filter: 'contains',
                                dataTextField: 'name',
                                dataValueField: 'addressbookId',
                                dataSource: $root.originsDestinationsDataSource,
                                value: $root.filter.destinationIds,
                                autoClose: false
                            }" className="w-100" />                    
                        </div>
                    </div>


                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'search', i18n: 'common.actions.search'"></label>
                        <div className="col-9">
                            <input type="text" data-bind="uniqueId: 'search', value: $root.filter.search" className="form-control w-100" />                    
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'uzpFrom', i18n: 'common.captions.uzpFrom'"></label>
                        <div className="col-9">
                            <input data-bind="uniqueId: 'uzpFrom', kendoDatePicker: { value: $root.filter.uzpFrom }" className="w-100" />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <label className="col-3 inline col-form-label" data-bind="uniqueFor: 'uzpTo', i18n: 'common.captions.uzpTo'"></label>
                        <div className="col-9">
                            <input data-bind="uniqueId: 'uzpTo', kendoDatePicker: { value: $root.filter.uzpTo }" className="w-100" />
                        </div>
                    </div>

                    <div className="mb-2">
                        <button type="button" className="btn btn-primary" data-bind="i18n: 'common.captions.generate', click: $root.generate.bind($root)"></button>
                    </div>

                    <ko if="$root.truncated">
                        <div className="alert alert-danger" data-bind="html: $root.truncatedOutputMessage"></div>
                    </ko>

                </div>
                <div className="col-xl-9 col-lg-8 col-md-8">

                    <div data-bind="css: { 'd-none': !$root.generated() }">
                        <div className="results-grid grid-autoheight"></div>
                    </div>

                </div>
            </div>

            <view-frame name="actionDialog" />

		</div>

	);

}