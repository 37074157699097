import { i18n } from "i18next-ko";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { h } from "../../../tracejs/src/utils/JSXFactory";
import { Objects } from "../../../tracejs/src/utils/Objects";
import { Tracedo } from "../../entities/Tracedo";
import { KendoHelpers } from "../../model/KendoHelpers";
import { BaseViewModel } from "../common/BaseViewModel";

/**
 * Tracedo chat
 */
@injectable()
export class DataLog extends BaseViewModel<any>
{
	
	/**
	 * Log - grid element
	 */
	private logGridElement: JQuery = null;

	/**
	 * Log - kendo grid
	 */
	private logGrid: kendo.ui.Grid;

	/**
	 * Data Log grid config
	 */
	private logGridConfig: kendo.ui.GridOptions = {
		autoBind: false,
		dataSource: {
			transport: {
				read: async (options: kendo.data.DataSourceTransportReadOptions) => {

					(options.data as any).search = (this.logGrid.dataSource as any).searchText;

					KendoHelpers.replaceRequestData(options.data, '__');

					let response: any = await this.rpc.call('tracedo.getLogs', {
						id: this.settings.tracedoId,
						locale: this.culture.localeShort,
						query: { 
							...options.data,
							select: '*,action(*),changes(*),createdUser(*),issue(*,type(*),category(*))'
						}
					});

					let data: any[] = [];
					response.data.forEach((log: any) => {

						// hodnoty, ktere se zmenily - jako string

						let changesString = '-';
						if(log.changes.length > 0) {
							changesString = '<div class="changes-container">';
							// changesString += '<button class="changes-container-button" type="button"><i class="icon-resize-full"></i></button>';
							changesString += '<table class="changes-table">';
							changesString += '<thead>';
							changesString += '<tr>';
							changesString += '<th>' + i18n.t('common.captions.field') + '</th>';
							changesString += '<th>' + i18n.t('common.captions.oldValue') + '</th>';
							changesString += '<th>' + i18n.t('common.captions.newValue') + '</th>';
							changesString += '</tr>';
							changesString += '</thead>';
							changesString += '<tbody>';
							log.changes.forEach((change: any) => {
								if(change.columnName && !change.hidden) {
									changesString += '<tr>';
									changesString += '<th>' + change.columnName + '</th>';
									changesString += '<td>' + (change.oldValueReadable ?? '') + '</td>';
									changesString += '<td>' + (change.newValueReadable ?? '') + '</td>';
									changesString += '</tr>';
								}
							});
							changesString += '</tbody>';
							changesString += '</table>';
							changesString += '</div>';
						}
						log.changesString = changesString;

						// nezobrazovat, pokud se jedna o zmenu poptavky, ale nejsou zadne pekne hodnoty k vypsani (ne vsechny zmeny se vypisuji, podle columnName == null)
						// if(changesString !== '') {
						data.push(log);
						// }

						let culture = jQuery.inArray(this.culture.locale, ['cs-CZ', 'en-US']) > -1 ? this.culture.locale : 'en-US';
						if (culture === 'cs-CZ') {
							if(log.actionId) {
								log.action.name = log.action.name;
							}
							if(log.issueId) {
								changesString = log.issue.category.nameTranslation + ' - ' + log.issue.type.nameTranslation + ': ';
								changesString += (log.issue.place ? log.issue.place + ', ' : '') + 
										(log.issue.delay ? i18n.t('common.captions.delay') + ': ' + log.issue.delay + 'min, ' : '') + 
										(log.issue.description ? i18n.t('common.captions.description') + ': ' + log.issue.description + ', ' : '');
								log.changesString = changesString;
							}
						}
						else {
							if (log.actionId) {
								log.action.name = log.action.nameTranslation;
							}
							if (log.issueId) {
								changesString = log.issue.category.name + ' - ' + log.issue.type.name + ': ';
								changesString += (log.issue.place ? log.issue.place + ', ' : '') + 
										(log.issue.delay ? i18n.t('common.captions.delay') + ': ' + log.issue.delay + 'min, ' : '') + 
										(log.issue.description ? i18n.t('common.captions.description') + ': ' + log.issue.description + ', ' : '');
								log.changesString = changesString;
							}
						}
					});

					response.data = Objects.flatten(data, '__');

					options.success(response);
				}
			},
			schema: {
				model: {
					fields: {
						'id': { editable: true, type: "string" },
						'createdStamp': { editable: true, type: "date" },
						'createdUser__realName': { editable: true, type: "string" },
						'action__name': { editable: true, type: "string" },
						'action__description': { editable: true, type: "string" }
					}
				},
				data: (d: any) => d.data,
				total: (d: any) => d.total
			},
			sort: { field: "id", dir: "desc" },
			pageSize: 10,
			serverPaging: true,
			serverFiltering: true,
			serverSorting: true
		},
		selectable: false,
		scrollable: true,
		reorderable: true,
		columnMenu: true,
		resizable: true,
		filterable: KendoHelpers.getGridFilter(),
		filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
		columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
		sortable: true,
		pageable: {
			refresh: true
		},
		dataBound: (arg: any) => {
			let dataView = arg.sender.dataSource.view();
			for(let i = 0; i < dataView.length; i++) {
				var item = dataView[i];
				if (item.log__issueId){
					var el = this.logGridElement.find("tr[data-uid=" + item.uid + "]").removeClass("k-alt");
					el.addClass('Clrorange');
				}
			}
		},
		columns: [{
			field: "action__name",
			title: i18n.t('common.captions.action'),
			width: 130,
			filterable: {
				ui: async (element: JQuery) => {
					let types: any[] = await this.rpc.call('logAction.get');
					types.forEach((action: any) => { action.langName = i18n.t('common.actions.' + action.nameTranslation); });
					element.kendoDropDownList({
						dataSource: types,
						optionLabel: i18n.t('common.captions.dropDownSelectValue'),
						dataTextField: "langName",
						dataValueField: "name"
					});
				}
			}
		}, {
			field: "createdStamp", 
			title: i18n.t('common.captions.createdTimestamp'), 
			width: 100,
			format: '{0:dd.MM HH:mm}'
		}, {
			field: "createdUser__realName",
			title: i18n.t('common.captions.createdBy'),
			width: 140
		}, {
			field: "changesString",
			title: i18n.t('common.captions.description'),
			template: '#= changesString #',
			sortable: false,
			filterable: false
		}]
	};

	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		const tracedo: Tracedo = await this.rpc.call('tracedo.getOne', { id: this.settings.tracedoId });
		const resourceName = 'entity.tracedo' + (tracedo.myField === 'spediter' ? 'Spediter' : (tracedo.myField === 'customer' ? 'Customer' : 'Carrier'));

		// Opravneni
		if (!this.user.isAllowed(resourceName, 'viewDataLog')) {
			throw new BadRequestError('Access denied', 403);
		}
	}

	public async rendered(): Promise<any>
	{
		this.logGridElement = this.element.find('[data-grid=dataLog]');
		this.logGrid = this.logGridElement.kendoGrid(this.logGridConfig).data('kendoGrid');
		this.logGrid.dataSource.read();
	}


	public template = (): HTMLElement => (
		<div>
			<div data-grid="dataLog"></div>
		</div>
	);

}
