import {h} from "../../../../tracejs/src/utils/JSXFactory";
import {BaseViewModel} from "../../common/BaseViewModel";
import {injectable} from "tsyringe";
import {KendoHelpers} from "../../../model/KendoHelpers";
import {Objects} from "../../../../tracejs/src/utils/Objects";
import {i18n} from "i18next-ko";
import {BadRequestError} from "../../../../tracejs/src/application/BadRequestError";
import {IGridConfiguratorOptions} from "../../components/GridConfigurator/IGridConfiguratorOptions";
import {GridConfigurator} from "../../components/GridConfigurator/GridConfigurator";
import {BaseDialogViewModel} from "../../common/BaseDialogViewModel";

/**
 * Create subject
 */
@injectable()
export class Create extends BaseDialogViewModel<any> {
    /**edit
     * Knockout Observable for subject data
     */

    private subject: KnockoutObservable<any> = ko.observable(ko.mapping.fromJS({
        'address1': null,
        'approved' : false,
        'attrCarrier' : false,
        'attrPartner' : false,
        'attrSpediter' : false,
        'city': null,
        'code' : null,
        'countryId' : null,
        'deleted' : false,
        'dic': null,
        'email': null,
        'ic': null,
        'ident' : null,
        'isRegistred' : false,
        'name': null,
        'phone': null,
        'postalCode': null,
        'reportsHeader': null,
        'subjectId' : null,
        'subjectCode': null,
        'traceCurrency' : 0,
        'valid' : true,
        'vipCode' : false,
    }));

    /**
     * Countries
     */
    private countries: KnockoutObservableArray<any> = null;

    /**
     * ViewModel Startup
     */
    public async startup(): Promise<any> {
        await super.startup();
        // Pokud nemuze editovat uzivatele
        if (!this.user.isAllowed('entity.subjects', 'manage')) {
            throw new BadRequestError('Access denied', 403);
        }
        let batch = this.rpc.batch();
        batch.call('countries', 'country.get');
        let response: any = await batch.run();
        this.countries = ko.mapping.fromJS(response['countries']) as KnockoutObservableArray<any>;
    }

    // ulozi nastaveni
    public async save(): Promise<boolean> {
        let subject = ko.mapping.toJS(this.subject);
        console.log(subject);
        return await this.rpc.call('subject.save', {subject: subject});
    }


    public dialogTemplate = (): HTMLElement => (
        <div>
            <ko with="$root.subject">

                <div id='orderTabStrip' data-bind="kendoTabStrip: { animation: false }">
                    <ul>
                        <li data-bind="css: 'k-state-active', i18n: 'common.captions.information'"></li>
                    </ul>
                    <div class="k-state-active">

                        <div class="row">
                            <div class="col">

                                <fieldset>
                                    <legend data-bind="i18n: 'common.captions.basicInfo'"></legend>

                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'name', i18n: 'common.captions.name'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: name, uniqueId: 'name'"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'address1', i18n: 'common.captions.address'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: address1, uniqueId: 'address1'"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'city', i18n: 'registration.firmCity'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: city, uniqueId: 'city'"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'postalCode', i18n: 'registration.firmZipCode'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: postalCode, uniqueId: 'postalCode'"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'firmCountry', i18n: 'registration.firmCountry'"></label>
                                        <div className="col-md-8">
                                            <input data-combobox="kendoCombobox" id="firmCountry" type="text"
                                                   className="w-100" data-bind="
												kendoDropDownList: {
													value: countryId, 
													placeholder: i18n.t('common.captions.chooseState'),
													dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
													dataValueField: 'countryId',
													filter: 'contains',
													minLength: 0,
													data: $root.countries()
												}
											"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'ic', i18n: 'registration.firmIco'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: ic, uniqueId: 'ic'"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'dic', i18n: 'registration.firmDic'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: dic, uniqueId: 'dic'"/>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                            <div class="col">

                                <fieldset>
                                    <legend data-bind="i18n: 'common.captions.contactInfo'"></legend>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'email', i18n: 'common.captions.email'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: email, uniqueId: 'email'"/>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'phone', i18n: 'common.captions.phone'"></label>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control"
                                                   data-bind="value: phone, uniqueId: 'phone'"/>
                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset>
                                    <legend data-bind="i18n: 'common.captions.otherInfo'"></legend>
                                    <div className="row mb-2">
                                        <label className="inline col-md-4 col-form-label"
                                               data-bind="uniqueFor: 'code', i18n: 'common.captions.subjectCode'"></label>
                                        <div className="col-md-4">
                                            <input type="text" className="form-control"
                                                   data-bind="value: code, uniqueId: 'subjectCode', enable: vipCode"/>
                                        </div>
                                        <div className="col-md-4">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox"
                                                       data-bind="uniqueId: 'vipCode', checked: vipCode"/>
                                                <label class="form-check-label"
                                                       data-bind="uniqueFor: 'vipCode', i18n: 'common.captions.vipCode'"></label>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                        </div>
                    </div>

                </div>

            </ko>
        </div>
    )
}
