import { injectable } from "tsyringe";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";

import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { IDialogSettings } from "../../common/IDialogSettings";

import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import {i18n} from "i18next-ko";
import {KendoHelpers} from "../../../model/KendoHelpers";
import {Objects} from "../../../../tracejs/src/utils/Objects";

export interface IAddressEditSettings extends IDialogSettings
{
	addressId?: number;
}

/**
 * Addresses - edit
 */
@injectable()
export class Edit extends BaseDialogViewModel<IAddressEditSettings>
{
	/**
	 * Knockout Observable for editing address
	 */
	private address: KnockoutObservable<any> = ko.observable(null);

	/**
	 * Knockout Observable array for ramps
	 */
	private ramps: KnockoutObservable<any> = ko.observable(null);

	private gridElement: JQuery<HTMLElement>;

	private grid: kendo.ui.Grid;

	private gridConfig: kendo.ui.GridOptions;

	private gridConfiguratorViewModel: GridConfigurator;

	/**
	 * Configure grid
	 */
	private configureGrid()
	{
		this.gridConfig = {
			autoBind: true,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
                        if (this.settings.addressId) {
                            let response: any = await this.rpc.call('addressbook.getRampsByAddressBookId', {
                                id: this.settings.addressId,
                                query: {
                                    ...options.data,
                                    select: 'id,name'
                                }
                            });
                            options.success(response);
                        }else{
                            options.success({});
                        }


					},
					update: async (options: kendo.data.DataSourceTransportOptions) => {
						let update: any = await this.rpc.call('addressbook.updateRamp', {
							data: options.data
						});
						options.success(update);
					},
					destroy: async (options: kendo.data.DataSourceTransportOptions) => {
						let remove: any = await this.rpc.call('addressbook.deleteRamp', {
							data: options.data
						});
						if (remove === 0) {
							options.error({code: 500, msg: "destroy error"});
						} else {
							options.success(remove);
						}
					},
					create: async (options: kendo.data.DataSourceTransportOptions) => {
						let save: any = await this.rpc.call('addressbook.insertRamp', {
							id: this.settings.addressId,
							data: options.data
						});
						options.success(save);
					},
					parameterMap: function (options: any, operation: any) {
						if (operation !== "read" && options.models) {
							return {models: kendo.stringify(options.models)};
						}
					}
				},
				error: function (err: any) {
					let that = this;
					if (err.xhr.msg === "destroy error") {
						kendo.alert(i18n.t('settings.addresses.ramps.alertUsed'));
						that.cancelChanges();
					}
				},
				schema: {
					model: {
						id: "id",
						fields: {
							'id': {editable: false, type: 'number', nullable: true},
							'name': {editable: true, type: 'string'},
						}
					}
				},
				batch: false,
				sort: {field: 'name', dir: 'asc'},
				pageSize: 20,
				serverPaging: false,
				serverFiltering: false,
				serverSorting: false
			},
			editable: 'inline',
			selectable: 'row',
			scrollable: false,
			reorderable: false,
			columnMenu: false,
			resizable: true,
			sortable: true,
			pageable: false,
			toolbar: ["create"],
			messages: {
				commands: {
					edit: "",
					destroy: ""
				}
			},
			columns: [
				{ field: "id", title: "ID", hidden: true},
				{ field: "name", title: i18n.t('common.captions.name'), width: 150 },
				{ command: ["edit", "destroy"], title: "&nbsp;", width: "180px" }
			],
		};
	}

	/**
	 * Countries codelist
	 */
	private countries: KnockoutObservableArray<any> = ko.observableArray();

	/**
	 * ViewModel Startup
	 */
	public async startup() {
		// Pokud nemuze editovat uzivatele
		if (this.settings.addressId && !this.user.isAllowed('entity.address', 'edit')) {
			throw new BadRequestError('Access denied', 403);
		}

		if (!this.settings.addressId && !this.user.isAllowed('entity.address', 'create')) {
			throw new BadRequestError('Access denied', 403);
		}

		await super.startup();

		let batch = this.rpc.batch();
		batch.call('countries', 'country.get', {
			query: {sort: [{field: 'descr', dir: 'asc'}]},
			select: 'countryId,descr,nameEn'
		});
		if (this.settings.addressId) {
			batch.call('address', 'addressbook.getOne', {id: this.settings.addressId, select: '*,country(*),addressbookRamps(*)'});
		}

		//stažení všech poli pro notifikace
		let response: any = await batch.run();

		if (response['address']) {
			this.address(ko.mapping.fromJS(response['address']));
		} else {
			this.address(ko.mapping.fromJS({
				name: '',
				street: '',
				street2: '',
				town: '',
				zipCode: '',
				countryId: null
			}));
		}

		this.countries(response['countries']);
		if (response['ramps']) {
			this.ramps(response['ramps']);
		}
		// Configure GRID
		this.configureGrid();
	}


	/**
	 * Render
	 */
	public async rendered()
	{
		// grid element
		this.gridElement = this.element.find('[data-grid=addresses]');

		/// initialize grid
		this.gridElement.kendoGrid(this.gridConfig);

		// Kendo GRID reference
		this.grid = this.gridElement.data('kendoGrid');

		KendoHelpers.allowFilterEmpty(this.gridElement);
	}


	/**
	 * Ulozeni nastaveni
	 */
	public async save(): Promise<void>
	{
		// pack do javascript objectu
		let ramps = await ko.mapping.toJS(this.ramps);
		console.log(ramps);
		// pack do javascript objectu
		let addressbook = ko.mapping.toJS(this.address);

		// ulozit
		let response = await this.rpc.call('addressbook.save', { addressbook: addressbook });

		//pokud se jedna o zalozeni, priradime firmu
		// this.
		// $(this).trigger('saved', [response['address'].subjectId]);

		// }).fail((error: any) => {
		// 	if (error.i18nFrontEndMessage) {
		// 		alert($.i18n.t('system.alerts.' + error.i18nFrontEndMessage));
		// 	} else {
		// 		alert($.i18n.t('system.alerts.error'));
		// 	}
		// });
	}


	public dialogTemplate = (): HTMLElement => (
		<div>
			<ko with="$root.address">
				<div id='orderTabStrip' data-bind="kendoTabStrip: { animation: false }">
					<ul>
						<li data-bind="css: 'k-state-active', i18n: 'common.captions.information'"></li>
						<li data-bind="i18n: 'settings.addresses.ramps.title'"></li>
					</ul>
					<div className="k-state-active">
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'name', i18n: 'trade.demand.edit.addressName'"></label>
							<div className="col-md-10">
								<input type="text" className="form-control" data-bind="value: name, uniqueId: 'name'"/>
							</div>
						</div>
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'street', i18n: 'trade.demand.edit.addressStreet'"></label>
							<div className="col-md-10">
								<input type="text" className="form-control"
									   data-bind="value: street, uniqueId: 'street'"/>
							</div>
						</div>
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'street2', i18n: 'trade.demand.edit.addressStreet2'"></label>
							<div className="col-md-10">
								<input type="text" className="form-control"
									   data-bind="value: street2, uniqueId: 'street2'"/>
							</div>
						</div>
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'town', i18n: 'trade.demand.edit.addressCity'"></label>
							<div className="col-md-10">
								<input type="text" className="form-control" data-bind="value: town, uniqueId: 'town'"/>
							</div>
						</div>
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'zipCode', i18n: 'trade.demand.edit.addressZipCode'"></label>
							<div className="col-md-10">
								<input type="text" className="form-control"
									   data-bind="value: zipCode, uniqueId: 'zipCode'"/>
							</div>
						</div>
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'countryId', i18n: 'trade.demand.edit.addressCountry'"></label>
							<div className="col-md-10">
								<input type="text" className="form-control" data-bind="
							uniqueId: 'countryId',
							kendoComboBox: {
								data: $root.countries,
								placeholder: i18n.t('common.captions.chooseState'),
								dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
								dataValueField: 'countryId',
								filter: 'contains',					
								value: $data.countryId
							}
						"/>
							</div>
						</div>
						<div className="row mb-2">
							<label className="inline col-md-2 col-form-label"
								   data-bind="uniqueFor: 'defaultNote', i18n: 'common.captions.defaultNote'"></label>
							<div className="col-md-10">
								<textarea className="form-control"
										  data-bind="uniqueId: 'defaultNote', value: $data.defaultNote"/>
							</div>
						</div>
					</div>
                        <div>
                            <div data-grid="addresses"></div>
                            <view-frame name="edit"/>
                        </div>
				</div>
			</ko>
		</div>
	);


}
