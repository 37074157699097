import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import {i18n, i18nextkoResourceStore} from "i18next-ko";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { Users} from "../../Settings/Users/Users";
import { Edit as UserEdit} from "../../../views/Settings/Users/Edit";
import { ConnectSubject } from "./ConnectSubject";
import {User} from "../../../../tracejs/src/security/User";
import {ConnectWithCarrier} from "../../Settings/Carriers/ConnectWithCarrier";

/**
 * Edit subject
 */
@injectable()
export class Edit extends BaseDialogViewModel<any>
{
	/**
	 * Knockout Observable for subject data
	 */
	private subject: KnockoutObservable<any> = null;

	/**
	 * Countries
	 */
	private countries: KnockoutObservableArray<any> = null;

	// GRID element
	private userGridElement: JQuery<HTMLElement>;

	// GRID element
	private subjectGridElement: JQuery<HTMLElement>;

	// Kendo grid
	private userGrid: kendo.ui.Grid;

	// Kendo grid
	private subjectGrid: kendo.ui.Grid;

	// Grid Config
	private userGridConfig: kendo.ui.GridOptions;

	// Grid Config
	private subjectGridConfig: kendo.ui.GridOptions;


	private tabstripInited: KnockoutObservable<boolean> = ko.observable(false);

	/**
	 * Configure kendo GRID for Users
	 */
	private userConfigureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var userToolbarConfig = [];
		if(this.user.isAllowed('entity.user', 'create')) {
			userToolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="create"><i class="icon-plus" ></i><span> ' + i18n.t('common.actions.create') + '</span></button>' });
		}

		if(this.user.isAllowed('entity.user', 'edit')) {
			userToolbarConfig.push({ template: '<button type="button" class="btn btn-outline-primary" data-action="update"><i class="icon-edit" ></i><span> ' + i18n.t('common.actions.edit') + '</span></button>' });
		}

		if(this.user.isAllowed('entity.user', 'delete')) {
			userToolbarConfig.push({ template: '<button type="button" class="btn btn-outline-danger" data-action="delete" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>' });
		}

		this.userGridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
						options.data.search = (this.userGrid.dataSource as any).searchText;
						let users: any = await this.rpc.call('user.getAllUsersBySubjectId',
							{
								query: {
									...options.data,
									select: 'userId,ident,loginname,firstName,lastName,email,companyFunction,phone,disabled,userRoles(role(*))'
								},
								subjectId: this.settings.subjectId
							});
						options.success(users);
					}
				},
				schema: {
					model: {
						id: 'userId',
						fields: {
							'userId': { editable: false, type: "number" },
							'ident': { editable: false, type: "string" },
							'email': { editable: false, type: "string" },
							'loginname': { editable: false, type: 'string' },
							'firstName': { editable: false, type: "string" },
							'lastName': { editable: false, type: "string" },
							'companyFunction': { editable: false, type: "string" },
							'phone': { editable: false, type: "string" },
							'disabled': { editable: false, type: "boolean" }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "lastName", dir: "asc" },
				pageSize: 100,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: false,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: userToolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.userGrid);
				this.userGridElement.find('[data-action=delete]').prop('disabled', true);
				this.userGridElement.find('[data-action=update]').prop('disabled', true);
			},
			change: (arg: kendo.ui.GridChangeEvent) => {
				var selected = this.userGrid.select().length > 0;
				this.userGridElement.find('[data-action=delete]').prop('disabled', !selected);
				this.userGridElement.find('[data-action=update]').prop('disabled', !selected);
			},
			columns: [{
				field: "ident",
				title: i18n.t('system.user.edit.ident'),
				width: 60
			}, {
				field: "loginname",
				title: i18n.t('system.user.profile.username'),
				width: 120
			}, {
				field: "lastName",
				title: i18n.t('system.user.profile.lastName'),
				width: 100
			}, {
				field: "firstName",
				title: i18n.t('system.user.profile.firstName'),
				width: 100
			}, {
				field: "email",
				title: i18n.t('system.user.profile.email'),
				width: 180
			}, {
				field: "companyFunction",
				title: i18n.t('common.captions.companyFunction'),
				width: 120
			}, {
				field: "phone",
				title: i18n.t('system.user.profile.phone'),
				width: 80
			}, {
				field: "roles",
				sortable: false,
				filterable: false,
				template: (data: any) => {
					let roles: string[] = [];
					data.userRoles.forEach((ur: any) => {
						roles.push(ur.role.name);
					});
					return roles.join(', ');
				}
			}]
		};
	}

	/**
	 * Configure kendo GRID for subjects
	 */
	private subjectConfigureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var subjectToolbarConfig = [];
		subjectToolbarConfig.push({template: '<button type="button" class="btn btn-outline-primary" data-action="create-subject-subject"><i class="icon-plus" ></i><span> ' + i18n.t('common.actions.connectToSubject') + '</span></button>'});
		subjectToolbarConfig.push({template: '<button type="button" class="btn btn-outline-danger" data-action="delete-subject-subject" title="' + i18n.t('common.actions.cancel') + '"><i class="icon-trash"></i></button>'});

		this.subjectGridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {
						options.data.search = (this.subjectGrid.dataSource as any).searchText;

						let subjects: any = await this.rpc.call('subject.getAllVazBySourceId', {
							query: {
								...options.data,
								select: '*, subjectUsers(userId), subject(*), country(*)'
							},
							subjectId: this.settings.subjectId
						});
						options.success(subjects);
					},
					update: async (options: kendo.data.DataSourceTransportOptions) => {
						let update: any = await this.rpc.call('subject.updateVaz', {
							id: options.data.id,
							data: {
								autoApprovePrice: options.data.autoApprovePrice,
							},
						});
						options.success(update);
					},
				},
				schema: {
					model: {
						id: "id",
						fields: {
							'id' :{ editable: false, type: "number" },
							'active': { editable: false, type: "boolean" },
							'subject.name': { editable: false, type: "string" },
							'subject.address1': { editable: false, type: "string" },
							'subject.ic': { editable: false, type: "string" },
							'subject.email': { editable: false, type: "string" },
							'subject.phone': { editable: false, type: "string" },
							'autoApprovePrice': { editable: true, type: 'boolean' },
							'attr': { editable: false, type: "string" }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "lastName", dir: "asc" },
				pageSize: 100,
				serverPaging: true,
				serverFiltering: false,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: false,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: subjectToolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.subjectGrid);
				this.subjectGridElement.find('[data-action=delete]').prop('disabled', true);
				this.subjectGridElement.find('[data-action=update]').prop('disabled', true);
			},
			change: (arg: kendo.ui.GridChangeEvent) => {
				var selected = this.subjectGrid.select().length > 0;
				this.subjectGridElement.find('[data-action=delete]').prop('disabled', !selected);
				this.subjectGridElement.find('[data-action=update]').prop('disabled', !selected);
			},
			columns: [
				{
					field: "active",
					title: i18n.t('common.captions.active'),
					template: "<input type='checkbox' #= active ? 'checked=checked' : '' # disabled='disabled' ></input>",
					width: 50
				}, {
					field: "subject.name",
					title: i18n.t('common.captions.name'),
					width: 150,
					template: (data: any) => {
						if(data.subject) {
							return data.subject.name;
						}
						if(data.subjectData) {
							return '<em class="opacity-50">' + data.subjectData.firmName + '</em>';
						}
						return '-';
					}
				}, {
					field: 'subject.address1',
					title: i18n.t('common.captions.address'),
					width: 200,
					template: (data: any) => {
						if(data.subject) {
							return data.subject.address1;
						}
						if(data.subjectData) {
							return '<em class="opacity-50">' + data.subjectData.firmStreet + ', ' + data.subjectData.firmZipCode + ' ' + data.subjectData.firmCity + '</em>';
						}
						return '-';
					}
				}, {
					field: "subject.ic",
					title: i18n.t('registration.firmIco'),
					width: 90,
					template: (data: any) => {
						if(data.subject) {
							return data.subject.ic;
						}
						if(data.subjectData) {
							return '<em class="opacity-50">' + data.subjectData.firmIco + '</em>';
						}
						return '-';
					}
				}, {
					field: "subject.email",
					title: i18n.t('registration.email'),
					width: 250,
					template: (data: any) => {
						if(data.subject) {
							return data.subject.email ?? '';
						}
						if(data.subjectData) {
							return '<em class="opacity-50">' + (data.subjectData.email ?? '') + '</em>';
						}
						return '-';
					}
				}, {
					field: "subject.phone",
					title: i18n.t('registration.phone'),
					width: 100,
					template: (data: any) => {
						if(data.subject) {
							return data.subject.phone ?? '';
						}
						if(data.subjectData) {
							return '<em class="opacity-50">' + (data.subjectData.phone ?? '') + '</em>';
						}
						return '-';
					}
				}, {
					field: "autoApprovePrice",
					title: i18n.t('common.captions.autoApprovePrice'),
					template: (data: any) => (data.autoApprovePrice ? i18n.t('common.captions.yes') : '-'),
					width: 80
				}, {
					field: "attr",
					title: i18n.t('common.captions.role'),
					template: (data: any) => i18n.t('common.captions.subjectsSubjectsRole.' + data.attr),
					width: 150
				}, {
					command: ["edit"],
					title: "&nbsp;",
					width: "250px"
				},
			],
			editable: "inline",
		};
		console.log(this.subjectGridConfig);
	}

	/**
	 * ViewModel Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		// Pokud nemuze editovat uzivatele
		if(!this.user.isAllowed('entity.subjects', 'manage')) {
			throw new BadRequestError('Access denied', 403);
		}
		// Zatim nelze subjekty touto cestou zakladat
		if(!this.settings.subjectId) {
			throw new BadRequestError('Creation of the subject is not implemented yet', 401);
		}
		let batch = this.rpc.batch();
		batch.call('countries', 'country.get');
		if(this.settings.subjectId) {
			// uzivatel k editaci
			batch.call('subject', 'subject.getOne', { id: this.settings.subjectId });
		}
		let response: any = await batch.run();
		this.countries = ko.mapping.fromJS(response['countries']) as KnockoutObservableArray<any>;
		if(response['subject']) {
			this.subject = ko.mapping.fromJS(response['subject']);
		}
		this.userConfigureGrid();
		this.subjectConfigureGrid();
	}

	public async rendered(): Promise<any>
	{
		this.element.find('.subject-edit-tab-strip').kendoTabStrip({ animation: false });
		this.tabstripInited(true);

		// GRID element for Users
		this.userGridElement = this.element.find('[data-grid=users]');
		// initialize grid and store reference
		this.userGrid = this.userGridElement.kendoGrid(this.userGridConfig).data('kendoGrid');

		// BIND action buttons
		//this.gridElement.on('dblclick', 'tbody tr', this.itemDetailHandler.bind(this));
		this.userGridElement.on('click', '[data-action=delete]', this.deleteUser.bind(this));
		this.userGridElement.on('click', '[data-action=create]', this.insertHandler.bind(this));
		this.userGridElement.on('click', 'button[data-action=update]', this.updateHandler.bind(this));
		this.userGridElement.on('dblclick', 'tbody tr', this.updateHandler.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.userGridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.userGrid,
			name: 'User.Management'
		} as IGridConfiguratorOptions);

		// GRID element for Subjects
		this.subjectGridElement = this.element.find('[data-grid=subjectssubjects]');
		// initialize grid and store reference
		this.subjectGrid = this.subjectGridElement.kendoGrid(this.subjectGridConfig).data('kendoGrid');

		// BIND action buttons
		this.subjectGridElement.on('click', '[data-action=delete-subject-subject]', this.deleteSubjectSubject.bind(this));
		this.subjectGridElement.on('click', '[data-action=create-subject-subject]', this.insertSubjectSubjectHandler.bind(this));
		// this.subjectGridElement.on('click', 'button[data-action=update-subject-subject]', this.updateSubjectSubjectHandler.bind(this));
		//this.subjectGridElement.on('dblclick', 'tbody tr', this.updateHandler.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.subjectGridElement);

		// load grid configurator
		let vm2 = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf2', {
			grid: this.subjectGrid,
			name: 'User.Management'
		} as IGridConfiguratorOptions);
	}


	/**
	 * Opens dialog for user insert
	 */
	public async insertHandler()
	{
		await this.userDialogHandler(null, this.settings.subjectId);
	}

	/**
	 * Opens dialog for user update
	 */
	public async updateHandler() {
		let user: any = this.userGrid.dataItem(this.userGrid.select());
		await this.userDialogHandler(user.userId, this.settings.subjectId)
	}

	/**
	 * Delete Subject connection with another Subject
	 */
	private async deleteSubjectSubject(): Promise<any> {
		let removeSubject: any = this.subjectGrid.dataItem(this.subjectGrid.select());
		let yesNo = await this.confirmDialog(i18n.t('system.question.deleteSubjectConnection'));
		if (yesNo) {
			await this.rpc.call('subject.deleteVazById', {vazId: removeSubject.id});
			this.subjectGrid.dataSource.read();
		}
	}

	/**
	 * Opens dialog for connect selected subject with another subject
	 */
	public async insertSubjectSubjectHandler()
	{
		await this.subjectSubjectDialogHandler(this.settings.subjectId);
	}

	/**
	 * Delete user
	 */
	private async deleteUser(): Promise<any>
	{
		let user: any = this.userGrid.dataItem(this.userGrid.select());
		let yesNo = await this.confirmDialog(i18n.t('system.question.deleteUser'));
		if(yesNo) {
			await this.rpc.call('user.delete', { userId: user.userId });
			this.userGrid.dataSource.read();
		}
	}

	/**
	 * Save Subject data
	 */
	public async save(): Promise<any>
	{
		let subject = ko.mapping.toJS(this.subject);
		return await this.rpc.call('subject.save', { subject: subject });
	}

	/**
	 * Opens dialog for user insert/update
	 */
	public async userDialogHandler(userId: number = null, customerId: number = null)
	{
		await this.loadViewFrame<UserEdit>(UserEdit, 'edit', {
			userId: userId,
			customerId: customerId,
			dialog: {
				modal: true,
				width: 1024,
				height: 768,
				title: userId ? i18n.t('system.user.edit.editUser') : i18n.t('system.user.edit.addUser'),
				buttons: (vm: Edit, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => {
								window.close();
							}
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: async () => {

								let result = await vm.save();
								if(result) {
									window.close();
									this.userGrid.dataSource.read();
								}
							}
						}
					];
				}
			}
		});
	}

	/**
	 * Opens dialog for connection subject with another subject
	 */
	public async subjectSubjectDialogHandler(sourceId: number = null)
	{
		await this.loadViewFrame<ConnectSubject>(ConnectSubject, 'subjectsSubjectsEdit', {
			// userId: userId,
			sourceId: sourceId,
			dialog: {
				modal: true,
				width: 700,
				height: 400,
				 title: i18n.t('common.captions.connectWithSubject'),
				buttons: (vm: ConnectSubject, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => {
								window.close();
							}
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: async () => {
								let result = await vm.save();
								if(result) {
									window.close();
									this.subjectGrid.dataSource.read();
								}
							}
						}
					];
				}
			}
		});
	}

	public dialogTemplate = (): HTMLElement => (
		<div>
			<ko with="$root.subject">

				<div className="subject-edit-tab-strip">
					<ul>
						<li data-bind="css: $root.settings.activateList === null ? 'k-state-active' : '', i18n: 'common.captions.information'"></li>
						<li data-bind="css: $root.settings.activateList === 2 ? 'k-state-active' : '', i18n: 'common.captions.users'"></li>
						<li data-bind="css: $root.settings.activateList === 3 ? 'k-state-active' : '', i18n: 'common.captions.subjects'"></li>
					</ul>

					{/*Edit form this selected Subject*/}
					<div className="k-state-active">

						<div className="row">
							<div className="col-md-3">

								<fieldset>
									<legend data-bind="i18n: 'common.captions.basicInfo'"></legend>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'name', i18n: 'common.captions.name'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: name, uniqueId: 'name'"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'address1', i18n: 'common.captions.address'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: address1, uniqueId: 'address1'"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'city', i18n: 'registration.firmCity'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: city, uniqueId: 'city'"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'postalCode', i18n: 'registration.firmZipCode'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: postalCode, uniqueId: 'postalCode'"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="col-md-4 col-form-label" data-bind="uniqueFor: 'firmCountry', i18n: 'registration.firmCountry'"></label>
										<div className="col-md-8">
											<input data-combobox="kendoCombobox" id="firmCountry" type="text" className="w-100" data-bind="
												kendoDropDownList: {
													value: countryId,
													placeholder: i18n.t('common.captions.chooseState'),
													dataTextField: i18n.t('edit.poItem.metOriginCountryName'),
													dataValueField: 'countryId',
													filter: 'contains',
													minLength: 0,
													data: $root.countries()
												}
											"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'ic', i18n: 'registration.firmIco'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: ic, uniqueId: 'ic'"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'dic', i18n: 'registration.firmDic'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: dic, uniqueId: 'dic'"/>
										</div>
									</div>
								</fieldset>
								<fieldset>
									<legend data-bind="i18n: 'common.captions.contactInfo'"></legend>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'email', i18n: 'common.captions.email'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: email, uniqueId: 'email'"/>
										</div>
									</div>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'phone', i18n: 'common.captions.phone'"></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: phone, uniqueId: 'phone'"/>
										</div>
									</div>
								</fieldset>

							</div>
							<div className="col-md-3">

								<fieldset>
									<legend data-bind="i18n: 'common.captions.otherInfo'"></legend>
									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'code', i18n: 'common.captions.subjectCode'"></label>
										<div className="col-md-4">
											<input type="text" className="form-control" data-bind="value: code, uniqueId: 'subjectCode', enable: vipCode"/>
										</div>
										<div className="col-md-4">
											<div className="form-check form-switch">
												<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'vipCode', checked: vipCode"/>
												<label className="form-check-label" data-bind="uniqueFor: 'vipCode', i18n: 'common.captions.vipCode'"></label>
											</div>
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'firmTradeCurrency', i18n: 'registration.firmTradeCurrency'"></label>
										<div className="col-md-7">
											<input type="text" className="form-control" data-bind="
												uniqueId: 'firmTradeCurrency',
												kendoNumericTextBox: {
													value: tradeCurrency,
													decimals: 2
												}"/>
										</div>
										<div className="col-md-1 p-0">
											<i data-bind="kendoTooltip: { content: i18n.t('registration.firmTradeCurrencyTipText') }" className="icon-help-circled" style="font-size:20px;line-height:25px;"></i>
										</div>
									</div>
									<div className="form-check form-switch mb-2">
										<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'isRegistered', checked: isRegistered"/>
										<label className="form-check-label" data-bind="uniqueFor: 'isRegistered', i18n: 'common.captions.isRegistered'"></label>
									</div>
									<div className="form-check form-switch mb-4">
										<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'approved', checked: approved"/>
										<label className="form-check-label" data-bind="uniqueFor: 'approved', i18n: 'common.captions.approved'"></label>
									</div>

								</fieldset>

								<fieldset>
									<legend data-bind="i18n: 'common.captions.attributes'"></legend>

									<div className="form-check form-switch mb-2">
										<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'attrSpediter', checked: attrSpediter"/>
										<label className="form-check-label" data-bind="uniqueFor: 'attrSpediter', i18n: 'common.captions.subjectRole.spediter'"></label>
									</div>
									<div className="form-check form-switch mb-2">
										<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'attrCarrier', checked: attrCarrier"/>
										<label className="form-check-label" data-bind="uniqueFor: 'attrCarrier', i18n: 'common.captions.subjectRole.carrier'"></label>
									</div>
									<div className="form-check form-switch mb-4">
										<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'attrPartner', checked: attrPartner"/>
										<label className="form-check-label" data-bind="uniqueFor: 'attrPartner', i18n: 'common.captions.subjectRole.customer'"></label>
									</div>

								</fieldset>


							</div>
							<div className="col-md-6">

								<ko if="$root.tabstripInited">
									<fieldset>
										<legend data-bind="i18n: 'common.captions.reportsHeader'"></legend>
										<textarea className="form-control reports-header-textarea" data-bind="kendoEditor: { value: reportsHeader, tools: ['bold', 'italic'] }" />
									</fieldset>
								</ko>

							</div>
						</div>
					</div>

					{/* GRID Subject Users*/}
					<div>
						<h2 data-bind="i18n: 'nav.userManagement'"></h2>
						<view-frame name="gridconf" className="mb-2"/>
						<view-frame name="edit" className="mb-2"/>
						<div data-grid="users" ></div>
					</div>

					{/* GRID Subjects Subjects*/}
					<div>
						<h2 data-bind="i18n: 'nav.subjectVazManagement'"></h2>
						<view-frame name="gridconf2" className="mb-2"/>
						<div data-grid="subjectssubjects" ></div>
						<view-frame name="subjectsSubjectsEdit" className="mb-2"/>
					</div>
				</div>
			</ko>
		</div>
	)
}
