import { i18n } from "i18next-ko";
import moment from "moment";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { BaseViewModel } from "../../common/BaseViewModel";
import { Uploader } from "./Uploader";
import { IUploaderResult } from "./IUploaderResult";
import { Tracedo } from "../../../entities/Tracedo";

/**
 * FileManager
 */
@injectable()
export class FileManager extends BaseViewModel<any>
{

	/**
	 * Files
	 */
	private files: KnockoutObservableArray<any> = ko.observableArray();

	/**
	 * Associative object FileId => Download Link
	 */
	private links: {};

	/**
	 * Uploader instance
	 */
	private uploader: Uploader;


	private resourceName: string;


	private userTypesForUpload: any[];
	private userTypesForDownload: any[];


	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		const tracedo: Tracedo = await this.rpc.call('tracedo.getOne', { id: this.settings.id });

		this.resourceName = 'entity.tracedo' + (tracedo.myField === 'spediter' ? 'Spediter' : (tracedo.myField === 'customer' ? 'Customer' : 'Carrier'));
		
		let batch2 = this.rpc.batch();
		batch2.call('userTypesForUpload', 'file.getUserTypes', { type: this.settings.type, action: 'upload', resource: this.resourceName });
		batch2.call('userTypesForDownload', 'file.getUserTypes', { type: this.settings.type, action: 'download', resource: this.resourceName });
		let response2: any = await batch2.run();


		this.userTypesForUpload = response2['userTypesForUpload'];
		this.userTypesForDownload = response2['userTypesForDownload'];

		return this.reloadFiles();
	}
	
	/**
	 * Reload FileList
	 */
	public async reloadFiles(): Promise<any>
	{
		let files: any = await this.rpc.call('file.getFor', {
			entity: this.settings.type,
			entityId: this.settings.id,
			query: {
				select: 'createdStamp,type(*),usertype(*,type(*)),latestVersion(*),createdUser,fileId,createdUserId,name,fileExt,fileSize,usertype(ident,name),createdUser(ident,firstName,lastName)'
			}
		});

		// Get IDS of files
		let ids: number[] = [];
		files.forEach((f: any) => {
			f.createdStamp = f.createdStamp ? kendo.parseDate(f.createdStamp) : null;
			ids.push(f.fileId);
		});

		this.links = {};
		if(ids.length > 0) {
			let links = await this.rpc.call('file.getDownloadLinks', { ids: ids });
			this.links = links;
		}

		this.files(files);
	}

	/**
	 * Render
	 */
	public async rendered(): Promise<any>
	{
		if(this.userTypesForUpload.length > 0) {
			let vm = await this.loadViewFrame<Uploader>(Uploader, 'uploader-frame', { multiple: true, type: this.settings.type, userTypes: this.userTypesForUpload });
			this.uploader = vm;
		}
	}

	/**
	 * Delete given file
	 * @param file 
	 */
	private async deleteFile(file: any)
	{
		let yesNo = await this.confirmDialog(i18n.t('common.captions.reallyDeleteFile'));
		if(yesNo) {
			await this.rpc.call('file.delete', { id: file.fileId });
			this.reloadFiles();
		}
	}

	/**
	 * Save
	 */
	public async save(): Promise<any>
	{
		let files: IUploaderResult[] = await this.uploader.upload();

		let uploadedfiles: any = [];
		files.forEach((file) => {
			var uploadedfile = {
				fid: file.fid,
				name: file.file.name,
				size: file.file.size,
				type: file.file.type,
				userTypeId: file.userTypeId
			};
			uploadedfiles.push(uploadedfile);
		});

		if(uploadedfiles.length > 0) {
			await this.rpc.call('file.uploadFile', {
				entity: this.settings.type,
				entityId: this.settings.id,
				files: uploadedfiles
			});

			await this.reloadFiles();
		}
	}

	/**
	 * Template
	 */
	public template = (): HTMLElement => (
		<div>
			<button style="position:absolute;right:10px;top:44px;" type="button" className="btn btn-outline-primary" data-bind="click: $root.reloadFiles.bind($root)"><i className="k-icon k-i-reload"></i></button>

			<ko if="$root.files().length > 0">
				<table className="table">
					<thead>
						<tr>
							<th></th>
							<th data-bind="i18n: 'common.captions.createdTimestamp'"></th>
							<th data-bind="i18n: 'common.captions.fileType'"></th>
							<th data-bind="i18n: 'common.captions.fileName'"></th>
							<th data-bind="i18n: 'common.captions.fileSize'"></th>
							{/*<!--<th data-bind="i18n: 'common.captions.fileVersion'"></th>-->*/}
							<th data-bind="i18n: 'common.captions.createdBy'"></th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody data-bind="foreach: $root.files">
						<tr>
							<td className="align-middle"><i data-bind="css: fileExt ? 'left margined tiniest file ' + fileExt.toLowerCase() : '-'"></i></td>
							<td className="align-middle" data-bind="text: kendo.toString(createdStamp, i18n.t('common.formats.kendoDate')) + ' ' + kendo.toString(createdStamp, i18n.t('common.formats.kendoTime'))"></td>
							<td className="align-middle" data-bind="text: usertype ? i18n.t('common.captions.userTypes.' + usertype.ident.toLocaleLowerCase()) : '-', attr: { title: usertype ? usertype.name : '-', href: $root.links[fileId]+'&forceDownload=0' }"></td>
							<td className="align-middle" style="max-width: 310px;overflow-wrap: break-word;"><a target="_blank" href="#" data-bind="text: name, attr: { href: $root.links[fileId]+'&forceDownload=0' }"></a></td>
							<td className="align-middle" data-bind="filesize: fileSize"></td>
							{/*<!--<td data-bind="text: latestVersion ? latestVersion.versionPost : '-' "></td>-->*/}
							<td className="align-middle" data-bind="
									text: createdUser ? createdUser.firstName + ' ' + createdUser.lastName : '-', 
									attr: { title: createdUser ? createdUser.ident : '-' }
								"></td>
							<td className="w-1 align-middle">
								<a target="_blank" className="btn btn-outline-primary" href="#" data-bind="attr: { href: $root.links[fileId] }, i18n: 'common.actions.download'"></a>
							</td>
							<td className="w-1 align-middle">
								<ko if="createdUserId == $root.user.id">
									<a className="btn btn-outline-danger" title="' + i18n.t('common.actions.cancel') + '" data-bind="click: $root.deleteFile.bind($root)"><i className="icon-trash"></i></a>
								</ko>
							</td>
						</tr>
					</tbody>
				</table>
			</ko>

			<ko if="$root.files().length <= 0">
				<p className="text-center"><em data-bind="i18n: 'common.captions.noDocuments'"></em></p>
			</ko>

			<hr style="border-color: #aaaaaa;" />

			<ko if="$root.userTypesForUpload.length > 0">

				<view-frame name="uploader-frame" className="mb-4" />

				<button data-bind="click: $root.save.bind($root) " className="btn btn-outline-primary">
					<i className="icon-edit"></i> <span data-bind="i18n: 'common.actions.upload'"></span>
				</button>

			</ko>

		</div>

	);




}