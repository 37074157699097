import { IIdentity } from './IIdentity';

/**
 * Identity class
 */
export class Identity implements IIdentity {

	/** ID */
	private _id: any;

	/** Roles */
	private _roles: string[];

	/** User data */
	private _data: any;


	/** Getter for ID */
	get id(): any {
		return this._id;
	}

	/** Setter for ID */
	set id(value) {
		this._id = value;
	}


	/** Getter for roles */
	get roles(): any {
		return this._roles;
	}

	/** Setter for roles */
	set roles(value) {
		this._roles = value;
	}


	/** Getter for Identity DATA */
	get data() {
		return this._data;
	}


	/**
	 * Konstruktor
	 * @param  mixed   identity ID
	 * @param  mixed   roles
	 * @param  array   user data
	 */
	constructor(id: string|number, roles: string[] = null, data: any = null) {
		this._id = id;
		this._roles = roles;
		this._data = data
	}

	/**
	 * Returns the ID of user.
	 * @return mixed
	 */
	getId() {
		return this._id;
	}

	/**
	 * Returns a list of roles that the user is a member of.
	 * @return array of roles
	 */
	getRoles(): any[] {
		return this._roles;
	}

	/**
	 * Returns a list of roles that the user is a member of.
	 * @return array of roles
	 */
	getData(): any[] {
		return this._data;
	}		

}
