import { FlashMessenger } from '../ui/FlashMessenger';
import { Application } from './Application';
import { User } from '../security/User';
import { Router } from './Router';
import { IRoute } from './IRoute';
import { ViewModelFactory } from './ViewModelFactory';

import { InjectionToken } from 'tsyringe';
import { IViewModelSettings } from './IViewModelSettings';
import { Culture } from './Culture';

import { IRouteParams } from './IRouteParams';
import { IQueryParams } from './IQueryParams';
import { IRouteMatchResult } from './IRouteMatchResult';

/**
 * TraceJS DI Container
 */
export abstract class ViewModel<T>
{

	/**
	 * Internal ViewModel Counter
	 */
	private static _internalViewModelCounter: number = 0;

	// Internal ViewModel Number
	private _viewModelNumber: number;

	/**
	 * Routes collection
	 */
	private _routes: IRoute[];

	/**
	 * Persistent parameters
	 */
	private _persistentParameters: string[];


	/**
	 * URL part for this ViewModel
	 */
	private _urlPart: string;

	/**
	 * Current Route (observable)
	 */
	private _route: KnockoutObservable<string>;

	/**
	 * Current Route's parameters
	 */
	private _parameters: IRouteParams;

	/**
	 * Current route computed observable (dependent on _route)
	 */
	protected currentRoute: KnockoutComputed<string>;




	/**
	 * Child Components (ViewModels)
	 */
	private _components: { [key:string]: ViewModel<IViewModelSettings> };


	/**
	 * Parent View Model (Owner)
	 */
	private _parent: ViewModel<IViewModelSettings>;

	/**
	 * Child Routed ViewModel
	 */
	private _child?: ViewModel<IViewModelSettings>;



	/**
	 * Inject call check flag
	 */
	private _injectCheck: boolean;

	/**
	 * Startup call check flag
	 */
	private _startupCheck: boolean;


	/**
	 * ViewModel settings
	 */
	protected _settings: T;

	/**
	 * JQuery Element for the current View
	 */
	protected _element: JQuery;





	/**
	 * ViewModelFactory
	 */
	private viewModelFactory: ViewModelFactory;


	/**
	 * Router
	 */
	protected router: Router;


	/**
	 * User
	 */
	protected user: User;

	/**
	 * Application
	 */
	protected application: Application;

	/**
	 * Flash Messenger Service
	 */
	protected flash: FlashMessenger

	/**
	 * Culture
	 */
	protected culture: Culture



	/**
	 * Returns internal ViewModel number
	 */
	public get viewModelNumber(): number {
		return this._viewModelNumber;
	}

	/**
	 * Settings getter
	 */
	public get settings(): T {
		return this._settings;
	}

	/**
	 * Current route getter
	 */
	public get route(): string {
		return this._route();
	}

	/**
	 * Route parameters getter
	 */
	public get parameters(): IRouteParams {
		return this._parameters; 
	}

	/**
	 * Query parameters getter
	 */
	public get query(): IQueryParams {
		return this.router.query; 
	}

	/**
	 * All defined routes
	 */
	public get routes(): IRoute[] {
		return this._routes;
	}

	/**
	 * Get persistent parameters
	 */
	public get persistentParameters(): string[] {
		return this._persistentParameters;
	}

	/**
	 * URL Part for this viewmodel
	 */
	public get urlPart(): string {
		return this._urlPart;
	}



	/**
	 * Components
	 */
	public get components(): { [key: string]: ViewModel<IViewModelSettings>; } {
		return this._components;
	}

	/**
	 * Child
	 */
	public get child(): ViewModel<IViewModelSettings> {
		return this._child;
	}

	/**
	 * Parent property getter
	 */
	 public get parent(): ViewModel<IViewModelSettings> {
		return this._parent;
	}

	/**
	 * Parent property setter. Must be called only once and just by ViewModel's loadViewModel method
	 */
	public set parent(viewModel: ViewModel<IViewModelSettings>) {
		if (this._parent !== null) {
			throw new Error('Parent ViewModel is already set.');
		}
		this._parent = viewModel;
	}



	/**
	 * View element
	 */
	public get element(): JQuery {
		return this._element;
	}


	/**
	 * Get depth level of this component in component tree
	 * @return Level number of this ViewModel
	 */
	public get level(): number {
		return this._parent === null ? 0 : this._parent.level + 1;
	}


	/**
	 * Constructor
	 */
	constructor()
	{
		this._viewModelNumber = ViewModel._internalViewModelCounter++;
		this._element = null;
		this._injectCheck = false;
		this._startupCheck = false;
		this._settings = null;

		// parent view model is null
		this._parent = null;
		// child view model is null
		this._child = null;

		// Current route
		this._parameters = null;
		this._route = ko.observable(null);
		this.currentRoute = ko.computed(() => this._route());
		
		this._components = {};
		this._routes = [];
	}


	/**
	 * Injects all required dependencies
	 */
	public injectRequired(
		element: JQuery,
		viewModelFactory: ViewModelFactory,
		application: Application,
		router: Router,
		user: User,
		flashMessenger: FlashMessenger,
		culture: Culture
	) {
		if(!this._injectCheck) {
			this._injectCheck = true;
			// set services
			this._element = element;
			this.viewModelFactory = viewModelFactory;
			this.application = application;
			this.router = router;
			this.user = user;
			this.flash = flashMessenger;
			this.culture = culture;
		}
	}

	/**
	 * Configure - called AFTER VM is instantiated and BEFORE primary dependencies are injected
	 * @param settings
	 */
	public configure(settings: T = null): void|Promise<void>
	{
		if(settings && typeof(settings) === 'object') {
			this._settings = jQuery.extend(true, {}, this._settings ?? {}, settings);
		}
	}

	/**
	 * ViewModel Startup
	 * @return Promise
	 */
	public async startup(): Promise<any>
	{
		// if we haven't injected required services, we cannot continue
		if (!this._injectCheck) {
			throw new Error('Unable to startup ViewModel. No injectRequered() call was made!');
		}
		this._startupCheck = true;
		return await Promise.all([true]);
	}

	/**
	 * When View and ViewModel is bound (ko.applyBindings) but not rendered yet
	 */
	public async bound(): Promise<any> { }

	/**
	 * When View is inserted into DOM (becomes visible)
	 */
	public async rendered(): Promise<any> { }


	/**
	 * Get Route Frame
	 *  @FIXME: unikatni oznaceni frames data-unique-id (+ hodnotu predat pred prirazenim do DOMu)
	 */
	public getRouteFrame(): JQuery<HTMLElement>
	{
		// if 'frame' was given, insert loaded View into this frame.
		let routeFrame = this.element.find('[data-route-frame]').first(); // first in this.element
		if (routeFrame.length <= 0) {
			throw new Error("There is no <route-frame /> element in this ViewModel");
		}
		return routeFrame;
	}
 
	/**
	 * Get View Frame of the given name
	 *  @FIXME: unikatni oznaceni frames data-unique-id (+ hodnotu predat pred prirazenim do DOMu)
	 * @param name 
	 */
	public getViewFrame(name: string): JQuery<HTMLElement>
	{
		let viewFrame = this.element.find('[data-view-frame="' + name + '"]').first(); // first in this.element
		if (viewFrame.length <= 0) {
			throw new Error("There is no <view-frame></view-frame> with the name '" + name + "' in this ViewModel");
		}
		return viewFrame;
	}	

	/**
	 * TearDown (destruct) all subsequent ViewModels plus myself
	 */
	public async teardownAllViewModels(): Promise<void>
	{
		if(this.child) {
			await this.child.teardownAllViewModels();
		}
		for(let frame in this.components) {
			this.components[frame].teardownAllViewModels();
		}
		await this.teardown();
	}


	/**
	 * Loads a ViewModel by token and put it to <view-frame></view-frame> of given 'frame' name
	 * 
	 * @param name ViewModel name
	 * @param frame Frame to insert loaded view into
	 * @param args Arguments for startup method
	 * @return Promise for view/viewmodel pair
	 */
	public async loadViewFrame<VMT>(token: InjectionToken<VMT>, frame: string = null, settings: IViewModelSettings = null): Promise<VMT>
	{
		// Load View Model
		const vmEl = this.viewModelFactory.createViewModel<VMT>(this, token, settings);
		// Run ViewModel
		await (vmEl.vm as any).run();

		// if 'frame' was given, insert loaded View into this frame.
		if(frame !== null) {

			let $viewFrame = this.getViewFrame(frame);
			ko.cleanNode($viewFrame.get(0));
			$viewFrame.html('').append(vmEl.el);
			
			// remove temporary element
			vmEl.temp.remove();
		}


		// component rendered (added to DOM)
		await (vmEl.vm as any).rendered();

		// if frame was passed, we must push it into this VM's children
		if(frame !== null) {
			// If there is previous ViewModel - call teardown()
			if(this._components[frame]) {
				await this.teardownAllViewModels();
			}
			// set new ViewModel
			this._components[frame] = vmEl.vm as any;
		}

		return vmEl.vm;
	}


	/**
	 * Loads a ViewModel by token and put it to <route-frame></route-frame>
	 * 
	 * @param token 
	 * @param settings 
	 * @returns 
	 */
	public async loadRouteFrame<VMT>(token: InjectionToken<VMT>, settings: IViewModelSettings = null): Promise<VMT>
	{
		// Load View Model
		const vmEl = this.viewModelFactory.createViewModel<VMT>(this, token, settings);
		// Run ViewModel
		await (vmEl.vm as any).run();

		let $routeFrame = this.getRouteFrame();
		ko.cleanNode($routeFrame.get(0));
		$routeFrame.html('').append(vmEl.el);
		
		// remove temporary element
		vmEl.temp.remove();

		// component rendered (added to DOM)
		await (vmEl.vm as any).rendered();

		// If there is previous ViewModel - call teardown()
		if(this._child) {
			this._child.teardown();
		}
		// set new child
		this._child = vmEl.vm as any;

		return vmEl.vm;		
	}

	/**
	 * Run ViewModel and returns promise for when all done
	 * @return Promise
	 */
	public async run(): Promise<void>
	{
		// Check inject
		if (!this._injectCheck) {
			throw new Error("Required dependencies were not injected by injectRequired() method!");
		}
		// setup this viewmodel routes
		this._routes = await this.configureRoutes();
		this._persistentParameters = await this.configurePersistentParameters();

		// When startup is done
		await this.startup();

		if (!this._startupCheck) {
			console.error("Method startup() or its descendant doesn't call super.startup().");
			throw new Error("Method startup() or its descendant doesn't call super.startup().");
		}

		// APPLY KNOCKOUT BINDINGS
		ko.cleanNode(this.element.get(0));
		//console.log('binding', this, this.element.get(0));
		ko.applyBindings(this, this.element.get(0));
		// Bound method
		await this.bound();
	}

	/**
	 * Execute route match result (called internally from Router)
	 * @param match 
	 */
	public async executeRoute(match?: IRouteMatchResult): Promise<void>
	{
		// No route match? call ErrorNotFound route
		if(!match) {
			this._urlPart = null;
			this._parameters = null;
			this._route(null);
			await this.routeErrorNotFound();
			return;
		}

		// Route is matched

		// Prev parameters
		const prevRoute = this._route();
		const prevParams = JSON.stringify(this._parameters);

		this._urlPart = match.matchedUrl;
		this._parameters = match.routeParams;
		this._route(match.route);

		// New parameters
		const newRoute = this._route();
		const newParams = JSON.stringify(this._parameters);

		// Nothing changed, URL is still the same - end here
		if(prevRoute === newRoute && prevParams === newParams) {
			return;
		}

		// Something has changed, call route method

		// method name to CALL
		const methodName = 'route' + newRoute.charAt(0).toUpperCase() + newRoute.slice(1);

		// Before routed
		const proceed = await this.beforeRouted(newRoute, methodName);
		if(proceed !== false) {

			// if specific routeXxxx method exists, use it OR call "route" otherwise
			if((this as any)[methodName]) {
				return await (this as any)[methodName]();
			}

			// No method was found, call defaultRoute
			return await this.defaultRoute(newRoute, methodName);
		}
	}

		
	/**
	 * Processes routing on this ViewModel (called internally by Application -> on Hash Change)
	 * 
	 * @param string urlPart
	 * @param forceRefresh Force reloading
	 *
	public async processRouting(urlPart: string): Promise<void>
	{
		// Backtup previous route+params
		const prevRoute = this._route();
		const prevParams = JSON.stringify(this._parameters);
		// Match URL and set new route + params		
		let result = this.router.matchUrl(this._routes, urlPart);
		// Store URL part
		this._urlPart = result ? result.matchedUrl : urlPart;
		this._parameters = result ? result.routeParams : null;
		this._route(result ? result.route : null);
		// Get new route+params
		const newRoute = this._route();
		const newParams = JSON.stringify(this._parameters);

		if(!result) {
			await this.routeErrorNotFound();
			return;
		}

		// console.log(prevRoute, newRoute, prevParams, newParams);
		if(prevRoute !== newRoute || prevParams !== newParams) {
			// method name to CALL
			const methodName = 'route' + newRoute.charAt(0).toUpperCase() + newRoute.slice(1);
			// if specific routeXxxx method exists, use it OR call "route" otherwise
			//this.currentRoute(requestedRoute); // for better responsive menu

			// Before routed
			const proceed = await this.beforeRouted(newRoute, methodName);
			if(proceed !== false) {
				if((this as any)[methodName]) {
					await (this as any)[methodName]();
				}
				else {
					await this.defaultRoute(newRoute, methodName);
				}
			}
		}		

		// Process routing in subsequent route frame
		// Only if:
		// - we have a child ViewModel
		// - Current route is not final
		if(this._child && !result.final) {
			const remainingUrl = result.remainingUrl ? result.remainingUrl : '/';
			await this._child.processRouting(remainingUrl);
		}
	}*/

	/**
	 * Configure routes for this ViewModel
	 */
	protected configureRoutes(): IRoute[]|Promise<IRoute[]> {
		return [];
	}

	/**
	 * Configure persistent parameters
	 */
	protected configurePersistentParameters(): string[]|Promise<string[]> {
		return [];
	}

	/**
	 * Gets called when query was changed
	 */
	public queryChanged(query: { [key: string]: any }): void {  }


	/**
	 * Before routeXxxx or defaultRoute method is called
	 * 
	 * @param route 
	 * @param method 
	 */
	public async beforeRouted(route: string, method: string): Promise<boolean>
	{
		return true;
	}

	/**
	 * Route given for this ViewModel
	 * @param method Path part
	 * @return Rest of the route for children
	 */
	public async defaultRoute(route: string, method: string): Promise<void>
	{
		console.warn("Warning! Missing route '" + route + "'. Neither '" + method + "' method nor 'defaultRoute' method is not defined in the '" + this.constructor.name + "' ViewModel, so nothing will happen!");
	}

	/**
	 * Route not found
	 */
	public async routeErrorNotFound()
	{
		this.getRouteFrame().html('<div style="text-align: center" class="ni-js-error-container">Error: Route not found!</div>');
	}

	/**
	 * Route to different route within current ViewModel or absolute route
	 * 
	 * 1) Route within current ViewModel (justCurrent):
	 * this.routeTo('lastRoute', { param: 12 });
	 * 
	 * 2) Route from root ViewModel to children (fromRootDown):
	 * this.routeTo('/firstRoute/secondRoute', { firstRoute: { code: '14d5d' }, secondRoute: { aaa: 2 });
	 * 
	 * 3) Route from current ViewModel to parents (fromCurrentUp):
	 * this.routeTo('lastButOne/:lastRoute', { lastButOne: { code: '14d5d' }, lastRoute: { param: 11 });
	 * 
	 * 4) Route from current ViewModel to children (fromCurrentUp):
	 * this.routeTo(':currentRoute/nextRoute', { currentRoute: { code: '14d5d' }, nextRoute: { param: 11 });
	 * 
	 * 5) Route from current ViewModel to both parents and children (fromCurrentUp):
	 * this.routeTo('prevRoute/:currentRoute/nextRoute', {  prevRoute: { code: '14d5d' } currentRoute: { code: '14d5d' }, nextRoute: { param: 11 });
	 * 
	 * @param route Where to route
	 * @param routeParams Route parameters
	 * @param query Query parameters
	 * @param hash HASH part of the URL
	 */
	public async routeTo(route: string, routeParams: { [key: string]: any } = null, query: { [key: string]: any } = null, hash: string = null): Promise<void>
	{
		this.router.routeTo(this, route, routeParams, query, hash);
	}
	

	/**
	 * Called when viewmodel is going to be replaced in view-frame / route-frame
	 * Destroy / close resources
	 */
	public async teardown(): Promise<void>
	{
		return;
	}



	/**
	 * TSX of external templates "id": HTMLElement
	 */
	public externalTemplates(): { [key: string]: HTMLElement }|null
	{
		return null;
	}
	
	
	/**
	 * TSX template definition
	 */
	public abstract template(): HTMLElement;	
	
}
