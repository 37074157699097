import { SyncEvent } from 'ts-events';
import { singleton } from 'tsyringe';
import { IFlashMessengerSettings } from './IFlashMessengerSettings';

/**
 * Default Flash Messenger
 */
export class FlashMessenger
{
	/**
	 * On message (type can be 'warning','error','info','success')
	 */
	public onMessage: SyncEvent<{ type: string, text: string }> = new SyncEvent<{ type: string, text: string }>();

	/**
	 * Defaults
	 */
	private defaults: IFlashMessengerSettings = {
		hideTimeout: 5000,
		fadeInDuration: 300,
		fadeOutDuration: 900,
		containerClass: 'tracejs-message-container',
		messageClass: 'tracejs-message',
		successClass: 'tracejs-success-message',
		warningClass: 'tracejs-warning-message',
		errorClass: 'tracejs-error-message',
		infoClass: 'tracejs-info-message'
	};

	/**
	 * Settings
	 */
	private settings: IFlashMessengerSettings;

	/**
	 * Element
	 */
	private elem: JQuery;

	/**
	 * Constructor
	 */
	constructor(settings: IFlashMessengerSettings = null) {
		this.settings = jQuery.extend({}, this.defaults, settings);
		this.prepareContainer();
	}

	/**
	 * Success message
	 */
	success(message: string): void {
		this.showMessage(message, 'success');
	}

	/**
	 * Warning message
	 */
	warning(message: string): void {
		this.showMessage(message, 'warning');
	}

	/**
	 * Error message
	 */
	error(message: string): void {
		this.showMessage(message, 'error');
	}

	/**
	 * Info message
	 */
	info(message: string): void {
		this.showMessage(message, 'info');
	}


	/**
	 * Creates container and place it into body end when loaded
	 */
	private prepareContainer() {
		this.elem = jQuery('<div style="visibility:hidden;"></div>');
		this.elem.addClass(this.settings.containerClass);
		jQuery(() => { jQuery('body').append(this.elem); });
	}

	/**
	 * Put the message in the message container bottom end and run the hiding timer
	 */
	private showMessage(text: string, type: string) {
		let message = jQuery('<div></div>');
		message.html(text);
		message.addClass(this.settings.messageClass);
		switch(type) {
			case 'info':
				message.addClass(this.settings.infoClass);
				break;
			case 'warning':
				message.addClass(this.settings.warningClass);
				break;
			case 'error':
				message.addClass(this.settings.errorClass);
				break;
			case 'success':
				message.addClass(this.settings.successClass);
				break;					
		}
		message.hide();
		message.appendTo(this.elem);
		message.fadeIn(this.settings.fadeInDuration);
		// On message
		this.onMessage.post({ type: type, text: text });
		// set timeout
		window.setTimeout(() => {
			message.fadeOut(this.settings.fadeOutDuration, () => {
				message.remove();
				this.toggleVisibility();
			});
		}, this.settings.hideTimeout);
		
		this.toggleVisibility();
	}

	private toggleVisibility()
	{
		let visibility = this.elem.children().length > 0 ? 'visible' : 'hidden';
		this.elem.css({ 'visibility': visibility });
	}

}
