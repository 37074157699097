import { IUrlParts } from './IUrlParts';
import { IConstructUrlResult } from './IConstructUrlResult';
import { IViewModelSettings } from './IViewModelSettings';
import { IRouteRequest } from './IRouteRequest';
import { IQueryParams } from './IQueryParams';
import { IRouteParams } from './IRouteParams';
import { IRouteMatchResult } from './IRouteMatchResult';
import { IRoute } from './IRoute';
import { SyncEvent } from 'ts-events';

import { singleton } from 'tsyringe';
import { ViewModel } from './ViewModel';

/**
 * Router listens for changes in URL and fires actions
 */
@singleton()
export class Router {

	/**
	 * Root ViewModel Settings
	 */
	public _rootViewModel: ViewModel<IViewModelSettings>;

	/**
	 * On route has changed (including hash change)
	 */
	public onRouteChanged: SyncEvent<IRouteRequest> = new SyncEvent<IRouteRequest>();

	/**
	 * URL parameters
	 */
	private _query: IQueryParams = {};

	/**
	 * Getter for root ViewModel settings
	 */
	public get rootVewModel(): ViewModel<IViewModelSettings> {
		return this._rootViewModel;
	}

	/**
	 * Query getter
	 * @returns any
	 */
	public get query(): IQueryParams {
		return this._query;
	}

	/**
	 * Hash getter
	 */
	public get hash(): string {
		return location.hash;
	}

	/**
	 * Path getter
	 */
	public get path(): string { 
		return location.pathname;
	}

	/**
	 * Current Query String
	 */
	public get queryString(): string {
		return this.buildQueryString(this._query);
	}


	/**
	 * Handle Trailing Slash in URL
	 */
	public trailingSlash(): void
	{
		let path = location.pathname;
		if(path.endsWith('/')) {
			history.replaceState(history.state, null, path.slice(0, -1) + location.search + location.hash);
		}
	}

	/**
	 * Match URL
	 * @param routes
	 * @param urlPart 
	 */
	public matchUrl(routes: IRoute[], url: string): IRouteMatchResult
	{
		let result: IRouteMatchResult = null;

		for(let i in routes) {
			let route = routes[i];			
			let regexAndFlags = this.patternToRegex(route.pattern);
			
			// varianta s lomitkem nebo bez lomitka - podle toho, co se preda
			let url2 = url.trim() !== '/' ? (url.endsWith('/') ? url.slice(0, -1) : url + '/') : null;

			let matches: RegExpExecArray = null;
			matches = regexAndFlags.regExp.exec(url);
			if(!matches && url2) {
				matches = regexAndFlags.regExp.exec(url2);
			}

			// we have a match
			if(matches) {
				let remaining = matches.input.substring(matches[0].length);
				result = {
					matchedUrl: matches[0],
					remainingUrl: remaining.trim() === '' ? null : remaining,
					route: route.name,
					routeParams: matches.groups ?? {},
					final: regexAndFlags.final
				};				
				let allParams = JSON.parse(JSON.stringify(result.routeParams));
				for(let qsName in this._query) {
					allParams[qsName] = this._query[qsName];
				}
				result.allParams = allParams;

				// Return matched result
				return result;
			}
		}
		// return NULL - no route matched
		return result;
	}

	/**
	 * Construct URL from RouteRequest and current Query state
	 * @param vm Start ViewModel (should be root viewmodel)
	 * @param routeRequest Route request
	 * @returns 
	 */
	public constructUrl(vm: ViewModel<IViewModelSettings>, routeRequest: IRouteRequest): IConstructUrlResult
	{
		// Consolidate Query Parameters
		let pp = this.findPersistentParametersRecursively(vm);
		const queryParams: IQueryParams = {};
		for(let par in this._query) {
			if(routeRequest.query && routeRequest.query[par]) {
				// je o tento parametr pozadano? dame jeho novou hodnotu
				queryParams[par] = routeRequest.query[par];
			}
			else if(pp[par]) {
				// Pokud neni pozadano, ale je tento parametr perzistentni, dame jeho soucasnou hodnotu
				queryParams[par] = this._query[par];
			}
		}
		// Doplnime o nove pridane parametry
		if(routeRequest.query) {
			for(let qp in routeRequest.query) {
				if(queryParams[qp] === undefined) {
					queryParams[qp] = routeRequest.query[qp];
				}
			}
		}
		// Build querystring
		const queryString = Object.keys(queryParams).length > 0 ? this.buildQueryString(queryParams, true) : '';

		// Hash
		const hash = routeRequest.hash ? '#' + routeRequest.hash : '';

		// Build Path
		const routeParts = (routeRequest.route.startsWith('/') ? routeRequest.route.substring(1) : routeRequest.route).split('/');
		let vmc = vm;
		let parts: string[] = [];
		while(vmc && routeParts.length > 0) {
			let routeName = routeParts.shift();
			let routePart = this.constructUrlPart(vmc.routes, routeName, routeRequest.parameters ? (routeRequest.parameters[routeName] ?? {}) : {});
			parts.push(routePart);
			vmc = vmc.child;
		}
		const path = parts.join('');
		const pathSanitized = path.endsWith('/') && path.trim() !== '/' ? path.substring(0, path.length - 1) : path;

		// Result
		return {
			url: pathSanitized + queryString + hash,
			absolute: location.protocol + '//' + location.host + pathSanitized + queryString + hash,
			path: pathSanitized,
			query: queryParams,
			queryString: queryString,
			hash: hash
		};
	}

	/**
	 * Find all persistent parameters in all components and child ViewModels recursively
	 * @param vm 
	 * @returns 
	 */
	private findPersistentParametersRecursively(vm: ViewModel<IViewModelSettings>): { [key:string]: boolean }
	{
		// ViewModel itself
		let pp: { [key:string]: boolean } = {};
		vm.persistentParameters.forEach(p => { pp[p] = true; });
		// ViewModel's components
		for(let frame in vm.components) {
			if(vm.components[frame]) {
				let compPps = this.findPersistentParametersRecursively(vm.components[frame]);
				for(let compPp in compPps) {
					pp[compPp] = compPps[compPp];
				}	
			}
		}
		// ViewModel's child
		if(vm.child) {
			let childPps = this.findPersistentParametersRecursively(vm.child);
			for(let childPp in childPps) {
				pp[childPp] = childPps[childPp];
			}
		}
		return pp;
	}

	/**
	 * Construct URL part
	 * @param routes 
	 * @param routeName 
	 * @param params 
	 */
	private constructUrlPart(routes: IRoute[], routeName: string, params: IRouteParams = null)
	{
		params = params ?? {};

		let found = routes.filter(r => r.name == routeName);
		if(found.length <= 0) {
			console.error("Undefined route requested: " + routeName);
			return;
		}
		let route: IRoute = found[0];
		
		let urlPart = route.pattern.endsWith('$') ? route.pattern.slice(0, -1) : route.pattern;
		// replace parameters
		let matches = (route.pattern as any).matchAll(/<([a-z0-9]+)(?:\s+)?([^>]+)?>/ig);
		for(const match of matches) {
			let paramName = match[1];
			if(!params[paramName]) {
				console.error("Missing '" + paramName + "' parameter!");
				return;
			}
			// force toString (because of match bellow)
			let paramValue = params[paramName].toString(); 
			if(match[2] && !(paramValue.match(new RegExp('^' + match[2] + '$', 'i')))) {
				console.error("Parameter '" + paramName + "' has invalid value! Parameter must match pattern '" + match[2] + "'.");
				return;
			}
			urlPart = urlPart.replace(match[0], paramValue);
		}
		return urlPart;
	}


	/**
	 * Convert route pattern to regular expression + flag if it is final
	 * @param pattern 
	 * @returns 
	 */
	private patternToRegex(pattern: string): { regExp: RegExp, final: boolean }
	{
		// Pattern is ending with a $ sign - route is final (no remaning route parts)
		const isFinal = pattern.endsWith('$');
		const trailing = isFinal ? '$' : '/|$';

		if(isFinal) {
			pattern = pattern.slice(0, -1);
		}

		let replaced = pattern.replace(/<([a-z0-9]+)(?:\s+)?([^>]+)?>/, '(?<$1>$2)');
		replaced = '^' + replaced + '(?=' + trailing + ')';

		let regex = new RegExp(replaced, 'i');
		return {
			regExp: regex,
			final: isFinal
		};
	}

	/**
	 * Constructor
	 */
	public constructor()
	{
	}

	/**
	 * Start routing from the current URL
	 * @param vm Root viewmodel
	 */
	public startRouting(vm: ViewModel<IViewModelSettings>)
	{
		if(this._rootViewModel) {
			throw new Error("Method startRouting can be called only once!");
		}

		this._rootViewModel = vm;

		// Parse QueryString on the beginning
		this._query = this.parseQueryString(location.search);

		// set interval handler
		window.onpopstate = (e: PopStateEvent) => {
			this.processRouting({
				pathName: location.pathname,
				queryString: location.search,
				hash: location.hash
			}, false);
		};
		
		this.processRouting({
			pathName: location.pathname,
			queryString: location.search,
			hash: location.hash
		});
	}

	/**
	 * Parse URL parameters
	 * 
	 * Arrays are parsed like this:
	 * how[are][you][doing][today]=value
	 * how
	 *  '- are
	 *      '- you
	 *          '-doing
	 *             '- today = value
	 */
	private parseQueryString(queryString: string): IQueryParams
	{
		if(queryString == '' || queryString == '?') {
			return {};
		}
		let qs = queryString.startsWith('?') ? queryString.substring(1) : queryString; // skip ?
		let params: IQueryParams = {};
		// split query string by ampersand
		let qsParts = qs.split('&');
		for(let param in qsParts) {
			let kvParts = qsParts[param].split('=');
			kvParts[0] = decodeURI(kvParts[0]);
			// parameter without explicitly set value has empty string value
			kvParts[1] = kvParts[1] ? kvParts[1] : '';
			// first parameter may have brackets (array), split by delimiters
			let keyParts = kvParts[0].split(/(?=\[[^\]]*\])/mg);
			// simple key value pair
			if(keyParts.length == 1) {
				let value = decodeURIComponent(kvParts[1]);
				// @fixme: type (string, number, boolean???)
				params[keyParts.pop()] = value;
				continue;
			}
			// fill recursively hash map
			let dest = params;
			let index = 0, keyPartsMaxIndex = keyParts.length - 1;
			let prevObj: any = null, prevKey: any = null;
			for(let kPart in keyParts) {
				let key: string|number = keyParts[kPart];
				key = key.charAt(0) === '[' ? key.substring(1) : key;
				key = key.charAt(key.length - 1) === ']' ? key.substring(0, key.length-1) : key;
				// if no key is passed, we will search for next numeric key
				if(key == '') {
					key = 0;
					while(dest[key] !== undefined) {
						key = key + 1;
					}
				}
				// zkonsolidovany key pro dest
				if(index < keyPartsMaxIndex) {
					if(dest[key] === undefined) {
						dest[key] = {};
					}
					prevObj = dest;
					prevKey = key;
					dest = dest[key] as any;
				}
				else {
					// Pokud je index ciselny a jeste v objektu nic neni, udelame z neho pole
					if(typeof key === 'number' && Object.keys(dest).length <= 0) {
						prevObj[prevKey] = [];
						dest = prevObj[prevKey];
					}
					else if(typeof key !== 'number' && Array.isArray(dest)) {
						let obj: {[key:string]:any} = {};
						dest.forEach((val: number|string, index: number) => {
							obj[index] = val;
						});
						prevObj[prevKey] = obj;
						dest = prevObj[prevKey];
					}
					dest[key] = decodeURIComponent(kvParts[1]);
				}
				index++;
			}
		}
		return params;
	}

	/**
	 * Build QueryString parameters from "parameters" parameter
	 * @param parameters 
	 * @param prependQuestionMark 
	 */
	private buildQueryString(parameters: IQueryParams, prependQuestionMark: boolean = true): string
	{
		if(!parameters) {
			return '';
		}
		let qs = jQuery.param(parameters);
		if(qs.trim() !== '') {
			return (prependQuestionMark ? '?' : '') + qs;
		}
		return '';
	}

	/**
	 * Route to given request
	 * 
	 * @param path Path component
	 * @param queryString Query String parameters
	 * @param hash Hash
	 */
	public pushState(path: string, queryString: string = null, hash: string = null): void
	{
		// remove trailing slash
		// path = path.endsWith('/') && path.trim() !== '/' ? path.slice(0, -1) : path;

		//const queryString = this.buildQueryString(query, true);
		//const hashString = hash !== null && hash.trim() !== '' ? '#' + hash : '';

		// TODO perzistentni parametry ???
		let fullUrl = path + queryString + hash;
		if(fullUrl.trim() === '') {
			fullUrl = '/';
		}

		history.pushState({}, null, fullUrl);
	}

	
	/**
	 * Route to different route within current ViewModel or absolute route
	 * 
	 * 1) Route within current ViewModel (justCurrent):
	 * this.routeTo('lastRoute', { param: 12 });
	 * 
	 * 2) Route from root ViewModel to children (fromRootDown):
	 * this.routeTo('/firstRoute/secondRoute', { firstRoute: { code: '14d5d' }, secondRoute: { aaa: 2 });
	 * 
	 * 3) Route from current ViewModel to parents (fromCurrentUp):
	 * this.routeTo('lastButOne/:lastRoute', { lastButOne: { code: '14d5d' }, lastRoute: { param: 11 });
	 * 
	 * 4) Route from current ViewModel to children (fromCurrentUp):
	 * this.routeTo(':currentRoute/nextRoute', { currentRoute: { code: '14d5d' }, nextRoute: { param: 11 });
	 * 
	 * 5) Route from current ViewModel to both parents and children (fromCurrentUp):
	 * this.routeTo('prevRoute/:currentRoute/nextRoute', {  prevRoute: { code: '14d5d' } currentRoute: { code: '14d5d' }, nextRoute: { param: 11 });
	 * 
	 * @param route Where to route
	 * @param routeParams Route parameters
	 * @param query Query parameters
	 * @param hash HASH part of the URL
	 */	
	public routeTo(vm: ViewModel<any>, route: string, routeParams: IRouteParams|{[key:string]: IRouteParams} = null, query: IQueryParams = null, hash: string = null): void
	{
		// 2) Pokud routa zacina lomitkem, mame jiz absolutni routu i s parametry
		if(route.startsWith('/')) {
			const routeRequest: IRouteRequest = { 
				route: route,
				parameters: routeParams as {[key:string]: IRouteParams},
				query: query,
				hash: hash 
			};
			//console.log('2)', routeRequest);
			this.callRouteChanged(routeRequest);
			return;
		}

		// 1) Split by delimiter
		const routeParts = route.split('/');

		// 1) Pokud je jen jeden prvek, jedna se o prvni pripad
		if(routeParts.length == 1) {
			const routeRequest: IRouteRequest = {
				route: '/' + route,
				parameters: {},
				query: query,
				hash: hash
			};
			routeRequest.parameters[route] = routeParams as IRouteParams;	
			let vmp = vm.parent;
			while(vmp) {
				let vmpRoute = vmp.route;
				routeRequest.route = '/' + vmpRoute + routeRequest.route;
				routeRequest.parameters[vmpRoute] = vmp.parameters;
				vmp = vmp.parent;
			}
			//console.log('1) ', routeRequest);
			this.callRouteChanged(routeRequest);
			return;
		}

		// 3), 4), 5)
		// Najit dvojtecku oznacujici aktualni routu
		let foundIndex = -1;
		routeParts.forEach((part: string, index: number) => {
			if(part.startsWith(':')) {
				if(foundIndex <= -1) {
					foundIndex = index;
				}
				else {
					throw new Error("Multiple routes prepended with ':' detected. There must only be one!");
				}
			}
		});
		if(foundIndex <= -1) {
			throw new Error("No route prepended with ':' detected. There must be one route prepended with ':' that indicates current route!");
		}
		// Current route level
		const currentLevelRoute = routeParts[foundIndex].substring(1); // remove ':' from the beginning
		const routeRequest: IRouteRequest = {
			route: '/' + currentLevelRoute,
			parameters: {},
			query: query,
			hash: hash
		};
		if((routeParams[currentLevelRoute] as IRouteParams)) {
			routeRequest.parameters[currentLevelRoute] = (routeParams[currentLevelRoute] as IRouteParams);
		}
		// append from foundIndex+1 to last index (routeParts.length - 1)
		for(let i = foundIndex + 1; i < routeParts.length; i++) {
			let routePart: string = routeParts[i];
			routeRequest.route += '/' + routePart;
			if((routeParams[routePart] as IRouteParams)) {
				routeRequest.parameters[routePart] = (routeParams[routePart] as IRouteParams);
			}
		}
		// prepend from index 0 to foundIndex-1
		let vmp = vm.parent;
		for(let i = foundIndex-1; i >= 0 && vmp !== null; i--) {
			let routePart: string = routeParts[i];
			routeRequest.route = '/' + routePart + routeRequest.route;
			if((routeParams[routePart] as IRouteParams)) {
				routeRequest.parameters[routePart] = (routeParams[routePart] as IRouteParams);
			}
			vmp = vmp.parent;
		}
		// prepend remaining to absolute route
		while(vmp) {
			routeRequest.route = '/' + vmp.route + routeRequest.route;
			routeRequest.parameters[vmp.route] = vmp.parameters;
			vmp = vmp.parent;
		}

		//console.log('345)', routeRequest);
		this.callRouteChanged(routeRequest);
	}

	/**
	 * Process routing on root viewmodel
	 * @param params
	 * @param callPushState FALSE = nevolat znovu push state (bylo volano z onpopstate eventu)
	 */
	public async processRouting(params: IUrlParts, callPushState: boolean = true): Promise<void>
	{
		let vm = this._rootViewModel;

		// Parse new Query and detect change
		const qsChanged = this.queryString !== params.queryString;
		this._query = this.parseQueryString(params.queryString);

		// call executeRoute on all ViewModels
		let cvm = vm;
		let urlPart = params.pathName;
		while(cvm) {

			// Match URL and set new route + params		
			let match = this.matchUrl(cvm.routes, urlPart);

			// Execure routing on the ViewModel
			await cvm.executeRoute(match);

			// Set Query if changed to the VM and its components
			if(qsChanged) {
				cvm.queryChanged(this._query);
				for(let frame in cvm.components) {
					if(cvm.components[frame]) {
						cvm.components[frame].queryChanged(this._query);
					}
				}
			}

			// Execute route on child ViewModel only if:
			// - there is a child ViewModel
			// - current route is not final
			if(!cvm.child || match.final) {
				break;
			}

			urlPart = match.remainingUrl ? match.remainingUrl : '/';
			cvm = cvm.child;
		}

		// Push state (change URL in addressbar)
		if(callPushState) {
			this.pushState(params.pathName, params.queryString, params.hash);
		}
	}


	private callRouteChanged(routeRequest: IRouteRequest)
	{
		const result = this.constructUrl(this.rootVewModel, routeRequest);
		this.processRouting({
			pathName: result.path,
			queryString: result.queryString,
			hash: result.hash
		});
		this.onRouteChanged.post(routeRequest);
	}


}