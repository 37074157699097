import { container, singleton, InjectionToken } from "tsyringe";

import { FlashMessenger } from './../ui/FlashMessenger';
import { Application } from './Application';
import { User } from './../security/User';
import { Router } from './Router';

import { ViewModel } from './ViewModel';
import { IViewModelSettings } from './IViewModelSettings';

/**
 * ViewModel Factory
 */
@singleton()
export class ViewModelFactory {

	/**
	 * Router
	 */
	router: Router;

	/**
	 * User
	 */
	user: User;

	/**
	 * Constructor
	 * @param container Container
	 */
	constructor(
			router: Router, 
			user: User
		) {
		this.router = router;
		this.user = user;
	}

	/**
	 * Create ViewModel by the given className
	 * @param parent Parent View Model
	 * @param token ViewModel
	 * @param args Arguments
	 * @return Promise
	 */
	public createViewModel<T>(parent: ViewModel<IViewModelSettings>, token: InjectionToken<T>, settings: IViewModelSettings = null): { vm: T, el: JQuery<HTMLElement>, temp: JQuery<HTMLElement> }
	{
		let application = container.resolve<Application>(Application);
		let messenger = container.resolve<FlashMessenger>(FlashMessenger);
		let vm: any = container.resolve<T>(token);

		// external templates
		let externalTemplates: any = vm.externalTemplates();
		for(let etId in externalTemplates) {
			if(jQuery('#' + etId).length <= 0) {
				jQuery(
					'<script type="text/html" id="' + etId + '">' + 
					jQuery('<div></div>').append(externalTemplates[etId]).html() + 
					'</script>'
				).appendTo('body');
			}
		}
		
		// Configure
		vm.configure(settings);

		// Get template
		let $el = jQuery(vm.template());

		// Inject Required
		vm.injectRequired($el, this, application, this.router, this.user, messenger, application.culture);

		// insert it into TEMP node, so knockout can find all its templates
		// temp node for loaded views
		let tempNode = jQuery('<div class="vm-temp-node" style="position:absolute;left:0;top:0;min-width:200px;min-height:100px;display:none"></div>');
		tempNode.appendTo('body');			
		tempNode.append($el);
		// set parent to ViewModel
		vm.parent = parent;
		// Return
		return { vm: vm, el: $el, temp: tempNode };
	}

}
