import { inject, injectable } from "tsyringe";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { Objects } from "../../../tracejs/src/utils/Objects";

import { h } from "../../../tracejs/src/utils/JSXFactory";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { CustomFieldsHelper } from "../../model/CustomFieldsHelper";

import { BaseViewModel } from "../common/BaseViewModel";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../model/KendoHelpers";
import { GridConfigurator } from "../components/GridConfigurator/GridConfigurator";
import { IGridConfiguratorOptions } from "../components/GridConfigurator/IGridConfiguratorOptions";
import { CodelistManager } from "../../model/CodelistManager";
import { TracedoHelpers } from "./TracedoHelpers";
import { TracedoManager } from "../../model/TracedoManager";
import { TypedCheckpointsHelper } from "../../model/TypedCheckpointsHelper";
import moment from "moment";

/**
 * Track And Trace - vypis preprav
 */
@injectable()
export class TrackAndTrace extends BaseViewModel<any>
{

	// GRID element
	private gridElement: JQuery<HTMLElement> = null;
	//private gridVm: any = null;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

	// filtrace dle export/import
	private kindId: number = null;

	// Transport kinds
	private transportKinds: Array<{ kindId: number, name: string }> = [];

	// Invoicing statuses
	private invoicingStatuses: Array<{ statusId: number, ident: string, name: string }> = [];


	// // pro moznost manipulaci s nadrazenimy akcemi
	private gridConfiguratorViewModel: GridConfigurator;


	// custom fields helper
	protected customFieldsHelper: CustomFieldsHelper;

	// typed checkpoints helper
	protected typedCheckpointsHelper: TypedCheckpointsHelper;

	// Codelist manager
	protected codelistManager: CodelistManager;

	// Tracedo manager
	protected tracedoManager: TracedoManager;


	// Main subject ID prihlaseneho uzivatele
	protected mainSubjectId: number;

	// Max passed carriers count
	protected maxCarriers: number;

	/**
	 * Constructor
	 * 
	 * @param rpc RPC
	 * @param culture Culture
	 */
	constructor(
			@inject(Client) rpc: Client, 
			@inject(CustomFieldsHelper) customFieldsHelper: CustomFieldsHelper,
			@inject(TypedCheckpointsHelper) typedCheckpointsHelper: TypedCheckpointsHelper,
			@inject(CodelistManager) codelistManager: CodelistManager,
			@inject(TracedoManager) tracedoManager: TracedoManager
		) {
		super(rpc);
		this.customFieldsHelper = customFieldsHelper;
		this.typedCheckpointsHelper = typedCheckpointsHelper;
		this.codelistManager = codelistManager;
		this.tracedoManager = tracedoManager;
	}	

	/**
	 * Metoda konfigurujici parametry GRIDu
	 */
	private configureGrid(): void
	{
		// pripravit toolbar
		let toolbarConfig = TracedoHelpers.tracedoGridCreateToolbar({
			'create': true,
			'edit': true,
			'duplicate': true,
			'detail': true,
			'setDriver': true,
			'setLoad': true,
			'setDischarge': true,
			'changeInvStatus': true,
			'suspend': true,
			// 'approve': true,
			// 'decline': true,
			'sellPriceDetail': true,
			'purchasePriceDetail': true,
			'cancel': true,
			'kind': true,
			'generateTransportOrder': true,
			'generateCMR': true,
			'pass': true
		}, this.user, this.invoicingStatuses, this.transportKinds);

		// pripravit definici sloupcu s filtrama
		let gridColumnsDef = TracedoHelpers.tracedoGridDefineColumns(this.user, this.codelistManager);
		// pridat custom fields
		this.customFieldsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShort);
		// pridat typed checkpoints
		this.typedCheckpointsHelper.appendColumnsTo(gridColumnsDef, this.culture.localeShortCapitalized);

		// Kendo grid Schema model fields
		let schemaModelFields = TracedoHelpers.tracedoGridDefineSchema();
		// pridat do neho custom fields
	 	this.customFieldsHelper.appendSchemaFieldsTo(schemaModelFields);
		// pridat do nebo typed checkpoints fields
		this.typedCheckpointsHelper.appendSchemaFieldsTo(schemaModelFields);

		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

						options.data.search = (this.grid.dataSource as any).searchText;

						// filtrovat prepravy dle import export
						options.data = KendoHelpers.filterByKindId(options.data, this.kindId, 'kindId');

						// řadit podle čísla = LEFT 2, RIGHT 5 (DEV-4898)
						if (options.data.sort) {
							options.data.sort.forEach((sortItem: { field: string; }) => {

								if (sortItem.field === 'myNumber') {
									sortItem.field = 'myShortNumber';
								}
							});
						}

						let dateFields = jQuery.extend({},
							TracedoHelpers.tracedoGridDateColumns(),
							this.typedCheckpointsHelper.getDateFields(),
							this.customFieldsHelper.getDateFields('fields_')
						);
						TracedoHelpers.compatibilizeRequestData(options.data, '__', dateFields);

						let response: any = await this.rpc.call('tracedo.getTransportView', { // NotMultipleDelivery
							query: {
								...options.data,
								select: TracedoHelpers.getSelect() + 
										this.customFieldsHelper.getCustomFieldsSelect() +
										this.typedCheckpointsHelper.getCheckpointTypesSelect()
							}
						});

						response.data = TracedoHelpers.processResponseData(response.data);
						response.data = KendoHelpers.tracedoSetRowColor(response.data);

						// TracedoHelpers.compatibilizeResponseData(response.data);

						options.success(response);
					}
				},
				schema: {
					model: {
						fields: schemaModelFields
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "etdFrom", dir: "desc" },
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			columns: gridColumnsDef,
			detailInit: (e: kendo.ui.GridDetailInitEvent) => { TracedoHelpers.tracedoGridDetailInit(this.rpc, e, this.codelistManager); },
			dataBound: (e: kendo.ui.GridDataBoundEvent) => { TracedoHelpers.tracedoGridDataBound(this.grid, this.gridElement, e); },
			change: (e: kendo.ui.GridChangeEvent) => { TracedoHelpers.tracedoGridSelectionChanged(this.grid, this.gridElement, this.user, this.codelistManager); }
		};
	}

	/**
	 * Reread grid
	 */
	public reload()
	{
		this.grid.dataSource.read();
	}

	// Startup
	public async startup(): Promise<void> {
	 	await super.startup();

	 	// Pokud nemuze do ROAD, tak ho vyhodime
	 	if (!this.user.isAllowed('section.trackAndTrace', 'enter')) {
	 		throw new BadRequestError('Access denied', 403);
	 	}
		 
		// Read Typed Checkpoints
		await this.typedCheckpointsHelper.loadCheckpointTypes();
	 	// Read custom fields
	 	await this.customFieldsHelper.loadCustomFields();

		// Read codelists
		let batch = this.rpc.batch();
		batch.call('mainSubject', 'subject.getMain');
		batch.call('maxCarriers', 'tracedo.getMaxCarrier');
		let batchResult: any = await batch.run();
		this.transportKinds = this.codelistManager.getKinds();
		this.invoicingStatuses = this.codelistManager.getInvoicingStatuses();
		this.mainSubjectId = batchResult['mainSubject'].subjectId;
		this.maxCarriers = batchResult['maxCarriers'];

		// Configure GRID
		this.configureGrid();
	}

	/**
	 * Component was rendered
	 */
	public async rendered(): Promise<void>
	{
		// grid element
		this.gridElement = this.element.find('div[data-grid=tracedo]');
		// initialize grid and save reference
		this.grid = this.gridElement.kendoGrid(this.gridConfig).data('kendoGrid');

		TracedoHelpers.tracedoGridBindActions(this, this.grid, this.gridElement, this.user, this.tracedoManager);

		// Rychlý filtr pro IMPORT/EXPORT - nastavit kindId promennou a obnovit grid
		this.gridElement.on('change', '[data-action=selectKind]', (event: JQuery.ChangeEvent) => {
			let val = jQuery(event.currentTarget).val();
			this.kindId = val !== '-' ? parseInt(val as string, 10) : null;
			this.grid.dataSource.read();
		});

		// Hromadna zmena stavu fakturace pro nastaveny filtr
		this.gridElement.on('click', '.change-inv-status-menu a', (event: JQuery.ClickEvent) => {
			let val = jQuery(event.currentTarget).data('status-id');
			let statusId = val ? parseInt(val as string, 10) : null;
			this.callSetInvStatus(statusId);
		});

		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Order.Tracedo',
			exports: [{
				name: 'XLS export',
				method: 'tracedo.xlsExport'
			}]
		} as IGridConfiguratorOptions);

		this.gridConfiguratorViewModel = vm;
	}





	protected async setInvStatus(statusId: number, query: any, columns: any): Promise<void>
	{
		var params: any = { 
			statusId: statusId,
			query: query,
			lang: this.culture.localeShort,
			columns: columns
		};
		let response: any = await this.rpc.call('tracedo.setIvoicingStatus', params);

		if(response != null && response.count > 0) {
			//this.gridConfiguratorViewModel.clearExportsParams();
			//this.gridConfiguratorViewModel.exportsParams['invStatus'] = status;
			//this.gridConfiguratorViewModel.xlsExport();
			location.href = response.url;
			this.grid.dataSource.read();
		}
	}

	/**
	 * Nastaveni stavu fakturace. Kontrola, zda jsou vyfiltrovane nejake zaznamy a pak zavolani setInvoidingStatus
	 * @param statusId 
	 * @returns 
	 */
	protected async callSetInvStatus(statusId: number): Promise<void>
	{
		let filterUsed: any = this.grid.dataSource.filter();
		let searchText = jQuery.trim(this.gridConfiguratorViewModel.searchText);

		// kontrola na nastaveny filtr NEBO zadanou vyhledavaci frazi
		if (!filterUsed && searchText === '') {
			let confirmed = await this.confirmDialog(i18n.t("common.captions.confirmNoFilter"));
			if(!confirmed) {
				return;
			}
		}

		// ... potvrzeno nebo nastavena filtrace => pokracujeme
		let query: any =  { filter: filterUsed, search: searchText };
		KendoHelpers.replaceRequestData(query, '__');

		var columns = this.gridConfiguratorViewModel.getColums();
		return await this.setInvStatus(statusId, query, columns);
	}




	

	public template = (): HTMLElement => (

		<div>
			<h1 data-bind="i18n: 'nav.tracedo'"></h1>

			<div className="panel">
				<view-frame name="gridconf" className="mb-2" />
				<div data-grid="tracedo" className="grid-autoheight"></div>
				<view-frame name="actionDialog" />
			</div>
		</div>
	);
}