import { Currency } from './../../../entities/Currency.d';
import { i18n } from 'i18next-ko';
import { CodelistManager } from './../../../model/CodelistManager';
import { Client } from "../../../../tracejs/src/net/jsonrpc/Client";
import { Exchange } from "../../../entities/Exchange";
import { Fee } from "../../../entities/Fee";
import { TracedoCalculator } from "../../../entities/TracedoCalculator";
import { Subject } from '../../../entities/Subject';
import {isEmptyObject} from "jquery";
import * as Console from "console";
import {Subjects} from "../../Manage/Subjects/Subjects";


export interface EditCalculatorHelpersSettings {
    /** Tracedo ID */
    tracedoId: number;
    /** Road Type ID */
    roadTypeId: KnockoutObservable<number>;
    /** Kind ID */
    kindId: KnockoutObservable<number>;
}

export class EditCalculatorHelpers {

    private rpc: Client;

    private codelistManager: CodelistManager;

    private settings: EditCalculatorHelpersSettings
    
    private _feeGroupName: KnockoutComputed<string>;
 
    private _currencyIso: KnockoutComputed<string>;

    private _invoicedCurrencyIso: KnockoutComputed<string>;
    
    private _rateUsedCzk: KnockoutComputed<string>;

    private _rateUsedInvoiced: KnockoutComputed<string>;
    

    // Nastaveni dropdownu pro vyber subjektu
    private _subjectComboDataSource: kendo.data.DataSourceOptions;

    private _fees: Fee[];
    
    private _calculator: MappedType<TracedoCalculator>;

    private _rates: Exchange[];

    private _czkId: number;

    private _tradeCurrency: number;


    public get feeGroupName(): KnockoutComputed<string> {
        return this._feeGroupName;
    }

    public get currencyIso(): KnockoutComputed<string> {
        return this._currencyIso;
    }

    public get invoicedCurrencyIso(): KnockoutComputed<string> {
        return this._invoicedCurrencyIso;
    }

    public get rateUsedCzk(): KnockoutComputed<string> {
        return this._rateUsedCzk;
    }

    public get rateUsedInvoiced(): KnockoutComputed<string> {
        return this._rateUsedInvoiced;
    }

    public get subjectComboDataSource(): kendo.data.DataSourceOptions {
        return this._subjectComboDataSource;
    }
    
    public get fees(): Fee[] {
        return this._fees;
    }

    public get calculator(): MappedType<TracedoCalculator> {
        return this._calculator;
    }

    public get rates(): Exchange[] {
        return this._rates;
    }

    public get czkId(): number {
        return this._czkId;
    }

    public get tradeCurrency(): number {
        return this._tradeCurrency;
    }

    public constructor(rpc: Client, codelistManager: CodelistManager, settings: EditCalculatorHelpersSettings)
    {
        this.rpc = rpc;
        this.codelistManager = codelistManager;
        this.settings = settings;
    }

    public async configureFor(type: string, calculator: TracedoCalculator = null)
    {
        let initialSubjectLoaded = false;

        await this.readDataFor(type, calculator);
        this.configureObservables();

        // naseptavac pro subjekty
        this._subjectComboDataSource = {
            serverFiltering: true,
            transport: {
                read: async (options: kendo.data.DataSourceTransportReadOptions) => {

                    let srch = options.data.filter && options.data.filter.filters && options.data.filter.filters[0] ? 
                        (options.data.filter.filters[0] as any).value : 
                        '';

                    let batch = this.rpc.batch();

                    // pocatecni nacteni nastavene adresy
                    if(calculator && !initialSubjectLoaded && calculator.subjectId) { // calculator.id &&
                        batch.call('selected', 'subject.getOneConnected', {
                            id: this.calculator.subjectId(),
                            deleted: true,
                            query: {
                                select: '*,country(iso)'
                            } 
                        });
                        initialSubjectLoaded = true;
                    }

                    batch.call('subjects', 'subject.getConnected', {
                        query: {
                            search: srch,
                            select: '*,country(iso)',
                            page: 1,
                            pageSize: 100,
                            sort: [{ field: 'name', dir: 'asc' }] 
                        }
                    });

                    let response: any = await batch.run();
                    let subjects: Subject[] = response['subjects'].data;

                    // Pokud se selected neobjevil v datovem zdroji (strakovani / deleted), doplnime ho na konec
                    if(response['selected']) {
                        let selected: Subject = response['selected'];
                        let isSelectedThere = subjects.filter(s => s.subjectId == selected.subjectId).length > 0;
                        if(!isSelectedThere) {
                            subjects.push(selected);
                        }
                    }

                    options.success(subjects);
                }
            }
        };


    }
    
    /**
     * Read data needed for cost/revenue calculation
     * @param type 'cost' or 'revenue'
     * @param calculator Calculator object for editing (or prefill)
     */
    private async readDataFor(type: string, calculator: TracedoCalculator = null)
    {
        const batch = this.rpc.batch();
        batch.call('fees', 'calculator.getFees', {
            roadTypeId: this.settings.roadTypeId(),
            kindId: this.settings.kindId(),
            costs: type === 'cost' ? true : null,
            revenues: type === 'revenue' ? true : null,
            query: {
                select: '*,group(*)'
            }
        });

        if(calculator && calculator.id) {
            batch.call('calculator', 'calculator.getOne', {
                tracedoId: this.settings.tracedoId,
                id: calculator.id,
                query: {
                    select: '*,exchange(*),invoicedExchange(*)'
                }
            });
        }
        else {
            batch.call('rates', 'exchange.getCurrent', {
                query: {
                    select: '*,currency(iso),fromCurrency(iso)'
                }
            });
            batch.call('mainSubject', 'subject.getMain',{
                query: {
                    select: 'tradeCurrency'
                }
            });
        }

        let response: any = await batch.run();

        this._fees = response['fees'];
        
        if(response['calculator']) {

            let calc: TracedoCalculator = response['calculator'];
            this._calculator = ko.mapping.fromJS(calc);
            this._tradeCurrency = response['calculator'].tradeCurrency;

            // Pokud ma polozka kalkulatoru nejake kurzy, vytahneme kurzovy listek pro stejny den
            if(calc.exchangeId || calc.invoicedExchangeId) {
                this._rates = await this.rpc.call('exchange.getForDate', { 
                    date: calc.exchangeId ? calc.exchange.forDate : calc.invoicedExchange.forDate,
                    query: {
                        select: '*,currency(iso),fromCurrency(iso)'
                    }
                });
            }
            else {
                // Pokud polozka kalkulatoru kurzy nema, vytahneme kurzovy listek podle casu zalozeni polozky
                this._rates = await this.rpc.call('exchange.getByDate', { 
                    date: calc.createdStamp,
                    query: {
                        select: '*,currency(iso),fromCurrency(iso)'
                    }
                });
            }

        } else {
            const p = calculator ?? null;
            this._calculator = ko.mapping.fromJS({
                    id: null,
                    tracedoId: this.settings.tracedoId,
                    text: p ? p.text : '',
                    description: p ? p.description : '',
                    unit: p ? p.unit : '',
                    unitPrice: p ? p.unitPrice : null,
                    amount: p ? p.amount : null,
                    price: p ? p.price : null,
                    currencyId: p ? p.currencyId : null,
                    priceCzk: p ? p.priceCzk : null,
                    exchangeId: p ? p.exchangeId : null,
                    feeId: p ? p.feeId : null,
                    costs: type == 'cost' ? true : false,
                    revenues: type == 'revenue' ? true : false,
                    subjectId: p ? p.subjectId : null,
                    invoicedPrice: p ? p.invoicedPrice : null,
                    invoicedCurrencyId: p ? p.invoicedCurrencyId : null,
                    invoicedExchangeId: p ? p.invoicedExchangeId : null,
                    statusId: p ? p.statusId : null,
                    autogenerated: p ? p.autogenerated : null,
                    ownerSubject: p && p.id ? p.ownerSubject : (response.mainSubject ?? null)
                }
            );

            this._rates = response['rates'];
            this._tradeCurrency = response['mainSubject'].tradeCurrency;
        }

        this._czkId = this.codelistManager.getOneCurrencyByIso('czk').currencyId;
    }


    /**
     * Configure new observables
     */
    private configureObservables()
    {
        this._feeGroupName = ko.computed(() => {
            let feeId = this.calculator.feeId();
            let filteredFees = this.fees.filter((f) => f.id == feeId);
            return filteredFees.length > 0 ? filteredFees[0].group.name : '';
        });
    
        this._currencyIso = ko.computed(() => {
            let currencyId = this.calculator.currencyId();
            let iso = this.codelistManager.getOneCurrency(currencyId, 'iso');
            return iso ? iso : '';
        });
    
        this._invoicedCurrencyIso = ko.computed(() => {
            let currencyId = this.calculator.invoicedCurrencyId();
            let iso = this.codelistManager.getOneCurrency(currencyId, 'iso');
            return iso ? iso : '';
        });
    
        this._rateUsedCzk = ko.computed(() => {
            for(let r of this.rates) {
                if((r.fromCurrencyId == this.calculator.currencyId() && r.currencyId == this.czkId) || 
                   (r.currencyId == this.calculator.currencyId() && r.fromCurrencyId == this.czkId)) {
                    let date = kendo.toString(kendo.parseDate(r.forDate as string), 'd');
                    return '<strong>' + kendo.toString(r.rate, 'n3') + ' ' + r.fromCurrency.iso + ' = ' +
                            kendo.toString(r.amount, 'n0') + ' ' + r.currency.iso + '</strong>' +
                            ' (' + date + ')';
                }
            }
            return '';
        });
    
        this._rateUsedInvoiced = ko.computed(() => {
            for(let r of this.rates) {
                if((r.fromCurrencyId == this.calculator.invoicedCurrencyId() && r.currencyId == this.czkId) || 
                   (r.currencyId == this.calculator.invoicedCurrencyId() && r.fromCurrencyId == this.czkId)) {
                    let date = kendo.toString(kendo.parseDate(r.forDate as string), 'd');
                    let rate = '<strong>' + kendo.toString(r.rate, 'n3') + ' ' + r.fromCurrency.iso + ' = ' +
                            kendo.toString(r.amount, 'n0') + ' ' + r.currency.iso + '</strong>' +
                            ' (' + date + ')';
                    return this._rateUsedCzk() != rate ? rate : '';
                }
            }
            return '';
        });


        let updatePrice = () => {
            if(this.calculator.amount() && this.calculator.unitPrice()) {
                this.calculator.price(this.calculator.amount() * this.calculator.unitPrice());
            }
        };
        // if(this.tradeCurrency && !this.calculator.ownerSubject.tradeCurrency()){
        //     this.calculator.ownerSubject.tradeCurrency(this.tradeCurrency);
        // }
        this.calculator.amount.subscribe(updatePrice);
        this.calculator.unitPrice.subscribe(updatePrice);

        let updateCzkAndInvoicedPrice = () => {
            // Update Invoiced price
            let invoiced = null;
            /* if (this.calculator.currencyId() && this.calculator.price() && this.calculator.invoicedCurrencyId()) { */
            if (this.calculator.currencyId() && this.calculator.price() && this.calculator.invoicedCurrencyId()) {
                if (this.calculator.currencyId() != this.czkId && this.calculator.invoicedCurrencyId() != this.czkId && this.calculator.currencyId() != this.calculator.invoicedCurrencyId()) {
                    var priceToCzk = this.exchange(this.calculator.price(), this.calculator.currencyId(), this.czkId, 0);
                    const tradeCurr = (this.calculator.costs() === true || this.calculator.currencyId() == this.calculator.invoicedCurrencyId()) ? 0 : this.tradeCurrency; // this.calculator.ownerSubject.tradeCurrency();
                    invoiced = this.exchange(priceToCzk.price, this.czkId, this.calculator.invoicedCurrencyId(), tradeCurr);  // CZK TO EUR
                } else {
                    // např EUR TO CZK
                    const tradeCurr = this.calculator.costs() === true || this.calculator.currencyId() == this.calculator.invoicedCurrencyId() ? 0 : this.tradeCurrency; // this.calculator.ownerSubject.tradeCurrency();
                    invoiced = this.exchange(this.calculator.price(), this.calculator.currencyId(), this.calculator.invoicedCurrencyId(), tradeCurr);
                }
                if (invoiced === null) {
                    kendo.alert(i18n.t('common.captions.noExchangeRate'));
                } else {
                    this.calculator.invoicedPrice(invoiced.price);
                    this.calculator.invoicedExchangeId(invoiced.exchangeRateId);
                }
                if (this.calculator.currencyId() != this.czkId && this.calculator.invoicedCurrencyId() != this.czkId && this.calculator.currencyId() != this.calculator.invoicedCurrencyId()) {
                    this.calculator.invoicedExchangeId(invoiced.exchangeRateId);
                    this.calculator.exchangeId(priceToCzk.exchangeRateId);
                }
            } else {
                this.calculator.invoicedPrice(null);
                this.calculator.invoicedExchangeId(null);
            }

            // update ekvivalent CZK price
            if (this.calculator.invoicedPrice() && this.calculator.invoicedCurrencyId() && this.czkId) {

                let czkPrice = this.exchange(this.calculator.invoicedPrice(), this.calculator.invoicedCurrencyId(), this.czkId, 0);
                if (czkPrice === null) {
                    kendo.alert(i18n.t('common.captions.noExchangeRate'));
                } else {
                    this.calculator.priceCzk(czkPrice.price);
                    if (this.calculator.currencyId() == this.czkId || this.calculator.invoicedCurrencyId() == this.czkId || this.calculator.currencyId() == this.calculator.invoicedCurrencyId()) {
                        this.calculator.exchangeId(czkPrice.exchangeRateId);
                    }
                }
            } else {
                this.calculator.priceCzk(null);
                this.calculator.exchangeId(null);
            }

        };
        this.calculator.price.subscribe(updateCzkAndInvoicedPrice);
        this.calculator.currencyId.subscribe(updateCzkAndInvoicedPrice);
        this.calculator.invoicedCurrencyId.subscribe(updateCzkAndInvoicedPrice);
    }


    /**
     * Konverze castky z jedne meny do druhe
     * @param price Castka
     * @param currencyFrom ID měny - převod z
     * @param currencyTo ID měny - převod do
     * @param tradeCurrency ID přirážka k měně
     * @returns Castka v cilove mene nebo NULL, pokud se nenajde kurz
     */
    private exchange(price: number, currencyFrom: number, currencyTo: number, tradeCurrency: number): { price: number, same: boolean, exchangeRate?: Exchange, exchangeRateId?: number } | null {
        let exchangeRate = null;
        let exchangeRateId = null;
        let same = true;
        let margin = 1;
        for (let r of this.rates) {

            if (r.fromCurrencyId == currencyFrom && r.currencyId == currencyTo) { // cz to eu
                same = false;
                margin = 1 / (r.rate / r.amount);
                exchangeRate = r;
                exchangeRateId = r.id;
            }

            if (r.fromCurrencyId == currencyTo && r.currencyId == currencyFrom) {// eu to cz
                same = false;
                margin = r.rate / r.amount;
                exchangeRate = r;
                exchangeRateId = r.id;
            }
        }

        if (tradeCurrency > 0 && same === false) {
            margin = margin * (1 + tradeCurrency / 100);
        }
        return {
            price: price * margin,
            same: same,
            exchangeRate: exchangeRate,
            exchangeRateId: exchangeRateId
        };

    }

}