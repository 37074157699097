import { i18n } from "i18next-ko";
import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";


/**
 * FileManager
 */
@injectable()
export class HtmlEditor extends BaseDialogViewModel<any>
{
	private _content: KnockoutObservable<string> = ko.observable<string>('');

	private _cssLink: string;

	public get content(): string
	{
		return this._content();
	}

	public get editor(): kendo.ui.Editor
	{
		return this.element.find('textarea').data('kendoEditor');
	}

	/**
	 * Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();
		this._cssLink = await this.rpc.call('report.getCssLink');
		this._content(this.settings.content);
	}

	/**
	 * Render
	 */
	public async rendered(): Promise<any>
	{
	}

	/**
	 * Save
	 */
	public async save(): Promise<any>
	{
	}

	/**
	 * Template
	 */
	public dialogTemplate = (): HTMLElement => (
		<div style="height: 100%;">
			<textarea className="form-control" style="height: 100%;" data-bind="kendoEditor: {
				value: _content,
				tools: $root.settings._tools,
				stylesheets: [$root._cssLink],
				pasteCleanup : {
					all: false,
					css: false,
					custom: function(html) {
						return html;
					},
					keepNewLines: false,
					msAllFormatting: false,
					msConvertLists: false,
					msTags: false,
					none: true,
					span: false
				}
			}"></textarea>
		</div>
	);

}