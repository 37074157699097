import api from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!./tracy.all.css";

var options = {};

options.insert = function insertAtTop(element) {
                var parent = document.querySelector('head');
                var regExp = new RegExp("#tracy-debug", "ig");
                setTimeout(function() {
                  if(regExp.test(element.innerHTML)) {
                    element.classList.add("tracy-debug");
                  }
                }, 0);
                parent.appendChild(element);
              };
options.singleton = false;

var update = api(content, options);



export default content.locals || {};