import { BaseRequest } from './BaseRequest';
import { Request } from './Request';

// Batch Request
export class BatchRequest extends BaseRequest {

	// Batch stack
	batch: Request[] = [];

	// adds a batch call
	public call(id: string, method: string, params: any = {}): BatchRequest {
		this.batch.push(new Request(this.client, method, params, false, id));
		return this;
	}

	// adds a batch notification
	public notify(method: string, params: any = {}): BatchRequest {
		this.batch.push(new Request(this.client, method, params, true));
		return this;
	}

	// returns batch request payload
	public getPayload(): string {
		var payloads: string[] = [];
		this.batch.forEach((r: BaseRequest) => {
			payloads.push(r.getPayload());
		});
		return `[${payloads.join(',')}]`;
	}

	// Parse response
	public parseResponse<T>(response: any): T {
		if (typeof (response) == 'string') {
			response = JSON.parse(response);
		}
		var responses: any = {};
		if (response.forEach) {
			response.forEach((r: any) => {
				if (r.error) {
					throw r;
				}
				responses[r.id] = r.result;
			});
		}
		else if (response.error) {
			throw response;
		}
		return responses;
	}

}