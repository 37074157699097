import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { i18n } from "i18next-ko";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { ConnectWithCustomer } from "./ConnectWithCustomer";


/**
 * Customers GRID
 */
@injectable()
export class Customers extends BaseViewModel<any>
{

	// GRID element
	private gridElement: JQuery<HTMLElement>;

	// Kendo grid
	private grid: kendo.ui.Grid;

	// Grid Config
	private gridConfig: kendo.ui.GridOptions;

	// pro moznost manipulaci s nadrazenimy akcemi
	private gridConfiguratorViewModel: GridConfigurator;


	/**
	 * Configure kendo GRID
	 */
	private configureGrid()
	{
		// Tlacitka, ktera budou na toolbaru
		var toolbarConfig = [];
		if (this.user.isAllowed('entity.customer', 'connect')) {
			toolbarConfig.push({ 
				template: '<button type="button" class="btn btn-outline-primary" data-action="connect"><i class="icon-plus" ></i><span> ' + i18n.t('common.actions.connectToNewCustomer') + '</span></button>' 
			});
		}
		this.gridConfig = {
			autoBind: false,
			dataSource: {
				transport: {
					read: async (options: kendo.data.DataSourceTransportOptions) => {

						options.data.search = (this.grid.dataSource as any).searchText;

						KendoHelpers.replaceRequestData(options.data, '__'); // also replaces fields__ to fields->>'

						let response: any = await this.rpc.call('customer.getMy', {
							excludeMe: true,
							query: {
								...options.data,
								select: '*,country(*)'
							}
						});

						response.data = Objects.flatten(response.data, '__');

						options.success(response);							
					}
				},
				schema: {
					model: {
						fields: {
							'name': { editable: false, type: "string" },
							'ic': { editable: false, type: "string" },
							'address1': { editable: false, type: "string" },
							'city': { editable: false, type: "string" },
							'country__iso': { editable: false, type: "string" },
							'phone': { editable: false, type: "string" },
							'email': { editable: false, type: "string" }
						}
					},
					data: (d: any) => d.data,
					total: (d: any) => d.total
				},
				sort: { field: "name", dir: "asc" },
				pageSize: 20,
				serverPaging: true,
				serverFiltering: true,
				serverSorting: true
			},
			selectable: 'row',
			scrollable: true,
			reorderable: true,
			columnMenu: true,
			resizable: true,
			filterable: KendoHelpers.getGridFilter(),
			filterMenuOpen: KendoHelpers.gridFilterMenuOpen,
			columnMenuOpen: KendoHelpers.gridColumnMenuOpen,
			sortable: true,
			pageable: {
				refresh: true
			},
			toolbar: toolbarConfig,
			dataBound: () => {
				KendoHelpers.highlightColumn(this.grid);
				this.gridElement.find('[data-action=delete]').prop('disabled', true);
				this.gridElement.find('[data-action=update]').prop('disabled', true);
			},
			change: () => {
				var selected = this.grid.select().length > 0;
				this.gridElement.find('[data-action=delete]').prop('disabled', !selected);
				this.gridElement.find('[data-action=update]').prop('disabled', !selected);
			},
			columns: [{ 
					field: "name", 
					title: i18n.t('common.captions.name'),
					width: 40 
				}, { 
					field: "ic", 
					title: i18n.t('registration.firmIco'),
					width: 40 
				}, {
					field: 'address1',
					title: i18n.t('common.captions.address'),
					width: 280,
					template: '#= address1 + ", " + city + (countryId ? " " + country__iso : "") #'
				}, { 
					field: "phone", 
					title: i18n.t('registration.phone'),
					width: 40 
				}, { 
					field: "email", 
					title: i18n.t('registration.email'),
					width: 40 					
				}]
		};
	}


	/**
	 * ViewModel startup
	 */
	public async startup()
	{
		// Pokud sem nemuze, tak ho vyhodime
		if(!this.user.isAllowed('section.settingsCustomers', 'enter')) {
			throw new BadRequestError('Access denied', 403);
		}

		await super.startup();

		// Configure GRID
		this.configureGrid();
	}

	public async rendered() 
	{
		// grid element
		this.gridElement = this.element.find('[data-grid=customers]');

		/// initialize grid
		this.gridElement.kendoGrid(this.gridConfig);
		// Kendo GRID reference
		this.grid = this.gridElement.data('kendoGrid');
		// BIND action buttons
		this.gridElement.on('click', '[data-action=connect]', this.connectWithCustomer.bind(this));
		//při vygenerovaní filtračního menu vytvořit i metodu pro vyhledání prázdných hodnot(IS NULL)
		KendoHelpers.allowFilterEmpty(this.gridElement);

		// load grid configurator
		let vm = await this.loadViewFrame<GridConfigurator>(GridConfigurator, 'gridconf', {
			grid: this.grid,
			name: 'Customers.Management',
			exports: [{
				name: 'XLS export',
				method: 'customer.xlsExport',
				params: {
					excludeMe: true
				}
			}]
		} as IGridConfiguratorOptions);

		this.gridConfiguratorViewModel = vm;
	}


	/**
	 * Open dialog for customer invitation/connection
	 */
	public async connectWithCustomer(): Promise<void>
	{
		let vm = await this.loadViewFrame<ConnectWithCustomer>(ConnectWithCustomer, 'edit', {
			dialog: {
				modal: true,
				width: 750,
				height: 550,
				title: i18n.t('common.actions.connectToNewCustomer'),
				buttons: (vm: ConnectWithCustomer, window: kendo.ui.Window): any[] => []
			}
		});
		vm.onDone.attach((savedInfo: any) => { 
			if(savedInfo.invitationSent) {
				this.flash.success(i18n.t('system.subjects.registrationInvitationSent'));
			}
			else if(savedInfo.approvalSent) {
				this.flash.success(i18n.t('system.subjects.connectionInvitationSent'));
			}
			else {
				this.flash.success(i18n.t('system.subjects.customerHasBeenAdded'));
			}
			vm.closeDialog(); 
			this.grid.dataSource.read();
		});
		// Pri zmene povolenych akci upravime tlaticka v dialogu
		vm.onButtonsChanged.attach((buttons) => {
			let btnPanel = vm.element.next('.k-button-panel');
			// remove old buttons
			btnPanel.find('.btn-wrapper').remove();
			let wrapper = jQuery('<div class="btn-wrapper float-end"></div>');
			wrapper.appendTo(btnPanel);
			// set new buttons
			buttons.forEach((btn) => {
				let button = jQuery('<button type="button" class="btn btn-primary ms-1"></button>');
				button.html(btn.label);
				button.on('click', () => { btn.method(); });
				wrapper.append(button);
			});
		});
		// po otevreni dialogu zavolame obnoveni tlacitek
		vm.onDialogOpened.attach(() => vm.refreshButtons());
	}
	
    
	public template = (): HTMLElement => (

		<div>

			<h2 data-bind="i18n: 'nav.customers'"></h2>

			<view-frame name="gridconf" className="mb-2" />

			<div data-grid="customers" className="grid-autoheight"></div>

			<view-frame name="edit" />

		</div>
	);

}