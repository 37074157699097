import { i18n } from "i18next-ko";
import { InjectionToken } from "tsyringe";
import { BaseWidget } from "./BaseWidget";
import { BaseWidgetSettings } from "./BaseWidgetSettings";
import { IWidgetBaseSettings } from "./IWidgetBaseSettings";
import { IWidgetSettings } from "./IWidgetSettings";

/**
 * Base class for all configurable Widgets
 */
export abstract class BaseConfigurableWidget extends BaseWidget<IWidgetSettings>
{
    /**
     * Is this widget configurable
     */
    protected _configurable: boolean = true;

    /**
     * Open widget settings dialog with given class
     */
    protected async openSettingsDialog<T>(token: InjectionToken<T>): Promise<void> 
	{
		// load view model
		await this.loadViewFrame<T>(token, 'widgetsettings', {
			widgetSettings: this.settings.settings,
			widgetId: this.id,
			dialog: {
				modal: true,
				width: 880,
				height: 550,
				title: i18n.t('dashboard.widgetSettings'),
				buttons: (vm: any, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.cancel'),
							click: () => window.close()
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: () => vm.saveSettings().then((newSettings: any) => {
								this.flash.success(i18n.t('widget.settingsSaved'));
								// refresh settings
								this.settings.settings = jQuery.extend({}, this.settings.definition.defaults, newSettings);
								// close and refresh
								window.close();
								this.refresh();
							})
						}
					];
				}
			}
		});		
	}


	/**
	 * Open settings
	 */
	 public abstract openSettings(): Promise<void>;

}