import { VoidAsyncEvent } from "ts-events";
import { h } from "../../../../tracejs/src/utils/JSXFactory";

export class ReadOnlyHeaderTemplate
{
    // #region Customer

    public static customerParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customer'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mySubject.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (myPicUserId() ? myPicUser.realName() : '')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.spediter'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: supplierPartner.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.spediterPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (supplierPartnerPicUserId() ? supplierPartnerPicUser.realName() : '')"></label>
            </div>

        </fieldset>
    );

    public static customerMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.mainInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'trade.demand.edit.kind'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: kind.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'trade.demand.edit.mode'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: roadType.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef2'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef2"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.clientRef3'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: clientRef3"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myUzp'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: kendo.toString(myUzp(), 'd')"></label>
            </div>

        </fieldset>
    );

    public static customerIdentification = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.identification'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.status'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: trcStatus.name"></label>
            </div>
            <ko if="myInvoicingStatusId()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.invoicingStatus'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: myInvoicingStatusId() ? myInvoicingStatus.name() : '-'"></label>
                </div>
            </ko>
            <ko if="mySeasonId()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.season'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mySeason.name"></label>
                </div>
            </ko>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.number'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: myNumber"></label>
            </div>
            <ko if="customerPartnerId()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartnerNumber'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: customerPartnerNumber"></label>
                </div>
            </ko>
            <ko if="supplierPartnerId() && supplierPartnerNumber() != myNumber()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.supplierPartnerNumber'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: supplierPartnerNumber"></label>
                </div>
            </ko>

        </fieldset>
    );

    public static customerContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.containerInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.sealNr'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: sealNr"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.cntrNr'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: cntrNr"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.cntrType'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: bdpCntrType.estoneCode"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.rejdar'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: rejdar"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.vessel'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: vessel"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.voyageNr'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: voyageNr"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mbl'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mbl"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customOffice'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: customOffice"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.shipper'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: shipperName"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.consignee'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: consigneeName"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.returnPickupDepot'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: depot.name"></label>
            </div>

        </fieldset>            
    );

    public static customerCargoInfo = (labelMd: number = 4, valueMd: number = 8): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.cargoInformation'"></legend>

            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.colli'"></label>
                <label className="col-md-3 col-form-label" data-bind="text: kendo.toString(colli(), 'n0')"></label>
                <label className="col-md-2 col-form-label" data-bind="i18n: 'common.captions.packingList'"></label>
                <label className="col-md-3 col-form-label" data-bind="text: kendo.toString(sumColli(), 'n0')"></label>
            </div>
            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.kgs'"></label>
                <label className="col-md-3 col-form-label" data-bind="text: kendo.toString(kgs(), 'n2')"></label>
                <label className="col-md-2 col-form-label" data-bind="i18n: 'common.captions.packingList'"></label>
                <label className="col-md-3 col-form-label" data-bind="text: kendo.toString(sumKgs(), 'n2')"></label>
            </div>
            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.cbm'"></label>
                <label className="col-md-3 col-form-label" data-bind="text: kendo.toString(cbm(), 'n2')"></label>
                <label className="col-md-2 col-form-label" data-bind="i18n: 'common.captions.packingList'"></label>
                <label className="col-md-3 col-form-label" data-bind="text: kendo.toString(sumCbm(), 'n2')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.chargeableVolume'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="text: kendo.toString(chargeableVolume(), 'n2')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.ldm'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="text: kendo.toString(ldm(), 'n2')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.cargoDescription'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="text: cargoDescription"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.adr'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="bool: adr"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.adrClass'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="text: adrClass"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.un'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="text: un"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-md-${labelMd} col-form-label`} data-bind="i18n: 'common.captions.packingGroup'"></label>
                <label className={`col-md-${valueMd} col-form-label`} data-bind="text: packingGroup"></label>
            </div>


        </fieldset>
    );

    public static customerDriverInfo = (): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.driverInformation'"></legend>

            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.truckType'"></label>
                <label className="col-md-8 col-form-label" data-bind="text: truckTypeId() ? truckType.name() : ''"></label>
            </div>
            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.spz'"></label>
                <label className="col-md-8 col-form-label" data-bind="text: spz"></label>
            </div>
            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.driver'"></label>
                <label className="col-md-8 col-form-label">
                    <ko if="driverUserId()">
                        <span data-bind="text: driverUser.realName"></span>
                        &nbsp;&nbsp;<i className="icon-phone"></i>
                        <span data-bind="text: driverUser.mobile() ? driverUser.mobile() : driverUser.phone()"></span>
                    </ko>
                </label>
            </div>
            <div className="row mb-2">
                <label className="col-md-4 col-form-label" data-bind="i18n: 'common.captions.driverInfo'"></label>
                <label className="col-md-8 col-form-label" data-bind="text: driverInfo"></label>
            </div>

        </fieldset>
    );

    public static customerPrices = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.pricesInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.purchasePrice'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                    <strong data-bind="text: kendo.toString(purchasePrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: purchasePriceCurrency.iso"></strong>
                </label>
            </div>

        </fieldset>
    );

    // #endregion
    

    // #region Spediter

    /**
     * Zucastnene strany, spediter vidi zakaznika, sebe a pripadne dalsiho dopravce
     */
    public static spediterParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartner'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: customerPartner.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartnerPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (customerPartnerPicUserId() ? customerPartnerPicUser.realName() : '')"></label>
            </div>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mySubject'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mySubject.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (myPicUserId() ? myPicUser.realName() : '')"></label>
            </div>

            <ko if="mySubjectId() != supplierPartnerId()">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.supplierPartner'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: supplierPartner.name"></label>
                </div>
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.supplierPartnerPicUser'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (supplierPartnerPicUserId() ? supplierPartnerPicUser.realName() : '')"></label>
                </div>
            </ko>

        </fieldset>
    );

    /**
     * Zakladni informace jsou stejne pro speditera jako pro zakaznika
     */
    public static spediterMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerMainInfo(l, v);

    /**
     * Spediter identification je stejna jako customer identification
     */
    public static spediterIdentification = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerIdentification(l, v);

    /**
     * Container info je stejne pro speditera jako pro zakaznika
     */
    public static spediterContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.customerContainerInfo(l, v);

    /**
     * Cargo info je stejne pro speditera jako pro zakaznika
     */
    public static spediterCargoInfo = (): HTMLElement => ReadOnlyHeaderTemplate.customerCargoInfo();

    /**
     * Spediter vidi stran ridice to same co zakaznik
     */
    public static spediterDriverInfo = (): HTMLElement => ReadOnlyHeaderTemplate.customerDriverInfo();

    /**
     * Spediter vidi cenu nakup + cenu prodej
     */
    public static spediterPrices = (l: number[] = [4, 3, 3], v: number[] = [8, 9, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.pricesInformation'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="uniqueFor: 'sellPrice', i18n: 'common.captions.sellPrice'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                    <strong data-bind="text: kendo.toString(sellPrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: sellPriceCurrency.iso"></strong>
                </label>
            </div>

            <ko if="purchasePrice() !== null">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.purchasePrice'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                        <strong data-bind="text: kendo.toString(purchasePrice(), 'n0')"></strong>&nbsp;<strong data-bind="text: purchasePriceCurrency.iso"></strong>
                    </label>
                </div>
            </ko>

        </fieldset>
    );

    // #endregion
    
    
    // #region Carrier

    /**
     * Zucastnene strany, carrier vidi prostrednika/speditera (odberatele), sebe a pripadne dalsiho dopravce/prostrednika (dodavatele)
     */
     public static carrierParties = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => (
        <fieldset>
            <legend data-bind="i18n: 'common.captions.parties'"></legend>

            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartner'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: customerPartner.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.customerPartnerPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (customerPartnerPicUserId() ? customerPartnerPicUser.realName() : '')"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.mySubject'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: mySubject.name"></label>
            </div>
            <div className="row mb-2">
                <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.myPicUser'"></label>
                <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (myPicUserId() ? myPicUser.realName() : '')"></label>
            </div>

            <ko if="supplierPartnerId() !== null">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.supplierPartner'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: supplierPartner.name"></label>
                </div>                
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.supplierPartnerPicUser'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`} data-bind="text: (supplierPartnerPicUserId() ? supplierPartnerPicUser.realName() : '')"></label>
                </div>                
            </ko>
            <ko if="supplierPartnerId() === null && $root.canPass">
                <div className="row mb-2">
                    <label className={`col-lg-${l[0]} col-xl-${l[1]} col-xxl-${l[2]} col-form-label`} data-bind="i18n: 'common.captions.supplierPartner'"></label>
                    <label className={`col-lg-${v[0]} col-xl-${v[1]} col-xxl-${v[2]} col-form-label`}>
                        <button className="btn btn-secondary" data-bind="click: $root.pass.bind($root), i18n: 'common.captions.pass'"></button>
                    </label>
                </div>
            </ko>

        </fieldset>
    );

    /**
     * Zakladni informace jsou stejne pro sopravce jako pro speditera
     */
    public static carrierMainInfo = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.spediterMainInfo(l, v);

    /**
     * Identifikace pro carriera je stejna jako pro speditera
     */
    public static carrierIdentification = (l: number[] = [5, 4, 3], v: number[] = [7, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.spediterIdentification(l, v);

    /**
     * Container info je stejne pro dopravce jako pro speditera
     */
    public static carrierContainerInfo = (l: number[] = [4, 4, 3], v: number[] = [8, 8, 9]): HTMLElement => ReadOnlyHeaderTemplate.spediterContainerInfo(l, v);
 
    /**
     * Cargo info je stejne pro dopravce jako pro speditera
     */
    public static carrierCargoInfo = (): HTMLElement => ReadOnlyHeaderTemplate.spediterCargoInfo();
 
    /**
     * Dopravce vidi stran ridice to same co spediter
     */
    public static carrierDriverInfo = (): HTMLElement => ReadOnlyHeaderTemplate.spediterDriverInfo();
 
    /**
     * Dopravce vidi stejne ceny, jako vidi spediter (nakupni + prodejni)
     */
    public static carrierPrices = (): HTMLElement => ReadOnlyHeaderTemplate.spediterPrices();

    // #endregion
}