import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";

/**
 * View for Supervisor - Managing role permissions
 */
@injectable()
export class RolePermissions extends BaseViewModel<any>
{

    protected roles: any[] = [];

    protected resourcesGroups: any[] = [];

    protected resources: any[] = [];

    protected actionsArray: any[] = [];

    protected groupActions: any[] = [];

    protected fileTypes: any[] = [];

    protected userTypes: any[] = [];


    // HashMapa zdroju
    protected resourcesMap: { [key: number]: any } = {};

    // HashMapa typu souboru (DAT_TYPES)
    protected fileTypesMap: { [key: number]: any } = {};

    // HashMapa userTypu (DAT_USERTYPES)
    protected userTypesMap: { [key: number]: any } = {};


    // HashMapa akci tykajicich se souboru (+ jejich pocet)
    protected actionsForFilesCount: number = 0;
    protected actionsForFilesMap: { [key: number]: any } = {};
    protected actionsForFiles: any = [];


    // HashMapa akci NEtykajicich se souboru (+ jejich pocet)
    protected actionsCount: number = 0;
    protected actionsMap: { [key: number]: any } = {};
    protected actions: any = [];    

    // Hashmapa pro relevantni akce pro skupiny resourcu
    protected groupActionsMap: { [key: number]: any } = {};

    // HashMapa pro skupiny zdroju
    protected resourcesGroupsMap: { [key: number]: any } = {};

    // ACL pro soubory
    protected rolesFiles: any = [];

    // ACL pro ostatni zdroje
    protected rolesResources: any = [];


    // zvolena role
    protected selectedRoleId: KnockoutObservable<number> = ko.observable(null);


	/**
	 * ViewModel startup
	 */
	public async startup()
	{
		// Ma povolenou spravu opravneni
		if (!this.user.isAllowed('section.rolePermissions', 'enter')) {
		 	throw new BadRequestError('Access denied', 403);
		}

        let batch = this.rpc.batch();
        batch.call('roles', 'acl.getRoles', { query: { sort: [{field: 'roleId', dir: 'asc'}] } });
        batch.call('resourcesGroups', 'acl.getResourcesGroups', { query: { sort: [{field: 'posIndex', dir: 'asc'}] } });
        batch.call('resources', 'acl.getResources', { query: { sort: [{field: 'path', dir: 'asc'}] } });
        batch.call('actions', 'acl.getActions', { query: { sort: [{field: 'name', dir: 'asc'}] }});
        batch.call('groupActions', 'acl.getResourcesGroupsActions', { query: { select: 'groupId,action(*)' } });
        batch.call('fileTypes', 'file.getFileTypes');
        batch.call('userTypes', 'file.getAllUserTypes');

        batch.call('rolesResources', 'acl.getRolesResources');
        batch.call('rolesFiles', 'acl.getRolesFiles');

        let response: any = await batch.run();

        this.roles = response['roles'];
        this.resourcesGroups = response['resourcesGroups'];
        this.resources = response['resources'];
        this.actionsArray = response['actions'];
        this.groupActions = response['groupActions'];
        this.fileTypes = response['fileTypes'];
        this.userTypes = response['userTypes'];

        this.groupActions.forEach((ga) => {
            if(this.groupActionsMap[ga.groupId] === undefined) {
                this.groupActionsMap[ga.groupId] = [];
            }
            this.groupActionsMap[ga.groupId].push(ga.action);
        });

        // HashMapa skupin zdroju
        this.resourcesGroups.forEach((rg) =>  {
            this.resourcesGroupsMap[rg.groupId] = rg;
        });

        // HashMapa zdroju
        this.resources.forEach((res) =>  {
            this.resourcesMap[res.resourceId] = res;
        });

        // HashMapa typu souboru (DAT_TYPES)
        this.fileTypes.forEach((type: any) => {
            this.fileTypesMap[type.typeId] = type;
        });

        // HashMapa userTypu souboru (DAT_USERTYPES)
        this.userTypes.forEach((userType: any) => {
            this.userTypesMap[userType.userTypeId] = userType;
        });
            
        // spocitat, kolik akci se tyka souboru
        // a naplnit HashMapu
        this.actionsForFilesCount = 0;
        this.actionsForFiles = [];
        this.actionsArray.forEach((action: any) => {
            if(action.forFiles) {
                this.actionsForFilesCount++;
                this.actionsForFilesMap[action.actionId] = action;
                this.actionsForFiles.push(action);
            }
        });

        // spocitat, kolik akci se NEtyka souboru
        // a naplnit HashMapu
        this.actionsCount = 0;
        this.actions = [];
        this.actionsArray.forEach((action: any) => {
            if(!action.forFiles) {
                this.actionsCount++;
                this.actionsMap[action.actionId] = action;
                this.actions.push(action);
            }
        });


        this.rolesFiles = ko.mapping.fromJS(response['rolesFiles']);
        this.rolesResources = ko.mapping.fromJS(response['rolesResources']);

		await super.startup();
	}

	public async rendered() 
	{
		
	}



    public async save(): Promise<void>
    {
        let rolesFiles = ko.mapping.toJS(this.rolesFiles);
        let rolesResources = ko.mapping.toJS(this.rolesResources);

        let batch = this.rpc.batch();
        batch.call('rolesFiles', 'acl.saveRolesFiles', { data: rolesFiles });
        batch.call('rolesResources', 'acl.saveRolesResources', { data: rolesResources });
        await batch.run();

        this.flash.success("Úspěšně uloženo");
    }

    
	public template = (): HTMLElement => (

		<div>

			<h2 data-bind="i18n: 'nav.rolePermissions'"></h2>
            <div className="panel">

                <h3>1. Zvolte uživatelskou roli</h3>
                <div className="mb-4">
                    <div className="row">
                        <label className="inline col-auto col-form-label" data-bind="uniqueFor: 'pn1'">User role:</label>
                        <div className="col-auto">
                            <select className="form-select" data-bind="options: $root.roles, optionsText: 'name', optionsValue: 'roleId', value: $root.selectedRoleId"></select>
                        </div>
                    </div>
                </div>

                <h3>2. Nastavte systémová oprávnění</h3>
                <div className="mb-4">
                    <ko foreach="$root.rolesResources">
                        <ko if="$data.roleId() === $root.selectedRoleId()">
                            <table className="table table-sm table-bordered">
                                <thead className="table-light">
                                    <tr>
                                        <th>Skupina zdrojů</th>
                                        <th style="width: 220px;">Zdroj</th>
                                        <th>Akce</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ko foreach="$data.groups">
                                        <tr>
                                            <td style="width:120px" className="align-middle text-start bg-light">
                                                <h4><span data-bind="text: $root.resourcesGroupsMap[$data.groupId()].name"></span></h4>
                                            </td>
                                            <td className="p-0" colspan="2">

                                                <table className="table table-sm w-auto mb-0">
                                                    <tbody>
                                                        <tr>
                                                            <th className="border-end"></th>
                                                            <ko foreach="$root.groupActionsMap[$data.groupId()]">
                                                                <th data-bind="text: $data.name" className="text-center border-end"></th>
                                                            </ko>
                                                        </tr>
                                                        <ko foreach="$data.resources">
                                                            <tr>
                                                                <td style="width:219px" className="border-end">
                                                                    <span data-bind="text: $root.resourcesMap[$data.resourceId()].name"></span>
                                                                </td>
                                                                <ko foreach="$root.groupActionsMap[$parent.groupId()]">
                                                                    <td className="text-center border-end" style="width:90px">
                                                                        <ko if="$parent.actions[$data.actionId] !== undefined">
                                                                            <label>
                                                                                <input type="checkbox" data-bind="
                                                                                    checked: $parent.actions[$data.actionId],
                                                                                    attr: { 'title': '\'' + $root.resourcesMap[$parent.resourceId()].code + '\', \'' + $root.actionsMap[$data.actionId].code + '\'' }
                                                                                " />
                                                                            </label>
                                                                        </ko>
                                                                    </td>
                                                                </ko>
                                                            </tr>
                                                        </ko>

                                                    </tbody>
                                                </table>
                                            
                                            </td>
                                        </tr>
                                    </ko>
                                </tbody>
                            </table>
                        </ko>
                    </ko>
                </div>

                <h3>3. Nastavte souborová oprávnění</h3>
                <div className="mb-4">
                    <ko foreach="$root.rolesFiles">
                        <ko if="$data.roleId() === $root.selectedRoleId()">
                            <ko foreach="$data.types">
                                <h4><span>Soubor typu:</span> <strong data-bind="text: $root.fileTypesMap[$data.typeId()].name"></strong></h4>
                                <table className="table table-sm table-bordered xtable-striped w-auto">
                                    <thead>
                                        <tr>
                                            <th className="align-middle text-center" rowspan="2">Zdroj</th>
                                            <th className="align-middle text-center" rowspan="2">Typ souboru</th>
                                            <th className="align-middle text-center" rowspan="2">Izolovaný</th>
                                            <th className="align-middle text-center" data-bind="attr: { 'colspan': $root.actionsForFilesCount }">Akce</th>
                                        </tr>
                                        <tr>
                                            <ko foreach="$root.actionsForFiles">
                                                <th data-bind="text: $data.name"></th>
                                            </ko>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ko foreach="$data.userTypes">
                                            <tr>
                                                <td><span data-bind="text: $root.resourcesMap[$data.resourceId()].name"></span></td>
                                                <td><span data-bind="text: $root.userTypesMap[$data.userTypeId()].name"></span></td>
                                                <td className="text-center"><span data-bind="html: $root.userTypesMap[$data.userTypeId()].isolated ? '<span title=\'Viditelný pouze pro stejný subjekt, který dokument nahraje do systému\'>Ano</span>' : '-'"></span></td>
                                                <ko foreach="$root.actionsForFiles">
                                                    <td className="text-center">
                                                        <label>
                                                            <input type="checkbox" data-bind="
                                                                checked: $parent.actions[$data.actionId],
                                                                attr: { 'title': '\'' + $root.resourcesMap[$parent.resourceId()].code + '_' + $root.userTypesMap[$parent.userTypeId()].ident + '\', \'' + $root.actionsForFilesMap[$data.actionId].code + '\'' }
                                                            " />
                                                        </label>
                                                    </td>
                                                </ko>
                                            </tr>
                                        </ko>
                                    </tbody>
                                </table>
                            </ko>

                        </ko>
                    </ko>
                </div>


                <div className="row mb-2">
                    <div className="col-auto">
                        <button className="btn btn-primary" data-bind="click: $root.save.bind($root), i18n: 'common.actions.save'"></button>
                    </div>
                </div>                


            </div>
		</div>
	);

}