import { ICultureSettings } from './ICultureSettings';
import { Culture } from './Culture';
import { KnockoutHelpers } from './../utils/KnockoutHelpers';
import "reflect-metadata";

import { IAuthorizator } from './../security/IAuthorizator';
import { IAuthenticator } from './../security/IAuthenticator';

import { container } from 'tsyringe';

import { Application } from './Application';
import { ViewModelFactory } from './ViewModelFactory';
import { TracyJS } from './../debug/TracyJS';
import { User } from './../security/User';
import { Router } from './Router';
import { Client } from '../net/jsonrpc/Client';
import { IClientOptions } from '../net/jsonrpc/IClientOptions';
import { AjaxLoader } from '../ui/AjaxLoader';
import { IAjaxLoader } from '../ui/IAjaxLoader';
import { FlashMessenger } from '../ui/FlashMessenger';
import { IFlashMessengerSettings } from '../ui/IFlashMessengerSettings';

/**
 * TraceJS lib
 */
export class Bootstrap {

	/**
	 * Config files to load
	 */
	private configFiles: string[];

	/**
	 * Configuration structures array
	 */
	private configs: any[];

	/**
	 * Settings
	 */
	private settings: any;


	/**
	 * TraceJS constructor
	 */
	constructor() {
		// config files to load
		this.configFiles = [];
		this.configs = [];
		// defaults
		this.settings = null;
	}

	/**
	 * Add configuration structure
	 * @param config 
	 */
	public addConfig(config: any): this {
		this.configs.push(config);
		return this;
	}

	/**
	 * Add a config file
	 * @param path Path to a config file
	 */
	public addConfigFile(path: string): this {
		this.configFiles.push(path);
		return this;
	}

	/**
	 * Reads config files and merges them together
	 */
	public async getSettings(): Promise<any> {
		if(this.settings !== null) {
			return this.settings;
		}

		// Merge built-in configuration structures
		this.settings = jQuery.extend(true, {}, this.settings, ...this.configs);

		// Load other configuration files
		let promises: JQuery.jqXHR<any>[] = [];
		this.configFiles.forEach(configFile => {
			promises.push(jQuery.ajax({
				url: (configFile + "?_" + new Date().getMilliseconds().toString()),
				dataType: 'json'
			}));
		});

		// after all config files are loaded, it will be merged into one config tree with built-in structures
		let responses = await Promise.all(promises);
		this.settings = jQuery.extend(true, {}, this.settings, ...responses);

		return this.settings;
	}

	/**
	 * Register system services and call callback for registering additional services
	 * Returns app
	 * @return Promise<Application>
	 */
	public async initialize(callback: Function): Promise<Application> {

		let settings = await this.getSettings();

		// Flash messenger service
		const flashMessenger = new FlashMessenger((this.settings.flashMessenger as IFlashMessengerSettings));
		container.registerInstance<FlashMessenger>(FlashMessenger, flashMessenger); // { hideTimeout: 50000000 }

		// Router instance
		const culture = new Culture((this.settings.culture as ICultureSettings));
		container.registerInstance<Culture>(Culture, culture);

		// Router instance
		const router = new Router();
		container.registerInstance<Router>(Router, router);

		// User instance
		const user = new User();
		container.registerInstance<User>(User, user);

		// DebugBar instance
		const tracyJS = new TracyJS(this.settings.tracyJS);
		container.registerInstance<TracyJS>(TracyJS, tracyJS);

		// ViewModelFactory instance
		const viewModelFactory = new ViewModelFactory(router, user);
		container.registerInstance<ViewModelFactory>(ViewModelFactory, viewModelFactory);
		
		// Application instance
		const application = new Application(this.settings.application, viewModelFactory, router, culture);
		container.registerInstance<Application>(Application, application);

		// RPC client
		if(this.settings.rpc) {
			const client = new Client(this.settings.rpc as IClientOptions);
			container.registerInstance<Client>(Client, client);
		}

		// Ajax Loader
		if(this.settings.ajaxLoader) {
			const client = new AjaxLoader(this.settings.ajaxLoader as IAjaxLoader);
			container.registerInstance<AjaxLoader>(AjaxLoader, client);
		}		

		// User-defined DI
		await callback(settings);

		// Attach Authenticator & Authorizator to User if defined
		if(container.isRegistered<IAuthenticator>('IAuthenticator')) {
			let authenticator = container.resolve<IAuthenticator>('IAuthenticator');
			user.authenticator = authenticator;
		}
		if(container.isRegistered<IAuthorizator>('IAuthorizator')) {
			let authorizator = container.resolve<IAuthorizator>('IAuthorizator');
			user.authorizator = authorizator;
		}

		// register additional KnockoutJS handlers
		KnockoutHelpers.registerHandlers();

		return application;
	}

	/**
	 * Get application instance
	 * @returns Application
	 */
	public getApplication(): Application {
		return container.resolve<Application>(Application);
	}
}