import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";


import { CultureSwitch } from "../components/CultureSwitch";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";

@injectable()
export class SetPassword extends BaseViewModel<any>
{

	private password: KnockoutObservable<string> = ko.observable('');
	private password2: KnockoutObservable<string> = ko.observable('');

    private email: KnockoutObservable<string> = ko.observable('');
    private token: KnockoutObservable<string> = ko.observable('');

    private userObject: KnockoutObservable<any> = ko.observable(null);

    private errorMessage: KnockoutObservable<string> = ko.observable('');


	public onPasswordSet: SyncEvent<void> = new SyncEvent<void>();


    public async startup()
    {
        await super.startup();
        this.email(this.router.query.email as string);
        this.token(this.router.query.token as string);
        
        let user = await this.rpc.call('user.getOneByEmailAndToken', { email: this.email(), token: this.token() });
        this.userObject(ko.mapping.fromJS(user));
    }

    /**
     * Odeslan formular pro zapomenute heslo
     */
	public async passwordSetFormSubmitted()
	{
		let password = this.password();
		let password2 = this.password2();
        if(jQuery.trim(password) === '') {
            kendo.alert(i18n.t('setPassword.fillPassword'));
            return;
        }
        if(password !== password2) {
            kendo.alert(i18n.t('setPassword.noMatch'));
            return;
        }

		let params = { 
			email: this.email(),
            token: this.token(),
            password: password,
			culture: this.culture.localeShort 
		};
		let response: any = await this.rpc.call('user.setForgottenPassword', params);

		if (response) {
            this.flash.success(i18n.t('setPassword.success'));
            this.onPasswordSet.post();
        }
        else {
            this.flash.error(i18n.t('setPassword.failure'));
        }
	}

	/**
	 * On render -> load cultures switch
	 */	
	public async rendered()
	{
		await this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures');
	}

	public template = (): HTMLElement => (

		<div class="centered-form container h-expand d-flex flex-column justify-content-center">
			<div class="row justify-content-md-center">
				<div style="max-width: 450px">

					<view-frame name="cultures" class="float-end" />

					<h1 class="mb-2 text-primary">TRACEDO</h1>

					<div class="panel radius panel-big-margin p-4 pb-2">

                        <ko if="$root.userObject()">

						    <h2 data-bind="i18n: 'setPassword.heading'" class="mb-3"></h2>

                            <form method="post" data-bind="submit: $root.passwordSetFormSubmitted">

                                <ko if="$root.errorMessage() !== ''">
                                    <div class="alert-box alert radius" data-bind="text: $data"></div>
                                </ko>

                                <div class="mb-3">
                                    <label for="setPassword-email" class="form-label" data-bind="i18n: 'setPassword.email'"></label>
                                    <input type="email" id="setPassword-email" disabled="disabled" data-bind="value: $root.userObject().email" class="form-control" />
                                </div>
                                <div class="mb-3">
                                    <label for="setPassword-realName" class="form-label" data-bind="i18n: 'setPassword.realName'"></label>
                                    <input type="text" id="setPassword-realName" disabled="disabled" data-bind="value: $root.userObject().firstName() + ' ' + $root.userObject().lastName()" class="form-control" />
                                </div>

                                <div class="mb-3">
                                    <label for="setPassword-password" class="form-label" data-bind="i18n: 'setPassword.password'"></label>
                                    <input type="password" id="setPassword-password" data-bind="value: $root.password" class="form-control" />
                                </div>
                                <div class="mb-3">
                                    <label for="setPassword-password2" class="form-label" data-bind="i18n: 'setPassword.password2'"></label>
                                    <input type="password" id="setPassword-password2" data-bind="value: $root.password2" class="form-control" />
                                </div>

                                <div class="row mb-3">
                                    <div class="col-auto">
                                        <button type="submit" class="btn btn-primary" data-bind="i18n: 'setPassword.btnSetPassword'"></button>
                                    </div>
                                </div>

                            </form>

                        </ko>
                        <ko ifnot="$root.userObject()">

                            <p><em data-bind="i18n: 'setPassword.invalidRequest'"></em></p>

                        </ko>

					</div>

				</div>
			</div>
		</div>

	);    

}