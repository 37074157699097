import { h } from "../../../../tracejs/src/utils/JSXFactory";


export class ReadOnlyCheckpointsTemplate
{

    public static customer = (): HTMLElement => (

        <fieldset>
            <h3 className="d-inline-block me-2 align-baseline" data-bind="i18n: 'common.captions.checkpoints'"></h3>

            <div class="k-grid k-widget k-display-block" style="margin: 5px 0 0 0">
                <table className="table checkpoints-table">
                    <thead>
                        <tr>
                            <th data-bind="i18n: 'common.captions.name'"></th>
                            <th data-bind="i18n: 'common.captions.place'"></th>
                            <th data-bind="i18n: 'common.captions.contact'"></th>
                            <th data-bind="i18n: 'common.captions.estimatedTime'"></th>
                            <th data-bind="i18n: 'common.captions.actualTime'"></th>
                            <th data-bind="i18n: 'common.captions.reference'"></th>
                            <th data-bind="i18n: 'common.captions.remark'"></th>
                        </tr>
                        <tr>
                            <td data-bind="i18n: 'common.captions.origin'"></td>
                            <td>
                                <ko ifnot="originAddressName()">
                                    <em>-</em>
                                </ko>
                                <ko if="originAddressName()">
                                    <address>
                                        <span data-bind="text: originAddressName"></span><br />
                                        <span data-bind="text: originAddressStreet"></span><br />
                                        <ko if="jQuery.trim(originAddressStreet2()) !== ''">
                                            <span data-bind="text: originAddressStreet2"></span><br />
                                        </ko>
                                        <span data-bind="text: originAddressCity"></span> <span data-bind="text: originAddressZipCode"></span><br />
                                        <span data-bind="text: $root.countriesHash[originAddressCountryId()] ? $root.countriesHash[originAddressCountryId()] : ''"></span>
                                    </address>
                                </ko>
                                <ko if="originRampId">
                                    <span data-bind="i18n: 'common.captions.ramp'"></span>: <span data-bind="text: originRampName()"></span>
                                </ko>
                            </td>
                            <td>
                                <ko ifnot="originContactLastName">
                                    <em>-</em>
                                </ko>
                                <ko if="originContactLastName">
                                    <p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: originContactFirstName"></span> <span data-bind="text:originContactLastName"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: originContactPhone"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: originContactEmail"></span></p>
                                </ko>
                            </td>
                            <td>
                                <ko if="etdFrom()">
                                    <p><span data-bind="i18n: 'common.captions.etd'"></span> <span data-bind="i18n: 'common.captions.from'"></span>: <span data-bind="text: kendo.toString(etdFrom(), 'g')"></span></p>
                                </ko>
                                <ko if="etdTo()">
                                    <p><span data-bind="i18n: 'common.captions.etd'"></span> <span data-bind="i18n: 'common.captions.to'"></span>: <span data-bind="text: kendo.toString(etdTo(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td>
                                <ko if="atd()">
                                    <p><span data-bind="i18n: 'common.captions.atd'"></span>: <span data-bind="text: kendo.toString(atd(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td data-bind="text: loadingReference"></td>
                            <td data-bind="text: originNote"></td>
                        </tr>
                    </thead>
                    <tbody data-bind="foreach: checkpoints">
                        <tr>
                            <td>
                                <ko if="typeId()">
                                    <span data-bind="text: $root.checkpointTypesHash[typeId()].nameTranslated"></span>
                                </ko>
                                <ko ifnot="typeId()">
                                    <span data-bind="text: name"></span>
                                </ko>
                            </td>
                            <td>
                                <ko ifnot="addressName()">
                                    <em>-</em>
                                </ko>
                                <ko if="addressName()">
                                    <address>
                                        <span data-bind="text: addressName"></span><br />
                                        <span data-bind="text: addressStreet"></span><br />
                                        <ko if="jQuery.trim(addressStreet2()) !== ''">
                                            <span data-bind="text: addressStreet2"></span><br />
                                        </ko>
                                        <span data-bind="text: addressTown"></span>, <span data-bind="text: addressZipCode"></span>, <span data-bind="text: $root.countriesHash[addressCountryId()] ? $root.countriesHash[addressCountryId()] : ''"></span>
                                    </address>
                                </ko>
                                <ko if="rampId">
                                    <span data-bind="i18n: 'common.captions.ramp'"></span>: <span data-bind="text: rampName"></span>
                                </ko>
                            </td>
                            <td>
                                <ko ifnot="contactLastName()">
                                    <em>-</em>
                                </ko>
                                <ko if="contactLastName()">
                                    <p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: contactFirstName"></span> <span data-bind="text: contactLastName"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: contactPhone"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: contactEmail"></span></p>
                                </ko>
                            </td>
                            <td>
                                <ko if="eta()">
                                    <p><span data-bind="i18n: 'common.captions.eta'"></span>: <span data-bind="text: kendo.toString(eta(), 'g')"></span></p>
                                </ko>
                                <ko if="etd()">
                                    <p><span data-bind="i18n: 'common.captions.etd'"></span>: <span data-bind="text: kendo.toString(etd(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td>
                                <ko if="ata()">
                                    <p><span data-bind="i18n: 'common.captions.ata'"></span>: <span data-bind="text: kendo.toString(ata(), 'g')"></span></p>
                                </ko>
                                <ko if="ata()">
                                    <p><span data-bind="i18n: 'common.captions.atd'"></span>: <span data-bind="text: kendo.toString(atd(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td data-bind="text: reference"></td>
                            <td data-bind="text: note"></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td data-bind="i18n: 'common.captions.destination'"></td>
                            <td>
                                <ko ifnot="destAddressName()">
                                    <em>-</em>
                                </ko>
                                <ko if="destAddressName()">
                                    <address>
                                        <span data-bind="text: destAddressName"></span><br />
                                        <span data-bind="text: destAddressStreet"></span><br />
                                        <ko if="jQuery.trim(destAddressStreet2()) !== ''">
                                            <span data-bind="text: destAddressStreet2"></span><br />
                                        </ko>										
                                        <span data-bind="text: destAddressCity"></span> <span data-bind="text: destAddressZipCode"></span><br />
                                        <span data-bind="text: $root.countriesHash[destAddressCountryId()] ? $root.countriesHash[destAddressCountryId()] : ''"></span>
                                    </address>
                                </ko>
                                <ko if="destinationRampId">
                                    <span data-bind="i18n: 'common.captions.ramp'"></span>: <span data-bind="text: destinationRampName()"></span>
                                </ko>
                            </td>
                            <td>
                                <ko ifnot="destinationContactLastName()">
                                    <em>-</em>
                                </ko>
                                <ko if="destinationContactLastName()">
                                    <p><span data-bind="i18n: 'common.captions.person'"></span>: <span data-bind="text: destinationContactFirstName"></span> <span data-bind="text:destinationContactLastName"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.phone'"></span>: <span data-bind="text: destinationContactPhone"></span></p>
                                    <p><span data-bind="i18n: 'common.captions.email'"></span>: <span data-bind="text: destinationContactEmail"></span></p>
                                </ko>
                            </td>
                            <td>
                                <ko if="etaFrom()">
                                    <p><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.from'"></span>: <span data-bind="text: kendo.toString(etaFrom(), 'g')"></span></p>
                                </ko>
                                <ko if="etaTo()">
                                    <p><span data-bind="i18n: 'common.captions.eta'"></span> <span data-bind="i18n: 'common.captions.to'"></span>: <span data-bind="text: kendo.toString(etaTo(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td>
                                <ko if="ata()">
                                    <p><span data-bind="i18n: 'common.captions.ata'"></span>: <span data-bind="text: kendo.toString(ata(), 'g')"></span></p>
                                </ko>
                            </td>
                            <td data-bind="text: unloadingReference"></td>
                            <td data-bind="text: destinationNote"></td>
                        </tr>
                    </tfoot>

                </table>
            </div>
        </fieldset>

    );
    
    public static spediter = (): HTMLElement => ReadOnlyCheckpointsTemplate.customer();

    public static carrier = (): HTMLElement => ReadOnlyCheckpointsTemplate.spediter();
}