import { inject } from "tsyringe";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { CustomFieldsHelper } from "../../model/CustomFieldsHelper";
import { BaseViewModel } from "./BaseViewModel";
import { IWidgetDefinition, IWidgetRecord, IWidgetSettings } from "./IWidgetSettings";

import { i18n } from "i18next-ko";
import { CodelistManager } from "../../model/CodelistManager";
import { TracedoManager } from "../../model/TracedoManager";
import { TypedCheckpointsHelper } from "../../model/TypedCheckpointsHelper";

/**
 * Base class for all Widgets
 */
export abstract class BaseWidget<T extends IWidgetSettings> extends BaseViewModel<T>
{
    /**
     * Is this widget configurable
     */
    protected _configurable: boolean = false;

    /**
     * Custom fields helper
     */
    protected customFieldsHelper: CustomFieldsHelper;

    /**
     * Typed checkpoints helper
     */
    protected typedCheckpointsHelper: TypedCheckpointsHelper;

    /**
     * Codelist manager
     */
    protected codelistManager: CodelistManager;

    /**
     * TGracedo manager
     */
    protected tracedoManager: TracedoManager;

    /**
     * Getter for widget ID
     */
    public get id(): number {
        return this.settings.widget.widgetId();
    }    

    public get record(): IWidgetRecord {
        return this.settings.widget;
    }

    public get definition(): IWidgetDefinition {
        return this.settings.definition;
    }

    public get widgetSettings(): any {
        return this.settings.settings;
    }

    public get configurable(): boolean {
        return this._configurable;
    }


	/**
	 * Constructor
	 */
    constructor(
        @inject(Client) rpc: Client, 
        @inject(CustomFieldsHelper) customFieldsHelper: CustomFieldsHelper,
        @inject(TypedCheckpointsHelper) typedCheckpointsHelper: TypedCheckpointsHelper,
        @inject(CodelistManager) codelistManager: CodelistManager,
        @inject(TracedoManager) tracedoManager: TracedoManager
    ) {
        super(rpc);
        this.customFieldsHelper = customFieldsHelper;
        this.typedCheckpointsHelper = typedCheckpointsHelper;
        this.codelistManager = codelistManager;
        this.tracedoManager = tracedoManager;
    }

    /**
     * Widget Startup
     */
    public async startup(): Promise<any>
    {
        await super.startup();
        this.element.attr('id', 'dashboard-widget-' + this.settings.widget.widgetId);
        return true;
    }

    /**
     * Refresh widget 
     */
    public refresh()
    {
    }

    /**
     * Open widget rename prompt and save new name
     */
    public async openRename(): Promise<void>
    {
        let name = this.settings.widget.userCaption() ? this.settings.widget.userCaption() : this.settings.definition.name;
		let newName = await this.promptDialog(i18n.t('dashboard.widgetRename'), name, i18n.t('dashboard.widgetRename'), 300);
        if(newName === null) {
            return;
        }
		newName = jQuery.trim(newName) === '' ? this.settings.definition.name : newName;
        let affected = await this.rpc.call('dashboard.rename', { id: this.id, name: newName });
        if(affected > 0) {
            this.settings.widget.userCaption(newName);
        }
    }
}