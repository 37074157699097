import { BaseWidget } from "../../common/BaseWidget";
import { IDriverPlanSettings } from "./IDriverPlanSettings";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { DriverPlanSettings } from "./DriverPlanSettings";
import { BaseConfigurableWidget } from "../../common/BaseConfigurableWidget";

/**
 * Default VM for Driver Plan widget
 */
@injectable()
export class DriverPlan extends BaseConfigurableWidget
{

	/**
	 * Open widget settings
	 */
    public async openSettings(): Promise<void>
    {
        await this.openSettingsDialog<DriverPlanSettings>(DriverPlanSettings);
    } 

    public template = (): HTMLElement => (
        <div>
            <view-frame name="gridconf" className="mb-2" />
            <p>Driver plan</p>
            <div data-grid="trackAndTrace"></div>
            <view-frame name="widgetsettings" />
        </div>
    );


}

