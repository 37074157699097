import moment from "moment";
import { inject, injectable } from "tsyringe";
import { h } from "../../../tracejs/src/utils/JSXFactory";

import { BaseDialogViewModel } from "../common/BaseDialogViewModel";

import { TracedoPriceLog } from "../../entities/TracedoPriceLog";
import { BadRequestError } from "../../../tracejs/src/application/BadRequestError";
import { Client } from "../../../tracejs/src/net/jsonrpc/Client";
import { CodelistManager } from "../../model/CodelistManager";
import { Currency } from "../../entities/Currency";
import { SyncEvent } from "ts-events";
import { i18n } from "i18next-ko";

/**
 * Log smlouvani ceny k useku mezi dodavatelem a odberatelem
 */
@injectable()
export class PriceLog extends BaseDialogViewModel<any>
{

	/**
	 * Knockout Observable for tracedo
	 */
	private records: KnockoutObservableArray<TracedoPriceLog> = ko.observableArray([]);

    private codelistManager: CodelistManager;

    private currencies: Currency[];

    private note: KnockoutObservable<string> = ko.observable('');
    private price: KnockoutObservable<number> = ko.observable();
    private currencyId: KnockoutObservable<number> = ko.observable();

    private isLoading: KnockoutObservable<boolean> = ko.observable(false);

    private isRejectedBySupplier: KnockoutObservable<boolean> = ko.observable(false);

    /**
     * Event when the PriceLog is saved
     */
    public onPriceLogSaved: SyncEvent<{ type: string, tracedoId: number, log: TracedoPriceLog }> = new SyncEvent<{ type: string, tracedoId: number, log: TracedoPriceLog }>();

    /**
     * Event when the PriceLog is approved
     */
    public onPriceLogApproved: SyncEvent<{ type: string, tracedoId: number, approved: boolean }> = new SyncEvent<{ type: string, tracedoId: number, approved: boolean }>();

    /**
     * Event when the PriceLog is rejected by supplier
     */
    public onPriceLogRejected: SyncEvent<{ type: string, tracedoId: number, rejected: boolean }> = new SyncEvent<{ type: string, tracedoId: number, rejected: boolean }>();


    // navrhnovat ceny muze kazda strana do te doby, dokud obe strany neschvali cenu
    private canPropose: KnockoutComputed<boolean> = ko.computed(() => {
        return (this.records().length <= 0 || !this.records()[0].customerApproved || !this.records()[0].supplierApproved);
    });

    /**
     * Constructor
     * 
     * @param rpc RPC
     * @param culture Culture
     */
    constructor(@inject(Client) rpc: Client, @inject(CodelistManager) codelistManager: CodelistManager) {
        super(rpc);
        this.codelistManager = codelistManager;
        this.currencies = codelistManager.getCurrencies();
    }


	/**
	 * ViewModel Startup
	 */
	public async startup(): Promise<void>
	{
		await super.startup();

        if(this.settings.type != 'customer' && this.settings.type != 'supplier') {
            throw new BadRequestError("Type must be either 'customer' or 'supplier'", 500);
        }

        await this.refresh();
	}

	/**
	 * Save, refresh and return saved PriceLog record
	 */
	public async save(): Promise<TracedoPriceLog>
	{
        let method = this.settings.type == 'customer' ? 'priceLog.saveAsCustomer' : 'priceLog.saveAsSupplier'

        let savedLog: TracedoPriceLog = await this.rpc.call(method, {
            tracedoId: this.settings.tracedoId,
            price: this.price(),
            currencyId: this.currencyId(),
            note: this.note()
        });

        this.price(null);
        this.currencyId(null);
        this.note('');

        await this.refresh();

        // fire event when new log is saved
        this.onPriceLogSaved.post({ type: this.settings.type, tracedoId: this.settings.tracedoId, log: savedLog });

        return savedLog;
	}

    /**
     * Odmítnout přepravu dodavatelem
     */
    public async rejectAsSupplier(): Promise<void>
    {
        if(this.settings.type === 'supplier') {
            let reject = await this.confirmDialog(i18n.t('common.actions.rejectConfirmation'), i18n.t('common.actions.rejectTransport'));
            if(reject) {
                let rejected: boolean = await this.rpc.call('priceLog.rejectAsSupplier', { tracedoId: this.settings.tracedoId });
                this.onPriceLogRejected.post({ type: this.settings.type, tracedoId: this.settings.tracedoId, rejected: rejected });
            }
        }
    }

    /**
     * Approve as customer
     */
    public async approveAsCustomer(): Promise<boolean>
    {
        let approved: boolean = await this.rpc.call('priceLog.approveAsCustomer', { tracedoId: this.settings.tracedoId });
        await this.refresh();

        // fire event when new log is saved
        this.onPriceLogApproved.post({ type: this.settings.type, tracedoId: this.settings.tracedoId, approved: approved });

        return approved;
    }

    /**
     * Approve as supplier
     */
    public async approveAsSupplier(): Promise<boolean>
    {
        let approved: boolean = await this.rpc.call('priceLog.approveAsSupplier', { tracedoId: this.settings.tracedoId });
        await this.refresh();

        // fire event when new log is saved
        this.onPriceLogApproved.post({ type: this.settings.type, tracedoId: this.settings.tracedoId, approved: approved });

        return approved;
    }


    /**
     * Read price log
     */
    public async refresh(): Promise<void>
    {
        this.isLoading(true);

        let method = this.settings.type == 'customer' ? 'priceLog.getAsCustomer' : 'priceLog.getAsSupplier';

		let records: TracedoPriceLog[] = await this.rpc.call(method, { 
            tracedoId: this.settings.tracedoId,
            query: {
                select: '*, currency(*), createdUser(*), supplier(name), customer(name)',
                sort: [{ field: 'logId', dir: 'desc' }] 
            }
        });
        
        // Date values
        records.forEach((rec: TracedoPriceLog) => {
            rec.createdStamp = (rec.createdStamp === null ? null : kendo.parseDate((rec.createdStamp as string)));
            rec.customerApprovedStamp = (rec.customerApprovedStamp === null ? null : kendo.parseDate((rec.customerApprovedStamp as string)));
            rec.supplierApprovedStamp = (rec.supplierApprovedStamp === null ? null : kendo.parseDate((rec.supplierApprovedStamp as string)));
        });
        
        this.records(records);

        // Byl posledni zaznam odmitnut dodavatelem ?
        this.isRejectedBySupplier(records.length > 0 && records[0].supplierRejected);

        this.isLoading(false);
    }

	/**
	 * Dialog template
	 */
	public dialogTemplate = (): HTMLElement => (

		<div>

            <ko if="$root.canPropose">

                <ko ifnot="$root.isRejectedBySupplier">
                    <fieldset>
                        <legend data-bind="i18n: 'common.captions.proposePrice'"></legend>
                        <div className="row mb-3">
                            <label className="col-auto col-form-label" data-bind="uniqueFor: 'note', i18n: 'common.captions.price'"></label>
                            <div className="col-md-3">
                                <input type="number" data-bind="value: $root.price" className="form-control" />
                            </div>
                            <div className="col-auto">
                                <select className="form-select" data-bind="
                                    options: $root.currencies,
                                    optionsCaption: '-',
                                    optionsValue: 'currencyId',
                                    optionsText: 'iso',
                                    value: $root.currencyId
                                "></select>
                            </div>
                            <label className="col-auto col-form-label" data-bind="uniqueFor: 'note', i18n: 'common.captions.note'"></label>
                            <div className="col">
                                <input className="form-control" data-bind="value: $root.note" />
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-primary" data-bind="i18n: 'common.actions.save', click: $root.save.bind($root)"></button>
                            </div>
                        </div>
                    </fieldset>
                </ko>

                <ko if="$root.settings.type == 'supplier'">
                    <hr/>
                    <ko if="$root.isRejectedBySupplier">
                        <div class="alert alert-danger text-center" data-bind="i18n: 'common.actions.rejectedByYou'"></div>
                    </ko>
                    <ko ifnot="$root.isRejectedBySupplier">
                        <div class="text-center"><button class="btn btn-danger" data-bind="i18n: 'common.actions.rejectTransport', click: $root.rejectAsSupplier.bind($root)"></button></div>
                    </ko>
                </ko>
                <ko if="$root.settings.type == 'customer' && $root.isRejectedBySupplier">
                    <div class="alert alert-danger text-center" data-bind="i18n: 'common.actions.rejectedBySupplier'"></div>
                </ko>

                <hr/>

            </ko>


            <fieldset>
                <legend>
                    <span data-bind="i18n: 'common.captions.priceLogCaption'"></span>
                    &nbsp; 
                    <button type="button" className="btn btn-light float-end" data-bind="click: $root.refresh.bind($root)">
                        <span class="k-icon k-i-reload"></span>
                    </button>
                </legend>
                
                <ko if="$root.isLoading">

                    <p className="text-center fs-1 py-4"><em data-bind="i18n: 'common.captions.loading'"></em></p>

                </ko>
                <ko ifnot="$root.isLoading">

                    <ko if="$root.records().length <= 0">
                        <p className="text-center fs-1 py-4"><em data-bind="i18n: 'common.captions.noLogPrices'"></em></p>
                    </ko>

                    <ko if="$root.records().length > 0">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="text-start align-middle" data-bind="i18n: 'common.captions.createdBy'"></th>
                                    <th className="text-end align-middle ps-2 pe-2" data-bind="i18n: 'common.captions.price'"></th>
                                    <th className="text-center align-middle" style="width: 110px;">
                                        <span data-bind="
                                            i18n: $root.settings.type === 'supplier' ? 'common.captions.acceptedByCustomer' : 'common.captions.acceptedByYou',
                                            attr: { title: $root.records()[0].customer.name }
                                        "></span>
                                    </th>
                                    <th className="text-center align-middle" style="width: 110px;">
                                        <span data-bind="
                                            i18n: $root.settings.type === 'customer' ? 'common.captions.acceptedBySupplier' : 'common.captions.acceptedByYou',
                                            attr: { title: $root.records()[0].supplier.name }
                                        "></span>
                                    </th>
                                    <th className="text-start align-middle ps-2" data-bind="i18n: 'common.captions.note'"></th>
                                </tr>
                            </thead>
                            <tbody data-bind="foreach: $root.records">
                                <tr>
                                    <td data-bind="text: '#' + ($root.records().length - $index())"></td>
                                    <td className="text-start align-middle text-nowrap">
                                        <span data-bind="text: createdUser.realName"></span><br/>
                                        <span className="opacity-50" data-bind="text: kendo.toString(createdStamp,'g')"></span>
                                    </td>
                                    <td className="text-end align-middle text-nowrap ps-2 pe-2">
                                        <span data-bind="text: kendo.toString(price, 'n0')"></span>&nbsp;<span data-bind="text: currency.iso"></span>
                                    </td>
                                    <td className="text-center align-middle" data-bind="css: {
                                        'bg-warning': createdByParty === 'customer',
                                        'bg-success': createdByParty === 'supplier' && customerApproved 
                                    }">
                                        <ko if="customerApproved">
                                            <span data-bind="text: kendo.toString(customerApprovedStamp,'g')"></span>
                                        </ko>
                                        <ko ifnot="customerApproved">
                                            <ko if="$root.settings.type === 'customer' && $index() === 0">
                                                <button type="button" className="btn btn-primary" data-bind="click: $root.approveAsCustomer.bind($root), i18n: 'common.actions.accept'"></button>
                                            </ko>
                                            <ko if="$root.settings.type !== 'customer' || $index() !== 0">
                                                -
                                            </ko>
                                        </ko>
                                    </td>
                                    <td className="text-center align-middle" data-bind="css: {
                                        'bg-warning': createdByParty === 'supplier',
                                        'bg-success': createdByParty === 'customer' && supplierApproved 
                                    }
                                    ">
                                        <ko if="supplierApproved">
                                            <span data-bind="text: kendo.toString(supplierApprovedStamp,'g')"></span>
                                        </ko>
                                        <ko ifnot="supplierApproved">
                                            <ko if="$root.settings.type === 'supplier' && $index() === 0">
                                                <ko ifnot="$root.isRejectedBySupplier">
                                                    <button type="button" className="btn btn-primary" data-bind="click: $root.approveAsSupplier.bind($root), i18n: 'common.actions.accept'"></button>
                                                </ko>
                                            </ko>
                                            <ko if="$root.settings.type !== 'supplier' || $index() !== 0">
                                                -
                                            </ko>
                                        </ko>
                                    </td>
                                    <td className="text-start align-middle ps-2"><span data-bind="text: note"></span></td>                                
                                </tr>
                            </tbody>
                        </table>
                    </ko>

                </ko>
            </fieldset>

		</div>

	);

}