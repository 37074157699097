import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BaseViewModel } from "../../common/BaseViewModel";
import { injectable } from "tsyringe";
import { KendoHelpers } from "../../../model/KendoHelpers";
import { Objects } from "../../../../tracejs/src/utils/Objects";
import { i18n } from "i18next-ko";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";
import { IGridConfiguratorOptions } from "../../components/GridConfigurator/IGridConfiguratorOptions";
import { GridConfigurator } from "../../components/GridConfigurator/GridConfigurator";
import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";

/**
 * Users
 */
@injectable()
export class Edit extends BaseDialogViewModel<any>
{
	/**
	 * Knockout Observable for userData
	 */
	private userData: KnockoutObservable<any> = ko.observable(ko.mapping.fromJS({
		firstName: '',
		lastName: '',
		phone: '',
		mobile: '',
		email: '',
		spz: '',
		driverInfo: '',
		loginname: '',
		disabled: false,
		// Hesla
		password: '',
		passwordAgain: '',
		valid: true
	}));

	// dostupne notifikace, ktere je mozne u uzivatele zaklikat
	private notifications: KnockoutObservableArray<any> = ko.observableArray(null);

	// Duplicate settings from other user
	private users: KnockoutObservableArray<any> = ko.observableArray(null);
	private duplicateFromUserId: KnockoutObservable<any> = ko.observable(null);

	// ciselnik roli
	private roles: KnockoutObservableArray<any> = ko.observableArray();

	// ciselnik jazyku
	private languages: KnockoutObservableArray<any> = ko.observableArray();

	/**
	 * Knockout Observable for user
	 */
	// subjects: KnockoutObservableArray<any> = ko.observableArray(null);
	// usersSubjects: KnockoutObservableArray<any> = ko.observableArray(null);


	/**
	 * ViewModel Startup
	 */
	public async startup(): Promise<any>
	{
		await super.startup();

		// Pokud nemuze editovat predaneho uzivatele
		if(this.settings.userId && !this.user.isAllowed('entity.user', 'edit')) {
			throw new BadRequestError('Access denied', 403);
		}

		// Pokud nemuze vytvaren noveho uzivatele
		if(!this.settings.userId && !this.user.isAllowed('entity.user', 'create')) {
			throw new BadRequestError('Access denied', 403);
		}

		let batch = this.rpc.batch();

		// kolekce jazyku
		batch.call('langs', 'language.get');

		if(this.settings.userId) {

			// uzivatel k editaci
			batch.call('user', 'user.getOne', { id: this.settings.userId, query: { select: '*,userRoles(roleId)' } });

			// ciselnik roli
			batch.call('roles', 'user.getRoles', { query: { sort: [ { field: 'name', dir: 'asc' } ] } });

			// notifikace
			batch.call('notifications', 'user.getNotifications', { userId: this.settings.userId });

			// vybrat ostatni uzivatele podle subjektu
			batch.call('users', 'user.getMain', {
				select: 'userId,ident,firstName,lastName,email,phone',
				excludeUserId: this.settings.userId,
				query: { sort: [{ field: 'lastName', dir: 'asc' }] }
			});
		}

		//stažení všech poli pro notifikace
		// batch.call('subjects', 'system.user.getSubjects', { userId: null, expand: "subject", criteria: { sort: [{ field: 'subject.name', dir: 'asc' }] } });
		// batch.call('usersSubjects', 'system.user.getSubjects', { userId: this.userId, expand: "subject" });

		let response: any = await batch.run();

		this.languages(response['langs']);

		if (this.settings.userId) {

			// var subjects: Array<any> = [];
			// $.each(response['subjects'], (key, subject) => {
			// 	subjects.push({ 'name': subject.subject.name, 'subjectId': subject.subject.subjectId});
			// });
			// this.subjects(subjects);
			// this.usersSubjects(response['usersSubjects']); //posible add condition: where subject.attr_spediter = true

			let user = response['user'];
			user.password = '';
			user.passwordAgain = '';
			this.userData(ko.mapping.fromJS(user));

			// Roles - prefill user's roles
			let roleHash: { [key: number]: boolean } = {};
			if(user.userRoles) {
				user.userRoles.forEach((ur: any) => {
					roleHash[ur.roleId] = true;
				});
			}
			this.roles.removeAll();
			response['roles'].forEach((role: any) => {
				role.set = roleHash[role.roleId] ? true : false;
				this.roles.push(role);
			});

			// notifications
			var lang = this.culture.localeShortCapitalized;
			response['notifications'].forEach((value: any) => {
				 value.name = value['name' + lang] ? value['name' + lang] : value['name'];
				 value.description = value['description' + lang] ? value['description' + lang] : value['description'];
			});
			this.notifications(response['notifications']);

			// Copy Users
			this.users.removeAll();
			let copyUsers = response['users'];
			copyUsers.forEach((cu: any) => {
				if(cu.userId != this.settings.userId) {
					this.users.push(cu);
				}
			});
		}
	}

	/**
	 * Set / Unset user's role
	 * @param role 
	 */
	private async setUserToRole(role: any)
	{
		let checked = null;
		this.roles().forEach((r: any) => {
			if(r.roleId == role.roleId) {
				checked = r.set;
			}
		})
		await this.rpc.call('user.setUnsetUserRole', {
			'userId': this.settings.userId,
			'roleId': role.roleId,
			'set': checked
		});

		this.flash.success('Role ' + role.name + ' ' + (checked ? 'assigned' : 'detached'));
	}


	private async duplicateFromUser(): Promise<any>
	{
		let response: any = await this.rpc.call('userSettings.copyFomUser', { 'fromUserId': this.duplicateFromUserId(), "toUserId": this.settings.userId });

		var result = '';
		result = result + 'Roles: ';
		jQuery.each(response.roles.success, (i, value) => {
			result = result + value + ', ';
		});
		if (response.roles.failed.length !== 0) {
			result = result + '; FAIL: ';
		}
		jQuery.each(response.roles.failed, (i, value) => {
			result = result + value+', ';
		});

		result = result + 'Widgets: ';
		jQuery.each(response.widgets.success, (i, value) => {
			result = result + value + ', ';
		});
		if (response.widgets.failed.length !== 0) {
			result = result + '; FAIL: ';
		}
		jQuery.each(response.widgets.failed, (i, value) => {
			result = result + value + ', ';
		});

		result = result + 'Settings: ';
		jQuery.each(response.settings.success, (i, value) => {
			result = result + value + ', ';
		});
		if (response.settings.failed.length !== 0) {
			result = result + '; FAIL: ';
		}
		jQuery.each(response.settings.failed, (i, value) => {
			result = result + value + ', ';
		});

		this.flash.info(result);
	}

	// ulozi nastaveni
	public async save(): Promise<boolean>
	{
		// pack do javascript objectu
		let user = ko.mapping.toJS(this.userData);

		if (user.userId == null) {
			if (user.password !== user.passwordAgain) {
				this.alertDialog(i18n.t('system.alerts.passMismatched'));
				return false;
			}
			if (user.password == '' || user.passwordAgain == '') {
				this.alertDialog(i18n.t('system.alerts.fillPassword'));
				return false;
			}
		}
		//check valid email validation 
		var re = /[^\s@]+@[^\s@]+\.[^\s@]+/;
		if (!re.test(user.email)) {
			this.alertDialog(i18n.t('system.alerts.notValidEmail'));
			return false;
		}
		if (user.firstName == null || user.lastName == null || user.email == null || user.loginname == null) {
			this.alertDialog(i18n.t('system.alerts.fillRequiredFields'));
			return false;
		}
		if (user.password !== '' || user.passwordAgain !== '') {
			if (user.password !== user.passwordAgain) {
				this.alertDialog(i18n.t('system.alerts.passMismatched'));
				return false;
			}
		}

		// ulozit
		let subjectId = this.settings.customerId === null ? null : this.settings.customerId;
			let saved = await this.rpc.call('user.save', { user: user, subjectId: subjectId });
			return true;
	}

	/**
	 * Nastavi / zrusi notifikaci
	 */
	public async setUserNotify(notification: any)
	{
		await this.rpc.call('user.setNotification', { notifyRoleId: notification.vazId, userId: this.settings.userId });
	}


	public dialogTemplate = (): HTMLElement => (
		<div>
			<ko with="$root.userData">

				<div id='orderTabStrip' data-bind="kendoTabStrip: { animation: false }">
					<ul>
						<li data-bind="css: 'k-state-active', i18n: 'common.captions.information'"></li>
						<ko if="$root.settings.userId">
							<li data-bind="i18n: 'system.user.profile.notification'"></li>
							<ko if="!$root.settings.customerId || $root.user.isAllowed('entity.user','manageRole')">
								<li data-bind="style: { display: $root.userData().userId() ? '' : 'none' }, i18n: 'common.captions.authorization'"></li>
							</ko>

						</ko>
					</ul>
					<div class="k-state-active">
						<div class="row">
							<div class="col-md-6">

								<fieldset>
									<legend data-bind="i18n: 'system.user.edit.userInfo'"></legend>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'firstName'"><span data-bind="i18n: 'system.user.edit.firstName'"></span>*</label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: firstName, uniqueId: 'firstName'" />
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'lastName'"><span data-bind="i18n: 'system.user.edit.lastName'"></span>*</label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: lastName, uniqueId: 'lastName'" />
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'email'"><span data-bind="i18n: 'system.user.profile.email'"></span>*</label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: email, uniqueId: 'email'" />
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'phone'"><span data-bind="i18n: 'system.user.profile.phone'"></span></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: phone, uniqueId: 'phone'" />
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'mobile'"><span data-bind="i18n: 'system.user.profile.mobile'"></span></label>
										<div className="col-md-8">
											<input type="text" className="form-control" data-bind="value: mobile, uniqueId: 'mobile'" />
										</div>
									</div>

									<ko if="!$root.settings.customerId">

										<div className="row mb-2">
											<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'spz'"><span data-bind="i18n: 'system.user.profile.spz'"></span></label>
											<div className="col-md-8">
												<input type="text" className="form-control" data-bind="value: spz, uniqueId: 'spz'" />
											</div>
										</div>

										<div className="row mb-2">
											<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'driverInfo'"><span data-bind="i18n: 'common.captions.driverInfo'"></span></label>
											<div className="col-md-8">
												<input type="text" className="form-control" data-bind="value: driverInfo, uniqueId: 'driverInfo'" />
											</div>
										</div>

									</ko>

								</fieldset>

							</div>
							<div class="col-md-6">

								<fieldset>
									<legend data-bind="i18n: 'system.user.edit.userLogInInfo'"></legend>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'loginname'"><span data-bind="i18n: 'system.user.edit.loginname'"></span>*</label>
										<div className="col-md-8">
											<input type="text" maxlength="30" className="form-control" data-bind="value: loginname, uniqueId: 'loginname'" autocomplete="off" />
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'password'"><span data-bind="i18n: 'system.user.edit.password'"></span></label>
										<div className="col-md-8">
											<input type="password" className="form-control" data-bind="value: password, uniqueId: 'password'" autocomplete="off" />
										</div>
									</div>

									<div className="row mb-2">
										<label className="inline col-md-4 col-form-label" data-bind="uniqueFor: 'passwordAgain'"><span data-bind="i18n: 'system.user.edit.passwordAgain'"></span></label>
										<div className="col-md-8">
											<input type="password" className="form-control" data-bind="value: passwordAgain, uniqueId: 'passwordAgain'" autocomplete="off" />
										</div>
									</div>

									<div className="form-check form-switch mt-4">
										<input className="form-check-input" type="checkbox" data-bind="uniqueId: 'valid', checked: valid" />
										<label className="form-check-label" data-bind="i18n: 'system.user.edit.valid', uniqueFor: 'valid'"></label>
									</div>
									
								</fieldset>
							</div>
						</div>
					</div>
					{/* Uzivatel je editovan */}
					<ko if="$root.settings.userId">
						<div>
							<div className="row">
								<div className="col-md-5">
									<label data-bind="i18n: 'system.user.profile.notificationLanguange', uniqueFor: 'language'"></label>
									<select className="form-select w-auto d-inline-block ms-2" data-bind="uniqueId: 'language', options: $root.languages, optionsText: 'name', optionsValue: 'languageId', value: notificationLanguageId"></select>
								</div>
								<div className="col-md-7">
									<div className="form-check pt-2">
										<input class="form-check-input" type="checkbox" data-bind="uniqueId: 'sendToAllTrcOrders', checked: notificationTrcSendAll" />
										<label class="form-check-label" data-bind="uniqueFor: 'sendToAllTrcOrders', i18n: 'system.user.profile.sendToAllTrcOrders'"></label>
									</div>
									<div className="form-check pt-2">
										<input class="form-check-input" type="checkbox" data-bind="uniqueId: 'sendToContactTrcOrders', checked: notificationTrcSendContact" />
										<label class="form-check-label" data-bind="uniqueFor: 'sendToContactTrcOrders', i18n: 'system.user.profile.sendToContactTrcOrders'"></label>
									</div>									
								</div>
							</div>

							<table className="table table-bordered mt-2" style="padding: 6px;">
								<thead className="table-light">
									<tr>
										<th data-bind="i18n: 'system.user.profile.notification'"></th>
										<th data-bind="i18n: 'common.captions.description'"></th>
										<th data-bind="i18n: 'system.user.profile.active'"></th>
									</tr>
								</thead>
								<tbody data-bind="foreach: $root.notifications">
									<tr>
										<td data-bind="text: $data.name"></td>
										<td data-bind="text: $data.description"></td>
										<td><input type="checkbox" data-bind="event:{ change: function(data) { $root.setUserNotify(data) } }, checked: $data.active" /></td>
									</tr>
								</tbody>
							</table>

						</div>

						{/* Neni to zakaznikovo uzivatel, zobrazime nastaveni roli */}
						<ko if="!$root.settings.customerId || $root.user.isAllowed('entity.user','manageRole')">
							<div>
								<table class="info-table expand" style="padding: 6px;">
									<thead>
										<tr>
											<th data-bind="i18n: 'system.user.edit.set', style:{'width':'80px'}"></th>
											<th data-bind="i18n: 'system.user.edit.roleName', style:{'width':'120px'}"></th>
											<th data-bind="i18n: 'common.captions.description'"></th>
										</tr>
									</thead>
									<tbody data-bind="foreach: $root.roles">
										<tr>
											<td width="40px"><input type="checkbox" data-bind="event:{ change: function(role) { $root.setUserToRole(role) } }, checked: $data.set" /></td>
											<td data-bind="text: $data.name"></td>
											<td data-bind="text: $data.description"></td>
										</tr>
									</tbody>
								</table>

								<hr />

								<div class="row">
									<div class="col-md-4">
										<label style="padding: 4px 10px 4px 3px;" data-bind="i18n: 'system.user.edit.duplicateFromUser', uniqueFor: 'duplicateFromUser'"></label>
									</div>
									<div class="col-md-3">
										<select className="form-select" data-bind="uniqueId: 'duplicateFromUser', options: $root.users, optionsText: 'realName', optionsValue: 'userId', value: $root.duplicateFromUserId"></select>
									</div>
									<div class="col-md-5">
										<button type="button" class="btn btn-outline-primary" data-bind="click: $root.duplicateFromUser.bind($root)">
											<i class="icon-trash"></i>
											<span data-bind="i18n: 'common.actions.duplicate'"></span>
										</button>
									</div>
								</div>
							</div>
						</ko>

					</ko>
				</div>

			</ko>
		</div>
	)


}
