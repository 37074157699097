import { injectable } from "tsyringe";

import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { BadRequestError } from "../../../../tracejs/src/application/BadRequestError";


import { BaseDialogViewModel } from "../../common/BaseDialogViewModel";
import { IDialogSettings } from "../../common/IDialogSettings";
import { i18n } from "i18next-ko";

import { MergeDuplicities } from "./MergeDuplicities";
import { isEmptyObject } from "jquery";

export interface IAddressEditSettings extends IDialogSettings
{
	addressId?: number;
}

/**
 * Addresses - edit
 */
@injectable()
export class EditContactPersons extends BaseDialogViewModel<IAddressEditSettings>
{
	/**
	 * Knockout Observable for editing users
	 */
	public users = ko.observableArray([]);

	/**
	 * Knockout Observable for actually selected user and edited data
	 */
	public userSelected = ko.mapping.fromJS({
		contactId: null,
		addressbookId: null,
		firstName: null,
		lastName: null,
		email: null,
		tel: null
	});

	/**
	 * Knockout Observables for managing messages
	 */
	public mess = ko.mapping.fromJS({
		success: null,
		error: null
	});

	/**
	 * Knockout computed - are selected enough users?
	 */
	public duplicitiesReady = ko.computed({
		owner: this,
		read: () => {
			return this.users().filter(user => user.checked()).length >= 2 ? true : false;
		}
	});

	/**
	 * ViewModel Startup
	 */
	public async startup()
	{
		// Pokud nemuze editovat uzivatele
		if(this.settings.addressId && !this.user.isAllowed('entity.address', 'edit')) {
			throw new BadRequestError('Access denied', 403);
		}

		if(!this.settings.addressId && !this.user.isAllowed('entity.address', 'create')) {
			throw new BadRequestError('Access denied', 403);
		}

		await super.startup();

		this.loadUsers().then(() => {
			if(this.users().length > 0){
				this.userSelected.contactId(this.users()[0].contactId);
			}
		});
	}

	/**
	 * Load Users from database
	 */
	public async loadUsers(){
		let batch = this.rpc.batch();
		if(this.settings.addressId){
			batch.call("contact", "contact.getByAddress", { addressId: this.settings.addressId });
		}
		let response: any = await batch.run();

		if(response["contact"]){
			let users = response["contact"];

			users.forEach((user: any) => {
				user.checked = ko.observable(false);
			});

			this.users(users);

			console.log(this.users());

			if(this.users().length > 0){
				this.userSelectedChangeData(this.users()[0]);
			}
		}
	}

	/**
	 * Check if user is edited
	 */
	public async userInfoEdited(activeUser: any){
		if(
			this.userSelected.firstName() != activeUser.firstName || 
			this.userSelected.lastName() != activeUser.lastName ||
			this.userSelected.email() != activeUser.email ||
			this.userSelected.tel() != activeUser.mobile
		){
			return true;
		}else{
			return false;
		}
	}



	/**
	 * User set selected and change className to active
	*/
	public async userSelectedSet(user: any){
		let activeUser = this.users().find((o: any) => o.contactId === this.userSelected.contactId());
		let nextUser = this.users().find((o: any) => o.contactId === user["contactId"]);

		if(activeUser.contactId != nextUser.contactId){
			this.userInfoEdited(activeUser).then((edited: any) => {
				if(edited){
					kendo.confirm(i18n.t("settings.addresses.saveOrLoseEdit"))
						.done(() => {
							this.userSelectedChangeData(nextUser, true);
						})
						.fail(() => {
							return;
						});
				}else{
					this.userSelectedChangeData(nextUser, true);
				}
			});
		}
	}

	public userSelectedChangeData(user: any, changeContactId: boolean = null){
		if(changeContactId){
			this.userSelected.contactId(user.contactId);
		}

		this.userSelected.addressbookId(user.addressbookId);
		this.userSelected.firstName(user.firstName);
		this.userSelected.lastName(user.lastName);
		this.userSelected.email(user.email);
		this.userSelected.tel(user.mobile);
	}

	/**
	 * Save changes
	 */
	public async save(): Promise<void>
	{
		let activeUser = this.users().find((o: any) => o.contactId === this.userSelected.contactId());
		await this.userInfoEdited(activeUser).then(async (edited: any) => {
			if(!edited){
				this.mess.error(null);
				this.mess.error(i18n.t("settings.addresses.noChanges"));
			}else{
				let batch = this.rpc.batch();
		
				batch.call("contact", "contact.save", { contact: {
					addressbookId: this.userSelected.addressbookId(),
					contactId: this.userSelected.contactId(),
					firstName: this.userSelected.firstName(),
					lastName: this.userSelected.lastName(),
					email: this.userSelected.email(),
					mobile: this.userSelected.tel()
				}});
		
				let response: any = await batch.run();
				if(response["contact"]){
					this.mess.success(null);
					this.mess.success(i18n.t("settings.addresses.successfulSave"));

					this.loadUsers().then(() => {
						this.userSelectedChangeData(this.users()[this.users().findIndex(item => item.contactId === this.userSelected.contactId())]);
					});
				}else{
					this.mess.error(null);
					this.mess.error(i18n.t("settings.addresses.saveFailed"));
				}
			}
		});
	}

	/**
	 * Modal for MergeDuplicities
	 */
	private async mergeDuplicities(): Promise<void>
	{
		let usersToMerge: any[] = this.users().filter(user => user.checked());

		let modal = await this.loadViewFrame<MergeDuplicities>(MergeDuplicities, 'mergeDuplicities', {
			usersToMerge: usersToMerge,
			dialog: {
				modal: true,
				width: 450,
				height: 350,
				title: i18n.t("settings.addresses.mergeDuplicities"),
				buttons: (vm: MergeDuplicities, window: kendo.ui.Window) => {
					return [
						{
							align: 'right',
							cls: 'btn-link',
							label: i18n.t('common.actions.close'),
							click: () => window.close()
						},
						{
							align: 'right',
							cls: 'btn-primary',
							label: i18n.t('common.actions.save'),
							click: async () => {
								const response = await vm.merge()
								this.loadUsers();
								this.mess.success(null);
								this.mess.success(i18n.t("settings.addresses.mergeSuccess"));
								window.close();
							}
						}
					]
				}
			}
		});
	}

	public dialogTemplate = (): HTMLElement => (
		<div className="row h-100">
			<ko if="$root.users().length > 0">
				<div className="col-auto d-flex flex-column h-100" style="width: 40%">
					<ul data-bind="foreach: users" className="list-group overflow-auto">
						<li data-bind="css: { active: parseInt($root.userSelected.contactId()) === $data.contactId }, click: $root.userSelectedSet.bind($root, $data)" className="list-group-item d-flex justify-content-between" style="cursor: pointer">
							<span data-bind="text: $data.fullName" style="user-select: none;"></span>
							<input type="checkbox" data-bind="
									checked: checked,
									click: function(data, event){ event.stopPropagation(); return true; },
									css: { 'border-white': parseInt($root.userSelected.contactId()) === $data.contactId }
								" className="form-check-input ms-4" />
						</li>
					</ul>

					<button className="btn btn-primary mt-2 w-100" data-bind="text: i18n.t('settings.addresses.mergeDuplicities'),enable: duplicitiesReady, click: mergeDuplicities"></button>
				</div>

				<div className="col">
					<input type="text" data-bind="value: $root.userSelected.firstName, attr: { 'placeholder': i18n.t('system.user.profile.firstName') }" className="form-control mb-2" />
					<input type="text" data-bind="value: $root.userSelected.lastName, attr: { 'placeholder': i18n.t('system.user.profile.lastName') }" className="form-control mb-2" />
					<input type="email" data-bind="value: $root.userSelected.email, attr: { 'placeholder': i18n.t('common.captions.email') }" className="form-control mb-2" />
					<input type="text" data-bind="value: $root.userSelected.tel, attr: { 'placeholder': i18n.t('common.captions.phone') }" className="form-control mb-2" />
				</div>

				<span data-bind="kendoNotification: { success: mess.success, error: mess.error }"></span>

				<view-frame name="mergeDuplicities" />
			</ko>
			
			<ko if="$root.users().length === 0">
				<div className="col">
					<div data-bind="text: i18n.t('settings.addresses.noContactsMess')" className="alert alert-danger py-2"></div>
				</div>
			</ko>
		</div>
	);
}
