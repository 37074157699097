import { i18n } from "i18next-ko";
import moment from "moment";
import { inject, singleton } from "tsyringe";
import { Culture } from "../../tracejs/src/application/Culture";
import { Client } from "../../tracejs/src/net/jsonrpc/Client";

@singleton()
export class TypedCheckpointsHelper {

	/**
	 * RPC Client
	 */
	private rpc: Client;

	/**
	 * Typy checkpointu pro aktualniho speditera
	 */
	public checkpointTypes: Array<any>;

	/**
	 * Select (carkami oddelene sloupce) pro vyber typovych zastavek z API
	 */
	public checkpointTypesSelect: string;


	/**
	 * Constructor
	 * @param rpc RPC
	 * @param culture Culture
	 */
	constructor(@inject(Client) rpc: Client)
	{
		this.rpc = rpc;
	}

	/**
	 * Reads and prepares custom fields for select + grid
	 */
	public async loadCheckpointTypes(): Promise<void>
	{
		this.checkpointTypes = await this.rpc.call('tracedo.getMyCheckpointTypes');

		let idents: string[] = [];
		this.checkpointTypes.forEach((ct) => {
			// ktere fieldy se budou vybirat z prislusneho checkpointu
			idents.push('checkpoint_' + ct.ident + '_eta');
			idents.push('checkpoint_' + ct.ident + '_etd');
			idents.push('checkpoint_' + ct.ident + '_ata');
			idents.push('checkpoint_' + ct.ident + '_atd');
			idents.push('checkpoint_' + ct.ident + '_address_full');
		});
		this.checkpointTypesSelect = idents.join(',');
	}

	/**
	 * Returns custom fields for select (for API) glued from start with the "glue"
	 * If no custom fields are defined, returns empty string without glue
	 * @param glue 
	 */
	public getCheckpointTypesSelect(glue: string = ','): string
	{
		return (jQuery.trim(this.checkpointTypesSelect) !== '' ? glue + this.checkpointTypesSelect : '');
	}


	/**
	 * Appends custom fields to given columns collection
	 * 
	 * @param kendoGridColumns
	 * @param langIdent
	 */
	public appendColumnsTo(kendoGridColumns: kendo.ui.GridColumn[], langIdentCapitalized: string): void
	{
		const lsc = langIdentCapitalized;

		this.checkpointTypes.forEach((ct) => {

			let fields: string[] = [];

			if(ct.ident === 'cargo_load') {
				// nakladka zbozi = CARGO_LOAD  ... CARGO ETD + CARGO ATD
				fields = ['etd', 'atd'];
			}
			else if(ct.ident === 'cargo_unload') {
				// vykladka zbozi = CARGO_UNLOAD CARGO ETA + CARGO ATA
				fields = ['eta', 'ata'];
			}

			fields.forEach((field) => {
				let col: kendo.ui.GridColumn = {
					field: 'checkpoint_' + ct.ident + '_' + field,
					title: i18n.t('checkpoints.typed.' + ct.ident + '_' + field),
					template: (data: any) => {
						var value = data['checkpoint_' + ct.ident + '_' + field] !== undefined ? data['checkpoint_' + ct.ident + '_' + field] : null;
						if (value === null) {
							return '';
						}
						return kendo.toString(moment(value).toDate(), i18n.t('common.formats.kendoDateTime'));
					},
					sortable: true,
					width: 70
				};
				kendoGridColumns.push(col);
			});

			// plna adresa checkpointu
			kendoGridColumns.push({
				field: 'checkpoint_' + ct.ident + '_address_full',
				title: i18n.t('checkpoints.typed.' + ct.ident + '_address_full'),
				template: (data: any) => {
					let addrFull = data['checkpoint_' + ct.ident + '_address_full'];
					return addrFull ? addrFull.replace(/\n/g, "<br>") : '';
				},
				sortable: true,
				width: 70
			});

		});
	}

	/**
	 * Append fields to schema - model - fields definition
	 * @param kendoSchemaModelFields
	 */
	public appendSchemaFieldsTo(kendoSchemaModelFields: kendo.data.DataSourceSchemaModelFields): void
	{
		this.checkpointTypes.forEach((ct) => {
			kendoSchemaModelFields['checkpoint_' + ct.ident + '_eta'] = { type: 'date' }
			kendoSchemaModelFields['checkpoint_' + ct.ident + '_etd'] = { type: 'date' }			
			kendoSchemaModelFields['checkpoint_' + ct.ident + '_ata'] = { type: 'date' }
			kendoSchemaModelFields['checkpoint_' + ct.ident + '_atd'] = { type: 'date' }
			kendoSchemaModelFields['checkpoint_' + ct.ident + '_address_full'] = { type: 'string' }
		});
	}

	public getDateFields(): {[key:string]:string}
	{
		let fields: {[key:string]:string} = {};
		this.checkpointTypes.forEach((ct) => {
			fields['checkpoint_' + ct.ident + '_eta'] = 'datetime';
			fields['checkpoint_' + ct.ident + '_etd'] = 'datetime';
			fields['checkpoint_' + ct.ident + '_ata'] = 'datetime';
			fields['checkpoint_' + ct.ident + '_atd'] = 'datetime';
		});
		return fields;
	}

}
