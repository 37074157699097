import "reflect-metadata";

import { container } from "tsyringe";

import { ApiAuthenticator } from './model/ApiAuthenticator';
import { AppAuthorizator } from './model/AppAuthorizator';

import { h } from "../tracejs/src/utils/JSXFactory";

import { Bootstrap } from './../tracejs/src/application/Bootstrap';

import { config } from './config/config.js';

import { App } from './views/App';
import { KnockoutHelpers } from "./model/KnockoutHelpers";

// Widgets
import { Origin } from "./views/widgets/Origin/Origin";
import { DriverPlan } from "./views/widgets/DriverPlan/DriverPlan";
import { Destination } from "./views/widgets/Destination/Destination";
import { TrackAndTrace } from "./views/widgets/TrackAndTrace/TrackAndTrace";
import { Client } from "../tracejs/src/net/jsonrpc/Client";
import { CodelistManager } from "./model/CodelistManager";


(window as any).h = h;

// Register additional KnockoutJS bindings
KnockoutHelpers.registerBindings();

(async function() {

	const bootstrap = new Bootstrap();

	// Tracy
	config.tracyJS.enabled = document.cookie.match(/vegetable=1/) !== null;

	bootstrap.addConfig(config);
	bootstrap.addConfigFile('/config/config-local.json');

	await bootstrap.initialize((settings: any) => {

		//container.register<CodelistManager>('CodelistManager', {  useClass: CodelistManager });

		container.register<ApiAuthenticator>('IAuthenticator', { useClass: ApiAuthenticator });
		container.register<AppAuthorizator>('IAuthorizator', { useClass: AppAuthorizator });

		// register all widgets
		container.register<Origin>('Widget__Origin', { useClass: Origin });
		container.register<DriverPlan>('Widget__DriverPlan', { useClass: DriverPlan });
		container.register<Destination>('Widget__Destination', { useClass: Destination });
		container.register<TrackAndTrace>('Widget__TrackAndTrace', { useClass: TrackAndTrace });
	});

	// RPC Client - attach onBefore send -> Authorization header
	let rpcClient = container.resolve(Client);
	rpcClient.onBeforeSend.attach((data) => {
		let token = localStorage.getItem('tracedo_api_token');
		document.cookie = 'vegetable=1';
		if(jQuery.trim(token) !== '') {
			data.xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			//data.xhr.setRequestHeader('Cookie', 'vegetable=1');
		}
	});

	jQuery(() => { bootstrap.getApplication().run(App); });

})();