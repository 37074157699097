import "reflect-metadata";

import { BaseViewModel } from "./common/BaseViewModel";
import { Main } from "./Main";
import { Login } from "./User/Login";

import { h } from "../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";

import { OldBrowser } from "./OldBrowser";
import { Forgot } from "./User/Forgot";
import { Register } from "./User/Register";
import { SetPassword } from "./User/SetPassword";
import { Activate } from "./User/Activate";
import { DetailGuest } from "./TrackAndTrace/DetailGuest";
import { Connect } from "./Settings/_common/Connect";
import { Request } from "../../tracejs/src/net/jsonrpc/Request";
import moment from "moment";
import { IRoute } from "../../tracejs/src/application/IRoute";
import { KendoHelpers } from "../model/KendoHelpers";

/**
 * Application class of the application
 */
@injectable()
export class App extends BaseViewModel<any> {

	protected isLoggedIn: KnockoutObservable<boolean> = ko.observable(false);

	protected notSecuredRoutes: string[] = ['index','login','register','forgot','setPassword','activate'];

	/**
	 * Add routes
	 */
	protected configureRoutes = (): IRoute[] => [
		{ name: 'index', pattern: '/$' },

		{ name: 'main', pattern: '/main' },
		{ name: 'login', pattern: '/login$' },
		{ name: 'forgot', pattern: '/forgot$' },
		{ name: 'setPassword', pattern: '/set-password$' },
		{ name: 'register', pattern: '/register$' },
		// Detail with token (email notification)
		{ name: 'detail', pattern: '/detail$' },
		// Connect
		{ name: 'connect', pattern: '/connect$' }
	];	

	/**
	 * Startup
	 */
	public async startup()
	{
		await super.startup();

		// Registrace pomocnych filtru pro kendo GRID
		KendoHelpers.registerCustomFilterHandlers();

		// set culture for libraries on locale changed
		await jQuery.getScript("/libs/kendo-ui/js/messages/kendo.messages." + this.culture.locale + ".min.js");
		kendo.culture(this.culture.locale);
		moment.locale(this.culture.locale);

		this.culture.onLocaleChanged.attach(async (e) => {
			await jQuery.getScript("/libs/kendo-ui/js/messages/kendo.messages." + e.locale + ".min.js");
			kendo.culture(e.locale);
			moment.locale(e.locale);
		});		

		// Old browser check
		if (typeof FileReader === 'undefined' || typeof Promise === 'undefined') {
			await this.routeTo('oldBrowser');
			return;
		}

		// On Login - route to login screen
		this.user.onLogin.attach(() => {
			this.isLoggedIn(true);
			this.routeTo('main');
		});

		// On Logout - route to login screen
		this.user.onLogout.attach(() => {
			this.isLoggedIn(false);
			this.routeTo('login');
		});

		// On error
		this.rpc.onError.attach(async (e) => {
			// Error code for session expiration
			if (e.errorResponse.error && e.errorResponse.error.code === 401) {
				this.flash.error(e.errorResponse.error.message);
				await this.user.logout();
			}
			else {
				// FIXME: better error handling
				if((e.request as Request).method && (e.request as Request).method !== 'user.login') {
					this.flash.error(e.errorResponse.error.message);
				}
			}
		});

	}

	public async beforeRouted(method: string, route: string): Promise<boolean>
	{
		let requestedRoute = this.currentRoute(); // router. getByLevel(this.level);
		if(requestedRoute !== 'detail') {

			// is logged
			this.isLoggedIn(await this.user.isLoggedIn());
			// Is logged and is on login screen => redirect to main
			if(this.isLoggedIn() && jQuery.inArray(requestedRoute, this.notSecuredRoutes) > -1) {
				this.routeTo('main');
				return false;
			}

			// not logged in AND not on login/registration/forgot screen => redirect to login
			if(!this.isLoggedIn() && jQuery.inArray(requestedRoute, this.notSecuredRoutes) < 0) {
				this.routeTo('login');
				return false;
			}
		}
	}

	/**
	 * Route for OLD BROWSER information
	 */
	public async routeOldBrowser()
	{
		this.loadRouteFrame<OldBrowser>(OldBrowser);
	}

	/**
	 * Index
	 * @returns 
	 */
	public async routeIndex()
	{
		if(!this.isLoggedIn()) {
			await this.routeTo('login');
		}
		else {
			await this.routeTo('main');
		}
	}

	/**
	 * Default MAIN APP route
	 */
	public async routeMain()
	{
		if(!this.isLoggedIn()) {
			return await this.routeTo('login');
		}
		return await this.loadRouteFrame<Main>(Main);
	}

	/**
	 * Login screen
	 */
	public async routeLogin(): Promise<any>
	{
		if(this.isLoggedIn()) {
			return await this.routeTo('main');
		}
		let login = await this.loadRouteFrame<Login>(Login);
		login.onForgotClicked.attach(() => this.routeTo('forgot'));
		login.onRegisterClicked.attach(() => this.routeTo('register'));
	}

	/**
	 * Forgot password screen
	 */
	public async routeForgot(): Promise<any>
	{
		if(this.isLoggedIn()) {
			return await this.routeTo('main');
		}
		let forgot = await this.loadRouteFrame<Forgot>(Forgot);
		forgot.onBackClicked.attach(() => this.routeTo('login'));
	}
 
	/**
	 * Set password screen
	 */
	public async routeSetPassword(): Promise<any>
	{
		if(this.isLoggedIn()) {
			return await this.routeTo('main');
		}
		let setPassword = await this.loadRouteFrame<SetPassword>(SetPassword);
		setPassword.onPasswordSet.attach(() => this.routeTo('login'));
	}
	  
	/**
	 * Register screen
	 */
	public async routeRegister(): Promise<any>
	{
		if(this.isLoggedIn()) {
			return await this.routeTo('main');
		}
		let register = await this.loadRouteFrame<Register>(Register);
		register.onBackClicked.attach(() => this.routeTo('login'));
	}
  
	/**
	 * Activate registered account
	 */
	public async routeActivate(): Promise<any>
	{
		if(this.isLoggedIn()) {
			return await this.routeTo('main');
		}
		let activate = await this.loadRouteFrame<Activate>(Activate);
		activate.onLoginClicked.attach(() => this.routeTo('login'));
	}

	/**
	 * Tracedo detail - read only - for guests (requires TOKEN) - from email notifications
	 */
	public async routeDetail(): Promise<any>
	{
		return await this.loadRouteFrame<DetailGuest>(DetailGuest);
	}

	/**
	 * Connect
	 */
	public async routeConnect(): Promise<any>
	{
		return await this.loadRouteFrame<Connect>(Connect);
	}

	/**
	 * When rendered
	 */
	public async rendered(): Promise<any>
	{
		// Select-on-zero = oznacit obsah, pokud dojde k focusu nad polem, ktere ma hodnotu 0
		jQuery('body').on('focus', 'input.select-on-zero', function() {
			let $input = jQuery(this);
			if($input.val() == 0) {
				setTimeout(() => { $input.get(0).select(); }, 20);
			}
		});
	}

	/**
	 * Template
	 */
	public template = (): HTMLElement => (

		<div class="h-expand">

			<span id="appWidePopupNotification"></span>

			<route-frame class="h-expand" />
			
		</div>
	);
}