import { h } from "../../../../tracejs/src/utils/JSXFactory";
import { injectable } from "tsyringe";
import { BaseViewModel } from "../../common/BaseViewModel";

/**
 * Accept or Deny connection requests
 */
@injectable()
export class Connect extends BaseViewModel<any>
{

    private vaz: any;


    private denied: KnockoutObservable<boolean> = ko.observable(false);

    private accepted: KnockoutObservable<boolean> = ko.observable(false); 

    private badRequest: KnockoutObservable<boolean> = ko.observable(false);

    /**
     * Startup
     */
    public async startup(): Promise<any>
    {
        await super.startup();
        let params = this.router.query;
        
        if(params.action !== 'deny' && params.action !== 'accept') {

            this.badRequest(true);
        }
        else if(!params.token) {

            this.badRequest(true);
        }
        else {

            this.vaz = await this.rpc.call('subject.getOneVazByToken', { token: params.token }).catch(() => {
                this.badRequest(true);
            });

            if(this.vaz && params.action === 'accept') {
                await this.rpc.call('subject.acceptVazByToken', { token: params.token });
                this.accepted(true);
            }
            else if(this.vaz && params.action === 'deny') {
                await this.rpc.call('subject.denyVazByToken', { token: params.token });
                this.denied(true);
            }
        }
    }

    /**
     * Template
     */
	public template = (): HTMLElement => (

		<div class="centered-form container h-expand d-flex flex-column justify-content-center">
			<div class="row justify-content-md-center">
				<div style="max-width: 450px">
                    <div class="panel">

                        <ko if="$root.badRequest">
                            <div class="alert alert-danger mb-0" role="alert" data-bind="i18n: 'system.subjects.linkExpired'"></div>
                        </ko>
                        
                        <ko if="$root.accepted">
                            <div class="alert alert-success mb-0" role="alert" data-bind="i18n: 'system.subjects.connectionRequestApproved'"></div>
                        </ko>
                        
                        <ko if="$root.denied">
                            <div class="alert alert-info mb-0" role="alert" data-bind="i18n: 'system.subjects.connectionRequestRejected'"></div>
                        </ko>

                    </div>
                </div>
            </div>
        </div>
	);

}