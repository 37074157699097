﻿import { BaseViewModel } from "../common/BaseViewModel";

import { h } from "../../../tracejs/src/utils/JSXFactory";

import { injectable } from "tsyringe";


import { CultureSwitch } from "../components/CultureSwitch";
import { i18n } from "i18next-ko";
import { SyncEvent } from "ts-events";
import { IIdentity } from "../../../tracejs/src/security/IIdentity";

@injectable()
export class Login extends BaseViewModel<any> {

	/// Username
	private username: KnockoutObservable<string> = ko.observable('');
	/// Password
	private password: KnockoutObservable<string> = ko.observable('');
	/// Remember password
	private remember: KnockoutObservable<boolean> = ko.observable(true);
	// Error message
	private errorMessage: KnockoutObservable<string> = ko.observable('');
	
	public onForgotClicked: SyncEvent<string> = new SyncEvent<string>();
	public onRegisterClicked: SyncEvent<string> = new SyncEvent<string>();
	public onLoggedIn: SyncEvent<IIdentity> = new SyncEvent<IIdentity>();

	/**
	 * Startup
	 */
	public async startup()
	{
		await super.startup();

		// if (this.router.params.token) {
		// 	this.registration(this.router.params.token);
		// }
	}
	
	/**
	 * User Login
	 */
	private async loginFormSubmitted()
	{
		try {
			let identity = await this.user.login(this.username(), this.password(), this.remember())
			this.onLoggedIn.post(identity);
		}
		catch(response) {
			this.flash.error(i18n.t('login.errorCode' + response.error.code));
		}
	}

	/**
	 * Show dialog with registration
	 **/
	private registration(token: string = null)
	{
		this.onRegisterClicked.post(token);
		/*(<any>this.loadViewFrame('System.Settings.User.Registration', [token])).done((vm: System.Settings.User.Registration, el: JQuery) => {
			el.kendoWindow({
				deactivate: () => { el.data('kendoWindow').destroy(); },
				actions: ['Close'],
				modal: true,
				visible: false,
				title: $.i18n.t('registration.registration'),
				resizable: true,
				width: vm.sizeRequest.width,
				height: vm.sizeRequest.height,
				open: (e) => {
					this.windowButtons(e.sender.element, [{
						align: 'right',
						label: $.i18n.t('common.actions.close'),
						click: () => {
							el.data('kendoWindow').close();
						}
					}, {
						align: 'right',
						label: $.i18n.t('common.actions.save'),
						click: () => {
							vm.register().done((newUser) => {
								el.data('kendoWindow').close();
								//redirect to login without parameter token
								this.router.setParams(null, true);
								this.router.routeTo(0, 'login');
							}).fail((error: any) => {
								if (typeof error === 'string') {
									alert(error);
								}
							});;
						}
					}]);
				}
			}).data('kendoWindow').center().open();
		});*/
	}

	private forgotPassword()
	{
		this.onForgotClicked.post(this.username());
		// let userLogin = jQuery.trim(this.username());
		// if(userLogin !== '') {
		// 	kendo.confirm(i18n.t('login.forgot.confirm.message')).done(async () => {

		// 		let response: any = await this.rpc.call('user.forgottenPassword', { userLogin: userLogin, culture: this.culture.localeShort });

		// 		if (response.code == '200') {
		// 			alert(i18n.t('login.emailSend'));
		// 		} else {
		// 			alert(i18n.t('login.userNotExist'));
		// 		}

		// 	});
		// }
		// else {
		// 	kendo.alert(i18n.t('login.fillLoginName'));
		// }
	}

	public async rendered()
	{
		await this.loadViewFrame<CultureSwitch>(CultureSwitch, 'cultures');
	}


	public template = (): HTMLElement => (

		<div class="centered-form container h-expand d-flex flex-column justify-content-center">
			<div class="row justify-content-md-center">
				<div style="max-width: 450px">

					<view-frame name="cultures" class="float-end" />

					<h1 class="mb-2 text-primary">TRACEDO</h1>

					<div class="panel radius panel-big-margin p-4 pb-2">

						<h2 data-bind="i18n: 'login.userLogin'" class="mb-3"></h2>

						<form method="post" data-bind="submit: $root.loginFormSubmitted">

							<ko if="$root.errorMessage() !== ''">
								<div class="alert-box alert radius" data-bind="text: $data"></div>
							</ko>

							<div class="mb-3">
								<label for="login-user" class="form-label" data-bind="i18n: 'login.user'"></label>
								<input type="text" id="login-user" data-bind="value: $root.username" class="form-control" />
							</div>

							<div class="mb-3">
								<label for="login-password" class="form-label" data-bind="i18n: 'login.password'"></label>
								<input type="password" id="login-password" data-bind="value: $root.password" class="form-control" />
							</div>

							<div class="row mb-3">
								<div class="col">
									<label><input type="checkbox" data-bind="checked: $root.remember" /> <span data-bind="i18n: 'login.permanentLogin'"></span></label>
								</div>
							</div>

							<div class="mb-3">
								<button type="submit" class="btn btn-primary d-block w-100 btn-lg" data-bind="i18n: 'login.login'"></button>
							</div>							

							<div class="row mb-3">
								<div class="col">
									{/*<button type="button" class="btn btn-link" data-bind="i18n: 'registration.registration', click: $root.registration.bind($root, null)"></button>*/}
								</div>
								<div class="col-auto">
									<button type="button" class="btn btn-link" data-bind="i18n: 'login.forgottenPassword', click: $root.forgotPassword.bind($root, null)"></button>
								</div>
							</div>

						</form>

					</div>

				</div>
			</div>
		</div>

	);

}
